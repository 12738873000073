import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-replacement-meter',
  templateUrl: './replacement-meter.component.html',
  styleUrls: ['./replacement-meter.component.css']
})
export class ReplacementMeterComponent implements OnInit {

  @Input() speed: number;

  constructor() { }

  ngOnInit() {
  }

}
