import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {selectQuestions} from '../../../store/catalog/question/question.reducer';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {SwitchLearningMode} from '../../../store/habitat/content-card.actions';
import {LearningMode} from '../../../store/habitat/content-card.model';
import {Score} from '../../../store/score/score.model';
import {Answer} from '../../../store/answer/answer.model';
import {Tour} from '../../../store/tour/tour.model';
import {Theme} from '../../../store/catalog/theme/theme.model';
import {selectThemes} from '../../../store/catalog/theme/theme.reducer';
import {Content} from '../../../store/catalog/content/content.model';
import {LocalResponse} from '../../../store/catalog/response/response.model';
import {Observable} from 'rxjs';
import {UpdateSpot} from '../../../store/spot/spot.actions';
import {User, UserHelper} from '../../../store/user/user.model';
import {selectUser} from '../../../store/user/user.reducer';
import {selectScores} from '../../../store/score/score.reducer';
import {selectAnswers} from '../../../store/answer/answer.reducer';
import {selectLearningMode} from '../../../store/habitat/content-card.reducer';
import {selectSelectedSpotOfSelectedTour} from '../../../store/spot/spot.reducer';
import {Side} from '../../../store/global/global.model';
import {LocalQuestion, Question} from '../../../store/catalog/question/question.model';
import {Spot} from '../../../store/spot/spot.model';
import {selectTourThematics} from '../../../store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from '../../../store/tour-thematic/tour-thematic.model';
import {selectSelectedTour} from '../../../store/tour/tour.reducer';


@Component({
  selector: 'app-determination-mode',
  templateUrl: './determination-mode.component.html',
  styleUrls: ['./determination-mode.component.css']
})
export class DeterminationModeComponent implements OnInit {

  public themes: Theme[];
  public subThemes: SubTheme[] = [];
  public questions: Question[] = [];

  public spot: Spot;
  public spot$: Observable<Spot>;
  public currentSubTheme: SubTheme;

  public answers$: Observable<Answer[]>;

  public questionType: LocalQuestion;
  public questionGroup: LocalQuestion;
  public questionForm: LocalQuestion;
  public isFormResponded = false;
  public isGroupResponded = false;

  public show = false;
  public justOpen = true;


  public tourThematic: TourThematic = null;
  public selectedTour: Tour;


  public scores: Score[]  = [];
  public answers: Answer[]  = [];
  public user: User;
  public UserHelper = UserHelper;
  public learningMode: LearningMode;

  constructor(private store: Store<any>) { }

  ngOnInit() {

    this.store.select(selectScores).subscribe((scores) => {
      this.scores = scores;
    });

    this.store.select(selectSelectedTour(Side.Left)).subscribe((tour) => {
      this.selectedTour = tour;
    });

    this.store.select(selectTourThematics).subscribe((tourThematics) => {
      this.tourThematic = tourThematics.find(x => +this.selectedTour.id == x.tourId);
    });



    this.store.select(selectLearningMode).subscribe((learningMode) => {
      this.learningMode = learningMode;
    });

    this.store.select(selectQuestions).subscribe(questions => {
      this.questions = questions.filter(x => x.determination);
    });



    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });

    this.store.select(selectSubThemes).subscribe(subThemes => {
      this.subThemes = subThemes;
    });

    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
    });



    this.spot$ = this.store.select(selectSelectedSpotOfSelectedTour(Side.Left));
    this.store
    .select(selectSelectedSpotOfSelectedTour(Side.Left))
    .subscribe( selectedSpot => {
      this.spot = null;
      this.spot = selectedSpot;
      this.questionType = null;
      this.questionGroup = null;
      this.questionForm = null;
      this.isGroupResponded = false;
      this.isFormResponded = false;

      this.show = this.getSpotScore() &&
        this.isScoreCorrect();
      if (this.show) {
        this.isGroupResponded = true;
        this.isFormResponded = true;
      }

      this.getQuestionType();
      this.getQuestionGroup();
      this.getQuestionForm();

      this.currentSubTheme = this.subThemes
                                  .find(x =>  this.spot.subThemeId == x.id);
    });

    this.answers$ = this.store.select(selectAnswers);

    this.store.select(selectAnswers).subscribe((answers) => {
      this.answers = answers;
      this.isGroupResponded = this.checkResponded(-2);
      this.isFormResponded = (this.checkResponded(-1));

      if (this.getSpotScore()) {
        this.store
        .dispatch(new UpdateSpot({spot: {id: this.spot.id, changes: this.spot}}));
      }

      if (this.justOpen) {
        this.isGroupResponded = false;
        this.isFormResponded = false;
        this.show = this.getSpotScore()
          && this.isScoreCorrect();
        if (this.show) {
          this.isGroupResponded = true;
          this.isFormResponded = true;
        }
        this.justOpen = false;
      }
      this.getQuestionType();
      this.getQuestionGroup();
      this.getQuestionForm();
    });
  }

  returnToGrid() {
    this.store.dispatch(new SwitchLearningMode({learningMode: LearningMode.Determination}));
  }

  getTheme() {
    let theme;
    if (this.tourThematic != null) {
      theme = this.themes.find(x => x.id == this.tourThematic.themeId);
    }
    return theme;
  }

  getQuestionType() {
    const questionType = new LocalQuestion();
    this.questionType = null;
    questionType.id = -3;

    const content = new Content();
    content.id = -3;
    content.textFr = 'De quel type s\'agit-il ?';
    content.textDe = 'Welcher Typ ist es?';
    content.textEn = 'Which type is it ?';

    questionType.content = content;

    const responses: LocalResponse[] = [];

    // for each subTheme (level 2) of the current theme
    this.subThemes.filter(x =>  this.tourThematic.themeId == x.themeId &&this.getGroup().id == x.superThemeId && 2 == x.level).forEach((x) => {
      const localResponse = new LocalResponse();
      localResponse.correct = +this.getType().id == x.id;

      const respContent = new Content();
      respContent.textFr = x.nameFr;
      respContent.textDe = x.nameDe;
      respContent.textEn = x.nameEn;

      localResponse.content = respContent;

      responses.push(localResponse);
    });

    questionType.responses = responses;

    this.questionType = questionType;
    return this.questionType;
  }

  getQuestionGroup() {
    const questionGroup = new LocalQuestion();
    this.questionGroup = null;
    questionGroup.id = -2;
    const content = new Content();
    content.id = -2;
    content.textEn = 'Which group is it ?';
    content.textDe = 'Welche Gruppe ist es?';
    content.textFr = 'De quel groupe s\'agit-il ?';

    questionGroup.content = content;

    const responses: LocalResponse[] = [];

    // for each subTheme (level 1) of the current theme
    this.subThemes.filter(x => this.tourThematic.themeId == x.themeId  && this.getForm().id == x.superThemeId && x.level == 1).forEach((x) => {
      const localResponse = new LocalResponse();
      localResponse.correct = x.id == +this.getGroup().id;
      const respContent = new Content();
      respContent.textFr = x.nameFr;
      respContent.textEn = x.nameEn;
      respContent.textDe = x.nameDe;
      localResponse.content = respContent;
      responses.push(localResponse);
    });

    questionGroup.responses = responses;

    this.questionGroup = questionGroup;
    return this.questionGroup;
  }

  getQuestionForm() {
    this.questionForm = null;
    const questionForm = new LocalQuestion();
    questionForm.id = -1;

    const content = new Content();
    content.id = -1;
    content.textFr = 'De quelle forme s\'agit-il ?';
    content.textDe = 'Welche Form ist es?';
    content.textEn = 'Which form is it ?';

    questionForm.content = content;

    const responses: LocalResponse[] = [];

    // for each subTheme (level 0) of the current theme
    this.subThemes.filter(x => this.tourThematic.themeId == x.themeId && 0 == +x.level).forEach((x) => {
      const localResponse = new LocalResponse();
      localResponse.correct = x.id == +this.getForm().id;
      const respContent = new Content();
      respContent.textFr = x.nameFr;
      respContent.textEn = x.nameEn;
      respContent.textDe = x.nameDe;

      localResponse.content = respContent;
      responses.push(localResponse);
    });

    questionForm.responses = responses;

    this.questionForm = questionForm;
    return this.questionForm;
  }

  getSpotScore() {
    if (this.spot == null) {
      return false;
    }
    const score = this.scores.find(x => +this.spot.id == x.spotId && this.learningMode == x.modeId && this.user.id == x.userId);

    if (score == null) {
      return false;
    } else {
      return this.answers.filter(x => score.id == x.scoreId).length == 3 /*|| (this.answers.filter(x => x.scoreId == score.id).length != this.answers.filter(x => x.scoreId == score.id && x.correct).length )*/;
    }
  }

  isScoreCorrect() {
    const spotScore = this.scores.find(x => +this.spot.id == x.spotId && this.learningMode == x.modeId && this.user.id == x.userId);
    if (spotScore != null) {
      return this.answers.filter(x => spotScore.id == x.scoreId && x.correct).length >= 3;
    } else {
      return false;
    }
  }

  getType() {
    return this.subThemes.find(x => this.spot.subThemeId == x.id);
  }

  getGroup() {
    const typ = this.getType();
    if (typ == null) {
      return null;
    }

    return this.subThemes.find(x => typ.superThemeId == x.id);
  }

  getForm() {
    const group = this.getGroup();
    if (group == null) {
      return null;
    }
    return this.subThemes.find(x => x.id == group.superThemeId);
  }

  checkResponded(scoreId: number) {
    let res = false;

    const spotScore = this.scores.find(x => +this.spot.id == x.spotId && this.learningMode == x.modeId);
    if (spotScore != null) {
      res =  this.answers.filter(x => spotScore.id == x.scoreId && scoreId == x.questionId).length > 0;
    }

    return res;
  }

}
