import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Side} from '../../store/global/global.model';
import {SetSelectedTour} from '../../store/global/global.action';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Tour} from '../../store/tour/tour.model';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {selectBarSize, selectTreeMapMode} from '../../store/global/global.reducer';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourComponent implements OnInit {
  public Side = Side;
  public dualMode = false;
  public treeMapMode = false;
  public tour$: Observable<Tour>;
  public size: number;

  constructor(private route: ActivatedRoute, private store: Store<any>) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const tourId = params.get('id');
      this.store.dispatch(new SetSelectedTour({side: Side.Left, tourId: tourId}));
    });

    this.tour$ = this.store.select(selectSelectedTour(Side.Left));
    this.store.select(selectBarSize).subscribe((barSize) => {
      this.size = barSize;
    });
    this.store.select(selectTreeMapMode).subscribe((treeMapMode) => {
      this.treeMapMode = treeMapMode;
    });
  }

}
