import {Component, OnInit} from '@angular/core';
import {SwitchLearningMode} from '../../../store/habitat/content-card.actions';
import {Store} from '@ngrx/store';
import {LearningMode} from '../../../store/habitat/content-card.model';
import {Content} from '../../../store/catalog/content/content.model';
import {BackendService} from '../../../services/backend.service';
import {selectMedias} from '../../../store/catalog/media/media.reducer';
import {Spot} from '../../../store/spot/spot.model';
import {Media} from '../../../store/catalog/media/media.model';
import {selectSelectedSpotOfSelectedTour} from '../../../store/spot/spot.reducer';
import {Side} from '../../../store/global/global.model';
import {selectContents} from '../../../store/catalog/content/content.reducer';
import {Question} from '../../../store/catalog/question/question.model';
import {selectQuestions} from '../../../store/catalog/question/question.reducer';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {Score} from '../../../store/score/score.model';
import {Answer} from '../../../store/answer/answer.model';
import {selectScores} from '../../../store/score/score.reducer';
import {selectAnswers} from '../../../store/answer/answer.reducer';
import {selectUser} from '../../../store/user/user.reducer';
import {User, UserHelper} from '../../../store/user/user.model';
import {selectLearningMode} from '../../../store/habitat/content-card.reducer';
import {TranslateService} from '@ngx-translate/core';
import {translation} from '../../../app.translation';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-discovery-mode',
  templateUrl: './discovery-mode.component.html',
  styleUrls: ['./discovery-mode.component.css']
})
export class DiscoveryModeComponent implements OnInit {

  public contents: Content[] = [];
  public subThemes: SubTheme[] = [];
  public medias: Media[] = [];
  public questions: Question[] = [];
  public spot: Spot;
  public currentSubTheme: SubTheme;

  public more = false;

  public descriptionContent: Content;
  public additionalContent: Content;
  public goodToKnowContent: Content;
  public associatedSpeciesContent: Content;
  public inventoryLevelContent: Content;
  public otherInfoContent: Content;
  public naturalFrequency: number;
  public operatedFrequency: number;
  public speedReplacement: number;
  public question: Question;

  public scores: Score[]  = [];
  public answers: Answer[]  = [];
  public answers$: Observable<Answer[]>;
  public user: User;
  public UserHelper = UserHelper;
  public learningMode: LearningMode;

  constructor(private store: Store<any>, private backendService: BackendService, private translate: TranslateService) { }

  ngOnInit() {

    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
    });

    this.store.select(selectScores).subscribe((scores) => {
      this.scores = scores;
    });

    this.answers$ = this.store.select(selectAnswers);
    this.store.select(selectAnswers).subscribe((answers) => {

      this.answers = answers;
    });

    this.store.select(selectLearningMode).subscribe((learningMode) => {
      this.learningMode = learningMode;
    });

    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias;
    });
    this.store.select(selectContents).subscribe(contents => {
      this.contents = contents;
    });
    this.store.select(selectQuestions).subscribe(questions => {
      this.questions = questions.filter(x => !x.determination);
    });
    this.store.select(selectSubThemes).subscribe(subThemes => {
      this.subThemes = subThemes;
    });

    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe( selectedSpot => {
      this.spot = selectedSpot;
      this.question = null;


      this.descriptionContent = null;
      this.additionalContent = null;
      this.goodToKnowContent = null;
      this.associatedSpeciesContent = null;
      this.inventoryLevelContent = null;
      this.otherInfoContent = null;

      // updating content
      this.additionalContent = this.contents.find(x => x.id == this.spot.contentId);

      this.currentSubTheme = this.subThemes.find(x => x.id == this.spot.subThemeId);

      if (this.currentSubTheme != null) {
        this.descriptionContent = this.contents.find(x => x.id == this.currentSubTheme.descriptionContentId);
        this.goodToKnowContent = this.contents.find(x => x.id == this.currentSubTheme.goodToKnowContentId);
        this.associatedSpeciesContent = this.contents.find(x => x.id == this.currentSubTheme.associatedSpeciesContentId);
        this.inventoryLevelContent = this.contents.find(x => x.id == this.currentSubTheme.inventoryLevelContentId);
        this.otherInfoContent = this.contents.find(x => x.id == this.currentSubTheme.otherInfoContentId);
        this.naturalFrequency = this.currentSubTheme.naturalFrequency;
        this.operatedFrequency = this.currentSubTheme.operatedFrequency;
        this.speedReplacement = this.currentSubTheme.replacementSpeed;
        // this.question = this.questions.filter(x => x.subThemeId == this.currentSubTheme.id)[0];
        this.getQuestion();
      }

    });
  }

  returnToGrid() {
    this.store.dispatch(new SwitchLearningMode({learningMode: LearningMode.Discovery}));
  }

  getFormIconUrl() {
    const type = this.subThemes.find(x => x.level == 2 && x.id == this.spot.subThemeId);
    const group = this.subThemes.find(x => x.level == 1 && x.id == type.superThemeId);
    if ( type != null && group != null && this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId).nameEn != null) {
      return '"/assets/images/spots/' + this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId).iconUrl + '.png"';
    } else {
      return '"/assets/images/spots/empty.png"';
    }
  }

  getFormName() {
    const type = this.subThemes.find(x => x.level == 2 && x.id == this.spot.subThemeId);
    if (type == null) {
      return null;
    }

    const group = this.subThemes.find(x => x.level == 1 && x.id == type.superThemeId);
    if (group == null) {
      return null;
    }

    // Translation
    const form = this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId);
    const formName = translation(form, 'name', this.translate);
    if (formName != null) {
      return formName;
    } else {
      return null;
    }
  }

  getGroupName() {
    const type = this.subThemes.find(x => x.level == 2 && x.id == this.spot.subThemeId);
    return translation(this.subThemes.find(x => x.level == 1 && x.id == type.superThemeId), 'name', this.translate);
  }

  getTypeName() {
    return translation(this.subThemes.find(x => x.level == 2 && x.id == this.spot.subThemeId), 'name', this.translate);
  }

  getQuestion() {

    if (this.question == null) {
      const list = this.questions.filter(x => x.subThemeId == this.spot.subThemeId);
      const notEvenAnsweredQuestions = list.filter(x => !this.isThisQuestionAlreadyAnswered(x));


      if (notEvenAnsweredQuestions.length > 0) {
        const random = Math.round(Math.random() * (notEvenAnsweredQuestions.length - 1));
        this.question = notEvenAnsweredQuestions[random];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  isThisQuestionAlreadyAnswered(question: Question) {
    const userScores = this.scores.filter(x => x.userId == this.user.id);
    const userAnswers = this.answers.filter(x => x.correct && x.questionId == question.id && userScores.find(y => y.id == x.scoreId) != null);


    return userAnswers.length > 0;
  }

  isScoreCorrect() {
    const spotScore = this.scores.find(x => x.spotId == +this.spot.id && x.modeId == this.learningMode && x.userId == this.user.id);
    if (spotScore != null) {
      return this.answers.filter(x => x.scoreId == spotScore.id && x.correct).length > 0;
    } else {
      return false;
    }
  }

  showPointBanner() {
    const spotScore = this.scores.find(x => x.spotId == +this.spot.id && x.modeId == this.learningMode && x.userId == this.user.id);
    if (spotScore != null) {
      return this.isScoreCorrect();
    } else {
      return null;
    }
  }

  isDescription() {
    if (this.descriptionContent == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.descriptionContent.textDe != null && this.descriptionContent.textDe != '';
        break;
      case 'fr':
        result = this.descriptionContent.textFr != null && this.descriptionContent.textFr != '';
        break;
      case 'en':
        result = this.descriptionContent.textEn != null && this.descriptionContent.textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.descriptionContent.id).length > 0;
    return result;
  }

  isAdditionalContent() {
    if (this.additionalContent == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.additionalContent.textDe != null && this.additionalContent.textDe != '';
        break;
      case 'fr':
        result = this.additionalContent.textFr != null && this.additionalContent.textFr != '';
        break;
      case 'en':
        result = this.additionalContent.textEn != null && this.additionalContent.textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.additionalContent.id).length > 0;
    return result;
  }

  isGoodToKnow() {
    if (this.goodToKnowContent == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.goodToKnowContent.textDe != null && this.goodToKnowContent.textDe != '';
        break;
      case 'fr':
        result = this.goodToKnowContent.textFr != null && this.goodToKnowContent.textFr != '';
        break;
      case 'en':
        result = this.goodToKnowContent.textEn != null && this.goodToKnowContent.textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.goodToKnowContent.id).length > 0;
    return result;
  }


  isMoreContent() {
    return this.isAssociatedSpecies() || this.isOtherInfo() || this.isInventoryLevel();
  }

  isAssociatedSpecies() {
    if (this.associatedSpeciesContent == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.associatedSpeciesContent.textDe != null && this.associatedSpeciesContent.textDe != '';
        break;
      case 'fr':
        result = this.associatedSpeciesContent.textFr != null && this.associatedSpeciesContent.textFr != '';
        break;
      case 'en':
        result = this.associatedSpeciesContent.textEn != null && this.associatedSpeciesContent.textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.associatedSpeciesContent.id).length > 0;
    return result;
  }

  isInventoryLevel() {
    if (this.inventoryLevelContent == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.inventoryLevelContent.textDe != null && this.inventoryLevelContent.textDe != '';
        break;
      case 'fr':
        result = this.inventoryLevelContent.textFr != null && this.inventoryLevelContent.textFr != '';
        break;
      case 'en':
        result = this.inventoryLevelContent.textEn != null && this.inventoryLevelContent.textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.inventoryLevelContent.id).length > 0;
    return result;
  }

  isOtherInfo() {
    if (this.otherInfoContent == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.otherInfoContent.textDe != null && this.otherInfoContent.textDe != '';
        break;
      case 'fr':
        result = this.otherInfoContent.textFr != null && this.otherInfoContent.textFr != '';
        break;
      case 'en':
        result = this.otherInfoContent.textEn != null && this.otherInfoContent.textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.otherInfoContent.id).length > 0;
    return result;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async scroll(el: HTMLElement) {
    await this.delay(300);
    el.scrollIntoView();
  }

}
