import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Answer } from './answer.model';

export enum AnswerActionTypes {
  LoadAnswers = '[Answer] Load Answers',
  AddAnswer = '[Answer] Add Answer',
  UpsertAnswer = '[Answer] Upsert Answer',
  AddAnswers = '[Answer] Add Answers',
  UpsertAnswers = '[Answer] Upsert Answers',
  UpdateAnswer = '[Answer] Update Answer',
  UpdateAnswers = '[Answer] Update Answers',
  DeleteAnswer = '[Answer] Delete Answer',
  DeleteAnswers = '[Answer] Delete Answers',
  ClearAnswers = '[Answer] Clear Answers'
}

export class LoadAnswers implements Action {
  readonly type = AnswerActionTypes.LoadAnswers;

  constructor(public payload: { answers: Answer[] }) {}
}

export class AddAnswer implements Action {
  readonly type = AnswerActionTypes.AddAnswer;

  constructor(public payload: { answer: Answer }) {}
}

export class UpsertAnswer implements Action {
  readonly type = AnswerActionTypes.UpsertAnswer;

  constructor(public payload: { answer: Answer }) {}
}

export class AddAnswers implements Action {
  readonly type = AnswerActionTypes.AddAnswers;

  constructor(public payload: { answers: Answer[] }) {}
}

export class UpsertAnswers implements Action {
  readonly type = AnswerActionTypes.UpsertAnswers;

  constructor(public payload: { answers: Answer[] }) {}
}

export class UpdateAnswer implements Action {
  readonly type = AnswerActionTypes.UpdateAnswer;

  constructor(public payload: { answer: Update<Answer> }) {}
}

export class UpdateAnswers implements Action {
  readonly type = AnswerActionTypes.UpdateAnswers;

  constructor(public payload: { answers: Update<Answer>[] }) {}
}

export class DeleteAnswer implements Action {
  readonly type = AnswerActionTypes.DeleteAnswer;

  constructor(public payload: { id: string }) {}
}

export class DeleteAnswers implements Action {
  readonly type = AnswerActionTypes.DeleteAnswers;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearAnswers implements Action {
  readonly type = AnswerActionTypes.ClearAnswers;
}

export type AnswerActions =
 LoadAnswers
 | AddAnswer
 | UpsertAnswer
 | AddAnswers
 | UpsertAnswers
 | UpdateAnswer
 | UpdateAnswers
 | DeleteAnswer
 | DeleteAnswers
 | ClearAnswers;
