import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TreeType } from './tree-type.model';

export enum TreeTypeActionTypes {
  LoadTreeTypes = '[TreeType] Load TreeTypes',
  AddTreeType = '[TreeType] Add TreeType',
  UpsertTreeType = '[TreeType] Upsert TreeType',
  AddTreeTypes = '[TreeType] Add TreeTypes',
  UpsertTreeTypes = '[TreeType] Upsert TreeTypes',
  UpdateTreeType = '[TreeType] Update TreeType',
  UpdateTreeTypes = '[TreeType] Update TreeTypes',
  DeleteTreeType = '[TreeType] Delete TreeType',
  DeleteTreeTypes = '[TreeType] Delete TreeTypes',
  ClearTreeTypes = '[TreeType] Clear TreeTypes'
}

export class LoadTreeTypes implements Action {
  readonly type = TreeTypeActionTypes.LoadTreeTypes;

  constructor(public payload: { treeTypes: TreeType[] }) {}
}

export class AddTreeType implements Action {
  readonly type = TreeTypeActionTypes.AddTreeType;

  constructor(public payload: { treeType: TreeType }) {}
}

export class UpsertTreeType implements Action {
  readonly type = TreeTypeActionTypes.UpsertTreeType;

  constructor(public payload: { treeType: TreeType }) {}
}

export class AddTreeTypes implements Action {
  readonly type = TreeTypeActionTypes.AddTreeTypes;

  constructor(public payload: { treeTypes: TreeType[] }) {}
}

export class UpsertTreeTypes implements Action {
  readonly type = TreeTypeActionTypes.UpsertTreeTypes;

  constructor(public payload: { treeTypes: TreeType[] }) {}
}

export class UpdateTreeType implements Action {
  readonly type = TreeTypeActionTypes.UpdateTreeType;

  constructor(public payload: { treeType: Update<TreeType> }) {}
}

export class UpdateTreeTypes implements Action {
  readonly type = TreeTypeActionTypes.UpdateTreeTypes;

  constructor(public payload: { treeTypes: Update<TreeType>[] }) {}
}

export class DeleteTreeType implements Action {
  readonly type = TreeTypeActionTypes.DeleteTreeType;

  constructor(public payload: { id: string }) {}
}

export class DeleteTreeTypes implements Action {
  readonly type = TreeTypeActionTypes.DeleteTreeTypes;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTreeTypes implements Action {
  readonly type = TreeTypeActionTypes.ClearTreeTypes;
}

export type TreeTypeActions =
 LoadTreeTypes
 | AddTreeType
 | UpsertTreeType
 | AddTreeTypes
 | UpsertTreeTypes
 | UpdateTreeType
 | UpdateTreeTypes
 | DeleteTreeType
 | DeleteTreeTypes
 | ClearTreeTypes;
