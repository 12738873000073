import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TourCategory} from './tour-category.model';
import {TourCategoryActions, TourCategoryActionTypes} from './tour-category.actions';
import {createSelector} from '@ngrx/store';
import {TourCanopyCoverDegree} from '../tour-canopy-cover-degree/tour-canopy-cover-degree.model';

export interface State extends EntityState<TourCategory> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TourCategory> = createEntityAdapter<TourCategory>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TourCategoryActions
): State {
  switch (action.type) {
    case TourCategoryActionTypes.AddTourCategory: {
      return adapter.addOne(action.payload.tourCategory, state);
    }

    case TourCategoryActionTypes.UpsertTourCategory: {
      return adapter.upsertOne(action.payload.tourCategory, state);
    }

    case TourCategoryActionTypes.AddTourCategories: {
      return adapter.addMany(action.payload.tourCategories, state);
    }

    case TourCategoryActionTypes.UpsertTourCategories: {
      return adapter.upsertMany(action.payload.tourCategories, state);
    }

    case TourCategoryActionTypes.UpdateTourCategory: {
      return adapter.updateOne(action.payload.tourCategory, state);
    }

    case TourCategoryActionTypes.UpdateTourCategories: {
      return adapter.updateMany(action.payload.tourCategories, state);
    }

    case TourCategoryActionTypes.DeleteTourCategory: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourCategoryActionTypes.DeleteTourCategories: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourCategoryActionTypes.LoadTourCategories: {
      return adapter.addAll(action.payload.tourCategories, state);
    }

    case TourCategoryActionTypes.ClearTourCategories: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TourCanopyCoverDegree> => state.root.tourCategories;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);


export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
