import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Answer} from './answer.model';
import {AnswerActions, AnswerActionTypes} from './answer.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<Answer> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Answer> = createEntityAdapter<Answer>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: AnswerActions
): State {
  switch (action.type) {
    case AnswerActionTypes.AddAnswer: {
      return adapter.addOne(action.payload.answer, state);
    }

    case AnswerActionTypes.UpsertAnswer: {
      return adapter.upsertOne(action.payload.answer, state);
    }

    case AnswerActionTypes.AddAnswers: {
      return adapter.addMany(action.payload.answers, state);
    }

    case AnswerActionTypes.UpsertAnswers: {
      return adapter.upsertMany(action.payload.answers, state);
    }

    case AnswerActionTypes.UpdateAnswer: {
      return adapter.updateOne(action.payload.answer, state);
    }

    case AnswerActionTypes.UpdateAnswers: {
      return adapter.updateMany(action.payload.answers, state);
    }

    case AnswerActionTypes.DeleteAnswer: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AnswerActionTypes.DeleteAnswers: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case AnswerActionTypes.LoadAnswers: {
      return adapter.addAll(action.payload.answers, state);
    }

    case AnswerActionTypes.ClearAnswers: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Answer> => state.root.answers;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectAnswers = createSelector(selectAll, entities => entities);
