import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Tour } from './tour.model';

export enum TourActionTypes {
  LoadTours = '[Tour] Load Tours',
  AddTour = '[Tour] Add Tour',
  UpsertTour = '[Tour] Upsert Tour',
  AddTours = '[Tour] Add Tours',
  UpsertTours = '[Tour] Upsert Tours',
  UpdateTour = '[Tour] Update Tour',
  UpdateTours = '[Tour] Update Tours',
  DeleteTour = '[Tour] Delete Tour',
  DeleteTours = '[Tour] Delete Tours',
  ClearTours = '[Tour] Clear Tours'
}

export class LoadTours implements Action {
  readonly type = TourActionTypes.LoadTours;

  constructor(public payload: { tours: Tour[] }) {}
}

export class AddTour implements Action {
  readonly type = TourActionTypes.AddTour;

  constructor(public payload: { tour: Tour }) {}
}

export class UpsertTour implements Action {
  readonly type = TourActionTypes.UpsertTour;

  constructor(public payload: { tour: Tour }) {}
}

export class AddTours implements Action {
  readonly type = TourActionTypes.AddTours;

  constructor(public payload: { tours: Tour[] }) {}
}

export class UpsertTours implements Action {
  readonly type = TourActionTypes.UpsertTours;

  constructor(public payload: { tours: Tour[] }) {}
}

export class UpdateTour implements Action {
  readonly type = TourActionTypes.UpdateTour;

  constructor(public payload: { tour: Update<Tour> }) {}
}

export class UpdateTours implements Action {
  readonly type = TourActionTypes.UpdateTours;

  constructor(public payload: { tours: Update<Tour>[] }) {}
}

export class DeleteTour implements Action {
  readonly type = TourActionTypes.DeleteTour;

  constructor(public payload: { id: string }) {}
}

export class DeleteTours implements Action {
  readonly type = TourActionTypes.DeleteTours;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTours implements Action {
  readonly type = TourActionTypes.ClearTours;
}

export type TourActions =
 LoadTours
 | AddTour
 | UpsertTour
 | AddTours
 | UpsertTours
 | UpdateTour
 | UpdateTours
 | DeleteTour
 | DeleteTours
 | ClearTours;
