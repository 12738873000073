import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Spot } from './spot.model';

export enum SpotActionTypes {
  LoadSpots = '[Spot] Load Spots',
  AddSpot = '[Spot] Add Spot',
  UpsertSpot = '[Spot] Upsert Spot',
  AddSpots = '[Spot] Add Spots',
  UpsertSpots = '[Spot] Upsert Spots',
  UpdateSpot = '[Spot] Update Spot',
  UpdateSpots = '[Spot] Update Spots',
  DeleteSpot = '[Spot] Delete Spot',
  DeleteSpots = '[Spot] Delete Spots',
  ClearSpots = '[Spot] Clear Spots'
}

export class LoadSpots implements Action {
  readonly type = SpotActionTypes.LoadSpots;

  constructor(public payload: { spots: Spot[] }) {}
}

export class AddSpot implements Action {
  readonly type = SpotActionTypes.AddSpot;

  constructor(public payload: { spot: Spot }) {}
}

export class UpsertSpot implements Action {
  readonly type = SpotActionTypes.UpsertSpot;

  constructor(public payload: { spot: Spot }) {}
}

export class AddSpots implements Action {
  readonly type = SpotActionTypes.AddSpots;

  constructor(public payload: { spots: Spot[] }) {}
}

export class UpsertSpots implements Action {
  readonly type = SpotActionTypes.UpsertSpots;

  constructor(public payload: { spots: Spot[] }) {}
}

export class UpdateSpot implements Action {
  readonly type = SpotActionTypes.UpdateSpot;

  constructor(public payload: { spot: Update<Spot> }) {}
}

export class UpdateSpots implements Action {
  readonly type = SpotActionTypes.UpdateSpots;

  constructor(public payload: { spots: Update<Spot>[] }) {}
}

export class DeleteSpot implements Action {
  readonly type = SpotActionTypes.DeleteSpot;

  constructor(public payload: { id: string }) {}
}

export class DeleteSpots implements Action {
  readonly type = SpotActionTypes.DeleteSpots;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearSpots implements Action {
  readonly type = SpotActionTypes.ClearSpots;
}

export type SpotActions =
 LoadSpots
 | AddSpot
 | UpsertSpot
 | AddSpots
 | UpsertSpots
 | UpdateSpot
 | UpdateSpots
 | DeleteSpot
 | DeleteSpots
 | ClearSpots;
