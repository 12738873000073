import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreeType} from './tree-type.model';
import {TreeTypeActions, TreeTypeActionTypes} from './tree-type.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TreeType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TreeType> = createEntityAdapter<TreeType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeTypeActions
): State {
  switch (action.type) {
    case TreeTypeActionTypes.AddTreeType: {
      return adapter.addOne(action.payload.treeType, state);
    }

    case TreeTypeActionTypes.UpsertTreeType: {
      return adapter.upsertOne(action.payload.treeType, state);
    }

    case TreeTypeActionTypes.AddTreeTypes: {
      return adapter.addMany(action.payload.treeTypes, state);
    }

    case TreeTypeActionTypes.UpsertTreeTypes: {
      return adapter.upsertMany(action.payload.treeTypes, state);
    }

    case TreeTypeActionTypes.UpdateTreeType: {
      return adapter.updateOne(action.payload.treeType, state);
    }

    case TreeTypeActionTypes.UpdateTreeTypes: {
      return adapter.updateMany(action.payload.treeTypes, state);
    }

    case TreeTypeActionTypes.DeleteTreeType: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeTypeActionTypes.DeleteTreeTypes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeTypeActionTypes.LoadTreeTypes: {
      return adapter.addAll(action.payload.treeTypes, state);
    }

    case TreeTypeActionTypes.ClearTreeTypes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TreeType> => state.root.treeTypes;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
