import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Side} from '../../store/global/global.model';
import {
  DecreaseMapSize,
  DecreaseMapSizeToMin,
  IncreaseMapSize,
  IncreaseMapSizeToMax,
  ToggleTreeMap
} from '../../store/global/global.action';
import {Store} from '@ngrx/store';
import {selectTreeMapMode} from '../../store/global/global.reducer';
import {Observable} from 'rxjs';
import {Tour} from '../../store/tour/tour.model';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {ActivatedRoute} from '@angular/router';
import {LoadTour} from '../../store/app.actions';
import {selectTreesOfSelectedTour} from '../../store/tree/tree.reducer';
import {Tree} from '../../store/tree/tree.model';
import {TreePropertyHelper} from '../../store/tree-property/tree-property.model';
import {TreeType} from '../../store/tree-type/tree-type.model';

@Component({
  selector: 'app-tour-tab',
  templateUrl: './tour-tab.component.html',
  styleUrls: ['./tour-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourTabComponent {


  public Side = Side;
  public treeMapMode = false;
  public tour$: Observable<Tour>;
  public trees$: Observable<Tree[]>;
  public tour: Tour;
  public trees: Tree[] = [];
  public types: TreeType[] = [];

  constructor(private route: ActivatedRoute, private store: Store<any>) {

    this.trees$ = this.store.select(selectTreesOfSelectedTour(Side.Left));
    this.store.select(selectTreesOfSelectedTour(Side.Left)).subscribe(trees => {
      this.trees = trees;
      this.reloadLegend();
    });
    this.tour$ = this.store.select(selectSelectedTour(Side.Left));
    this.store.select(selectSelectedTour(Side.Left)).subscribe((tour) => {
      this.tour = tour;
      this.reloadLegend();
    });
    this.store.select(selectTreeMapMode).subscribe((treeMapMode) => {
      this.treeMapMode = treeMapMode;
      this.reloadLegend();
    });
    this.reloadLegend();
  }

  reloadLegend() {
    this.trees.forEach(x => {
      TreePropertyHelper.getType(x, this.store).subscribe((v) => {
        if (this.types.find(y => y.id === v.id) == null) {
          this.types.push(v);
        }
      });
    });
  }

  decreaseMapSize() {
    this.store.dispatch(new DecreaseMapSize());
  }

  increaseMapSize() {
    this.store.dispatch(new IncreaseMapSize());
  }

  increaseMapSizeToMax() {
    this.store.dispatch(new IncreaseMapSizeToMax());
  }

  decreaseMapSizeToMin() {
    this.store.dispatch(new DecreaseMapSizeToMin());
  }

  switchFullScreenMap() {
    this.store.dispatch(new LoadTour({tourId: this.tour.id}));
    this.store.dispatch(new ToggleTreeMap());
  }
}
