import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TourInventoryType} from './tour-inventory-type.model';
import {TourInventoryTypeActions, TourInventoryTypeActionTypes} from './tour-inventory-type.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TourInventoryType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TourInventoryType> = createEntityAdapter<TourInventoryType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TourInventoryTypeActions
): State {
  switch (action.type) {
    case TourInventoryTypeActionTypes.AddTourInventoryType: {
      return adapter.addOne(action.payload.tourInventoryType, state);
    }

    case TourInventoryTypeActionTypes.UpsertTourInventoryType: {
      return adapter.upsertOne(action.payload.tourInventoryType, state);
    }

    case TourInventoryTypeActionTypes.AddTourInventoryTypes: {
      return adapter.addMany(action.payload.tourInventoryTypes, state);
    }

    case TourInventoryTypeActionTypes.UpsertTourInventoryTypes: {
      return adapter.upsertMany(action.payload.tourInventoryTypes, state);
    }

    case TourInventoryTypeActionTypes.UpdateTourInventoryType: {
      return adapter.updateOne(action.payload.tourInventoryType, state);
    }

    case TourInventoryTypeActionTypes.UpdateTourInventoryTypes: {
      return adapter.updateMany(action.payload.tourInventoryTypes, state);
    }

    case TourInventoryTypeActionTypes.DeleteTourInventoryType: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourInventoryTypeActionTypes.DeleteTourInventoryTypes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourInventoryTypeActionTypes.LoadTourInventoryTypes: {
      return adapter.addAll(action.payload.tourInventoryTypes, state);
    }

    case TourInventoryTypeActionTypes.ClearTourInventoryTypes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TourInventoryType> => state.root.tourInventoryTypes;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
