  import {TreeProperty} from '../tree-property/tree-property.model';
import {TourPosition} from '../tour/tour.model';

export enum TreeStateId {
  NOTHING = 0,
  CUT = 1,
  KEEP = 2,
}

export interface Tree extends TreeProperty {
  tourId: string;
  position: TourPosition;
  stateId: string;
  reasonIds: string[];
  comment: string;
  width: number;
}

export interface SceneTreeData {
  ath: number;
  atv: number;
  width: number;
  treenumber: string;
  tree_id: string;
}
