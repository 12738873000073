import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {BackendService} from '../../services/backend.service';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {selectSelectedTreeOfSelectedTour, selectTreesOfSelectedTour} from '../../store/tree/tree.reducer';
import {selectTreeCorrectionsFromPanorama} from '../../store/tree-correction/tree-correction.reducer';
import {Side} from '../../store/global/global.model';
import {AngleHelper, Tour} from '../../store/tour/tour.model';
import {TreeCorrection} from '../../store/tree-correction/tree-correction.model';
import {Tree} from '../../store/tree/tree.model';
import {UpdateTreeCorrection} from '../../store/tree-correction/tree-correction.actions';
import {SetSelectedTree} from '../../store/runtime-tour/runtime-tour.actions';
import {
  selectPanoramasOfSelectedTour,
  selectSelectedPanoramaOfSelectedTour
} from '../../store/panorama/panorama.reducer';
import {Panorama} from '../../store/panorama/panorama.model';
import {selectSelectedRealtimePanoramaOfSelectedTour} from '../../store/realtime-panorama/realtime-panorama.reducer';
import {Observable} from 'rxjs';
import {RealtimePanorama} from '../../store/realtime-panorama/realtime-panorama.model';
import {UpdatePanorama} from '../../store/panorama/panorama.actions';
import {selectTreeMapMode} from '../../store/global/global.reducer';

@Component({
  selector: 'app-corrections-panel',
  templateUrl: './corrections-panel.component.html',
  styleUrls: ['./corrections-panel.component.css']
})
export class CorrectionsPanelComponent implements OnInit {

  LITTLE_STEP = 0.5;
  BIG_STEP = 2.0;

  globalHorizontal: number;

  tmpCorrection: TreeCorrection;
  treeCorrection: TreeCorrection;
  newCorrection = false;

  public realtimePanorama$: Observable<RealtimePanorama>;
  public AzimuthHelper = AngleHelper;

  private panoramas: Panorama[] = [];
  private trees: Tree[] = [];
  private treeCorrections: TreeCorrection[] = [];
  private tmpCorrections: TreeCorrection[] = [];
  private selectedTour: Tour;
  private selectedPanorama: Panorama;
  private selectedTree: Tree;
  public treeMapMode = false;

  constructor(private store: Store<AppState>, private backendService: BackendService) { }

  ngOnInit() {

    this.store.select(selectTreeMapMode).subscribe((treeMapMode) => {
      this.treeMapMode = treeMapMode;
    });

    this.realtimePanorama$ = this.store.select(selectSelectedRealtimePanoramaOfSelectedTour(Side.Left));

    this.store.select(selectSelectedTour(Side.Left)).subscribe((tour) => {
      this.selectedTour = tour;

    });

    this.store.select(selectTreesOfSelectedTour(Side.Left)).subscribe((trees) => {
      this.trees = trees;
    });

    this.store.select(selectPanoramasOfSelectedTour(Side.Left)).subscribe((panoramas) => {
      this.panoramas = panoramas;

    });

    this.store.select(selectTreeCorrectionsFromPanorama(Side.Left)).subscribe((treeCorrections) => {
      this.treeCorrections = treeCorrections.filter(x => this.panoramas.find(y => y.id == x.camera_id && y.tourId == this.selectedTour.id) != null);
      this.tmpCorrections = treeCorrections.filter(x => this.panoramas.find(y => y.id == x.camera_id && y.tourId == this.selectedTour.id) != null);
    });

    this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left)).subscribe((panorama) => {
      this.selectedPanorama = panorama;
      if (this.globalHorizontal == null) {

        this.globalHorizontal = panorama.athCorrection;
      }
    });

    this.store.select(selectSelectedTreeOfSelectedTour(Side.Left)).subscribe((tree) => {
      this.selectedTree = tree;
      this.loadCurrentCorrections();
    });

  }

  loadCurrentCorrections() {
    if (this.selectedTree != null) {
      const correction = this.treeCorrections.find(x => x.treenumber == this.selectedTree.treenumber);

      if (correction != undefined && correction != null) {
        this.tmpCorrection = new TreeCorrection();
        this.tmpCorrection.id = correction.id;
        this.tmpCorrection.camera_id = correction.camera_id;
        this.tmpCorrection.treenumber = correction.treenumber;
        this.tmpCorrection.atv_correction = +correction.atv_correction;
        this.tmpCorrection.ath_correction = +correction.ath_correction;

        this.treeCorrection = correction;
        this.treeCorrection.atv_correction = +correction.atv_correction;
        this.treeCorrection.ath_correction = +correction.ath_correction;
      } else {
        this.newCorrection = true;

        this.tmpCorrection = new TreeCorrection();
        this.tmpCorrection.treenumber = this.selectedTree.treenumber;
        this.tmpCorrection.camera_id = this.selectedPanorama.id;
        if (this.treeCorrections.length == 0) {
          this.tmpCorrection.id = '0';
        } else {
          this.tmpCorrection.id = (+this.treeCorrections[this.treeCorrections.length - 1].id + 1).toString();
        }

        this.tmpCorrection.atv_correction = 0;
        this.tmpCorrection.ath_correction = 0;

        this.treeCorrection = new TreeCorrection();
        this.treeCorrection.treenumber = this.selectedTree.treenumber;
        this.treeCorrection.camera_id = this.selectedPanorama.id;
        if (this.treeCorrections.length == 0) {
          this.treeCorrection.id = '0';
        } else {
          this.treeCorrection.id = (+this.treeCorrections[this.treeCorrections.length - 1].id + 1).toString();
        }

        this.treeCorrection.atv_correction = 0;
        this.treeCorrection.ath_correction = 0;
      }


      // load all treecorrections and create empty ones for tree without treecorrections

      // load all tmp treecorrections for cancelling

    }
  }

  increaseGlobalHorizontal(step: number) {
    this.selectedPanorama.athCorrection += step;
    this.store.dispatch(new UpdatePanorama({panorama: {id: this.selectedPanorama.id, changes: this.selectedPanorama}}));
    //this.store.dispatch(new SetSelectedPanorama({tourId: this.selectedTour.id, side: Side.Left, panoramaId: this.selectedPanorama.id}));
  }

  increaseTreeHorizontal(step: number, tree: Tree, correction: TreeCorrection) {
    this.treeCorrection.ath_correction += step;
    this.store.dispatch(new UpdateTreeCorrection({treeCorrection: {id: this.treeCorrection.id, changes: this.treeCorrection}}));
    this.store.dispatch(new SetSelectedTree({tourId: this.selectedTree.tourId, treeId: tree.id}));
  }

  increaseTreeVertical(step: number) {
    this.treeCorrection.atv_correction += step;
    this.store.dispatch(new UpdateTreeCorrection({treeCorrection: {id: this.treeCorrection.id, changes: this.treeCorrection}}));
    this.store.dispatch(new SetSelectedTree({tourId: this.selectedTree.tourId, treeId: this.selectedTree.id}));
  }

  save() {
    // if global != 00
      // save for all treecorrections


    if (this.selectedPanorama.athCorrection != this.globalHorizontal) {
      this.backendService.updatePanorama(this.selectedPanorama).subscribe((res) => {

        this.globalHorizontal = this.selectedPanorama.athCorrection;
      });
    }


    if (this.newCorrection) {
      // create

      this.backendService.createTreeCorrection(this.treeCorrection).subscribe((res) => {
        this.treeCorrection.id = res;
        this.tmpCorrection.id = res;
        this.tmpCorrection.atv_correction = this.treeCorrection.atv_correction;
        this.tmpCorrection.ath_correction = this.treeCorrection.ath_correction;

        this.store.dispatch(new UpdateTreeCorrection({treeCorrection: {id: this.treeCorrection.id, changes: this.treeCorrection}}));
        this.store.dispatch(new SetSelectedTree({tourId: this.selectedTree.tourId, treeId: this.selectedTree.id}));
      });
    } else {
      // update

      this.backendService.updateTreeCorrection(this.treeCorrection).subscribe((res) => {
        this.tmpCorrection.atv_correction = this.treeCorrection.atv_correction;
        this.tmpCorrection.ath_correction = this.treeCorrection.ath_correction;

        this.store.dispatch(new UpdateTreeCorrection({treeCorrection: {id: this.treeCorrection.id, changes: this.treeCorrection}}));
        this.store.dispatch(new SetSelectedTree({tourId: this.selectedTree.tourId, treeId: this.selectedTree.id}));
      });
    }
  }

  cancel() {

    this.selectedPanorama.athCorrection = this.globalHorizontal;

    this.treeCorrection.atv_correction = this.tmpCorrection.atv_correction;
    this.treeCorrection.ath_correction = this.tmpCorrection.ath_correction;
    this.store.dispatch(new UpdateTreeCorrection({treeCorrection: {id: this.treeCorrection.id, changes: this.treeCorrection}}));
    this.store.dispatch(new SetSelectedTree({tourId: this.selectedTree.tourId, treeId: this.selectedTree.id}));
  }

}
