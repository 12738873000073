import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { MediaType } from './media-type.model';

export enum MediaTypeActionTypes {
  LoadMediaTypes = '[MediaType] Load MediaTypes',
  AddMediaType = '[MediaType] Add MediaType',
  UpsertMediaType = '[MediaType] Upsert MediaType',
  AddMediaTypes = '[MediaType] Add MediaTypes',
  UpsertMediaTypes = '[MediaType] Upsert MediaTypes',
  UpdateMediaType = '[MediaType] Update MediaType',
  UpdateMediaTypes = '[MediaType] Update MediaTypes',
  DeleteMediaType = '[MediaType] Delete MediaType',
  DeleteMediaTypes = '[MediaType] Delete MediaTypes',
  ClearMediaTypes = '[MediaType] Clear MediaTypes'
}

export class LoadMediaTypes implements Action {
  readonly type = MediaTypeActionTypes.LoadMediaTypes;

  constructor(public payload: { mediaTypes: MediaType[] }) {}
}

export class AddMediaType implements Action {
  readonly type = MediaTypeActionTypes.AddMediaType;

  constructor(public payload: { mediaType: MediaType }) {}
}

export class UpsertMediaType implements Action {
  readonly type = MediaTypeActionTypes.UpsertMediaType;

  constructor(public payload: { mediaType: MediaType }) {}
}

export class AddMediaTypes implements Action {
  readonly type = MediaTypeActionTypes.AddMediaTypes;

  constructor(public payload: { mediaTypes: MediaType[] }) {}
}

export class UpsertMediaTypes implements Action {
  readonly type = MediaTypeActionTypes.UpsertMediaTypes;

  constructor(public payload: { mediaTypes: MediaType[] }) {}
}

export class UpdateMediaType implements Action {
  readonly type = MediaTypeActionTypes.UpdateMediaType;

  constructor(public payload: { mediaType: Update<MediaType> }) {}
}

export class UpdateMediaTypes implements Action {
  readonly type = MediaTypeActionTypes.UpdateMediaTypes;

  constructor(public payload: { mediaTypes: Update<MediaType>[] }) {}
}

export class DeleteMediaType implements Action {
  readonly type = MediaTypeActionTypes.DeleteMediaType;

  constructor(public payload: { id: string }) {}
}

export class DeleteMediaTypes implements Action {
  readonly type = MediaTypeActionTypes.DeleteMediaTypes;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearMediaTypes implements Action {
  readonly type = MediaTypeActionTypes.ClearMediaTypes;
}

export type MediaTypeActions =
 LoadMediaTypes
 | AddMediaType
 | UpsertMediaType
 | AddMediaTypes
 | UpsertMediaTypes
 | UpdateMediaType
 | UpdateMediaTypes
 | DeleteMediaType
 | DeleteMediaTypes
 | ClearMediaTypes;
