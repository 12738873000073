import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Tour, TourHelper} from '../../store/tour/tour.model';
import {Side} from '../../store/global/global.model';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {PanoramaTree} from '../../store/panorama-tree/panorama-tree.model';
import {Tree} from '../../store/tree/tree.model';
import {selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour} from '../../store/panorama-tree/panorama-tree.reducer';
import {selectSelectedTreeOfSelectedTour} from '../../store/tree/tree.reducer';
import {User, UserHelper} from '../../store/user/user.model';
import {selectUser, UserSetAction} from '../../store/user/user.reducer';
import {SetFromUserAction} from '../../store/language/language.actions';
import {Spot} from '../../store/spot/spot.model';
import {selectSelectedSpotOfSelectedTour} from '../../store/spot/spot.reducer';
import {selectTourThematics} from '../../store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from '../../store/tour-thematic/tour-thematic.model';
import {selectThemes} from '../../store/catalog/theme/theme.reducer';
import {Theme} from '../../store/catalog/theme/theme.model';
import {BackendService} from '../../services/backend.service';
import {translation} from '../../app.translation';
import 'rxjs/add/operator/delay';

enum View {
  Tours,
  Tour,
  Habitat,
  Tree
}

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarComponent implements OnInit {
  public TourHelper = TourHelper;

  public tour$: Observable<Tour>;
  public selectedTree$: Observable<Tree>;
  public selectedSpot$: Observable<Spot>;
  public selectedPanoramaTree$: Observable<PanoramaTree>;
  public viewType = View;

  private view: View;
  private lastView: View;
  private lastSelectedPanoramaTree: PanoramaTree;
  private lastSelectedTree: Tree;
  private lastSelectedSpot: Spot;

  public themes: Theme[];
  public selectedTour: Tour;
  public tourThematic: TourThematic = null;

  private showDescription = false;

  public user$: Observable<User>;
  public user: User;
  public UserHelper = UserHelper;

  @ViewChild('descbox', {static: false }) elementView: ElementRef;
  public contentHeight = 0;
  public contentHeight$: Observable<number>;

  constructor(private store: Store<AppState>, private backendService: BackendService, public translate: TranslateService, private cd: ChangeDetectorRef) {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });
    this.tour$ = this.store.select(selectSelectedTour(Side.Left));
    this.selectedPanoramaTree$ = this.store.select(selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour);
    this.selectedTree$ = this.store.select(selectSelectedTreeOfSelectedTour(Side.Left));
    this.selectedSpot$ = this.store.select(selectSelectedSpotOfSelectedTour(Side.Left));

    this.tour$.subscribe(this.updateTour.bind(this));
    this.selectedPanoramaTree$.subscribe(this.updateSelectedPanoramaTree.bind(this));
    this.selectedTree$.subscribe(this.updateSelectedTree.bind(this));
    this.selectedSpot$.subscribe(this.updateSelectedSpot.bind(this));

    setTimeout(() => {
      const lang = localStorage.getItem('language');
      this.setLanguage(lang);
    }, 2000);

  }

  showDesc() {
    this.showDescription = !this.showDescription;

    if (this.elementView.nativeElement.offsetHeight > this.contentHeight) {
      this.contentHeight = this.elementView.nativeElement.offsetHeight;
    }
  }

  logout() {
    this.backendService.logout().subscribe( res => {

      this.store.dispatch(new UserSetAction(null));
      window.location.reload();
    });
  }

  public updateTour(tour: Tour): void {
    this.selectedTour = tour;
    if (tour && !this.view) {
      this.store.select(selectTourThematics).subscribe(this.updateTourThematic.bind(this));
      if (tour.treeCount) {
        this.initView(View.Tour);
      } else if (tour.hasPanoramas) {
        this.initView(View.Habitat);
      } else {
        this.initView(View.Tours);
      }
      this.cd.markForCheck();
    }
  }

  public updateTourThematic(tourThematics: TourThematic[]) {


    this.tourThematic = tourThematics.find(x => x.tourId == +this.selectedTour.id);
  }

  private updateSelectedPanoramaTree(selectedPanoramaTree: PanoramaTree): void {
    this.openTree(selectedPanoramaTree);
    this.lastSelectedPanoramaTree = selectedPanoramaTree;
  }

  private updateSelectedSpot(selectedSpot: Spot): void {
    this.openSpot(selectedSpot);
    this.lastSelectedSpot = selectedSpot;
  }

  private updateSelectedTree(selectedTree: Tree): void {
    this.openTree(selectedTree);
    this.lastSelectedTree = selectedTree;
  }

  private openTree(current: any) {
    this.setView(View.Tree);
    if (!this.lastSelectedPanoramaTree && !this.lastSelectedTree && current) {
      this.setView(View.Tree);
      this.cd.markForCheck();
    } else if (!current) {
      this.view = this.lastView;
      this.cd.markForCheck();
    }
  }

  private openSpot(current: any) {
    this.setView(View.Habitat);
    if (!this.lastSelectedSpot && current) {
      this.setView(View.Habitat);
      this.cd.markForCheck();
    } else if (!current) {
      this.view = this.lastView;
      this.cd.markForCheck();
    }
  }

  tourSpot() {
    return !((this.selectedTour != null && this.tourThematic != null) || (this.user != null && UserHelper.isBackendUser(this.user)));
  }

  getTheme() {
    let theme;
    if (this.tourThematic != null) {
      theme = this.themes.find(x => x.id == this.tourThematic.themeId);
    }

    if (theme != null) {
      return theme;
    } else {
      const the = new Theme();
      the.nameFr = 'Spots';
      the.nameEn = 'Spots';
      the.nameDe = 'Spots';
      return the;
    }
  }

  initView(view: View): void {
    this.setView(view);
    this.lastView = view;
  }

  setView(view: View): void {
    this.lastView = this.view;
    this.view = view;
  }

  setLanguage(language: string): void {
    this.store.dispatch(new SetFromUserAction(language));
    localStorage.setItem('language', language);
  }

  translateTheme(preString: string): string {
    return translation(this.getTheme(), preString, this.translate);
  }

}
