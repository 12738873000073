import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {Side} from '../../store/global/global.model';
import {Observable} from 'rxjs';
import {Tour} from '../../store/tour/tour.model';
import {selectSelectedTour} from '../../store/tour/tour.reducer';

enum View {
  Tours,
  Panoramas,
  Content,
}

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideComponent implements OnInit {
  @Input() side: Side;

  public View = View;
  public view: View = View.Content;
  public selectedTour$: Observable<Tour>;

  constructor(public store: Store<AppState>) {
  }

  ngOnInit() {
    this.selectedTour$ = this.store.select(selectSelectedTour(this.side));

    this.selectedTour$.subscribe(this.updateSelectedTour.bind(this));
  }

  private updateSelectedTour(tour: Tour): void {
    if (tour == null && this.view == View.Content) {
      this.view = View.Tours;
    }
  }

  onSelectPanorama() {
    this.view = View.Content;
  }

  onSelectTour() {
    this.view = View.Content;
  }

  setView(view: View) {
    if (this.view == view) {
      this.view = View.Content;
    } else {
      this.view = view;
    }
  }
}
