import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {InfoHabitat, SwitchLearningMode} from '../../../store/habitat/content-card.actions';
import {LearningMode} from '../../../store/habitat/content-card.model';

@Component({
  selector: 'app-info-mode',
  templateUrl: './info-mode.component.html',
  styleUrls: ['./info-mode.component.css']
})
export class InfoModeComponent implements OnInit {

  constructor(private store: Store<any>) { }

  ngOnInit() {

  }

  showMultimediaContent() {
    this.store.dispatch(new SwitchLearningMode({learningMode: LearningMode.Discovery}));
  }

  showHabitatInfo() {
    this.store.dispatch(new InfoHabitat());
  }
}
