import { Component, OnInit } from '@angular/core';
import {Store} from '@ngrx/store';
import {MultimediaContent} from '../../../store/habitat/content-card.actions';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {selectTourThematics} from '../../../store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from '../../../store/tour-thematic/tour-thematic.model';
import {Tour} from '../../../store/tour/tour.model';
import {selectSelectedTour} from '../../../store/tour/tour.reducer';
import {Side} from '../../../store/global/global.model';
import {translation} from "../../../app.translation";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-info-habitat',
  templateUrl: './info-habitat.component.html',
  styleUrls: ['./info-habitat.component.css']
})
export class InfoHabitatComponent implements OnInit {

  public subThemes: SubTheme[];
  public selectedTour: Tour;
  public tourThematic: TourThematic;

  constructor(private store: Store<any>, private translate: TranslateService) { }

  ngOnInit() {

    this.store.select(selectSelectedTour(Side.Left)).subscribe(this.updateTour.bind(this));

    this.store.select(selectTourThematics).subscribe(this.updateTourThematic.bind(this));

    this.store.select(selectSubThemes).subscribe(subThemes => {
      this.subThemes = subThemes;
    });
  }

  public updateTour(selectedTour: Tour): void {
    this.selectedTour = selectedTour;
  }

  public updateTourThematic(tourThematics: TourThematic[]) {
    this.tourThematic = tourThematics.find(x => x.tourId == +this.selectedTour.id);
  }


  getForms(): SubTheme[] {
    return this.subThemes.filter(x => x.themeId == this.tourThematic.themeId && x.level == 0);
  }

  getFormIconUrl(form: SubTheme) {
    if ( form.iconUrl != null && form.iconUrl != '') {
      return '"/assets/images/spots/' + form.iconUrl + '.png"';
    } else {
      return '"/assets/images/spots/empty.png"';
    }
  }

  getGroups(form: SubTheme) {
    return this.subThemes.filter(x => x.themeId == this.tourThematic.themeId && x.superThemeId == form.id && x.level == 1);
  }

  closeInfoHabitat() {
    this.store.dispatch(new MultimediaContent());
  }

  translateInfoHabitat(object, preString: string): string {
    return translation(object, preString, this.translate);
  }

  getMoreInfoLink() {
    let result = 'https://totholz.wsl.ch/de/habitatbaeume/baummikrohabitate.html';
    switch (this.translate.currentLang) {
      case 'de':
        result = 'https://totholz.wsl.ch/de/habitatbaeume/baummikrohabitate.html';
        break;
      case 'fr':
        result = 'https://totholz.wsl.ch/fr/arbres-habitats/diversite-des-dendromicrohabitats.html';
        break;
      case 'en':
        result = 'https://totholz.wsl.ch/en/habitat-trees/tree-microhabitats.html';
        break;
    }
    return result;
  }

}
