import {Action} from '@ngrx/store';
import {SideView} from './realtime-panorama.model';
import {Side} from '../global/global.model';

export enum RealtimePanoramaActionTypes {
  SetView = '[RealtimePanorama] SetView',
  TogglePanoramaSync = '[RealtimePanorama] Toggle Panorama Sync',
  SetSyncOrigin = '[RealtimePanorama] Set Sync Origin',
  OrientToNorth = '[RealtimePanorama] Orient To North',
}

export class SetView implements Action {
  readonly type = RealtimePanoramaActionTypes.SetView;

  constructor(public payload: { side: Side; view: SideView }) {
  }
}

export class TogglePanoramaSync implements Action {
  readonly type = RealtimePanoramaActionTypes.TogglePanoramaSync;
}

export class SetSyncOrigin implements Action {
  readonly type = RealtimePanoramaActionTypes.SetSyncOrigin;

  constructor(public payload: { side: Side }) {
  }
}

export class OrientToNorth implements Action {
  readonly type = RealtimePanoramaActionTypes.OrientToNorth;

  constructor(public payload: { side: Side }) {
  }
}

export type RealtimePanoramaActions =
  SetView |
  TogglePanoramaSync |
  SetSyncOrigin |
  OrientToNorth;
