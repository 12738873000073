import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreeVerticalStructure} from './tree-vertical-structure.model';
import {TreeVerticalStructureActions, TreeVerticalStructureActionTypes} from './tree-vertical-structure.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TreeVerticalStructure> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TreeVerticalStructure> = createEntityAdapter<TreeVerticalStructure>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeVerticalStructureActions
): State {
  switch (action.type) {
    case TreeVerticalStructureActionTypes.AddTreeVerticalStructure: {
      return adapter.addOne(action.payload.treeVerticalStructure, state);
    }

    case TreeVerticalStructureActionTypes.UpsertTreeVerticalStructure: {
      return adapter.upsertOne(action.payload.treeVerticalStructure, state);
    }

    case TreeVerticalStructureActionTypes.AddTreeVerticalStructures: {
      return adapter.addMany(action.payload.treeVerticalStructures, state);
    }

    case TreeVerticalStructureActionTypes.UpsertTreeVerticalStructures: {
      return adapter.upsertMany(action.payload.treeVerticalStructures, state);
    }

    case TreeVerticalStructureActionTypes.UpdateTreeVerticalStructure: {
      return adapter.updateOne(action.payload.treeVerticalStructure, state);
    }

    case TreeVerticalStructureActionTypes.UpdateTreeVerticalStructures: {
      return adapter.updateMany(action.payload.treeVerticalStructures, state);
    }

    case TreeVerticalStructureActionTypes.DeleteTreeVerticalStructure: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeVerticalStructureActionTypes.DeleteTreeVerticalStructures: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeVerticalStructureActionTypes.LoadTreeVerticalStructures: {
      return adapter.addAll(action.payload.treeVerticalStructures, state);
    }

    case TreeVerticalStructureActionTypes.ClearTreeVerticalStructures: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TreeVerticalStructure> => state.root.treeVerticalStructures;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
