import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TourDevelopmentalStage } from './tour-developmental-stage.model';

export enum TourDevelopmentalStageActionTypes {
  LoadTourDevelopmentalStages = '[TourDevelopmentalStage] Load TourDevelopmentalStages',
  AddTourDevelopmentalStage = '[TourDevelopmentalStage] Add TourDevelopmentalStage',
  UpsertTourDevelopmentalStage = '[TourDevelopmentalStage] Upsert TourDevelopmentalStage',
  AddTourDevelopmentalStages = '[TourDevelopmentalStage] Add TourDevelopmentalStages',
  UpsertTourDevelopmentalStages = '[TourDevelopmentalStage] Upsert TourDevelopmentalStages',
  UpdateTourDevelopmentalStage = '[TourDevelopmentalStage] Update TourDevelopmentalStage',
  UpdateTourDevelopmentalStages = '[TourDevelopmentalStage] Update TourDevelopmentalStages',
  DeleteTourDevelopmentalStage = '[TourDevelopmentalStage] Delete TourDevelopmentalStage',
  DeleteTourDevelopmentalStages = '[TourDevelopmentalStage] Delete TourDevelopmentalStages',
  ClearTourDevelopmentalStages = '[TourDevelopmentalStage] Clear TourDevelopmentalStages'
}

export class LoadTourDevelopmentalStages implements Action {
  readonly type = TourDevelopmentalStageActionTypes.LoadTourDevelopmentalStages;

  constructor(public payload: { tourDevelopmentalStages: TourDevelopmentalStage[] }) {}
}

export class AddTourDevelopmentalStage implements Action {
  readonly type = TourDevelopmentalStageActionTypes.AddTourDevelopmentalStage;

  constructor(public payload: { tourDevelopmentalStage: TourDevelopmentalStage }) {}
}

export class UpsertTourDevelopmentalStage implements Action {
  readonly type = TourDevelopmentalStageActionTypes.UpsertTourDevelopmentalStage;

  constructor(public payload: { tourDevelopmentalStage: TourDevelopmentalStage }) {}
}

export class AddTourDevelopmentalStages implements Action {
  readonly type = TourDevelopmentalStageActionTypes.AddTourDevelopmentalStages;

  constructor(public payload: { tourDevelopmentalStages: TourDevelopmentalStage[] }) {}
}

export class UpsertTourDevelopmentalStages implements Action {
  readonly type = TourDevelopmentalStageActionTypes.UpsertTourDevelopmentalStages;

  constructor(public payload: { tourDevelopmentalStages: TourDevelopmentalStage[] }) {}
}

export class UpdateTourDevelopmentalStage implements Action {
  readonly type = TourDevelopmentalStageActionTypes.UpdateTourDevelopmentalStage;

  constructor(public payload: { tourDevelopmentalStage: Update<TourDevelopmentalStage> }) {}
}

export class UpdateTourDevelopmentalStages implements Action {
  readonly type = TourDevelopmentalStageActionTypes.UpdateTourDevelopmentalStages;

  constructor(public payload: { tourDevelopmentalStages: Update<TourDevelopmentalStage>[] }) {}
}

export class DeleteTourDevelopmentalStage implements Action {
  readonly type = TourDevelopmentalStageActionTypes.DeleteTourDevelopmentalStage;

  constructor(public payload: { id: string }) {}
}

export class DeleteTourDevelopmentalStages implements Action {
  readonly type = TourDevelopmentalStageActionTypes.DeleteTourDevelopmentalStages;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTourDevelopmentalStages implements Action {
  readonly type = TourDevelopmentalStageActionTypes.ClearTourDevelopmentalStages;
}

export type TourDevelopmentalStageActions =
 LoadTourDevelopmentalStages
 | AddTourDevelopmentalStage
 | UpsertTourDevelopmentalStage
 | AddTourDevelopmentalStages
 | UpsertTourDevelopmentalStages
 | UpdateTourDevelopmentalStage
 | UpdateTourDevelopmentalStages
 | DeleteTourDevelopmentalStage
 | DeleteTourDevelopmentalStages
 | ClearTourDevelopmentalStages;
