import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Theme } from './theme.model';

export enum ThemeActionTypes {
  LoadThemes = '[Theme] Load Themes',
  AddTheme = '[Theme] Add Theme',
  UpsertTheme = '[Theme] Upsert Theme',
  AddThemes = '[Theme] Add Themes',
  UpsertThemes = '[Theme] Upsert Themes',
  UpdateTheme = '[Theme] Update Theme',
  UpdateThemes = '[Theme] Update Themes',
  DeleteTheme = '[Theme] Delete Theme',
  DeleteThemes = '[Theme] Delete Themes',
  ClearThemes = '[Theme] Clear Themes'
}

export class LoadThemes implements Action {
  readonly type = ThemeActionTypes.LoadThemes;

  constructor(public payload: { themes: Theme[] }) {}
}

export class AddTheme implements Action {
  readonly type = ThemeActionTypes.AddTheme;

  constructor(public payload: { theme: Theme }) {}
}

export class UpsertTheme implements Action {
  readonly type = ThemeActionTypes.UpsertTheme;

  constructor(public payload: { theme: Theme }) {}
}

export class AddThemes implements Action {
  readonly type = ThemeActionTypes.AddThemes;

  constructor(public payload: { themes: Theme[] }) {}
}

export class UpsertThemes implements Action {
  readonly type = ThemeActionTypes.UpsertThemes;

  constructor(public payload: { themes: Theme[] }) {}
}

export class UpdateTheme implements Action {
  readonly type = ThemeActionTypes.UpdateTheme;

  constructor(public payload: { theme: Update<Theme> }) {}
}

export class UpdateThemes implements Action {
  readonly type = ThemeActionTypes.UpdateThemes;

  constructor(public payload: { themes: Update<Theme>[] }) {}
}

export class DeleteTheme implements Action {
  readonly type = ThemeActionTypes.DeleteTheme;

  constructor(public payload: { id: string }) {}
}

export class DeleteThemes implements Action {
  readonly type = ThemeActionTypes.DeleteThemes;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearThemes implements Action {
  readonly type = ThemeActionTypes.ClearThemes;
}

export type ThemeActions =
 LoadThemes
 | AddTheme
 | UpsertTheme
 | AddThemes
 | UpsertThemes
 | UpdateTheme
 | UpdateThemes
 | DeleteTheme
 | DeleteThemes
 | ClearThemes;
