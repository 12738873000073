import {Content} from '../content/content.model';

export class Response {
  id: number;
  questionId: number;
  contentId: number;
  correct: boolean;
}

export class LocalResponse {
  id: number;
  content: Content;
  correct: boolean;
}
