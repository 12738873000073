import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TreeState } from './tree-state.model';

export enum TreeStateActionTypes {
  LoadTreeStates = '[TreeState] Load TreeStates',
  AddTreeState = '[TreeState] Add TreeState',
  UpsertTreeState = '[TreeState] Upsert TreeState',
  AddTreeStates = '[TreeState] Add TreeStates',
  UpsertTreeStates = '[TreeState] Upsert TreeStates',
  UpdateTreeState = '[TreeState] Update TreeState',
  UpdateTreeStates = '[TreeState] Update TreeStates',
  DeleteTreeState = '[TreeState] Delete TreeState',
  DeleteTreeStates = '[TreeState] Delete TreeStates',
  ClearTreeStates = '[TreeState] Clear TreeStates'
}

export class LoadTreeStates implements Action {
  readonly type = TreeStateActionTypes.LoadTreeStates;

  constructor(public payload: { treeStates: TreeState[] }) {}
}

export class AddTreeState implements Action {
  readonly type = TreeStateActionTypes.AddTreeState;

  constructor(public payload: { treeState: TreeState }) {}
}

export class UpsertTreeState implements Action {
  readonly type = TreeStateActionTypes.UpsertTreeState;

  constructor(public payload: { treeState: TreeState }) {}
}

export class AddTreeStates implements Action {
  readonly type = TreeStateActionTypes.AddTreeStates;

  constructor(public payload: { treeStates: TreeState[] }) {}
}

export class UpsertTreeStates implements Action {
  readonly type = TreeStateActionTypes.UpsertTreeStates;

  constructor(public payload: { treeStates: TreeState[] }) {}
}

export class UpdateTreeState implements Action {
  readonly type = TreeStateActionTypes.UpdateTreeState;

  constructor(public payload: { treeState: Update<TreeState> }) {}
}

export class UpdateTreeStates implements Action {
  readonly type = TreeStateActionTypes.UpdateTreeStates;

  constructor(public payload: { treeStates: Update<TreeState>[] }) {}
}

export class DeleteTreeState implements Action {
  readonly type = TreeStateActionTypes.DeleteTreeState;

  constructor(public payload: { id: string }) {}
}

export class DeleteTreeStates implements Action {
  readonly type = TreeStateActionTypes.DeleteTreeStates;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTreeStates implements Action {
  readonly type = TreeStateActionTypes.ClearTreeStates;
}

export type TreeStateActions =
 LoadTreeStates
 | AddTreeState
 | UpsertTreeState
 | AddTreeStates
 | UpsertTreeStates
 | UpdateTreeState
 | UpdateTreeStates
 | DeleteTreeState
 | DeleteTreeStates
 | ClearTreeStates;
