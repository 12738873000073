import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TourCanopyCoverDegree } from './tour-canopy-cover-degree.model';

export enum TourCanopyCoverDegreeActionTypes {
  LoadTourCanopyCoverDegrees = '[TourCanopyCoverDegree] Load TourCanopyCoverDegrees',
  AddTourCanopyCoverDegree = '[TourCanopyCoverDegree] Add TourCanopyCoverDegree',
  UpsertTourCanopyCoverDegree = '[TourCanopyCoverDegree] Upsert TourCanopyCoverDegree',
  AddTourCanopyCoverDegrees = '[TourCanopyCoverDegree] Add TourCanopyCoverDegrees',
  UpsertTourCanopyCoverDegrees = '[TourCanopyCoverDegree] Upsert TourCanopyCoverDegrees',
  UpdateTourCanopyCoverDegree = '[TourCanopyCoverDegree] Update TourCanopyCoverDegree',
  UpdateTourCanopyCoverDegrees = '[TourCanopyCoverDegree] Update TourCanopyCoverDegrees',
  DeleteTourCanopyCoverDegree = '[TourCanopyCoverDegree] Delete TourCanopyCoverDegree',
  DeleteTourCanopyCoverDegrees = '[TourCanopyCoverDegree] Delete TourCanopyCoverDegrees',
  ClearTourCanopyCoverDegrees = '[TourCanopyCoverDegree] Clear TourCanopyCoverDegrees'
}

export class LoadTourCanopyCoverDegrees implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.LoadTourCanopyCoverDegrees;

  constructor(public payload: { tourCanopyCoverDegrees: TourCanopyCoverDegree[] }) {}
}

export class AddTourCanopyCoverDegree implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.AddTourCanopyCoverDegree;

  constructor(public payload: { tourCanopyCoverDegree: TourCanopyCoverDegree }) {}
}

export class UpsertTourCanopyCoverDegree implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.UpsertTourCanopyCoverDegree;

  constructor(public payload: { tourCanopyCoverDegree: TourCanopyCoverDegree }) {}
}

export class AddTourCanopyCoverDegrees implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.AddTourCanopyCoverDegrees;

  constructor(public payload: { tourCanopyCoverDegrees: TourCanopyCoverDegree[] }) {}
}

export class UpsertTourCanopyCoverDegrees implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.UpsertTourCanopyCoverDegrees;

  constructor(public payload: { tourCanopyCoverDegrees: TourCanopyCoverDegree[] }) {}
}

export class UpdateTourCanopyCoverDegree implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.UpdateTourCanopyCoverDegree;

  constructor(public payload: { tourCanopyCoverDegree: Update<TourCanopyCoverDegree> }) {}
}

export class UpdateTourCanopyCoverDegrees implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.UpdateTourCanopyCoverDegrees;

  constructor(public payload: { tourCanopyCoverDegrees: Update<TourCanopyCoverDegree>[] }) {}
}

export class DeleteTourCanopyCoverDegree implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.DeleteTourCanopyCoverDegree;

  constructor(public payload: { id: string }) {}
}

export class DeleteTourCanopyCoverDegrees implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.DeleteTourCanopyCoverDegrees;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTourCanopyCoverDegrees implements Action {
  readonly type = TourCanopyCoverDegreeActionTypes.ClearTourCanopyCoverDegrees;
}

export type TourCanopyCoverDegreeActions =
 LoadTourCanopyCoverDegrees
 | AddTourCanopyCoverDegree
 | UpsertTourCanopyCoverDegree
 | AddTourCanopyCoverDegrees
 | UpsertTourCanopyCoverDegrees
 | UpdateTourCanopyCoverDegree
 | UpdateTourCanopyCoverDegrees
 | DeleteTourCanopyCoverDegree
 | DeleteTourCanopyCoverDegrees
 | ClearTourCanopyCoverDegrees;
