import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {Side} from '../../store/global/global.model';
import {Panorama} from '../../store/panorama/panorama.model';
import {PanoramaTree} from '../../store/panorama-tree/panorama-tree.model';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {selectSelectedPanoramaOfSelectedTour} from '../../store/panorama/panorama.reducer';
import {selectPanoramaTreesOfSelectedPanoramaOfSelectedTour} from '../../store/panorama-tree/panorama-tree.reducer';

@Component({
  selector: 'app-panorama-tab',
  templateUrl: './panorama-tab.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaTabComponent implements OnInit {
  public panorama$: Observable<Panorama>;
  public panoramaTree$: Observable<PanoramaTree[]>;

  constructor(private store: Store<AppState>, public translate: TranslateService) {
  }

  ngOnInit() {
    this.panorama$ = this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left));
    this.panoramaTree$ = this.store.select(selectPanoramaTreesOfSelectedPanoramaOfSelectedTour(Side.Left));
  }
}
