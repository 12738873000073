import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TourThematic } from './tour-thematic.model';

export enum TourThematicActionTypes {
  LoadTourThematics = '[TourThematic] Load TourThematics',
  AddTourThematic = '[TourThematic] Add TourThematic',
  UpsertTourThematic = '[TourThematic] Upsert TourThematic',
  AddTourThematics = '[TourThematic] Add TourThematics',
  UpsertTourThematics = '[TourThematic] Upsert TourThematics',
  UpdateTourThematic = '[TourThematic] Update TourThematic',
  UpdateTourThematics = '[TourThematic] Update TourThematics',
  DeleteTourThematic = '[TourThematic] Delete TourThematic',
  DeleteTourThematics = '[TourThematic] Delete TourThematics',
  ClearTourThematics = '[TourThematic] Clear TourThematics'
}

export class LoadTourThematics implements Action {
  readonly type = TourThematicActionTypes.LoadTourThematics;

  constructor(public payload: { tourThematics: TourThematic[] }) {}
}

export class AddTourThematic implements Action {
  readonly type = TourThematicActionTypes.AddTourThematic;

  constructor(public payload: { tourThematic: TourThematic }) {}
}

export class UpsertTourThematic implements Action {
  readonly type = TourThematicActionTypes.UpsertTourThematic;

  constructor(public payload: { tourThematic: TourThematic }) {}
}

export class AddTourThematics implements Action {
  readonly type = TourThematicActionTypes.AddTourThematics;

  constructor(public payload: { tourThematics: TourThematic[] }) {}
}

export class UpsertTourThematics implements Action {
  readonly type = TourThematicActionTypes.UpsertTourThematics;

  constructor(public payload: { tourThematics: TourThematic[] }) {}
}

export class UpdateTourThematic implements Action {
  readonly type = TourThematicActionTypes.UpdateTourThematic;

  constructor(public payload: { tourThematic: Update<TourThematic> }) {}
}

export class UpdateTourThematics implements Action {
  readonly type = TourThematicActionTypes.UpdateTourThematics;

  constructor(public payload: { tourThematics: Update<TourThematic>[] }) {}
}

export class DeleteTourThematic implements Action {
  readonly type = TourThematicActionTypes.DeleteTourThematic;

  constructor(public payload: { id: string }) {}
}

export class DeleteTourThematics implements Action {
  readonly type = TourThematicActionTypes.DeleteTourThematics;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTourThematics implements Action {
  readonly type = TourThematicActionTypes.ClearTourThematics;
}

export type TourThematicActions =
 LoadTourThematics
 | AddTourThematic
 | UpsertTourThematic
 | AddTourThematics
 | UpsertTourThematics
 | UpdateTourThematic
 | UpdateTourThematics
 | DeleteTourThematic
 | DeleteTourThematics
 | ClearTourThematics;
