import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TourDevelopmentalStage} from './tour-developmental-stage.model';
import {TourDevelopmentalStageActions, TourDevelopmentalStageActionTypes} from './tour-developmental-stage.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TourDevelopmentalStage> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TourDevelopmentalStage> = createEntityAdapter<TourDevelopmentalStage>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TourDevelopmentalStageActions
): State {
  switch (action.type) {
    case TourDevelopmentalStageActionTypes.AddTourDevelopmentalStage: {
      return adapter.addOne(action.payload.tourDevelopmentalStage, state);
    }

    case TourDevelopmentalStageActionTypes.UpsertTourDevelopmentalStage: {
      return adapter.upsertOne(action.payload.tourDevelopmentalStage, state);
    }

    case TourDevelopmentalStageActionTypes.AddTourDevelopmentalStages: {
      return adapter.addMany(action.payload.tourDevelopmentalStages, state);
    }

    case TourDevelopmentalStageActionTypes.UpsertTourDevelopmentalStages: {
      return adapter.upsertMany(action.payload.tourDevelopmentalStages, state);
    }

    case TourDevelopmentalStageActionTypes.UpdateTourDevelopmentalStage: {
      return adapter.updateOne(action.payload.tourDevelopmentalStage, state);
    }

    case TourDevelopmentalStageActionTypes.UpdateTourDevelopmentalStages: {
      return adapter.updateMany(action.payload.tourDevelopmentalStages, state);
    }

    case TourDevelopmentalStageActionTypes.DeleteTourDevelopmentalStage: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourDevelopmentalStageActionTypes.DeleteTourDevelopmentalStages: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourDevelopmentalStageActionTypes.LoadTourDevelopmentalStages: {
      return adapter.addAll(action.payload.tourDevelopmentalStages, state);
    }

    case TourDevelopmentalStageActionTypes.ClearTourDevelopmentalStages: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TourDevelopmentalStage> => state.root.tourDevelopmentalStages;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
