import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { PanoramaTree } from './panorama-tree.model';

export enum PanoramaTreeActionTypes {
  LoadPanoramaTrees = '[PanoramaTree] Load PanoramaTrees',
  AddPanoramaTree = '[PanoramaTree] Add PanoramaTree',
  UpsertPanoramaTree = '[PanoramaTree] Upsert PanoramaTree',
  AddPanoramaTrees = '[PanoramaTree] Add PanoramaTrees',
  UpsertPanoramaTrees = '[PanoramaTree] Upsert PanoramaTrees',
  UpdatePanoramaTree = '[PanoramaTree] Update PanoramaTree',
  UpdatePanoramaTrees = '[PanoramaTree] Update PanoramaTrees',
  DeletePanoramaTree = '[PanoramaTree] Delete PanoramaTree',
  DeletePanoramaTrees = '[PanoramaTree] Delete PanoramaTrees',
  ClearPanoramaTrees = '[PanoramaTree] Clear PanoramaTrees'
}

export class LoadPanoramaTrees implements Action {
  readonly type = PanoramaTreeActionTypes.LoadPanoramaTrees;

  constructor(public payload: { panoramaTrees: PanoramaTree[] }) {}
}

export class AddPanoramaTree implements Action {
  readonly type = PanoramaTreeActionTypes.AddPanoramaTree;

  constructor(public payload: { panoramaTree: PanoramaTree }) {}
}

export class UpsertPanoramaTree implements Action {
  readonly type = PanoramaTreeActionTypes.UpsertPanoramaTree;

  constructor(public payload: { panoramaTree: PanoramaTree }) {}
}

export class AddPanoramaTrees implements Action {
  readonly type = PanoramaTreeActionTypes.AddPanoramaTrees;

  constructor(public payload: { panoramaTrees: PanoramaTree[] }) {}
}

export class UpsertPanoramaTrees implements Action {
  readonly type = PanoramaTreeActionTypes.UpsertPanoramaTrees;

  constructor(public payload: { panoramaTrees: PanoramaTree[] }) {}
}

export class UpdatePanoramaTree implements Action {
  readonly type = PanoramaTreeActionTypes.UpdatePanoramaTree;

  constructor(public payload: { panoramaTree: Update<PanoramaTree> }) {}
}

export class UpdatePanoramaTrees implements Action {
  readonly type = PanoramaTreeActionTypes.UpdatePanoramaTrees;

  constructor(public payload: { panoramaTrees: Update<PanoramaTree>[] }) {}
}

export class DeletePanoramaTree implements Action {
  readonly type = PanoramaTreeActionTypes.DeletePanoramaTree;

  constructor(public payload: { id: string }) {}
}

export class DeletePanoramaTrees implements Action {
  readonly type = PanoramaTreeActionTypes.DeletePanoramaTrees;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearPanoramaTrees implements Action {
  readonly type = PanoramaTreeActionTypes.ClearPanoramaTrees;
}

export type PanoramaTreeActions =
 LoadPanoramaTrees
 | AddPanoramaTree
 | UpsertPanoramaTree
 | AddPanoramaTrees
 | UpsertPanoramaTrees
 | UpdatePanoramaTree
 | UpdatePanoramaTrees
 | DeletePanoramaTree
 | DeletePanoramaTrees
 | ClearPanoramaTrees;
