import {TourInventoryType} from '../tour-inventory-type/tour-inventory-type.model';
import {AppState} from '../app.reducer';
import {Store} from '@ngrx/store';
import * as fromCanopyCoverDegree from '../tour-canopy-cover-degree/tour-canopy-cover-degree.reducer';
import * as fromCategory from '../tour-category/tour-category.reducer';
import * as fromDevelopmentalStage from '../tour-developmental-stage/tour-developmental-stage.reducer';
import * as fromInventoryType from '../tour-inventory-type/tour-inventory-type.reducer';
import * as fromPanorama from '../panorama/panorama.reducer';
import * as fromTree from '../tree/tree.reducer';
import * as fromTreeType from '../tree-type/tree-type.reducer';
import {Observable} from 'rxjs';
import {TourCanopyCoverDegree} from '../tour-canopy-cover-degree/tour-canopy-cover-degree.model';
import {TourCategory} from '../tour-category/tour-category.model';
import {TourDevelopmentalStage} from '../tour-developmental-stage/tour-developmental-stage.model';
import {Tree} from '../tree/tree.model';
import {Panorama} from '../panorama/panorama.model';
import {TreeType} from '../tree-type/tree-type.model';
import {Azimuth} from './tour.model';

export enum TourShape {
  CIRCLE = 0,
  SQUARE = 1,
}

export interface GeodesyPosition {
  latitude: number;
  longitude: number;
}

export function positionFromString(stringPosition: string): GeodesyPosition {
  if (!stringPosition) {
    return null;
  }

  const fields = stringPosition.split(',');

  if (fields.length !== 2) {
    throw new Error('unable to parse position');
    return null;
  }

  return {
    latitude: +fields[0],
    longitude: +fields[1],
  };
}

export function positionToArray(position: GeodesyPosition): [number, number] {
  return [position.latitude, position.longitude];
}

export interface Azimuth {
  radians: number;
}

export interface Angle {
  radians: number;
}

export interface TourPosition {
  x: number;
  y: number;
}

export namespace TourPositionHelper {
  export function distance(position1: TourPosition, position2: TourPosition): number {
    const dx = position1.x - position2.x;
    const dy = position1.y - position2.y;

    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
  }
}


export namespace AngleHelper {
  export function fromDegrees(degrees: number): Angle {
    return {radians: (degrees) / 180 * Math.PI};
  }

  export function fromGeodesyDegrees(geodesyDegrees): Azimuth {
    return {radians: (90 - geodesyDegrees) / 180 * Math.PI};
  }

  export function fromPositions(position1: TourPosition, position2: TourPosition): Azimuth {
    const dx = position2.x - position1.x;
    const dy = position2.y - position1.y;

    return {radians: Math.atan2(dy, dx)}; // yes, dy ist the first parameter
  }

  export function north(): Azimuth {
    return fromGeodesyDegrees(0);
  }

  export function getDegrees(angle: Angle) {
    return angle.radians / Math.PI * 180;
  }

  export function getGeodesyDegrees(azimuth: Azimuth): number {
    return 90 - azimuth.radians / Math.PI * 180;
  }

  export function getGeodesyDegreesNormalized(angle: Azimuth): number {
    return (getGeodesyDegrees(angle) % 360 + 360) % 360;
  }

  export function difference(angle1: Angle, angle2: Angle): Angle {
    return {radians: angle1.radians - angle2.radians};
  }

  export function add(angle: Angle, offset: Angle): Azimuth {
    return {radians: angle.radians + offset.radians};
  }

  export function subtract(angle: Angle, offset: Angle): Azimuth {
    return {radians: angle.radians - offset.radians};
  }

  export function getX(angle: Azimuth): number {
    return Math.cos(angle.radians);
  }

  export function getY(angle: Azimuth): number {
    return Math.sin(angle.radians);
  }

  export function divide(span: Angle, divisor: number): Angle {
    return {radians: span.radians / divisor};
  }

  export function flipSign(angle: Angle): Angle {
    return {radians: -angle.radians};
  }
}

export interface Tour {
  id: string;
  title: string;
  titleFr: string;
  titleDe: string;
  titleEn: string;
  name: string;
  canopyCoverDegreeId: string;
  categoryId: string;
  developmentalStageId: string;
  inventoryTypeId: string;
  treeTypeId: string;
  description: string;
  descriptionFr: string;
  descriptionDe: string;
  descriptionEn: string;
  path: string;
  imagePath: string;
  date: string;
  location: string;
  position: GeodesyPosition;
  surface: number;
  shape: TourShape;
  rotate: Angle;
  order: number;
  published: number;
  statistic: number;
  coniferousProportion: number;
  mapSymboleScaleOffset: number;
  mapSymboleScaleFactor: number;
  fieldVd: string;
  fieldEk: string;
  treeCount: number;
  hasPanoramas: number;
  statusCount: [number, number, number];
  displayDistance: number;
}

export namespace TourHelper {
  export function getCanopyCoverDegree(tour: Tour, store: Store<AppState>): Observable<TourCanopyCoverDegree> {
    if (tour) {
      return store.select(fromCanopyCoverDegree.selectById(tour.canopyCoverDegreeId));
    } else {
      return null;
    }
  }

  export function getCategory(tour: Tour, store: Store<AppState>): Observable<TourCategory> {
    if (tour) {
      return store.select(fromCategory.selectById(tour.categoryId));
    } else {
      return null;
    }
  }

  export function getDevelopmentalStage(tour: Tour, store: Store<AppState>): Observable<TourDevelopmentalStage> {
    if (tour) {
      return store.select(fromDevelopmentalStage.selectById(tour.developmentalStageId));
    } else {
      return null;
    }
  }

  export function getInventoryType(tour: Tour, store: Store<AppState>): Observable<TourInventoryType> {
    if (tour) {
      return store.select(fromInventoryType.selectById(tour.inventoryTypeId));
    } else {
      return null;
    }
  }

  export function getPanoramas(tour: Tour, store: Store<AppState>): Observable<Panorama[]> {
    if (tour) {
      return store.select(fromPanorama.selectAllByTourId(tour.id));
    } else {
      return null;
    }
  }

  export function getTrees(tour: Tour, store: Store<AppState>): Observable<Tree[]> {
    if (tour) {
      return store.select(fromTree.selectAllByTourId(tour.id));
    } else {
      return null;
    }
  }

  export function getType(tour: Tour, store: Store<AppState>): Observable<TreeType> {
    if (tour) {
      return store.select(fromTreeType.selectById(tour.id));
    } else {
      return null;
    }
  }
}
