import {createEntityAdapter, Dictionary, EntityAdapter, EntityState, Update} from '@ngrx/entity';
import {RealtimePanorama, SideView, View, ViewHelper, ViewOffset} from './realtime-panorama.model';
import {RealtimePanoramaActions, RealtimePanoramaActionTypes} from './realtime-panorama.actions';
import {PanoramaActions, PanoramaActionTypes} from '../panorama/panorama.actions';
import {Panorama} from '../panorama/panorama.model';
import {createSelector} from '@ngrx/store';
import * as fromRuntimeTour from '../runtime-tour/runtime-tour.reducer';
import {Side, SideHelper} from '../global/global.model';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';
import {AppState} from '../app.reducer';
import {RuntimeTourActions, RuntimeTourActionTypes} from '../runtime-tour/runtime-tour.actions';
import {AngleHelper} from '../tour/tour.model';

export interface Synchronization {
  enabled: boolean;
  origin: Side;
  offset: ViewOffset;
}

export interface State extends EntityState<RealtimePanorama> {
  synchronization: Synchronization; // keep this in a separate object to avoid unnecessary updates
}

export const adapter: EntityAdapter<RealtimePanorama> = createEntityAdapter<RealtimePanorama>();

export const initialState: State = adapter.getInitialState({
  synchronization: {
    enabled: false,
    origin: Side.Left,
    offset: null,
  }
});

function generateOne(panorama: Panorama): RealtimePanorama {
  return {
    id: panorama.id,
    view: null
  };
}

function generateMany(panoramas: Panorama[]) {
  return panoramas.map(generateOne);
}

export function reducer(
  state = initialState,
  action: RealtimePanoramaActions | PanoramaActions | RuntimeTourActions,
  root: { root: AppState },
): State {
  switch (action.type) {
    case PanoramaActionTypes.AddPanorama: {
      return adapter.addOne(generateOne(action.payload.panorama), state);
    }

    case PanoramaActionTypes.UpsertPanorama: {
      return adapter.upsertOne(generateOne(action.payload.panorama), state);
    }

    case PanoramaActionTypes.AddPanoramas: {
      return adapter.addMany(generateMany(action.payload.panoramas), state);
    }

    case PanoramaActionTypes.UpsertPanoramas: {
      return adapter.upsertMany(generateMany(action.payload.panoramas), state);
    }

    case PanoramaActionTypes.DeletePanorama: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PanoramaActionTypes.DeletePanoramas: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PanoramaActionTypes.LoadPanoramas: {
      return adapter.addAll(generateMany(action.payload.panoramas), state);
    }

    case PanoramaActionTypes.ClearPanoramas: {
      return adapter.removeAll(state);
    }

    case RealtimePanoramaActionTypes.SetView: {
      let newState = state;
      const thisRealtimePanorama = selectSelectedRealtimePanoramaOfSelectedTour(action.payload.side)(root);

      if (!thisRealtimePanorama) {
        return state;
      }

      const thisUpdate: Update<RealtimePanorama> = {
        id: thisRealtimePanorama.id,
        changes: {
          view: Object.assign({}, action.payload.view),
        }
      };

      newState = adapter.updateOne(thisUpdate, newState);

      if (thisRealtimePanorama.view == null) {
        return calculateSyncOffset(newState, root);
      } else {
        return synchronize(newState, root, action.payload.side);
      }
    }

    case RuntimeTourActionTypes.SetSelectedPanorama: {
      const otherUpdate: Update<RealtimePanorama> = {
        id: action.payload.panoramaId,
        changes: {
          view: null
        }
      };

      return adapter.updateOne(otherUpdate, state);
    }

    case RealtimePanoramaActionTypes.TogglePanoramaSync: {
      const newState: State = {
        ...state,
        synchronization: {
          ...state.synchronization,
          enabled: !state.synchronization.enabled,
        }
      };

      return calculateSyncOffset(newState, root);
    }

    case RealtimePanoramaActionTypes.SetSyncOrigin: {
      return {
        ...state,
        synchronization: {
          ...state.synchronization,
          origin: action.payload.side,
        }
      };
    }

    case RealtimePanoramaActionTypes.OrientToNorth: {
      const realtimePanorama = selectSelectedRealtimePanoramaOfSelectedTour(action.payload.side)(root);

      if (!realtimePanorama.view) {
        return state;
      }

      const view: SideView = {
        ...realtimePanorama.view,
        azimuth: AngleHelper.fromGeodesyDegrees(0),
        side: null,
      };

      const update: Update<RealtimePanorama> = {
        id: realtimePanorama.id,
        changes: {
          view: view
        }
      };

      const newState: State = {
        ...adapter.updateOne(update, state),
        synchronization: {
          ...state.synchronization,
          origin: null,
        }
      };
      return synchronize(newState, root, action.payload.side);
    }

    default: {
      return state;
    }
  }
}

function getRealtimeTourOnNewState(state: State, root: { root: AppState }, side: Side): RealtimePanorama {
  const oldRealtimePanorama = selectSelectedRealtimePanoramaOfSelectedTour(side)(root);

  if (oldRealtimePanorama) {
    return state.entities[oldRealtimePanorama.id];
  } else {
    return null;
  }
}

function calculateSyncOffset(state: State, root: { root: AppState }): State {
  const leftRealtimePanorama = getRealtimeTourOnNewState(state, root, Side.Left);
  const rightRealtimePanorama = getRealtimeTourOnNewState(state, root, Side.Right);

  let offset: ViewOffset = null;

  if (state.synchronization.enabled && rightRealtimePanorama && rightRealtimePanorama.view && leftRealtimePanorama && leftRealtimePanorama.view) {
    offset = ViewHelper.difference(leftRealtimePanorama.view, rightRealtimePanorama.view);
  }

  return {
    ...state,
    synchronization: {
      ...state.synchronization,
      offset,
    }
  };
}

function synchronize(state: State, root: { root: AppState }, side: Side) {
  const thisRealtimePanorama = getRealtimeTourOnNewState(state, root, side);
  const otherRealtimePanorama = getRealtimeTourOnNewState(state, root, SideHelper.other(side));

  if (!(state.synchronization.enabled && otherRealtimePanorama && otherRealtimePanorama.view)) {
    return state;
  }

  let otherView: View;

  if (side === Side.Left) {
    otherView = ViewHelper.subtract(thisRealtimePanorama.view, state.synchronization.offset);
  } else {
    otherView = ViewHelper.add(thisRealtimePanorama.view, state.synchronization.offset);
  }

  const otherUpdate: Update<RealtimePanorama> = {
    id: otherRealtimePanorama.id,
    changes: {
      view: {
        ...otherView,
        side: state.synchronization.origin
      }
    }
  };

  return adapter.updateOne(otherUpdate, state);
}

export const selectFeature = (state): EntityState<RealtimePanorama> => state.root.realtimePanoramas;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectSelectedRealtimePanoramaOfSelectedTour = (side: Side) => createSelector(
  selectEntities,
  fromRuntimeTour.selectSelectedRuntimeTour(side),
  (realtimePanoramaDictionary: Dictionary<RealtimePanorama>, runtimeTour: RuntimeTour) => {
    if (runtimeTour) {
      const panoramaId = runtimeTour.panoramaIds[side];
      return panoramaId ? realtimePanoramaDictionary[panoramaId] : null;
    } else {
      return null;
    }
  }
);

export const selectSynchronization = createSelector(
  selectFeature,
  (state: State) => state.synchronization
);
