import {selectSelectedPanoramaOfSelectedTour} from './../../../store/panorama/panorama.reducer';
import {Component, OnInit} from '@angular/core';
import {Content} from '../../../store/catalog/content/content.model';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {Media} from '../../../store/catalog/media/media.model';
import {Question} from '../../../store/catalog/question/question.model';
import {Spot} from '../../../store/spot/spot.model';
import {Store} from '@ngrx/store';
import {BackendService} from '../../../services/backend.service';
import {selectMedias} from '../../../store/catalog/media/media.reducer';
import {selectContents} from '../../../store/catalog/content/content.reducer';
import {selectQuestions} from '../../../store/catalog/question/question.reducer';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {selectSpots} from '../../../store/spot/spot.reducer';
import {Side} from '../../../store/global/global.model';
import {SwitchLearningMode} from '../../../store/habitat/content-card.actions';
import {LearningMode} from '../../../store/habitat/content-card.model';
import {Tree} from '../../../store/tree/tree.model';
import {selectSelectedTreeOfSelectedTour} from '../../../store/tree/tree.reducer';
import {translation} from '../../../app.translation';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Panorama} from 'src/app/store/panorama/panorama.model';

@Component({
  selector: 'app-tree-marking-mode',
  templateUrl: './tree-marking-mode.component.html',
  styleUrls: ['./tree-marking-mode.component.css']
})
export class TreeMarkingModeComponent implements OnInit {

  public selectedTree: Tree;
  public contents: Content[] = [];
  public subThemes: SubTheme[] = [];
  public medias: Media[] = [];
  public questions: Question[] = [];
  public spots: Spot[] = [];
  public selectedTree$: Observable<Tree>;
  public allSpots: Spot[] = [];
  public mores: boolean[] = [];
  public expands: boolean[] = [];
  public selectedPanorama: Panorama;

  constructor(private store: Store<any>, private backendService: BackendService, private translate: TranslateService) { }

  ngOnInit() {
    this.selectedTree$ = this.store.select(selectSelectedTreeOfSelectedTour(Side.Left));
    this.store.select(selectSelectedTreeOfSelectedTour(Side.Left)).subscribe((selectedTree) => {
      this.selectedTree = selectedTree;

      if (this.allSpots.length > 0) {
        this.updateSpots();
      }
    });
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias;
    });
    this.store.select(selectContents).subscribe(contents => {
      this.contents = contents;
    });
    this.store.select(selectQuestions).subscribe(questions => {
      this.questions = questions;
    });
    this.store.select(selectSubThemes).subscribe(subThemes => {
      this.subThemes = subThemes;
    });
    this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left)).subscribe((panorama) => {
      this.selectedPanorama = panorama;
    });

    this.store.select(selectSpots).subscribe(spots => {
      this.allSpots = spots;
      this.updateSpots();
    });
  }

  updateSpots() {
    if (this.selectedTree != null) {
      this.spots = this.allSpots.filter(x => x.treeNumber == this.selectedTree.treenumber &&
        x.panoramaId == this.selectedPanorama.id &&
        x.subThemeId != null);

      this.mores = [];
      this.expands = [];


      this.spots.forEach((x) => {

        this.mores.push(false);
        this.expands.push(false);
      });
    }
  }

  getAdditionalContent(spot: Spot): Content {
    return this.contents.find(x => x.id == spot.contentId);
  }

  getSubTheme(spot: Spot): SubTheme {
    if (this.subThemes.find(x => x.id == spot.subThemeId) != null) {
      return this.subThemes.find(x => x.id == spot.subThemeId);
    } else {
      return null;
    }
  }

  getDescriptionContent(spot: Spot): Content {
    return this.contents.find(x => x.id == this.getSubTheme(spot).descriptionContentId);
  }

  getGoodToKnowContent(spot: Spot): Content {
    return this.contents.find(x => x.id == this.getSubTheme(spot).goodToKnowContentId);
  }

  getAssociatedSpeciesContent(spot: Spot): Content {
    return this.contents.find(x => x.id == this.getSubTheme(spot).associatedSpeciesContentId);
  }

  getInventoryLevelContent(spot: Spot): Content {
    return this.contents.find(x => x.id == this.getSubTheme(spot).inventoryLevelContentId);
  }

  getOtherInfoContent(spot: Spot): Content {
    return this.contents.find(x => x.id == this.getSubTheme(spot).otherInfoContentId);
  }

  getNaturalFrequency(spot: Spot): number {
    if (this.getSubTheme(spot) != null) {
      return this.getSubTheme(spot).naturalFrequency;
    } else {
      return 0;
    }
  }

  getOperatedFrequency(spot: Spot): number {
    if (this.getSubTheme(spot) != null) {
      return this.getSubTheme(spot).operatedFrequency;
    } else {
      return 0;
    }
  }

  getSpeedReplacement(spot: Spot): number {
    if ( this.getSubTheme(spot) != null) {
      return this.getSubTheme(spot).replacementSpeed;
    } else {
      return 0;
    }
  }


  returnToGrid() {
    this.store.dispatch(new SwitchLearningMode({learningMode: LearningMode.Discovery}));
  }

  getFormIconUrl(spot: Spot) {
    const type = this.subThemes.find(x => x.level == 2 && x.id == spot.subThemeId);
    const group = this.subThemes.find(x => x.level == 1 && x.id == type.superThemeId);
    if ( type != null && group != null && this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId).nameEn != null) {
      return '"/assets/images/spots/' + this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId).iconUrl + '.png"';
    } else {
      return '"/assets/images/spots/empty.png"';
    }
  }

  getFormName(spot: Spot) {
    const type = this.subThemes.find(x => x.level == 2 && x.id == spot.subThemeId);
    const group = this.subThemes.find(x => x.level == 1 && x.id == type.superThemeId);
    if ( type != null && group != null && this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId) != null) {
      return translation(this.subThemes.find(x => x.level == 0 && x.id == group.superThemeId), 'name', this.translate);
    } else {
      return null;
    }
  }

  getGroupName(spot: Spot) {
    const type = this.subThemes.find(x => x.level == 2 && x.id == spot.subThemeId);
    return translation(this.subThemes.find(x => x.level == 1 && x.id == type.superThemeId), 'name', this.translate);
  }

  getTypeName(spot: Spot) {
    return translation(this.subThemes.find(x => x.level == 2 && x.id == spot.subThemeId), 'name', this.translate);
  }

  /*getQuestion(spot: Spot) {
    return this.questions.filter(x => x.subThemeId == spot.subThemeId)[0];
  }*/

  isDescription(spot: Spot) {
    if (this.getDescriptionContent(spot) == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.getDescriptionContent(spot).textDe != null && this.getDescriptionContent(spot).textDe != '';
        break;
      case 'fr':
        result = this.getDescriptionContent(spot).textFr != null && this.getDescriptionContent(spot).textFr != '';
        break;
      case 'en':
        result = this.getDescriptionContent(spot).textEn != null && this.getDescriptionContent(spot).textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.getDescriptionContent(spot).id).length > 0;
    return result;
  }

  isAdditionalContent(spot: Spot) {
    if (this.getAdditionalContent(spot) == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.getAdditionalContent(spot).textDe != null && this.getAdditionalContent(spot).textDe != '';
        break;
      case 'fr':
        result = this.getAdditionalContent(spot).textFr != null && this.getAdditionalContent(spot).textFr != '';
        break;
      case 'en':
        result = this.getAdditionalContent(spot).textEn != null && this.getAdditionalContent(spot).textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.getAdditionalContent(spot).id).length > 0;
    return result;
  }

  isGoodToKnow(spot: Spot) {
    if (this.getGoodToKnowContent(spot) == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.getGoodToKnowContent(spot).textDe != null && this.getGoodToKnowContent(spot).textDe != '';
        break;
      case 'fr':
        result = this.getGoodToKnowContent(spot).textFr != null && this.getGoodToKnowContent(spot).textFr != '';
        break;
      case 'en':
        result = this.getGoodToKnowContent(spot).textEn != null && this.getGoodToKnowContent(spot).textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.getGoodToKnowContent(spot).id).length > 0;
    return result;
  }

  isMoreContent(spot: Spot) {
    return this.isAssociatedSpecies(spot) || this.isOtherInfo(spot) || this.isInventoryLevel(spot);
  }

  isAssociatedSpecies(spot: Spot) {
    if (this.getAssociatedSpeciesContent(spot) == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.getAssociatedSpeciesContent(spot).textDe != null && this.getAssociatedSpeciesContent(spot).textDe != '';
        break;
      case 'fr':
        result = this.getAssociatedSpeciesContent(spot).textFr != null && this.getAssociatedSpeciesContent(spot).textFr != '';
        break;
      case 'en':
        result = this.getAssociatedSpeciesContent(spot).textEn != null && this.getAssociatedSpeciesContent(spot).textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.getAssociatedSpeciesContent(spot).id).length > 0;
    return result;
  }

  isInventoryLevel(spot: Spot) {
    if (this.getInventoryLevelContent(spot) == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.getInventoryLevelContent(spot).textDe != null && this.getInventoryLevelContent(spot).textDe != '';
        break;
      case 'fr':
        result = this.getInventoryLevelContent(spot).textFr != null && this.getInventoryLevelContent(spot).textFr != '';
        break;
      case 'en':
        result = this.getInventoryLevelContent(spot).textEn != null && this.getInventoryLevelContent(spot).textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.getInventoryLevelContent(spot).id).length > 0;
    return result;
  }

  isOtherInfo(spot: Spot) {
    if (this.getOtherInfoContent(spot) == null) {
      return false;
    }
    let result;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.getOtherInfoContent(spot).textDe != null && this.getOtherInfoContent(spot).textDe != '';
        break;
      case 'fr':
        result = this.getOtherInfoContent(spot).textFr != null && this.getOtherInfoContent(spot).textFr != '';
        break;
      case 'en':
        result = this.getOtherInfoContent(spot).textEn != null && this.getOtherInfoContent(spot).textEn != '';
        break;
    }
    result = result || this.medias.filter(x => x.contentId == this.getOtherInfoContent(spot).id).length > 0;
    return result;
  }

}
