import {Content} from '../content/content.model';
import {LocalResponse} from '../response/response.model';

export class Question {
  id: number;
  subThemeId: number;
  contentId: number;
  multipleChoice: boolean;
  determination: boolean;
  explanationFr: string;
  explanationDe: string;
  explanationEn: string;
}

export class LocalQuestion {
  id: number;
  content: Content;
  responses: LocalResponse[];
}
