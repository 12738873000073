 import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Tour, TourHelper} from '../../store/tour/tour.model';
import {Store} from '@ngrx/store';
import * as FromPanorama from 'src/app/store/all-panorama/all-panorama.reducer';
import {Tree} from 'src/app/store/tree/tree.model';
import {selectTourDisabledModus} from 'src/app/store/tour-disabled-modus/tour-disabled-modus.reducer';
import {TourDisabledModus} from 'src/app/store/tour-disabled-modus/tour-disabled-modus.model';
import {Spot} from 'src/app/store/spot/spot.model';
import {LearningMode} from 'src/app/store/habitat/content-card.model';
import {selectUser} from 'src/app/store/user/user.reducer';
import {selectSpots} from 'src/app/store/spot/spot.reducer';
import {User, UserHelper} from 'src/app/store/user/user.model';
import {selectScores} from 'src/app/store/score/score.reducer';
import {selectAnswers} from 'src/app/store/answer/answer.reducer';
import {Score} from 'src/app/store/score/score.model';
import {Answer} from 'src/app/store/answer/answer.model';
import {Theme} from 'src/app/store/catalog/theme/theme.model';
import {selectThemes} from 'src/app/store/catalog/theme/theme.reducer';
import {selectTourThematics} from 'src/app/store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from 'src/app/store/tour-thematic/tour-thematic.model';
import {translation} from 'src/app/app.translation';
import {AllPanorama} from 'src/app/store/all-panorama/all-panorama.model';

@Component({
  selector: 'app-tours-item',
  templateUrl: './tours-item.component.html',
  styleUrls: ['./tours-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToursItemComponent implements OnInit {
  @Input() tour: Tour;
  @Input() selected: boolean;
  @Output() selectTour = new EventEmitter<Tour>();

  public trees: Tree[];

  public TourHelper = TourHelper;
  private tourDisabledModus: TourDisabledModus[] = [];

  public scores: Score[]  = [];
  public answers: Answer[]  = [];
  public panoramas: AllPanorama[]  = [];
  public spots: Spot[] = [];
  public user: User;

  public themes: Theme[];
  public tourThematic: TourThematic = null;

  public blueSize = 0; // keep
  public redSize = 0; // cut
  public greenSize = 100; // no status

  public mode1Count = 0;
  public mode2Count = 0;
  public mode3Count = 0;
  public modeTotalCount = 0;

  constructor(public translate: TranslateService, public store: Store<any>) {
  }

  ngOnInit() {

    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });

    this.store.select(selectTourThematics).subscribe((tourThematics: TourThematic[]) => {


      this.tourThematic = tourThematics.find(x => x.tourId == +this.tour.id);
    });

    this.store.select(selectTourDisabledModus).subscribe((tourDisabledModus) => {


      this.tourDisabledModus = tourDisabledModus.filter(x => x.tourId == +this.tour.id);
    });

    this.store.select(selectUser).subscribe((user) => {
      this.user = user;

    });

    this.store.select(FromPanorama.selectAll).subscribe((panoramas) => {
      this.panoramas = panoramas;

    });

    this.store.select(selectSpots).subscribe((spots) => {
      this.spots = spots;

    });


    if (UserHelper.isLoggedIn(this.user)) {

      this.store.select(selectScores).subscribe((scores) => {
        this.scores = scores;

      });

      this.store.select(selectAnswers).subscribe((answers) => {
        this.answers = answers;

      });


      // Martelage stats
      if (+this.tour.statusCount[1] > 0) {
        this.redSize = Math.max(5, ((+this.tour.statusCount[1]) / (+this.tour.treeCount)) * 100);
      }
      if (+this.tour.statusCount[2] > 0) {
        this.blueSize =  Math.max(5, ((+this.tour.statusCount[2]) / (+this.tour.treeCount)) * 100);
      }
      this.greenSize = 100 - this.redSize - this.blueSize;

      // Habitat stats






      this.mode1Count = this.getPoints(this.tour, LearningMode.Discovery);
      this.mode2Count = this.getPoints(this.tour, LearningMode.Determination);
      this.mode3Count = this.getPoints(this.tour, LearningMode.Research);
      this.modeTotalCount = this.getMaxPoints(this.tour);

    }


  }

  isMode4Available() {
    return this.tourDisabledModus.find(x => x.modusId === 4) == null;
  }

  isMode3Available() {
    return this.tourDisabledModus.find(x => x.modusId === 3) == null;
  }

  isMode2Available() {
    return this.tourDisabledModus.find(x => x.modusId === 2) == null;
  }

  isMode1Available() {
    return this.tourDisabledModus.find(x => x.modusId === 1) == null;
  }

  onSelectTour(tour: Tour) {
    this.selectTour.emit(tour);
  }

  isSpotResponded(spot: Spot, tour: Tour, learningMode: LearningMode): boolean {
    const score = this.scores.find(x => x.tourId == +tour.id
      && x.spotId == +spot.id && x.userId === this.user.id && x.modeId === learningMode);
    if (score != null) {
      if (learningMode === LearningMode.Discovery) {
        return this.answers.filter(x => x.scoreId === score.id && x.correct).length > 0;
      } else {
        return this.answers.filter(x => x.scoreId === score.id && x.correct).length === this.answers.filter(x => x.scoreId === score.id).length;
      }
    } else {
      return null;
    }
  }

  getPoints(tour: Tour, learningMode: LearningMode): number {
    let res = 0;
    this.getPanoramas(tour).forEach( (p) => {
      this.getSpots(p).forEach( (s) => {
        if (this.isSpotResponded(s, tour, learningMode)) {
          res += 1;
        }
      });
    });
    return res;
  }

  getMaxPoints(tour: Tour): number {
    let res = 0;
    this.getPanoramas(tour).forEach( (p) => {
      this.getSpots(p).forEach( (s) => {
        res += 1;
      });
    });
    return res;
  }

  getPanoramas(tour: Tour) {
    return this.panoramas.filter(x => x.tourId === tour.id);
  }

  getSpots(panorama: AllPanorama) {
    return this.spots.filter(x => x.panoramaId === panorama.id);
  }

  getTheme() {
    let theme;
    if (this.tourThematic != null) {
      theme = this.themes.find(x => x.id === this.tourThematic.themeId);
    }

    if (theme != null) {
      return theme;
    } else {
      return null;
    }
  }

  translateTheme(preString: string): string {
    return translation(this.getTheme(), preString, this.translate);
  }

  getTitle() {
    let result = this.tour.title;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.tour.titleDe;
        break;
      case 'fr':
        result = this.tour.titleFr;
        break;
      case 'en':
        result = this.tour.titleEn;
        break;
    }
    return result;
  }

  getDescription() {
    let result = this.tour.description;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.tour.descriptionDe;
        break;
      case 'fr':
        result = this.tour.descriptionFr;
        break;
      case 'en':
        result = this.tour.descriptionEn;
        break;
    }
    return result;
  }

}
