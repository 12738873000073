import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Media} from '../../../store/catalog/media/media.model';
import {MediaType} from '../../../store/catalog/media-type/media-type.model';
import {selectMediaTypes} from '../../../store/catalog/media-type/media-type.reducer';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {BackendService} from '../../../services/backend.service';
import {selectMedias} from '../../../store/catalog/media/media.reducer';
import {DeleteMedia, UpdateMedia} from '../../../store/catalog/media/media.actions';
import {TranslateService} from "@ngx-translate/core";
import {translation} from "../../../app.translation";

export interface DialogData {
  editor: boolean;
  media: Media;
}

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.css']
})
export class MediaDialogComponent implements OnInit {

  mediaTypes: MediaType[] = [];
  medias: Media[];

  tmpMedia = new Media();

  player: YT.Player;
  private id = 'aVS4W7GZSq0';

  constructor(
      private backendService: BackendService,
      private store: Store<AppState>,
      public dialogRef: MatDialogRef<MediaDialogComponent>,
      private translate: TranslateService,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
    this.tmpMedia.textFr = this.data.media.textFr;
    this.tmpMedia.textEn = this.data.media.textEn;
    this.tmpMedia.textDe = this.data.media.textDe;
    this.tmpMedia.mediaUrl = this.data.media.mediaUrl;
    this.tmpMedia.mediaType = this.data.media.mediaType;
    this.tmpMedia.hyperlink = this.data.media.hyperlink;
    this.tmpMedia.id = this.data.media.id;
    this.store.select(selectMediaTypes).subscribe(mediaTypes => {
      this.mediaTypes = mediaTypes;
    });
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  updateMedia() {
    this.data.media.textFr = this.tmpMedia.textFr;
    this.data.media.textEn = this.tmpMedia.textEn;
    this.data.media.textDe = this.tmpMedia.textDe;
    this.data.media.mediaUrl = this.tmpMedia.mediaUrl;
    this.data.media.mediaType = this.tmpMedia.mediaType;
    this.data.media.hyperlink = this.tmpMedia.hyperlink;
    this.data.media.id = this.tmpMedia.id;

    this.backendService.updateMedia(this.data.media).subscribe( res => {

      this.store.dispatch(new UpdateMedia({media: {id: this.data.media.id, changes: this.data.media}}));
    });
  }

  deleteMedia() {
    this.backendService.deleteMedia(this.data.media).subscribe(res => {

      this.dialogRef.close();
      this.medias.splice(this.medias.indexOf(this.data.media), 1);
      this.store.dispatch(new DeleteMedia( {id: this.data.media.id.toString()}));
    });
  }

  savePlayer(player) {
    this.player = player;

  }

  onStateChange(event) {

  }

  translateMediaDialog(object, preString: string): string {
    return translation(object, preString, this.translate);
  }

}
