import {Component, Input, OnInit} from '@angular/core';
import {Media} from '../../../store/catalog/media/media.model';
import {Content} from '../../../store/catalog/content/content.model';
import {Store} from '@ngrx/store';
import {BackendService} from '../../../services/backend.service';
import {MediaDialogComponent} from '../../dialogs/media-dialog/media-dialog.component';
import {MatDialog} from '@angular/material';
import {AddMedia} from '../../../store/catalog/media/media.actions';
import {selectMedias} from '../../../store/catalog/media/media.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-liste-media',
  templateUrl: './liste-media.component.html',
  styleUrls: ['./liste-media.component.css']
})
export class ListeMediaComponent implements OnInit {

  @Input() editable: boolean;
  @Input() contentId: number;
  @Input() more: boolean;

  public medias: Media[] = [];
  public medias$: Observable<Media[]>;
  public content: Content;


  constructor(private store: Store<any>, private backendService: BackendService, public dialog: MatDialog) { }

  ngOnInit() {
    if (this.more == null) {
      this.more = false;
    }

    this.medias$ = this.store.select(selectMedias);
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias;
    });
  }

  getMedias() {
    return this.medias.filter(x => x.contentId == this.contentId);
  }

  createMedia() {
    if (this.contentId != null && this.contentId !== 0) {

      const media: Media = new Media();
      media.contentId = this.contentId;
      media.mediaUrl = '';
      media.hyperlink = '';
      media.mediaType = 1;
      this.backendService.createMedia(media).subscribe( res => {

        if (res > 0) {
          media.id = res;
          this.medias.push(media);
          this.store.dispatch(new AddMedia({media: media}));
        }
      });

    }
  }

  mediaClicked(media: Media) {
    const dialogRef = this.dialog.open(MediaDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {editor: this.editable, media: media}
    });

    dialogRef.afterClosed();
  }

}
