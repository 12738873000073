import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class XHRService {
  constructor(private httpClient: HttpClient) {
  }

  get<T>(url): Observable<T> {
    const httpOptions = {
      //withCredentials: true,
    };

    return this.httpClient.get<T>(url, httpOptions).pipe(catchError(error => {
      console.error('unable to get data', error);
      return throwError('unable to get data');
    }));
  }

  post<T>(url, data: any): Observable<T> {
    const httpOptions = {
      //withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.httpClient.post<T>(url, data, httpOptions).pipe(catchError(error => {
      console.error('unable to post data', error);
      return throwError('unable to post data');
    }));
  }
}
