import {TranslateService} from '@ngx-translate/core';

export function translation(object, preString: string, translate: TranslateService): string {
  let result: string = object[`${preString}En`];
  switch (translate.currentLang) {
    case 'de':
      result = object[`${preString}De`];
      break;
    case 'fr':
      result = object[`${preString}Fr`];
      break;
    case 'en':
      result = object[`${preString}En`];
      break;
  }
  return result;
}
