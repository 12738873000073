import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TourThematic} from './tour-thematic.model';
import {TourThematicActions, TourThematicActionTypes} from './tour-thematic.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TourThematic> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TourThematic> = createEntityAdapter<TourThematic>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TourThematicActions
): State {
  switch (action.type) {
    case TourThematicActionTypes.AddTourThematic: {
      return adapter.addOne(action.payload.tourThematic, state);
    }

    case TourThematicActionTypes.UpsertTourThematic: {
      return adapter.upsertOne(action.payload.tourThematic, state);
    }

    case TourThematicActionTypes.AddTourThematics: {
      return adapter.addMany(action.payload.tourThematics, state);
    }

    case TourThematicActionTypes.UpsertTourThematics: {
      return adapter.upsertMany(action.payload.tourThematics, state);
    }

    case TourThematicActionTypes.UpdateTourThematic: {
      return adapter.updateOne(action.payload.tourThematic, state);
    }

    case TourThematicActionTypes.UpdateTourThematics: {
      return adapter.updateMany(action.payload.tourThematics, state);
    }

    case TourThematicActionTypes.DeleteTourThematic: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourThematicActionTypes.DeleteTourThematics: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourThematicActionTypes.LoadTourThematics: {
      return adapter.addAll(action.payload.tourThematics, state);
    }

    case TourThematicActionTypes.ClearTourThematics: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TourThematic> => state.root.tourThematics;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectTourThematics = createSelector(selectAll, tourThematics => tourThematics);
