import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {Tree} from './tree.model';

export enum TreeActionTypes {
  LoadTrees = '[Tree] Load Trees',
  AddTree = '[Tree] Add Tree',
  UpsertTree = '[Tree] Upsert Tree',
  AddTrees = '[Tree] Add Trees',
  UpsertTrees = '[Tree] Upsert Trees',
  UpdateTree = '[Tree] Update Tree',
  UpdateTrees = '[Tree] Update Trees',
  DeleteTree = '[Tree] Delete Tree',
  DeleteTrees = '[Tree] Delete Trees',
  ClearTrees = '[Tree] Clear Trees',
  SetTreeState = '[Tree] Set Tree State',
  SetTreeComment = '[Tree] Set Tree Comment',
  ToggleTreeReason = '[Tree] Toggle Tree Reason',
}

export class LoadTrees implements Action {
  readonly type = TreeActionTypes.LoadTrees;

  constructor(public payload: { trees: Tree[] }) {
  }
}

export class AddTree implements Action {
  readonly type = TreeActionTypes.AddTree;

  constructor(public payload: { tree: Tree }) {
  }
}

export class UpsertTree implements Action {
  readonly type = TreeActionTypes.UpsertTree;

  constructor(public payload: { tree: Tree }) {
  }
}

export class AddTrees implements Action {
  readonly type = TreeActionTypes.AddTrees;

  constructor(public payload: { trees: Tree[] }) {
  }
}

export class UpsertTrees implements Action {
  readonly type = TreeActionTypes.UpsertTrees;

  constructor(public payload: { trees: Tree[] }) {
  }
}

export class UpdateTree implements Action {
  readonly type = TreeActionTypes.UpdateTree;

  constructor(public payload: { tree: Update<Tree> }) {
  }
}

export class UpdateTrees implements Action {
  readonly type = TreeActionTypes.UpdateTrees;

  constructor(public payload: { trees: Update<Tree>[] }) {
  }
}

export class DeleteTree implements Action {
  readonly type = TreeActionTypes.DeleteTree;

  constructor(public payload: { id: string }) {
  }
}

export class DeleteTrees implements Action {
  readonly type = TreeActionTypes.DeleteTrees;

  constructor(public payload: { ids: string[] }) {
  }
}

export class ClearTrees implements Action {
  readonly type = TreeActionTypes.ClearTrees;
}

export class SetTreeState implements Action {
  readonly type = TreeActionTypes.SetTreeState;

  constructor(public payload: { treeId: string, stateId: string }) {
  }
}

export class SetTreeComment implements Action {
  readonly type = TreeActionTypes.SetTreeComment;

  constructor(public payload: { treeId: string, comment: string }) {
  }
}

export class ToggleTreeReason implements Action {
  readonly type = TreeActionTypes.ToggleTreeReason;

  constructor(public payload: { treeId: string, reasonId: string }) {
  }
}

export type TreeActions =
  LoadTrees
  | AddTree
  | UpsertTree
  | AddTrees
  | UpsertTrees
  | UpdateTree
  | UpdateTrees
  | DeleteTree
  | DeleteTrees
  | ClearTrees
  | SetTreeState
  | SetTreeComment
  | ToggleTreeReason;
