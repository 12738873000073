import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {TourCategory} from './tour-category.model';

export enum TourCategoryActionTypes {
  LoadTourCategories = '[TourCategory] Load TourCategories',
  AddTourCategory = '[TourCategory] Add TourCategory',
  UpsertTourCategory = '[TourCategory] Upsert TourCategory',
  AddTourCategories = '[TourCategory] Add TourCategories',
  UpsertTourCategories = '[TourCategory] Upsert TourCategories',
  UpdateTourCategory = '[TourCategory] Update TourCategory',
  UpdateTourCategories = '[TourCategory] Update TourCategories',
  DeleteTourCategory = '[TourCategory] Delete TourCategory',
  DeleteTourCategories = '[TourCategory] Delete TourCategories',
  ClearTourCategories = '[TourCategory] Clear TourCategories'
}

export class LoadTourCategories implements Action {
  readonly type = TourCategoryActionTypes.LoadTourCategories;

  constructor(public payload: { tourCategories: TourCategory[] }) {
  }
}

export class AddTourCategory implements Action {
  readonly type = TourCategoryActionTypes.AddTourCategory;

  constructor(public payload: { tourCategory: TourCategory }) {
  }
}

export class UpsertTourCategory implements Action {
  readonly type = TourCategoryActionTypes.UpsertTourCategory;

  constructor(public payload: { tourCategory: TourCategory }) {
  }
}

export class AddTourCategories implements Action {
  readonly type = TourCategoryActionTypes.AddTourCategories;

  constructor(public payload: { tourCategories: TourCategory[] }) {
  }
}

export class UpsertTourCategories implements Action {
  readonly type = TourCategoryActionTypes.UpsertTourCategories;

  constructor(public payload: { tourCategories: TourCategory[] }) {
  }
}

export class UpdateTourCategory implements Action {
  readonly type = TourCategoryActionTypes.UpdateTourCategory;

  constructor(public payload: { tourCategory: Update<TourCategory> }) {
  }
}

export class UpdateTourCategories implements Action {
  readonly type = TourCategoryActionTypes.UpdateTourCategories;

  constructor(public payload: { tourCategories: Update<TourCategory>[] }) {
  }
}

export class DeleteTourCategory implements Action {
  readonly type = TourCategoryActionTypes.DeleteTourCategory;

  constructor(public payload: { id: string }) {
  }
}

export class DeleteTourCategories implements Action {
  readonly type = TourCategoryActionTypes.DeleteTourCategories;

  constructor(public payload: { ids: string[] }) {
  }
}

export class ClearTourCategories implements Action {
  readonly type = TourCategoryActionTypes.ClearTourCategories;
}

export type TourCategoryActions =
  LoadTourCategories
  | AddTourCategory
  | UpsertTourCategory
  | AddTourCategories
  | UpsertTourCategories
  | UpdateTourCategory
  | UpdateTourCategories
  | DeleteTourCategory
  | DeleteTourCategories
  | ClearTourCategories;
