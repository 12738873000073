import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {SwitchLearningMode} from '../../../store/habitat/content-card.actions';
import {LearningMode} from '../../../store/habitat/content-card.model';
import {Score} from '../../../store/score/score.model';
import {Answer} from '../../../store/answer/answer.model';
import {User, UserHelper} from '../../../store/user/user.model';
import {selectUser} from '../../../store/user/user.reducer';
import {selectScores} from '../../../store/score/score.reducer';
import {selectAnswers} from '../../../store/answer/answer.reducer';
import {selectLearningMode} from '../../../store/habitat/content-card.reducer';
import {selectSelectedSpotOfSelectedTour} from '../../../store/spot/spot.reducer';
import {Side} from '../../../store/global/global.model';
import {LocalQuestion, Question} from '../../../store/catalog/question/question.model';
import {Spot} from '../../../store/spot/spot.model';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {selectQuestions} from '../../../store/catalog/question/question.reducer';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {selectTourThematics} from '../../../store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from '../../../store/tour-thematic/tour-thematic.model';
import {selectSelectedTour} from '../../../store/tour/tour.reducer';
import {Tour} from '../../../store/tour/tour.model';
import {Theme} from '../../../store/catalog/theme/theme.model';
import {selectThemes} from '../../../store/catalog/theme/theme.reducer';
import {Content} from '../../../store/catalog/content/content.model';
import {LocalResponse} from '../../../store/catalog/response/response.model';
import {Observable} from 'rxjs';
import {UpdateSpot} from '../../../store/spot/spot.actions';
import {BackendService} from 'src/app/services/backend.service';


@Component({
  selector: 'app-research-mode',
  templateUrl: './research-mode.component.html',
  styleUrls: ['./research-mode.component.css']
})
export class ResearchModeComponent implements OnInit {
  public themes: Theme[];
  public subThemes: SubTheme[] = [];
  public questions: Question[] = [];

  public scores: Score[]  = [];
  public answers: Answer[]  = [];
  public user: User;
  public UserHelper = UserHelper;
  public learningMode: LearningMode;

  public spot: Spot;
  public spot$: Observable<Spot>;
  public currentSubTheme: SubTheme;

  public questionType: LocalQuestion;
  public questionGroup: LocalQuestion;
  public questionForm: LocalQuestion;
  public isFormResponded = false;
  public isGroupResponded = false;

  public answers$: Observable<Answer[]>;

  public tourThematic: TourThematic = null;
  public selectedTour: Tour;

  public show = false;
  public justOpen = true;

  constructor(private store: Store<any>, private backendService: BackendService) { }

  ngOnInit() {

    this.store.select(selectSelectedTour(Side.Left)).subscribe((tour) => {
      this.selectedTour = tour;
    });

    this.store.select(selectTourThematics).subscribe((tourThematics) => {
      this.tourThematic = tourThematics.find(x => x.tourId == +this.selectedTour.id);
    });

    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
    });

    this.store.select(selectScores).subscribe((scores) => {
      this.scores = scores;
    });

    this.store.select(selectLearningMode).subscribe((learningMode) => {
      this.learningMode = learningMode;
    });

    this.store.select(selectQuestions).subscribe(questions => {
      this.questions = questions.filter(x => x.determination);
    });
    this.store.select(selectSubThemes).subscribe(subThemes => {
      this.subThemes = subThemes;
    });

    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });

    this.spot$ = this.store.select(selectSelectedSpotOfSelectedTour(Side.Left));
    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe( selectedSpot => {
      this.spot = null;
      this.spot = selectedSpot;
      this.questionType = null;
      this.questionGroup = null;
      this.questionForm = null;
      this.isGroupResponded = false;
      this.isFormResponded = false;

      this.show = this.getSpotScore() && this.isScoreCorrect();

      if (this.show) {
        this.isGroupResponded = true;
        this.isFormResponded = true;
      }

      this.getQuestionType();
      this.getQuestionGroup();
      this.getQuestionForm();

      this.currentSubTheme = this.subThemes.find(x => this.spot != null && x.id == this.spot.subThemeId);
    });

    this.answers$ = this.store.select(selectAnswers);

    this.store.select(selectAnswers).subscribe((answers) => {
      this.answers = answers;

      this.isGroupResponded = this.checkResponded(-2);
      this.isFormResponded = this.checkResponded(-1);

      if (this.getSpotScore()) {
        this.store.dispatch(new UpdateSpot({spot: {id: this.spot.id, changes: this.spot}}));
      }

      if (this.justOpen) {
        this.isGroupResponded = false;
        this.isFormResponded = false;

        this.show = this.getSpotScore() && this.isScoreCorrect();

        if (this.show) {
          this.isGroupResponded = true;
          this.isFormResponded = true;
        }

        this.justOpen = false;
      }

      this.getQuestionType();
      this.getQuestionGroup();
      this.getQuestionForm();


    });
  }

  returnToGrid() {
    this.store.dispatch(new SwitchLearningMode({learningMode: LearningMode.Research}));
  }

  getTheme() {
    let theme;
    if (this.tourThematic != null) {
      theme = this.themes.find(x => x.id == this.tourThematic.themeId);
    }
    return theme;
  }

  getQuestionType() {
    this.questionType = null;
    if (/*UserHelper.isLoggedIn(this.user) &&*/ this.questionType == null) {

      const questionType = new LocalQuestion();
      questionType.id = -3;

      const content = new Content();
      content.id = -3;
      content.textFr = 'De quel type s\'agit-il ?';
      content.textDe = 'Welcher Typ ist es?';
      content.textEn = 'Which type is it ?';

      questionType.content = content;

      const responses: LocalResponse[] = [];

      // for each subTheme (level 2) of the current theme
      this.subThemes.filter(x => x.themeId == this.tourThematic.themeId && x.superThemeId == this.getGroup().id && x.level == 2).forEach((x) => {
        const localResponse = new LocalResponse();
        if (x.id == +this.getType().id) {
          localResponse.correct = true;
        } else {
          localResponse.correct = false;
        }
        const respContent = new Content();
        respContent.textFr = x.nameFr;
        respContent.textDe = x.nameDe;
        respContent.textEn = x.nameEn;

        localResponse.content = respContent;

        responses.push(localResponse);
      });

      questionType.responses = responses;

      this.questionType = questionType;
      return this.questionType;
    } else {
      return null;
    }
  }

  getQuestionGroup() {
    this.questionGroup = null;
    if (/*UserHelper.isLoggedIn(this.user) &&*/ this.questionGroup == null) {
      const questionGroup = new LocalQuestion();
      questionGroup.id = -2;

      const content = new Content();
      content.id = -2;
      content.textFr = 'De quel groupe s\'agit-il ?';
      content.textDe = 'Welche Gruppe ist es?';
      content.textEn = 'Which group is it ?';

      questionGroup.content = content;

      const responses: LocalResponse[] = [];

      // for each subTheme (level 1) of the current theme
      this.subThemes.filter(x => x.themeId == this.tourThematic.themeId && x.superThemeId == this.getForm().id && x.level == 1).forEach((x) => {
        const localResponse = new LocalResponse();
        if (x.id == +this.getGroup().id) {
          localResponse.correct = true;
        } else {
          localResponse.correct = false;
        }
        const respContent = new Content();
        respContent.textFr = x.nameFr;
        respContent.textDe = x.nameDe;
        respContent.textEn = x.nameEn;

        localResponse.content = respContent;

        responses.push(localResponse);
      });

      questionGroup.responses = responses;

      this.questionGroup = questionGroup;
      return this.questionGroup;
    } else {
      return null;
    }
  }

  getQuestionForm() {
    this.questionForm = null;
    if (/*UserHelper.isLoggedIn(this.user) && */this.questionForm == null) {
      const questionForm = new LocalQuestion();
      questionForm.id = -1;

      const content = new Content();
      content.id = -1;
      content.textFr = 'De quelle forme s\'agit-il ?';
      content.textDe = 'Welche Form ist es?';
      content.textEn = 'Which form is it ?';

      questionForm.content = content;

      const responses: LocalResponse[] = [];

      // for each subTheme (level 0) of the current theme


      this.subThemes.filter(x => x.themeId == this.tourThematic.themeId && +x.level == 0).forEach((x) => {
        const localResponse = new LocalResponse();
        if (x.id == +this.getForm().id) {
          localResponse.correct = true;
        } else {
          localResponse.correct = false;
        }
        const respContent = new Content();
        respContent.textFr = x.nameFr;
        respContent.textDe = x.nameDe;
        respContent.textEn = x.nameEn;

        localResponse.content = respContent;

        responses.push(localResponse);
      });

      questionForm.responses = responses;

      this.questionForm = questionForm;
      return this.questionForm;
    } else {
      return null;
    }
  }

  getSpotScore() {
    if (this.spot == null) {
      return false;
    }
    const score = this.scores.find(x => x.spotId == +this.spot.id && x.modeId == this.learningMode && x.userId == this.user.id);

    if (score == null) {
      return false;
    } else {
      return this.answers.filter(x => x.scoreId == score.id).length == 3 /*|| (this.answers.filter(x => x.scoreId == score.id).length != this.answers.filter(x => x.scoreId == score.id && x.correct).length )*/;
    }
  }

  isScoreCorrect() {
    const spotScore = this.scores.find(x => x.spotId == +this.spot.id && x.modeId == this.learningMode && x.userId == this.user.id);
    if (spotScore != null) {
      return this.answers.filter(x => x.scoreId == spotScore.id && x.correct).length >= 3;
    } else {
      return false;
    }
  }

  getType() {
    const typ = this.subThemes.find(x => x.id == this.spot.subThemeId);
    if (typ == null) {
      return null;
    }
    return typ;
  }

  getGroup() {
    const typ = this.subThemes.find(x => x.id == this.spot.subThemeId);
    if (typ == null) {
      return null;
    }
    const group = this.subThemes.find(x => x.id == typ.superThemeId);
    if (group == null) {
      return null;
    }
    return group;
  }

  getForm() {
    const typ = this.subThemes.find(x => x.id == this.spot.subThemeId);
    if (typ == null) {
      return null;
    }
    const group = this.subThemes.find(x => x.id == typ.superThemeId);
    if (group == null) {
      return null;
    }
    const form = this.subThemes.find(x => x.id == group.superThemeId);
    if (form == null) {
      return null;
    }
    return form;
  }

  checkResponded(scoreId: number) {
    let res = false;

    const spotScore = this.scores.find(x => this.spot != null && x.spotId == +this.spot.id && x.modeId == this.learningMode);
    if (spotScore != null) {
      res =  this.answers.filter(x => x.scoreId == spotScore.id && x.questionId == scoreId).length > 0;
    }

    //
    return res;
  }

}
