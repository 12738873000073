import {Component, Input, OnInit} from '@angular/core';
import {Question} from '../../../../store/catalog/question/question.model';
import {Response} from '../../../../store/catalog/response/response.model';
import {Store} from '@ngrx/store';
import {selectMedias} from '../../../../store/catalog/media/media.reducer';
import {selectContents} from '../../../../store/catalog/content/content.reducer';
import {Media} from '../../../../store/catalog/media/media.model';
import {Content} from '../../../../store/catalog/content/content.model';
import {selectResponses} from '../../../../store/catalog/response/response.reducer';
import {selectSelectedTour} from '../../../../store/tour/tour.reducer';
import {Side} from '../../../../store/global/global.model';
import {selectLearningMode} from '../../../../store/habitat/content-card.reducer';
import {selectSelectedSpotOfSelectedTour} from '../../../../store/spot/spot.reducer';
import {selectScores} from '../../../../store/score/score.reducer';
import {selectAnswers} from '../../../../store/answer/answer.reducer';
import {Tour} from '../../../../store/tour/tour.model';
import {LearningMode} from '../../../../store/habitat/content-card.model';
import {Spot} from '../../../../store/spot/spot.model';
import {Answer} from '../../../../store/answer/answer.model';
import {Score} from '../../../../store/score/score.model';
import {BackendService} from '../../../../services/backend.service';
import {User} from '../../../../store/user/user.model';
import {selectUser} from '../../../../store/user/user.reducer';
import {AddScore} from '../../../../store/score/score.actions';
import {AddAnswer} from '../../../../store/answer/answer.actions';
import {TranslateService} from '@ngx-translate/core';
import {translation} from '../../../../app.translation';

@Component({
  selector: 'app-question-content',
  templateUrl: './question-content.component.html',
  styleUrls: ['./question-content.component.css']
})
export class QuestionContentComponent implements OnInit {

  @Input() question: Question;

  public responses: Response[] = [];
  public checked: boolean[] = [];
  public medias: Media[] = [];
  public contents: Content[] = [];

  public selectedTour: Tour;
  public user: User;
  public learningMode: LearningMode;
  public selectedSpot: Spot;
  public scores: Score[] = [];
  public answers: Answer[] = [];

  public controlled = false;

  constructor(private store: Store<any>, private backendService: BackendService, private translate: TranslateService) { }

  ngOnInit() {

    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
    });

    this.store.select(selectSelectedTour(Side.Left)).subscribe((selectedTour) => {
      this.selectedTour = selectedTour;
    });

    this.store.select(selectLearningMode).subscribe((learningMode) => {
      this.learningMode = learningMode;
    });

    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe((selectedSpot) => {
      this.selectedSpot = selectedSpot;
      this.checked = [];
      this.responses.filter(x => x.questionId == this.question.id).forEach( x => this.checked.push(false));
      this.controlled = false;
    });

    this.store.select(selectScores).subscribe((scores) => {
      this.scores = scores;
    });

    this.store.select(selectAnswers).subscribe((answers) => {
      this.answers = answers;
    });

    this.store.select(selectResponses).subscribe(responses => {
      this.responses = responses;

      this.checked = [];
      responses.filter(x => x.questionId == this.question.id).forEach( x => this.checked.push(false));
    });
    this.store.select(selectContents).subscribe(contents => {
      this.contents = contents;
    });
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias.filter(x => x.contentId == this.getQuestionContent().id);


    });

  }

  getResponses() {
    return this.responses.filter(x => x.questionId == this.question.id);
  }

  getQuestionContent() {
    return this.contents.find(x => x.id == this.question.contentId);
  }

  isContentValid() {
    return this.question != null;
  }

  getResponseContent(response: Response) {
    return this.contents.find(x => x.id == response.contentId);
  }

  getScore() {
    return this.scores.find(x => +x.userId == this.user.id && +x.modeId == this.learningMode && +x.spotId == +this.selectedSpot.id && +x.tourId == +this.selectedTour.id);
  }

  isChecked(response: Response) {
    return this.checked[this.getResponses().indexOf(response)];
  }

  isCorrect(response: Response) {
    return response.correct;
  }

  showExplanation() {
   return this.controlled;
  }

  isExplanation() {
    let result = false;
    switch (this.translate.currentLang) {
      case 'de':
        result = this.question.explanationDe != null && this.question.explanationDe != '';
        break;
      case 'fr':
        result = this.question.explanationFr != null && this.question.explanationFr != '';
        break;
      case 'en':
        result = this.question.explanationEn != null && this.question.explanationEn != '';
        break;
    }
    return result;
  }

  onClickRadioButton(response: Response) {
    const score = this.getScore();
    if (!this.controlled && score == null || (this.answers.find(x => x.scoreId == score.id && x.questionId == this.question.id && x.correct) == null)) {
      this.checked[this.getResponses().indexOf(response)] = !this.checked[this.getResponses().indexOf(response)];
      if (!this.question.multipleChoice) {
        this.onClickVerify();
      }
    }
  }



  onClickVerify() {
    if (this.checked.length > 0) {
      this.controlled = true;
      const correct = this.getResponses().filter(x => this.isChecked(x) &&
        this.isCorrect(x)).length == this.getResponses().filter(x => this.isCorrect(x)).length;

      let score = this.getScore();

        if (this.scores.length == 0
          || score == null) {
          score = new Score();
          score.tourId = +this.selectedTour.id;
          score.spotId = +this.selectedSpot.id;
          score.modeId = this.learningMode;
          score.userId = this.user.id;

          this.backendService
          .createScore(score)
          .subscribe((res) => {
            score.id = res;
            this.store.dispatch(new AddScore({score: score}));
            this.createAnswer(score, correct);
          });

        } else {
          this.createAnswer(score, correct);
        }
    }
  }

  createAnswer(score: Score, correct: boolean){
    const answer = new Answer();
    answer.questionId = +this.question.id;
    answer.scoreId = +score.id;
    answer.correct = correct;

    this.backendService.createAnswer(answer).subscribe((res) => {
      answer.id = res;
      this.store.dispatch(new AddAnswer({answer: answer}));
    });
  }

  translateQuestion(preString: string): string {
    return translation(this.getQuestionContent(), preString, this.translate);
  }

  translateExplanation(preString: string): string {
    return translation(this.question, preString, this.translate);
  }

  translateResponse(response: Response, preString: string): string {
    return translation(this.getResponseContent(response), preString, this.translate);
  }

}
