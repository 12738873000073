import {DecreaseMapSizeToMin, GlobalActions, GlobalActionTypes, IncreaseMapSizeToMax} from './global.action';
import {Global, Side} from './global.model';
import {createSelector} from '@ngrx/store';
import {RealtimePanoramaActionTypes} from '../realtime-panorama/realtime-panorama.actions';


export const initialState: Global = {
  selectedTourIds: [null, null],
  size: 0.25,
  treeMapMode: false
};

export function reducer(
  state = initialState,
  action: GlobalActions
): Global {
  switch (action.type) {
    case GlobalActionTypes.SetSelectedTour: {
      const selectedTourIds: [string, string] = [state.selectedTourIds[0], state.selectedTourIds[1]];
      selectedTourIds[action.payload.side] = action.payload.tourId;

      return {
        ...state,
        selectedTourIds: selectedTourIds
      };
    }

    case GlobalActionTypes.UnsetSelectedTour: {
      return {
        ...state,
        selectedTourIds: [null, null]
      };
    }

    case GlobalActionTypes.IncreaseMapSize: {
      const res = Math.min(state.size + 0.16, 0.73);
      return {
        ...state,
        size: res
      };
    }
    case GlobalActionTypes.DecreaseMapSize: {
      const res = Math.max(state.size - 0.16, 0.25);
      return {
        ...state,
        size: res
      };
    }
    case GlobalActionTypes.IncreaseMapSizeToMax: {
      return {
        ...state,
        size: 0.73
      };
    }
    case GlobalActionTypes.DecreaseMapSizeToMin: {
      return {
        ...state,
        size: 0.25
      };
    }
    case GlobalActionTypes.ToggleTreeMap: {
      return {
        ...state,
        treeMapMode: !state.treeMapMode
      };
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): Global => state.root.global;

export const selectBarSize = createSelector(
    selectFeature,
    (state: Global) => state.size
);

export const selectTreeMapMode = createSelector(
    selectFeature,
    (state: Global) => state.treeMapMode
);
