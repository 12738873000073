import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Side} from '../../store/global/global.model';
import {Observable} from 'rxjs';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {selectSelectedRealtimePanoramaOfSelectedTour} from '../../store/realtime-panorama/realtime-panorama.reducer';
import {RealtimePanorama} from '../../store/realtime-panorama/realtime-panorama.model';
import {OrientToNorth} from '../../store/realtime-panorama/realtime-panorama.actions';
import {Panorama} from '../../store/panorama/panorama.model';
import {AngleHelper, Tour} from '../../store/tour/tour.model';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {selectSelectedPanoramaOfSelectedTour} from '../../store/panorama/panorama.reducer';

@Component({
  selector: 'app-panorama-info',
  templateUrl: './panorama-info.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaInfoComponent implements OnInit {
  @Input() side: Side;

  public selectedTour$: Observable<Tour>;
  public selectedPanorama$: Observable<Panorama>;
  public realtimePanorama$: Observable<RealtimePanorama>;

  public AzimuthHelper = AngleHelper;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.selectedTour$ = this.store.select(selectSelectedTour(this.side));
    this.selectedPanorama$ = this.store.select(selectSelectedPanoramaOfSelectedTour(this.side));
    this.realtimePanorama$ = this.store.select(selectSelectedRealtimePanoramaOfSelectedTour(this.side));
  }

  orientToNorth() {
    this.store.dispatch(new OrientToNorth({side: this.side}));
  }
}
