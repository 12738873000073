import {Action} from '@ngrx/store';
import {Side} from './global.model';
import {RealtimePanoramaActionTypes} from '../realtime-panorama/realtime-panorama.actions';

export enum GlobalActionTypes {
  SetSelectedTour = '[Global] Set Selected Tour',
  UnsetSelectedTour = '[Global] UnSet Selected Tour',
  IncreaseMapSize = '[Global] Increase the size of the map of 1 step',
  DecreaseMapSize = '[Global] decrease the size of the map of 1 step',
  IncreaseMapSizeToMax = '[Global] Increase the size of the map to max',
  DecreaseMapSizeToMin = '[Global] Decrease the size of the map to min',
  ToggleTreeMap = '[Global] Switch between panorama and TreeMap',
}


export class SetSelectedTour implements Action {
  readonly type = GlobalActionTypes.SetSelectedTour;

  constructor(public payload: { side: Side, tourId: string }) {
  }
}

export class UnsetSelectedTour implements Action {
  readonly type = GlobalActionTypes.UnsetSelectedTour;

  constructor() {
  }
}

export class IncreaseMapSize implements Action {
  readonly type = GlobalActionTypes.IncreaseMapSize;

  constructor() {
  }
}

export class DecreaseMapSize implements Action {
  readonly type = GlobalActionTypes.DecreaseMapSize;

  constructor() {
  }
}

export class IncreaseMapSizeToMax implements Action {
  readonly type = GlobalActionTypes.IncreaseMapSizeToMax;

  constructor() {
  }
}

export class DecreaseMapSizeToMin implements Action {
  readonly type = GlobalActionTypes.DecreaseMapSizeToMin;

  constructor() {
  }
}

export class ToggleTreeMap implements Action {
  readonly type = GlobalActionTypes.ToggleTreeMap;

  constructor() {
  }
}

export type GlobalActions =
  SetSelectedTour |
  UnsetSelectedTour |
    IncreaseMapSize |
    DecreaseMapSize |
  IncreaseMapSizeToMax |
    ToggleTreeMap |
  DecreaseMapSizeToMin;
