import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-new-panel',
  templateUrl: './new-panel.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPanelComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
