import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import {Spot} from './spot.model';
import {SpotActions, SpotActionTypes} from './spot.actions';
import {createSelector} from '@ngrx/store';
import {Side} from '../global/global.model';
import {selectSelectedRuntimeTour} from '../runtime-tour/runtime-tour.reducer';
import {Tree} from '../tree/tree.model';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';

export interface State extends EntityState<Spot> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Spot> = createEntityAdapter<Spot>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: SpotActions
): State {
  switch (action.type) {
    case SpotActionTypes.AddSpot: {
      return adapter.addOne(action.payload.spot, state);
    }

    case SpotActionTypes.UpsertSpot: {
      return adapter.upsertOne(action.payload.spot, state);
    }

    case SpotActionTypes.AddSpots: {
      return adapter.addMany(action.payload.spots, state);
    }

    case SpotActionTypes.UpsertSpots: {
      return adapter.upsertMany(action.payload.spots, state);
    }

    case SpotActionTypes.UpdateSpot: {
      return adapter.updateOne(action.payload.spot, state);
    }

    case SpotActionTypes.UpdateSpots: {
      return adapter.updateMany(action.payload.spots, state);
    }

    case SpotActionTypes.DeleteSpot: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SpotActionTypes.DeleteSpots: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SpotActionTypes.LoadSpots: {
      return adapter.addAll(action.payload.spots, state);
    }

    case SpotActionTypes.ClearSpots: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Spot> => state.root.spots;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectSpots = createSelector(selectAll, entities => entities);

export function selectSelectedSpotOfSelectedTour(side: Side) {
  return createSelector(
      selectEntities,
      selectSelectedRuntimeTour(side),
      (spotDictionary: Dictionary<Spot>, runtimeTour: RuntimeTour) => {
        if (runtimeTour) {
          const spotId = runtimeTour.selectedSpotId;
          return spotId ? spotDictionary[spotId] : null;
        } else {
          return null;
        }
      });
}

export function selectSpotsOfSelectedTour(side: Side) {
  return createSelector(
      selectAll,
      selectSelectedRuntimeTour(side),
      (spots: Spot[], runtimeTour: RuntimeTour) => {
        if (runtimeTour) {
          const panoramaId = runtimeTour.panoramaIds[0];
          return panoramaId ? spots.filter(spot => spot.panoramaId === panoramaId) : [];
        } else {
          return [];
        }
      });
}
