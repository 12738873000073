 import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tour} from '../../store/tour/tour.model';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {Panorama} from '../../store/panorama/panorama.model';
import {Router} from '@angular/router';
import {Side} from '../../store/global/global.model';
import {Observable} from 'rxjs';
import {selectPanoramasOfSelectedTour, selectSelectedPanoramaOfSelectedTour} from '../../store/panorama/panorama.reducer';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {SetSelectedPanorama} from '../../store/runtime-tour/runtime-tour.actions';

@Component({
  selector: 'app-panorama-selector',
  templateUrl: './panorama-selector.component.html',
  styleUrls: ['./panorama-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaSelectorComponent implements OnInit {
  @Input() side: Side;
  @Output() selectPanorama = new EventEmitter<Panorama>();

  selectedTour$: Observable<Tour>;
  panoramas$: Observable<Panorama[]>;
  selectedPanorama$: Observable<Panorama>;

  panoramas: Panorama[] = [];

  constructor(public store: Store<AppState>, private router: Router) {
  }

  ngOnInit() {
    this.selectedTour$ = this.store.select(selectSelectedTour(this.side));
    this.panoramas$ = this.store.select(selectPanoramasOfSelectedTour(this.side));
    this.selectedPanorama$ = this.store.select(selectSelectedPanoramaOfSelectedTour(this.side));
  }

  onSelectPanorama(panorama: Panorama) {
    this.store.dispatch(new SetSelectedPanorama({side: this.side, tourId: panorama.tourId, panoramaId: panorama.id}));
    this.selectPanorama.emit(panorama);
  }
}
