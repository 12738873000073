import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Response } from './response.model';

export enum ResponseActionTypes {
  LoadResponses = '[Response] Load Responses',
  AddResponse = '[Response] Add Response',
  UpsertResponse = '[Response] Upsert Response',
  AddResponses = '[Response] Add Responses',
  UpsertResponses = '[Response] Upsert Responses',
  UpdateResponse = '[Response] Update Response',
  UpdateResponses = '[Response] Update Responses',
  DeleteResponse = '[Response] Delete Response',
  DeleteResponses = '[Response] Delete Responses',
  ClearResponses = '[Response] Clear Responses'
}

export class LoadResponses implements Action {
  readonly type = ResponseActionTypes.LoadResponses;

  constructor(public payload: { responses: Response[] }) {}
}

export class AddResponse implements Action {
  readonly type = ResponseActionTypes.AddResponse;

  constructor(public payload: { response: Response }) {}
}

export class UpsertResponse implements Action {
  readonly type = ResponseActionTypes.UpsertResponse;

  constructor(public payload: { response: Response }) {}
}

export class AddResponses implements Action {
  readonly type = ResponseActionTypes.AddResponses;

  constructor(public payload: { responses: Response[] }) {}
}

export class UpsertResponses implements Action {
  readonly type = ResponseActionTypes.UpsertResponses;

  constructor(public payload: { responses: Response[] }) {}
}

export class UpdateResponse implements Action {
  readonly type = ResponseActionTypes.UpdateResponse;

  constructor(public payload: { response: Update<Response> }) {}
}

export class UpdateResponses implements Action {
  readonly type = ResponseActionTypes.UpdateResponses;

  constructor(public payload: { responses: Update<Response>[] }) {}
}

export class DeleteResponse implements Action {
  readonly type = ResponseActionTypes.DeleteResponse;

  constructor(public payload: { id: string }) {}
}

export class DeleteResponses implements Action {
  readonly type = ResponseActionTypes.DeleteResponses;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearResponses implements Action {
  readonly type = ResponseActionTypes.ClearResponses;
}

export type ResponseActions =
 LoadResponses
 | AddResponse
 | UpsertResponse
 | AddResponses
 | UpsertResponses
 | UpdateResponse
 | UpdateResponses
 | DeleteResponse
 | DeleteResponses
 | ClearResponses;
