import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Theme} from './theme.model';
import {ThemeActions, ThemeActionTypes} from './theme.actions';
import {createSelector} from '@ngrx/store';
import {Content} from '../content/content.model';

export interface State extends EntityState<Theme> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Theme> = createEntityAdapter<Theme>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: ThemeActions
): State {
  switch (action.type) {
    case ThemeActionTypes.AddTheme: {
      return adapter.addOne(action.payload.theme, state);
    }

    case ThemeActionTypes.UpsertTheme: {
      return adapter.upsertOne(action.payload.theme, state);
    }

    case ThemeActionTypes.AddThemes: {
      return adapter.addMany(action.payload.themes, state);
    }

    case ThemeActionTypes.UpsertThemes: {
      return adapter.upsertMany(action.payload.themes, state);
    }

    case ThemeActionTypes.UpdateTheme: {
      return adapter.updateOne(action.payload.theme, state);
    }

    case ThemeActionTypes.UpdateThemes: {
      return adapter.updateMany(action.payload.themes, state);
    }

    case ThemeActionTypes.DeleteTheme: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ThemeActionTypes.DeleteThemes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ThemeActionTypes.LoadThemes: {
      return adapter.addAll(action.payload.themes, state);
    }

    case ThemeActionTypes.ClearThemes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Theme> => state.root.themes;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectThemes = createSelector(selectAll, themes => themes);
