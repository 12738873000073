import {Actions, Effect, ofType} from '@ngrx/effects';
import {filter, map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoadTours, TourActionTypes} from '../tour/tour.actions';
import {AppState} from '../app.reducer';
import {TourHelper} from '../tour/tour.model';

@Injectable()
export class RuntimeTourEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {
  }

  @Effect({dispatch: false})
  setDefaultPanorama = this.actions$.pipe(
    ofType(TourActionTypes.LoadTours),
    map((action: LoadTours) => {
      action.payload.tours.forEach(tour => {
      });
    }),
  );
}
