import * as fromTreeVerticalStructure from '../tree-vertical-structure/tree-vertical-structure.reducer';
import {Store} from '@ngrx/store';
import {TreeSocialPosition} from '../tree-social-position/tree-social-position.model';
import {TreeVerticalStructure} from '../tree-vertical-structure/tree-vertical-structure.model';
import * as fromTreeSocialPosition from '../tree-social-position/tree-social-position.reducer';
import {TreeStemType} from '../tree-stem-type/tree-stem-type.model';
import {TreeType} from '../tree-type/tree-type.model';
import {AppState} from '../app.reducer';
import {Observable} from 'rxjs';
import * as fromTreeType from '../tree-type/tree-type.reducer';
import * as fromTreeStemType from '../tree-stem-type/tree-stem-type.reducer';

export interface TreeProperty {
  id: string;
  socialPositionId: string;
  stemTypeId: string;
  typeId: string;
  verticalStructureId: string;
  treenumber: number;
  diameter: number;
  quality: string;
  ecology: string;
  ecologyNote: number;
  volume: number;
  comment: string;
  observation: string;
}

export namespace TreePropertyHelper {
  export function getSocialPosition(treeProperty: TreeProperty, store: Store<AppState>): Observable<TreeSocialPosition> {
    return store.select(fromTreeSocialPosition.selectById(treeProperty.socialPositionId));
  }

  export function getStemType(treeProperty: TreeProperty, store: Store<AppState>): Observable<TreeStemType> {
    return store.select(fromTreeStemType.selectById(treeProperty.stemTypeId));
  }

  export function getType(treeProperty: TreeProperty, store: Store<AppState>): Observable<TreeType> {
    return store.select(fromTreeType.selectById(treeProperty.typeId));
  }

  export function getVerticalStructure(treeProperty: TreeProperty, store: Store<AppState>): Observable<TreeVerticalStructure> {
    return store.select(fromTreeVerticalStructure.selectById(treeProperty.verticalStructureId));
  }
}
