import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreeSocialPosition} from './tree-social-position.model';
import {TreeSocialPositionActions, TreeSocialPositionActionTypes} from './tree-social-position.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TreeSocialPosition> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TreeSocialPosition> = createEntityAdapter<TreeSocialPosition>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeSocialPositionActions
): State {
  switch (action.type) {
    case TreeSocialPositionActionTypes.AddTreeSocialPosition: {
      return adapter.addOne(action.payload.treeSocialPosition, state);
    }

    case TreeSocialPositionActionTypes.UpsertTreeSocialPosition: {
      return adapter.upsertOne(action.payload.treeSocialPosition, state);
    }

    case TreeSocialPositionActionTypes.AddTreeSocialPositions: {
      return adapter.addMany(action.payload.treeSocialPositions, state);
    }

    case TreeSocialPositionActionTypes.UpsertTreeSocialPositions: {
      return adapter.upsertMany(action.payload.treeSocialPositions, state);
    }

    case TreeSocialPositionActionTypes.UpdateTreeSocialPosition: {
      return adapter.updateOne(action.payload.treeSocialPosition, state);
    }

    case TreeSocialPositionActionTypes.UpdateTreeSocialPositions: {
      return adapter.updateMany(action.payload.treeSocialPositions, state);
    }

    case TreeSocialPositionActionTypes.DeleteTreeSocialPosition: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeSocialPositionActionTypes.DeleteTreeSocialPositions: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeSocialPositionActionTypes.LoadTreeSocialPositions: {
      return adapter.addAll(action.payload.treeSocialPositions, state);
    }

    case TreeSocialPositionActionTypes.ClearTreeSocialPositions: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TreeSocialPosition> => state.root.treeSocialPositions;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
