import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BackendService} from './services/backend.service';
import {XHRService} from './services/xhr.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RouterModule} from '@angular/router';
import {TourComponent} from './components/tour/tour.component';
import {ToursComponent} from './components/tours/tours.component';
import {ToursItemComponent} from './components/tours-item/tours-item.component';
import {appReducer} from './store/app.reducer';
import {effects} from './store/app.effects';
import {TreeMapComponent} from './components/tree-map/tree-map.component';
import {PanoramaTreeMapComponent} from './components/panorama-tree-map/panorama-tree-map.component';
import {TourMapComponent} from './components/tour-map/tour-map.component';
import {PanoramaSelectorComponent} from './components/panorama-selector/panorama-selector.component';
import {SideComponent} from './components/side/side.component';
import {SideBarComponent} from './components/side-bar/side-bar.component';
import {PanoramaTabComponent} from './components/panorama-tab/panorama-tab.component';
import {TreeTabComponent} from './components/tree-tab/tree-tab.component';
import {TourTabComponent} from './components/tour-tab/tour-tab.component';
import {ToursTabComponent} from './components/tours-tab/tours-tab.component';
import {TourSelectorComponent} from './components/tour-selector/tour-selector.component';
import {ResizableTreeMapComponent} from './components/resizable-tree-map/resizable-tree-map.component';
import {PanoramaComponent} from './components/panorama/panorama.component';
import {PanoramaInfoComponent} from './components/panorama-info/panorama-info.component';
import {TreePanelComponent} from './components/tree-panel/tree-panel.component';
import {PanoramaTreePanelComponent} from './components/panorama-tree-panel/panorama-tree-panel.component';
import {NewPanelComponent} from './components/new-panel/new-panel.component';
import {StatusPanelComponent} from './components/status-panel/status-panel.component';
import {FormsModule} from '@angular/forms';
import {HabitatTabComponent} from './components/habitat-tab/habitat-tab.component';
import { DiscoveryModeComponent } from './components/habitat/discovery-mode/discovery-mode.component';
import { DeterminationModeComponent } from './components/habitat/determination-mode/determination-mode.component';
import { ResearchModeComponent } from './components/habitat/research-mode/research-mode.component';
import { TreeMarkingModeComponent } from './components/habitat/tree-marking-mode/tree-marking-mode.component';
import { ScoreGridComponent } from './components/habitat/score-grid/score-grid.component';
import { InfoHabitatComponent } from './components/habitat/info-habitat/info-habitat.component';
import { InfoModeComponent } from './components/habitat/info-mode/info-mode.component';
import {CatalogComponent} from './components/catalog/catalog/catalog.component';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDialogModule,
    MatDividerModule,
    MatExpansionModule, MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule, MatSelectModule, MatSliderModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ThemeComponent } from './components/catalog/theme/theme.component';
import { SubThemeComponent } from './components/catalog/sub-theme/sub-theme.component';
import { ContentComponent } from './components/catalog/content/content.component';
import { QuestionComponent } from './components/catalog/question/question.component';
import { MediaComponent } from './components/catalog/media/media.component';
import { ResponseComponent } from './components/catalog/response/response.component';
import { SpotPanelComponent } from './components/habitat/spot-panel/spot-panel.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { ListeMediaComponent } from './components/habitat/liste-media/liste-media.component';
import { MediaDialogComponent } from './components/dialogs/media-dialog/media-dialog.component';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import { CorrectionsPanelComponent } from './components/corrections-panel/corrections-panel.component';
import { HabitatContentComponent } from './components/habitat/content/habitat-content/habitat-content.component';
import { FrequencyMeterComponent } from './components/habitat/content/frequency-meter/frequency-meter.component';
import { ReplacementMeterComponent } from './components/habitat/content/replacement-meter/replacement-meter.component';
import { QuestionContentComponent } from './components/habitat/content/question-content/question-content.component';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog.component';
import { LocalQuestionContentComponent } from './components/habitat/content/local-question-content/local-question-content.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    TourComponent,
    ToursComponent,
    ToursItemComponent,
    TreeMapComponent,
    PanoramaTreeMapComponent,
    TourMapComponent,
    PanoramaSelectorComponent,
    SideComponent,
    SideBarComponent,
    PanoramaTabComponent,
    TreeTabComponent,
    TourTabComponent,
    ToursTabComponent,
    TourSelectorComponent,
    ResizableTreeMapComponent,
    PanoramaComponent,
    PanoramaInfoComponent,
    TreePanelComponent,
    PanoramaTreePanelComponent,
    NewPanelComponent,
    StatusPanelComponent,
    HabitatTabComponent,
    DiscoveryModeComponent,
    DeterminationModeComponent,
    ResearchModeComponent,
    TreeMarkingModeComponent,
    ScoreGridComponent,
    InfoHabitatComponent,
    InfoModeComponent,
    CatalogComponent,
    ThemeComponent,
    SubThemeComponent,
    ContentComponent,
    QuestionComponent,
    MediaComponent,
    ResponseComponent,
    SpotPanelComponent,
    ToolBarComponent,
    ListeMediaComponent,
    MediaDialogComponent,
    CorrectionsPanelComponent,
    HabitatContentComponent,
    FrequencyMeterComponent,
    ReplacementMeterComponent,
    QuestionContentComponent,
      DeleteDialogComponent,
      LocalQuestionContentComponent
  ],
    entryComponents: [MediaDialogComponent, DeleteDialogComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MatTabsModule,
        MatTreeModule,
        MatStepperModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatToolbarModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDividerModule,
        MatCardModule,
        MatSliderModule,
        MatSelectModule,
        MatCheckboxModule,
        MatGridListModule,
        FormsModule,
        StoreModule.forRoot({root: appReducer}),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot([
            {path: 'tours/:id', component: TourComponent},
            {path: 'catalog', component: CatalogComponent},
            {path: '', component: ToursComponent},
            {path: 'tours', redirectTo: '', pathMatch: 'full'}, // use to complete redirection of the php front
            {path: 'logout', redirectTo: '', pathMatch: 'full'}, // use to complete redirection of the php front
        ]),
        MatDialogModule,
        NgxYoutubePlayerModule.forRoot(),
    ],
  providers: [
    XHRService,
    BackendService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
