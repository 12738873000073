import {ClearSelectedTree} from './../../../store/runtime-tour/runtime-tour.actions';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Tree} from '../../../store/tree/tree.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectSelectedTreeOfSelectedTour, selectTreesOfSelectedTour} from '../../../store/tree/tree.reducer';
import {Side} from '../../../store/global/global.model';
import {selectSelectedPanoramaOfSelectedTour} from '../../../store/panorama/panorama.reducer';
import {Panorama} from 'src/app/store/panorama/panorama.model';
import {AddSpot, DeleteSpot, UpdateSpot} from '../../../store/spot/spot.actions';
import {Spot} from '../../../store/spot/spot.model';
import {CancelSpotEdit, SetSelectedSpot} from '../../../store/runtime-tour/runtime-tour.actions';
import {Tour} from '../../../store/tour/tour.model';
import {selectSelectedTour} from '../../../store/tour/tour.reducer';
import {selectSelectedSpotOfSelectedTour, selectSpots} from '../../../store/spot/spot.reducer';
import {BackendService} from '../../../services/backend.service';
import {Theme} from '../../../store/catalog/theme/theme.model';
import {selectThemes} from '../../../store/catalog/theme/theme.reducer';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {TourThematic} from '../../../store/tour-thematic/tour-thematic.model';
import {selectTourThematics} from '../../../store/tour-thematic/tour-thematic.reducer';
import {AddTourThematic, UpdateTourThematic} from '../../../store/tour-thematic/tour-thematic.actions';
import {TourDisabledModus} from '../../../store/tour-disabled-modus/tour-disabled-modus.model';
import {selectTourDisabledModus} from '../../../store/tour-disabled-modus/tour-disabled-modus.reducer';
import {
  AddTourDisabledModus,
  DeleteTourDisabledModus
} from '../../../store/tour-disabled-modus/tour-disabled-modus.actions';
import {Media} from '../../../store/catalog/media/media.model';
import {Content} from '../../../store/catalog/content/content.model';
import {selectMedias} from '../../../store/catalog/media/media.reducer';
import {selectContents} from '../../../store/catalog/content/content.reducer';
import {AddContent} from '../../../store/catalog/content/content.actions';
import {TranslateService} from '@ngx-translate/core';
import {translation} from "../../../app.translation";

@Component({
  selector: 'app-spot-panel',
  templateUrl: './spot-panel.component.html',
  styleUrls: ['./spot-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpotPanelComponent implements OnInit {

  public expandParameter = false;
  public addMode = false;
  public selectedTree$: Observable<Tree>;
  public selectedTree: Tree;
  public selectedPanorama$: Observable<Panorama>;
  public selectedPanorama: Panorama;
  public selectedTour$: Observable<Tour>;
  public selectedTour: Tour;
  public selectedSpot$: Observable<Spot>;
  public selectedSpot: Spot;
  public spots: Spot[] = [];
  public trees: Tree[] = [];
  public themes: Theme[];
  public medias: Media[];
  public tmpContent = new Content();
  public additionalContent: Content;
  public subThemes: SubTheme[];
  public tourThematic: TourThematic;
  public tourDisabledModus: TourDisabledModus[] = [];

  currentThematic: number;
  currentLevel1Theme: number;
  currentLevel2Theme: number;
  currentLevel3Theme: number;

  public spot: Spot;

  constructor(private store: Store<AppState>, private backendService: BackendService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.tmpContent.textFr = '';
    this.tmpContent.textDe = '';
    this.tmpContent.textEn = '';
    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });
    this.store.select(selectSubThemes).subscribe(subThemes => {
      this.subThemes = subThemes;
    });
    this.selectedTree$ = this.store.select(selectSelectedTreeOfSelectedTour(Side.Left));
    this.selectedTree$.subscribe(this.updateTree.bind(this));
    this.selectedPanorama$ = this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left));
    this.selectedPanorama$.subscribe(this.updatePanorama.bind(this));
    this.selectedTour$ = this.store.select(selectSelectedTour(Side.Left));
    this.selectedTour$.subscribe(this.updateTour.bind(this));
    this.store.select(selectTourThematics).subscribe(this.updateTourThematic.bind(this));
    this.store.select(selectTourDisabledModus).subscribe(this.updateTourDisabledModus.bind(this));
    this.selectedSpot$ = this.store.select(selectSelectedSpotOfSelectedTour(Side.Left));
    this.selectedSpot$.subscribe(this.updateSpot.bind(this));
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias;
    });
    this.store.select(selectSpots).subscribe(spots => {
      this.spots = spots;
    });
    this.store.select(selectTreesOfSelectedTour(Side.Left)).subscribe(trees => {
      this.trees = trees;
    });
    this.store.select(selectContents).subscribe(contents => {
      this.additionalContent = contents.find(x => x.id == this.spot.contentId);
      if (this.additionalContent == null) {
        this.createAdditionalContent();
      } else {
        this.tmpContent = this.additionalContent;
      }
    });
  }

  public updateTourDisabledModus(tourDisabledModus: TourDisabledModus[]) {

    this.tourDisabledModus = tourDisabledModus.filter(x => x.tourId == +this.selectedTour.id);
  }

  public updateTourThematic(tourThematics: TourThematic[]) {

    this.tourThematic = tourThematics.find(x => x.tourId == +this.selectedTour.id);
    if (this.tourThematic != null) {
      this.currentThematic = this.tourThematic.themeId;
    }
  }

  public updateTree(selectedTree: Tree): void {
    this.selectedTree = selectedTree;
  }

  public updatePanorama(selectedPanorama: Panorama): void {
    this.selectedPanorama = selectedPanorama;
  }

  public updateTour(selectedTour: Tour): void {
    this.selectedTour = selectedTour;
  }

  public updateSpot(selectedSpot: Spot): void {

    this.currentLevel1Theme = null;
    this.currentLevel2Theme = null;
    this.currentLevel3Theme = null;

    this.spot = selectedSpot;
    if (selectedSpot != null && selectedSpot.subThemeId != null) {

      let subTheme = this.subThemes.find(x => x.id == selectedSpot.subThemeId);
      // this.currentThematic = subTheme.themeId;
      if (subTheme != null && subTheme.themeId == this.currentThematic && subTheme.level == 2) {
        this.currentLevel3Theme = subTheme.id;
        subTheme = this.subThemes.find(x => subTheme != null && x.id == subTheme.superThemeId);
      }


      if (subTheme != null && subTheme.level == 1) {
        this.currentLevel2Theme = subTheme.id;
        subTheme = this.subThemes.find(x => subTheme != null && x.id == subTheme.superThemeId);
      }


      if (subTheme != null && subTheme.level == 0) {
        this.currentLevel1Theme = subTheme.id;
      }

    }

    if (this.spot == null) {
      this.store.dispatch(new DeleteSpot({id: '0'}));
      this.addMode = false;
    } else {

      this.store.select(selectContents).subscribe(contents => {
        this.tmpContent = new Content();
        this.tmpContent.id = null;
        this.tmpContent.textFr = '';
        this.tmpContent.textDe = '';
        this.tmpContent.textEn = '';
        this.additionalContent = contents.find(x => x.id == this.spot.contentId);

        if (this.additionalContent == null) {
          this.createAdditionalContent();
        } else {

          this.tmpContent = this.additionalContent;
        }
      });
    }
  }

  isModeChecked(mode: number): boolean {
    return this.tourDisabledModus.find(x => x.modusId == mode) == null;
  }

  onCheckedModeChanged(mode: number, checked: boolean) {
    if (!checked) {
      // -> create disabled
      const tourDisabledMode = new TourDisabledModus();
      tourDisabledMode.modusId = mode;
      tourDisabledMode.tourId = +this.selectedTour.id;
      this.backendService.createTourDisabledModus(tourDisabledMode).subscribe( res => {

        if (res > 0) {
          tourDisabledMode.id = +res;

          this.store.dispatch(new AddTourDisabledModus({tourDisabledModus: tourDisabledMode}));
        }
      });
    } else {
      // -> delete element
      const tourDisabledMode = this.tourDisabledModus.find(x => x.modusId == mode);
      this.backendService.deleteTourDisabledModus(tourDisabledMode).subscribe( res => {

        this.store.dispatch(new DeleteTourDisabledModus({id: tourDisabledMode.id.toString()}));
      });
    }
  }

  addSpot(): void {
    this.addMode = true;
    this.spot = new Spot();
    this.spot.id = '0';
    this.spot.treeNumber = this.selectedTree.treenumber;
    this.spot.panoramaId = this.selectedPanorama.id;

    this.store.dispatch(new AddSpot({spot: this.spot}));
    // set selected spot
    this.store.dispatch(new SetSelectedSpot({tourId: this.selectedTour.id, spotId: this.spot.id}));
  }

  cancel(): void {
    this.addMode = false;

    /*if (this.spot.id !== '0') {
      this.store.dispatch(new DeleteSpot({id: this.spot.id}));
    }*/
    // deselect spot
    if (this.spot.azimuthTmp != null && this.spot.altitudeTmp != null) {
      this.spot.azimuth = this.spot.azimuthTmp;
      this.spot.altitude = this.spot.altitudeTmp;
      this.store.dispatch(new UpdateSpot({spot: {id: this.spot.id, changes: this.spot}}));
    }

    if (this.spot.id === '0') {
      this.store.dispatch(new DeleteSpot({id: this.spot.id}));
    }
    this.store.dispatch(new CancelSpotEdit());
  }

  save(): void {
    if (this.spot.azimuth != null && this.spot.altitude != null && (this.currentLevel1Theme != null || this.currentLevel2Theme != null || this.currentLevel3Theme != null)) {
      if (this.currentLevel3Theme != null) {
        this.spot.subThemeId = this.currentLevel3Theme;
      } else if (this.currentLevel2Theme != null) {
        this.spot.subThemeId = this.currentLevel2Theme;
      } else if (this.currentLevel1Theme != null) {
        this.spot.subThemeId = this.currentLevel1Theme;
      }
      this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe(this.updateSpot.bind(this));
      if (this.spot.id === '0') {
        // create new spot
        const spotCopy = this.spot;
        this.backendService.createSpot(this.spot).subscribe( res => {

          if (res > 0) {
            spotCopy.id = res.toString();
            // this.store.dispatch(new UpdateSpot({spot: spotCopy}));

            this.store.dispatch(new AddSpot({spot: spotCopy}));
            // this.store.dispatch(new SetSelectedSpot({tourId: this.selectedTour.id, spotId: spotCopy.id}));
            this.store.dispatch(new CancelSpotEdit());
            this.store.dispatch(new ClearSelectedTree());
          }
        });
        this.store.dispatch(new DeleteSpot({id: '0'}));
      } else {
        // update the existing spot

        this.backendService.updateSpot(this.spot).subscribe( res => {

          this.spot.azimuthTmp = null;
          this.spot.altitudeTmp = null;
          this.store.dispatch(new UpdateSpot({spot: {id: this.spot.id, changes: this.spot}}));
          this.store.dispatch(new ClearSelectedTree());
        });
      }
    }
  }

  delete(): void {
    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe(this.updateSpot.bind(this));
    if (this.spot.id === '0') {
      this.addMode = false;
      this.cancel();
    } else {
      this.addMode = false;
      this.backendService.deleteSpot(this.spot).subscribe( res => {

        this.store.dispatch(new DeleteSpot({id: this.spot.id}));
        this.store.dispatch(new CancelSpotEdit());
      });
    }
  }

  getThematicList() {
    return this.themes;
  }

  canChangeThematic() {
    let result = true;

    this.spots.forEach((x) => {
      const tree = this.trees.find(t => t.treenumber == x.treeNumber);
      if (tree != null && x.subThemeId != null) {
        console.log('strange');
        console.log(tree);
        result = false;
      }
    });

    return result;
  }

  thematicChange(event: any) {

    if (this.canChangeThematic()) {

      const id = event;
      this.currentLevel1Theme = null;
      this.currentLevel2Theme = null;
      this.currentLevel3Theme = null;
      if (this.tourThematic == null) {
        const tourThematic = new TourThematic();
        tourThematic.themeId = this.getThematicList()[id].id;
        tourThematic.tourId = +this.selectedTour.id;
        this.backendService.createTourThematic(tourThematic).subscribe((res: any) => {

          if (res > 0) {
            tourThematic.id = res;
            this.tourThematic = tourThematic;
            this.store.dispatch(new AddTourThematic({tourThematic: tourThematic}));
          }
        });
      } else {
        this.tourThematic.themeId = this.getThematicList()[id].id;
        this.backendService.updateTourThematic(this.tourThematic).subscribe((res: any) => {

          this.store.dispatch(new UpdateTourThematic({tourThematic: {id: this.tourThematic.id, changes: this.tourThematic}}));
        });
      }
    }
  }

  thematicValue() {
    if (this.currentThematic != null) {
      return this.getThematicList().indexOf(this.themes.find(x => x.id === this.currentThematic));
    }
  }

  getLevel1ThemeList() {
    return this.subThemes.filter(x => x.themeId === this.currentThematic && x.level == 0);
  }

  getLevel2ThemeList() {
    return this.subThemes.filter(x => x.superThemeId === this.currentLevel1Theme && x.level == 1);
  }
  getLevel3ThemeList() {
    return this.subThemes.filter(x => x.superThemeId === this.currentLevel2Theme && x.level == 2);
  }

  level1themeChange(event: any) {
    const id = event;
    this.currentLevel2Theme = null;
    this.currentLevel3Theme = null;
    this.currentLevel1Theme = this.getLevel1ThemeList()[id].id;

  }

  level2themeChange(event: any) {
    const id = event;
    this.currentLevel3Theme = null;
    this.currentLevel2Theme = this.getLevel2ThemeList()[id].id;

  }

  level3themeChange(event: any) {
    const id = event;
    this.currentLevel3Theme = this.getLevel3ThemeList()[id].id;

  }

  level1ThemeValue() {
    if (this.currentLevel1Theme != null) {
      return this.getLevel1ThemeList().indexOf(this.subThemes.find(x => x.id === this.currentLevel1Theme));
    }
  }

  level2ThemeValue() {
    if (this.currentLevel2Theme != null) {
      return this.getLevel2ThemeList().indexOf(this.subThemes.find(x => x.id === this.currentLevel2Theme));
    }
  }

  level3ThemeValue() {
    if (this.currentLevel3Theme != null) {
      return this.getLevel3ThemeList().indexOf(this.subThemes.find(x => x.id === this.currentLevel3Theme));
    }
  }

  createAdditionalContent() {
    const newContent: Content = new Content();
    newContent.textFr = '';
    newContent.textDe = '';
    newContent.textEn = '';
    this.backendService.createContent(newContent).subscribe( res => {
      if (res > 0) {
        newContent.id = res;
        this.spot.contentId = newContent.id;
        this.store.dispatch(new AddContent({content: newContent}));
        this.backendService.updateSpot(this.spot).subscribe( r => {
          this.store.dispatch(new UpdateSpot({spot: {id: this.spot.id, changes: this.spot}}));
        });
      }
    });
  }

  updateContent() {
    if (this.spot.contentId != null && this.spot.contentId != 0 && this.additionalContent != null) {
      this.additionalContent = this.tmpContent;
      this.backendService.updateContent(this.additionalContent).subscribe( res => {

      });
    }
  }

  deleteContent() {
    this.tmpContent.textFr = '';
    this.tmpContent.textDe = '';
    this.tmpContent.textEn = '';
    if (this.spot.contentId != null && this.spot.contentId != 0 && this.additionalContent != null) {
      this.backendService.deleteContent(this.additionalContent).subscribe(res => {

        this.tmpContent = new Content();
        this.tmpContent.id = null;
        this.tmpContent.textFr = '';
        this.tmpContent.textDe = '';
        this.tmpContent.textEn = '';
        this.spot.contentId = null;
        this.createAdditionalContent();
      });
    }
  }

  translateSpotPanel(object, preString: string): string {
    return translation(object, preString, this.translate);
  }
}
