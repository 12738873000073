export class Media {
  id: number;
  contentId: number;
  mediaType: number;
  mediaUrl: string;
  hyperlink: string;
  textFr: string;
  textDe: string;
  textEn: string;
}
