import {Component, Input, OnInit} from '@angular/core';
import {Content} from '../../../../store/catalog/content/content.model';
import {Store} from '@ngrx/store';
import {selectMedias} from '../../../../store/catalog/media/media.reducer';
import {Media} from '../../../../store/catalog/media/media.model';
import {TranslateService} from '@ngx-translate/core';
import {translation} from "../../../../app.translation";

@Component({
  selector: 'app-habitat-content',
  templateUrl: './habitat-content.component.html',
  styleUrls: ['./habitat-content.component.css']
})
export class HabitatContentComponent implements OnInit {

  @Input() content: Content;
  @Input() more: boolean;
  public medias: Media[] = [];

  constructor(private store: Store<any>, private translate: TranslateService) { }

  ngOnInit() {
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias.filter(x => x.contentId == this.content.id);
    });
  }

  translateContent(preString: string): string {
    return translation(this.content, preString, this.translate);
  }

}
