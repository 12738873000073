import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Content} from './content.model';
import {ContentActions, ContentActionTypes} from './content.actions';
import {createSelector} from '@ngrx/store';
import {Tree} from '../../tree/tree.model';

export interface State extends EntityState<Content> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Content> = createEntityAdapter<Content>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: ContentActions
): State {
  switch (action.type) {
    case ContentActionTypes.AddContent: {
      return adapter.addOne(action.payload.content, state);
    }

    case ContentActionTypes.UpsertContent: {
      return adapter.upsertOne(action.payload.content, state);
    }

    case ContentActionTypes.AddContents: {
      return adapter.addMany(action.payload.contents, state);
    }

    case ContentActionTypes.UpsertContents: {
      return adapter.upsertMany(action.payload.contents, state);
    }

    case ContentActionTypes.UpdateContent: {
      return adapter.updateOne(action.payload.content, state);
    }

    case ContentActionTypes.UpdateContents: {
      return adapter.updateMany(action.payload.contents, state);
    }

    case ContentActionTypes.DeleteContent: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ContentActionTypes.DeleteContents: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ContentActionTypes.LoadContents: {
      return adapter.addAll(action.payload.contents, state);
    }

    case ContentActionTypes.ClearContents: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Content> => state.root.contents;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectContents = createSelector(selectAll, entities => entities);
