import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AllPanorama } from './all-panorama.model';

export enum AllPanoramaActionTypes {
  LoadAllPanoramas = '[AllPanorama] Load Panoramas',
  AddAllPanorama = '[AllPanorama] Add AllPanorama',
  UpsertAllPanorama = '[AllPanorama] Upsert AllPanorama',
  AddAllPanoramas = '[AllPanorama] Add Panoramas',
  UpsertAllPanoramas = '[AllPanorama] Upsert Panoramas',
  UpdateAllPanorama = '[AllPanorama] Update AllPanorama',
  UpdateAllPanoramas = '[AllPanorama] Update Panoramas',
  DeleteAllPanorama = '[AllPanorama] Delete AllPanorama',
  DeleteAllPanoramas = '[AllPanorama] Delete Panoramas',
  ClearAllPanoramas = '[AllPanorama] Clear Panoramas'
}

export class LoadAllPanoramas implements Action {
  readonly type = AllPanoramaActionTypes.LoadAllPanoramas;

  constructor(public payload: { panoramas: AllPanorama[] }) {}
}

export class AddAllPanorama implements Action {
  readonly type = AllPanoramaActionTypes.AddAllPanorama;

  constructor(public payload: { panorama: AllPanorama }) {}
}

export class UpsertAllPanorama implements Action {
  readonly type = AllPanoramaActionTypes.UpsertAllPanorama;

  constructor(public payload: { panorama: AllPanorama }) {}
}

export class AddAllPanoramas implements Action {
  readonly type = AllPanoramaActionTypes.AddAllPanoramas;

  constructor(public payload: { panoramas: AllPanorama[] }) {}
}

export class UpsertAllPanoramas implements Action {
  readonly type = AllPanoramaActionTypes.UpsertAllPanoramas;

  constructor(public payload: { panoramas: AllPanorama[] }) {}
}

export class UpdateAllPanorama implements Action {
  readonly type = AllPanoramaActionTypes.UpdateAllPanorama;

  constructor(public payload: { panorama: Update<AllPanorama> }) {}
}

export class UpdateAllPanoramas implements Action {
  readonly type = AllPanoramaActionTypes.UpdateAllPanoramas;

  constructor(public payload: { panoramas: Update<AllPanorama>[] }) {}
}

export class DeleteAllPanorama implements Action {
  readonly type = AllPanoramaActionTypes.DeleteAllPanorama;

  constructor(public payload: { id: string }) {}
}

export class DeleteAllPanoramas implements Action {
  readonly type = AllPanoramaActionTypes.DeleteAllPanoramas;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearAllPanoramas implements Action {
  readonly type = AllPanoramaActionTypes.ClearAllPanoramas;
}

export type AllPanoramaActions =
 LoadAllPanoramas
 | AddAllPanorama
 | UpsertAllPanorama
 | AddAllPanoramas
 | UpsertAllPanoramas
 | UpdateAllPanorama
 | UpdateAllPanoramas
 | DeleteAllPanorama
 | DeleteAllPanoramas
 | ClearAllPanoramas;
