import {Component, Input, OnInit} from '@angular/core';
import {Content} from '../../../store/catalog/content/content.model';
import {Response} from '../../../store/catalog/response/response.model';
import {BackendService} from '../../../services/backend.service';
import {MatDialog} from '@angular/material';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.css']
})
export class ResponseComponent implements OnInit {

  @Input() responses: Response[];
  @Input() response: Response;
  @Input() content: Content;

  constructor(private backendService: BackendService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  updateResponse() {
    this.backendService.updateContent(this.content).subscribe( res => {
    });
    this.backendService.updateResponse(this.response).subscribe( res => {
    });
  }

  deleteResponse() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {ok: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.deleteResponse(this.response).subscribe(res => {
          this.responses.splice(this.responses.indexOf(this.response), 1);
        });
      }
    });

  }

}
