import {createEntityAdapter, Dictionary, EntityAdapter, EntityState, Update} from '@ngrx/entity';
import {Tree} from './tree.model';
import {TreeActions, TreeActionTypes} from './tree.actions';
import {createSelector} from '@ngrx/store';
import {Global, Side} from '../global/global.model';
import {selectSelectedRuntimeTour} from '../runtime-tour/runtime-tour.reducer';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';
import * as fromGlobal from '../global/global.reducer';
import {RuntimeTourActions, RuntimeTourActionTypes} from '../runtime-tour/runtime-tour.actions';
import {AppState} from '../app.reducer';

export interface State extends EntityState<Tree> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Tree> = createEntityAdapter<Tree>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeActions | RuntimeTourActions,
  root: { root: AppState }
): State {
  switch (action.type) {
    case TreeActionTypes.AddTree: {
      return adapter.addOne(action.payload.tree, state);
    }

    case TreeActionTypes.UpsertTree: {
      return adapter.upsertOne(action.payload.tree, state);
    }

    case TreeActionTypes.AddTrees: {
      return adapter.addMany(action.payload.trees, state);
    }

    case TreeActionTypes.UpsertTrees: {
      return adapter.upsertMany(action.payload.trees, state);
    }

    case TreeActionTypes.UpdateTree: {
      return adapter.updateOne(action.payload.tree, state);
    }

    case TreeActionTypes.UpdateTrees: {
      return adapter.updateMany(action.payload.trees, state);
    }

    case TreeActionTypes.DeleteTree: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeActionTypes.DeleteTrees: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeActionTypes.LoadTrees: {
      return adapter.addAll(action.payload.trees, state);
    }

    case TreeActionTypes.ClearTrees: {
      return adapter.removeAll(state);
    }

    case RuntimeTourActionTypes.CancelTreeEdit: {
      const runtimeTour = selectSelectedRuntimeTour(Side.Left)(root);
      return adapter.upsertOne(runtimeTour.undoTree, state);
    }

    case TreeActionTypes.SetTreeState: {
      const tree = selectSelectedTreeOfSelectedTour(Side.Left)(root);
      let reasonIds = tree.reasonIds;
      let comment = tree.comment;

      if (tree.stateId !== action.payload.stateId) {
        reasonIds = [];
        comment = null;
      }

      const update: Update<Tree> = {
        id: action.payload.treeId,
        changes: {
          stateId: action.payload.stateId,
          comment,
          reasonIds,
        }
      };

      return adapter.updateOne(update, state);
    }

    case TreeActionTypes.SetTreeComment: {
      const tree = selectSelectedTreeOfSelectedTour(Side.Left)(root);

      const update: Update<Tree> = {
        id: action.payload.treeId,
        changes: {
          comment: action.payload.comment,
        }
      };

      return adapter.updateOne(update, state);
    }

    case TreeActionTypes.ToggleTreeReason: {
      let reasonIds = state.entities[action.payload.treeId].reasonIds;

      if (reasonIds.includes(action.payload.reasonId)) {
        reasonIds = reasonIds.filter(reasonId => reasonId !== action.payload.reasonId);
      } else {
        reasonIds.push(action.payload.reasonId);
      }

      const update: Update<Tree> = {
        id: action.payload.treeId,
        changes: {
          reasonIds: reasonIds,
        }
      };

      return adapter.updateOne(update, state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Tree> => state.root.trees;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export function selectAllByTourId(tourId: string) {
  return createSelector(
    selectAll,
    fromGlobal.selectFeature,
    (trees: Tree[], global: Global) => {
      if (global) {
        return trees.filter(tree => tree.tourId === tourId);
      } else {
        return [];
      }
    });
}

export function selectSelectedTreeOfSelectedTour(side: Side) {
  return createSelector(
    selectEntities,
    selectSelectedRuntimeTour(side),
    (treeDictionary: Dictionary<Tree>, runtimeTour: RuntimeTour) => {
      if (runtimeTour) {
        const treeId = runtimeTour.selectedTreeId;
        return treeId ? treeDictionary[treeId] : null;
      } else {
        return null;
      }
    });
}

export function selectTreesOfSelectedTour(side: Side) {
  return createSelector(
    selectAll,
    fromGlobal.selectFeature,
    (trees: Tree[], global: Global) => {
      if (global) {
        const tourId = global.selectedTourIds[side];
        return tourId ? trees.filter(tree => tree.tourId === tourId) : [];
      } else {
        return [];
      }
    });
}

export function selectTreeOfByTreeNumber(treeNumber: number) {
  return createSelector(
      selectAll,
      (trees: Tree[]) => {
        return trees.find(tree => tree.treenumber == treeNumber);
      });
}
