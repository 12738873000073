import {Component, OnInit} from '@angular/core';
import {ContentType, LearningMode} from '../../store/habitat/content-card.model';
import {Store} from '@ngrx/store';
import {selectContentType, selectLearningMode} from '../../store/habitat/content-card.reducer';
import {InfoHabitat, SwitchLearningMode, MultimediaContent} from '../../store/habitat/content-card.actions';
import {TourDisabledModus} from '../../store/tour-disabled-modus/tour-disabled-modus.model';
import {selectTourDisabledModus} from '../../store/tour-disabled-modus/tour-disabled-modus.reducer';
import {Tour} from '../../store/tour/tour.model';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {Side} from '../../store/global/global.model';
import {User, UserHelper} from '../../store/user/user.model';
import {selectUser} from '../../store/user/user.reducer';
import { selectSelectedSpotOfSelectedTour } from 'src/app/store/spot/spot.reducer';
import { Spot } from 'src/app/store/spot/spot.model';

@Component({
  selector: 'app-habitat-tab',
  templateUrl: './habitat-tab.component.html',
  styleUrls: ['./habitat-tab.component.css']
})
export class HabitatTabComponent implements OnInit {

  public user: User;
  public UserHelper = UserHelper;
  private learningMode: LearningMode;
  private learningModeType = LearningMode;
  private tourDisabledModus: TourDisabledModus[];
  private selectedTour: Tour;
  public selectedSpot: Spot;

  private content: ContentType;
  private contentType = ContentType;

  constructor(private store: Store<any>) {
  }

  ngOnInit() {
    this.store.select(selectUser).subscribe(user => {
      this.user = user;
    });
    this.store.select(selectLearningMode).subscribe((learningMode) => {
      this.learningMode = learningMode;
    });
    this.store.select(selectContentType).subscribe((contentType) => {
      this.content = contentType;
    });

    this.store.select(selectSelectedTour(Side.Left)).subscribe((tour) => {
      this.selectedTour = tour;
    });

    this.store.select(selectTourDisabledModus).subscribe((tourDisabledModus) => {
      this.tourDisabledModus = tourDisabledModus.filter(x => x.tourId == +this.selectedTour.id);
    });

    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe((selectedSpot) => {
      this.selectedSpot = selectedSpot;
    });
  }

  switchingMode(event: any) {
    const mode: LearningMode = event.value;
    this.store.dispatch(new SwitchLearningMode({learningMode: mode}));
  }

  showHabitatInfo() {
    this.store.dispatch(new InfoHabitat());
  }

  showMultimediaContent() {
    this.store.dispatch(new MultimediaContent());
  }

  showGrid() {
    this.store.dispatch(new SwitchLearningMode({learningMode: this.learningMode}));
  }

  isMode1Available() {
    return this.tourDisabledModus.find(x => x.modusId == 1) == null;
  }

  isMode2Available() {
    return this.tourDisabledModus.find(x => x.modusId == 2) == null;
  }

  isMode3Available() {
    return this.tourDisabledModus.find(x => x.modusId == 3) == null;
  }

  isMode4Available() {
    return this.tourDisabledModus.find(x => x.modusId == 4) == null;
  }

  isMode5Available() {
    return UserHelper.isBackendUser(this.user);
  }

  getAvailableMode() {
    if (this.learningMode.valueOf() == 0 && this.isMode1Available()) {
      return 0;
    } else if (this.learningMode.valueOf() == 1 && this.isMode2Available()) {
      return 1;
    } else if (this.learningMode.valueOf() == 2 && this.isMode3Available()) {
      return 2;
    } else if (this.learningMode.valueOf() == 3 && this.isMode4Available()) {
      return 3;
    } else if (this.learningMode.valueOf() == 4) {
      return 4;
    } else {
      return this.getFirstAvailableMode();
    }
  }

  getFirstAvailableMode() {
    if (this.isMode1Available()) {
      this.learningMode = LearningMode.Discovery;
      return 0;
    } else if (this.isMode2Available()) {
      this.learningMode = LearningMode.Determination;
      return 1;
    } else if (this.isMode3Available()) {
      this.learningMode = LearningMode.Research;
      return 2;
    } else if (this.isMode4Available()) {
      this.learningMode = LearningMode.TreeMarking;
      return 3;
    } else if (this.user != null && UserHelper.isBackendUser(this.user)){
      this.learningMode = LearningMode.Editor;
      return 4;
    } else {
      return 0;
    }
  }

}
