import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Panorama } from './panorama.model';

export enum PanoramaActionTypes {
  LoadPanoramas = '[Panorama] Load Panoramas',
  AddPanorama = '[Panorama] Add Panorama',
  UpsertPanorama = '[Panorama] Upsert Panorama',
  AddPanoramas = '[Panorama] Add Panoramas',
  UpsertPanoramas = '[Panorama] Upsert Panoramas',
  UpdatePanorama = '[Panorama] Update Panorama',
  UpdatePanoramas = '[Panorama] Update Panoramas',
  DeletePanorama = '[Panorama] Delete Panorama',
  DeletePanoramas = '[Panorama] Delete Panoramas',
  ClearPanoramas = '[Panorama] Clear Panoramas'
}

export class LoadPanoramas implements Action {
  readonly type = PanoramaActionTypes.LoadPanoramas;

  constructor(public payload: { panoramas: Panorama[] }) {}
}

export class AddPanorama implements Action {
  readonly type = PanoramaActionTypes.AddPanorama;

  constructor(public payload: { panorama: Panorama }) {}
}

export class UpsertPanorama implements Action {
  readonly type = PanoramaActionTypes.UpsertPanorama;

  constructor(public payload: { panorama: Panorama }) {}
}

export class AddPanoramas implements Action {
  readonly type = PanoramaActionTypes.AddPanoramas;

  constructor(public payload: { panoramas: Panorama[] }) {}
}

export class UpsertPanoramas implements Action {
  readonly type = PanoramaActionTypes.UpsertPanoramas;

  constructor(public payload: { panoramas: Panorama[] }) {}
}

export class UpdatePanorama implements Action {
  readonly type = PanoramaActionTypes.UpdatePanorama;

  constructor(public payload: { panorama: Update<Panorama> }) {}
}

export class UpdatePanoramas implements Action {
  readonly type = PanoramaActionTypes.UpdatePanoramas;

  constructor(public payload: { panoramas: Update<Panorama>[] }) {}
}

export class DeletePanorama implements Action {
  readonly type = PanoramaActionTypes.DeletePanorama;

  constructor(public payload: { id: string }) {}
}

export class DeletePanoramas implements Action {
  readonly type = PanoramaActionTypes.DeletePanoramas;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearPanoramas implements Action {
  readonly type = PanoramaActionTypes.ClearPanoramas;
}

export type PanoramaActions =
 LoadPanoramas
 | AddPanorama
 | UpsertPanorama
 | AddPanoramas
 | UpsertPanoramas
 | UpdatePanorama
 | UpdatePanoramas
 | DeletePanorama
 | DeletePanoramas
 | ClearPanoramas;
