import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tour} from '../../store/tour/tour.model';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import * as fromTour from '../../store/tour/tour.reducer';
import {Side} from '../../store/global/global.model';
import {User, UserHelper} from '../../store/user/user.model';
import {TranslateService} from '@ngx-translate/core';
import {BackendService} from '../../services/backend.service';
import {selectUser} from '../../store/user/user.reducer';
import {SetFromUserAction} from '../../store/language/language.actions';
import {translation} from '../../app.translation';
import {Theme} from '../../store/catalog/theme/theme.model';
import {selectThemes} from '../../store/catalog/theme/theme.reducer';
import {selectTourThematics} from 'src/app/store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from 'src/app/store/tour-thematic/tour-thematic.model';

@Component({
  selector: 'app-tour-selector',
  templateUrl: './tour-selector.component.html',
  styleUrls: ['./tour-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourSelectorComponent implements OnInit {
  @Input() side: Side = Side.Left;
  @Input() requiresPanorama: boolean = false;
  @Output() selectTour = new EventEmitter<Tour>();

  public tours$: Observable<Tour[]>;
  public tours: Tour[] = [];
  public tourThematics: TourThematic[] = [];

  public user$: Observable<User>;
  public user: User;
  public UserHelper = UserHelper;
  public themes: Theme[];

  constructor(private store: Store<any>, private backendService: BackendService, private translate: TranslateService) {
    this.user$ = this.store.select(selectUser);
    this.store.select(selectUser).subscribe(user => {
      this.user = user;
    });
    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });
  }

  ngOnInit() {

    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });

    this.store.select(selectTourThematics).subscribe(tourThematics => {
      this.tourThematics = tourThematics;
    });

    this.tours$ = this.store.select(fromTour.selectAll);
    this.store.select(fromTour.selectAll).subscribe(tours => {
      tours.sort((t1, t2) => t2.order - t1.order);
      tours.reverse();
      const tmp = tours;
      this.tours = tmp.filter(x => x.published != 0);
      console.log(tours);
      console.log(this.tourThematics);
      this.tours = tmp.filter(x => {
        if (BackendService.THEMATIC == null) {
          return true;
        } else if (this.tourThematics.find(y => +y.tourId === +x.id) != null &&
          this.tourThematics.find(y => +y.tourId == +x.id).themeId == BackendService.THEMATIC) {
          return true;
        } else {
          return false;
        }
      });

      console.log(this.tours);
    });
  }

  onSelectTour(tour: Tour): void {
    //this.store.dispatch(new SetSelectedTour({side: this.side, tourId: tour.id}));
    this.selectTour.emit(tour);
  }

  setLanguage(language: string): void {
    this.store.dispatch(new SetFromUserAction(language));
  }

  logout() {
    this.backendService.logout().subscribe( res => {
      window.location.reload();
    });
  }

  getTheme() {
    let theme;
    theme = this.themes.find(x => x.id == BackendService.THEMATIC); // stay in double == , DO NOT!! change to triple ===

    if (theme != null) {
      return theme;
    } else {
      return null;
    }
  }

  translateTheme(preString: string): string {
    return translation(this.getTheme(), preString, this.translate);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
