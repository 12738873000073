import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TourCanopyCoverDegree} from './tour-canopy-cover-degree.model';
import {TourCanopyCoverDegreeActions, TourCanopyCoverDegreeActionTypes} from './tour-canopy-cover-degree.actions';
import {TourCategory} from '../tour-category/tour-category.model';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TourCanopyCoverDegree> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TourCanopyCoverDegree> = createEntityAdapter<TourCanopyCoverDegree>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TourCanopyCoverDegreeActions
): State {
  switch (action.type) {
    case TourCanopyCoverDegreeActionTypes.AddTourCanopyCoverDegree: {
      return adapter.addOne(action.payload.tourCanopyCoverDegree, state);
    }

    case TourCanopyCoverDegreeActionTypes.UpsertTourCanopyCoverDegree: {
      return adapter.upsertOne(action.payload.tourCanopyCoverDegree, state);
    }

    case TourCanopyCoverDegreeActionTypes.AddTourCanopyCoverDegrees: {
      return adapter.addMany(action.payload.tourCanopyCoverDegrees, state);
    }

    case TourCanopyCoverDegreeActionTypes.UpsertTourCanopyCoverDegrees: {
      return adapter.upsertMany(action.payload.tourCanopyCoverDegrees, state);
    }

    case TourCanopyCoverDegreeActionTypes.UpdateTourCanopyCoverDegree: {
      return adapter.updateOne(action.payload.tourCanopyCoverDegree, state);
    }

    case TourCanopyCoverDegreeActionTypes.UpdateTourCanopyCoverDegrees: {
      return adapter.updateMany(action.payload.tourCanopyCoverDegrees, state);
    }

    case TourCanopyCoverDegreeActionTypes.DeleteTourCanopyCoverDegree: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourCanopyCoverDegreeActionTypes.DeleteTourCanopyCoverDegrees: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourCanopyCoverDegreeActionTypes.LoadTourCanopyCoverDegrees: {
      return adapter.addAll(action.payload.tourCanopyCoverDegrees, state);
    }

    case TourCanopyCoverDegreeActionTypes.ClearTourCanopyCoverDegrees: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TourCategory> => state.root.tourCanopyCoverDegrees;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
