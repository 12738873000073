import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Reason} from './reason.model';
import {ReasonActions, ReasonActionTypes} from './reason.actions';
import {Side} from '../global/global.model';
import {selectSelectedTreeOfSelectedTour} from '../tree/tree.reducer';
import {Tree} from '../tree/tree.model';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<Reason> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Reason> = createEntityAdapter<Reason>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: ReasonActions
): State {
  switch (action.type) {
    case ReasonActionTypes.AddReason: {
      return adapter.addOne(action.payload.reason, state);
    }

    case ReasonActionTypes.UpsertReason: {
      return adapter.upsertOne(action.payload.reason, state);
    }

    case ReasonActionTypes.AddReasons: {
      return adapter.addMany(action.payload.reasons, state);
    }

    case ReasonActionTypes.UpsertReasons: {
      return adapter.upsertMany(action.payload.reasons, state);
    }

    case ReasonActionTypes.UpdateReason: {
      return adapter.updateOne(action.payload.reason, state);
    }

    case ReasonActionTypes.UpdateReasons: {
      return adapter.updateMany(action.payload.reasons, state);
    }

    case ReasonActionTypes.DeleteReason: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ReasonActionTypes.DeleteReasons: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ReasonActionTypes.LoadReasons: {
      return adapter.addAll(action.payload.reasons, state);
    }

    case ReasonActionTypes.ClearReasons: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Reason> => state.root.reasons;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectReasonsByTreeState = createSelector(
  selectAll,
  selectSelectedTreeOfSelectedTour(Side.Left),
  (reasons: Reason[], tree: Tree) => {
    if (tree) {
      return reasons.filter(reason => reason.stateId === tree.stateId);
    } else {
      return [];
    }
  }
);

export const selectSelectedReasonsByTreeState = createSelector(
  selectAll,
  selectSelectedTreeOfSelectedTour(Side.Left),
  (reasons: Reason[], tree: Tree) => {
    if (tree) {
      return reasons.filter(reason => tree.reasonIds.includes(reason.id));
    } else {
      return [];
    }
  }
);
