import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Media} from './media.model';
import {MediaActions, MediaActionTypes} from './media.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<Media> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Media> = createEntityAdapter<Media>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: MediaActions
): State {
  switch (action.type) {
    case MediaActionTypes.AddMedia: {
      return adapter.addOne(action.payload.media, state);
    }

    case MediaActionTypes.UpsertMedia: {
      return adapter.upsertOne(action.payload.media, state);
    }

    case MediaActionTypes.AddMedias: {
      return adapter.addMany(action.payload.medias, state);
    }

    case MediaActionTypes.UpsertMedias: {
      return adapter.upsertMany(action.payload.medias, state);
    }

    case MediaActionTypes.UpdateMedia: {
      return adapter.updateOne(action.payload.media, state);
    }

    case MediaActionTypes.UpdateMedias: {
      return adapter.updateMany(action.payload.medias, state);
    }

    case MediaActionTypes.DeleteMedia: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MediaActionTypes.DeleteMedias: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MediaActionTypes.LoadMedias: {
      return adapter.addAll(action.payload.medias, state);
    }

    case MediaActionTypes.ClearMedias: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Media> => state.root.medias;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectMedias = createSelector(selectAll, entities => entities);
