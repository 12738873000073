import {Component, Input, OnInit} from '@angular/core';
import {Question} from '../../../store/catalog/question/question.model';
import {Response} from '../../../store/catalog/response/response.model';
import {Content} from '../../../store/catalog/content/content.model';
import {BackendService} from '../../../services/backend.service';
import {Media} from '../../../store/catalog/media/media.model';
import {MatDialog} from '@angular/material';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  @Input() medias: Media[];
  @Input() questions: Question[];
  @Input() question: Question;
  @Input() responses: Response [];
  @Input() contents: Content [];

  content: Content;

  constructor(private backendService: BackendService, public dialog: MatDialog) { }

  ngOnInit() {
    this.content = this.contents.find( x => x.id === this.question.contentId);
  }

  updateQuestion() {
    this.backendService.updateContent(this.content).subscribe( res => {
    });
    this.backendService.updateQuestion(this.question).subscribe( res => {
    });
  }

  getMedias() {
    return this.medias.filter(x => x.contentId === this.content.id);
  }

  getResponse() {
    return this.responses.filter( x => x.questionId === this.question.id);
  }

  getContentByResponse(response: Response) {
    return this.contents.find(x => x.id === response.contentId);
  }

  createMedia() {
    const media: Media = new Media();
    media.contentId = this.content.id;
    media.mediaUrl = '';
    media.hyperlink = '';
    this.backendService.createMedia(media).subscribe( res => {
      if (res > 0) {
        media.id = res;
        this.medias.push(media);
      }
    });
  }

  createResponse() {
    const response: Response = new Response();
    response.questionId = this.question.id;
    const newContent: Content = new Content();
    newContent.textFr = '';
    newContent.textDe = '';
    newContent.textEn = '';
    this.backendService.createContent(newContent).subscribe( res => {
      if (res > 0) {
        newContent.id = res;
        response.contentId = newContent.id;
        this.contents.push(newContent);
        this.backendService.createResponse(response).subscribe( result => {
          if (result > 0) {
            response.id = result;
            this.responses.push(response);
          }
        });
      }
    });
  }

  deleteQuestion() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {ok: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.deleteQuestion(this.question).subscribe(res => {
          this.questions.splice(this.questions.indexOf(this.question), 1);
        });
      }
    });

  }

}
