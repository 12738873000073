import {createEntityAdapter, Dictionary, EntityAdapter, EntityState, Update} from '@ngrx/entity';
import {RuntimePanorama} from './runtime-panorama.model';
import {RuntimePanoramaActions, RuntimePanoramaActionTypes} from './runtime-panorama.actions';
import {PanoramaActions, PanoramaActionTypes} from '../panorama/panorama.actions';
import {Panorama} from '../panorama/panorama.model';
import {createSelector} from '@ngrx/store';
import * as fromRuntimeTour from '../runtime-tour/runtime-tour.reducer';
import {Side} from '../global/global.model';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';

export interface State extends EntityState<RuntimePanorama> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RuntimePanorama> = createEntityAdapter<RuntimePanorama>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

function generateOne(panorama: Panorama): RuntimePanorama {
  return {
    id: panorama.id,
    selectedPanoramaTreeId: null,
  };
}

function generateMany(panoramas: Panorama[]) {
  return panoramas.map(generateOne);
}

export function reducer(
  state = initialState,
  action: RuntimePanoramaActions | PanoramaActions
): State {
  switch (action.type) {
    case PanoramaActionTypes.AddPanorama: {
      return adapter.addOne(generateOne(action.payload.panorama), state);
    }

    case PanoramaActionTypes.UpsertPanorama: {
      return adapter.upsertOne(generateOne(action.payload.panorama), state);
    }

    case PanoramaActionTypes.AddPanoramas: {
      return adapter.addMany(generateMany(action.payload.panoramas), state);
    }

    case PanoramaActionTypes.UpsertPanoramas: {
      return adapter.upsertMany(generateMany(action.payload.panoramas), state);
    }

    case PanoramaActionTypes.DeletePanorama: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PanoramaActionTypes.DeletePanoramas: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PanoramaActionTypes.LoadPanoramas: {
      return adapter.addAll(generateMany(action.payload.panoramas), state);
    }

    case PanoramaActionTypes.ClearPanoramas: {
      return adapter.removeAll(state);
    }

    case RuntimePanoramaActionTypes.SetSelectedPanoramaTree: {
      const update: Update<RuntimePanorama> = {
        id: action.payload.panoramaId,
        changes: {
          selectedPanoramaTreeId: action.payload.panoramaTreeId,
        }
      };

      return adapter.updateOne(update, state);
    }

    case RuntimePanoramaActionTypes.ClearSelectedPanoramaTree: {
      const update: Update<RuntimePanorama> = {
        id: action.payload.panoramaId,
        changes: {
          selectedPanoramaTreeId: null,
        }
      };

      return adapter.updateOne(update, state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<RuntimePanorama> => state.root.runtimePanoramas;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectSelectedRuntimePanoramaOfSelectedTour = (side: Side) => createSelector(
  selectEntities,
  fromRuntimeTour.selectSelectedRuntimeTour(side),
  (runtimePanoramaDictionary: Dictionary<RuntimePanorama>, runtimeTour: RuntimeTour) => {
    if (runtimeTour) {
      const panoramaId = runtimeTour.panoramaIds[side];
      return panoramaId ? runtimePanoramaDictionary[panoramaId] : null;
    } else {
      return null;
    }
  }
);
