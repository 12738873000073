import {Component, Input, OnInit} from '@angular/core';
import {MediaType} from '../../../store/catalog/media-type/media-type.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {BackendService} from '../../../services/backend.service';
import {selectMediaTypes} from '../../../store/catalog/media-type/media-type.reducer';
import {Media} from '../../../store/catalog/media/media.model';
import {MatDialog} from '@angular/material';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  @Input() medias: Media[];
  @Input() media: Media;
  mediaTypes: MediaType[] = [];

  constructor(private store: Store<AppState>, private backendService: BackendService, public dialog: MatDialog) { }

  ngOnInit() {
    this.store.select(selectMediaTypes).subscribe(mediaTypes => {
      this.mediaTypes = mediaTypes;
    });
  }

  updateMedia() {

    this.backendService.updateMedia(this.media).subscribe( res => {

    });
  }

  deleteMedia() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {ok: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.deleteMedia(this.media).subscribe(res => {

          this.medias.splice(this.medias.indexOf(this.media), 1);
        });
      }
    });

  }
}
