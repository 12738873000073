import {Component, Input, OnInit} from '@angular/core';
import {BackendService} from '../../../services/backend.service';
import {Media} from '../../../store/catalog/media/media.model';
import {Content} from '../../../store/catalog/content/content.model';
import {MatDialog} from '@angular/material';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @Input() title;
  @Input() medias;
  @Input() contents: Content[];
  @Input() content;
  @Input() fix;

  constructor(private backendService: BackendService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  updateContent() {

    this.backendService.updateContent(this.content).subscribe( res => {

    });
  }

  getMedias() {
    return this.medias.filter(x => x.contentId === this.content.id);
  }

  createMedia() {
    const media: Media = new Media();
    media.contentId = this.content.id;
    media.mediaUrl = '';
    media.hyperlink = '';
    this.backendService.createMedia(media).subscribe( res => {

      if (res > 0) {
        media.id = res;
        this.medias.push(media);
      }
    });
  }

  deleteContent() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {ok: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.deleteContent(this.content).subscribe(res => {

          this.contents.splice(this.contents.indexOf(this.content), 1);
        });
      }
    });

  }

}
