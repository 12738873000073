import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {User, UserHelper} from '../../store/user/user.model';
import {Store} from '@ngrx/store';
import {BackendService} from '../../services/backend.service';
import {TranslateService} from '@ngx-translate/core';
import {selectUser, UserSetAction} from '../../store/user/user.reducer';
import {SetFromUserAction} from '../../store/language/language.actions';
import {Theme} from '../../store/catalog/theme/theme.model';
import {selectThemes} from '../../store/catalog/theme/theme.reducer';
import {translation} from '../../app.translation';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {

  public user$: Observable<User>;
  public user: User;
  public UserHelper = UserHelper;
  public themes: Theme[];

  constructor(private store: Store<any>, private backendService: BackendService, private translate: TranslateService) {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      this.user = user;
    });
    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });

    setTimeout(() => {
      const lang = localStorage.getItem('language');
      this.setLanguage(lang);
    }, 2000);
  }

  ngOnInit() {
  }

  setLanguage(language: string): void {
    this.store.dispatch(new SetFromUserAction(language));
    localStorage.setItem('language', language);
  }

  logout() {
    this.backendService.logout().subscribe( res => {
      this.store.dispatch(new UserSetAction(null));
      window.location.reload();
    });
  }

  getTheme() {
    let theme;
    theme = this.themes.find(x => x.id == BackendService.THEMATIC);

    if (theme != null) {
      return theme;
    } else {
      return null;
    }
  }

  translateTheme(preString: string): string {
    return translation(this.getTheme(), preString, this.translate);
  }
}
