import {debounceTime, scan} from 'rxjs/operators';
import {pipe} from 'rxjs';

export class Update<T> {
  readonly updated: boolean;

  constructor(
    readonly value: T,
    readonly last: T,
  ) {
    this.updated = this.value !== this.last;
  }
}

export const consistent = pipe(
  debounceTime(0)
);

export const updates = pipe(
  scan((oldChanges, newValues) => {
      if (oldChanges) {
        return newValues.map((value, index) => new Update(value, oldChanges[index].value));
      } else {
        return newValues.map(value => new Update(value, undefined));
      }
    },
    null
  )
);

export const consistentUpdates = pipe(
  consistent,
  updates,
);
