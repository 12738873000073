import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectThemes} from '../../../store/catalog/theme/theme.reducer';
import {Theme} from '../../../store/catalog/theme/theme.model';
import {BackendService} from '../../../services/backend.service';
import {translation} from '../../../app.translation';
import {TranslateService} from '@ngx-translate/core';
import {selectUser, UserSetAction} from '../../../store/user/user.reducer';
import {Observable} from 'rxjs';
import {User, UserHelper} from '../../../store/user/user.model';
import {Router} from '@angular/router';
import {SetFromUserAction} from '../../../store/language/language.actions';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  themes: Theme[] = [];
  public user$: Observable<User>;
  public isAdminConnected = false;

  constructor(private store: Store<AppState>, private backendService: BackendService, private translate: TranslateService, private router: Router) {}

  ngOnInit() {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      if (user != null && (UserHelper.isAdmin(user) || UserHelper.isDeveloper(user))) {
        this.isAdminConnected = true;
      } else {
        this.isAdminConnected = false;
      }
    });
    this.store.select(selectThemes).subscribe(themes => {
      this.themes = themes;
    });

    setTimeout(() => {
      let lang = localStorage.getItem('language');
      if (lang == null || lang === 'null') {
        lang = 'fr';
      }
      this.setLanguage(lang);
    }, 2000);
  }

  logout() {
    this.backendService.logout().subscribe( res => {

      this.store.dispatch(new UserSetAction(null));
      window.location.reload();
    });
  }

  addTab(selectAfterAdding: boolean) {
    /*this.tabs.push('New');

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);
    }*/

    const newTheme: Theme = new Theme();
    newTheme.nameFr = 'nouvelle thématique';
    newTheme.nameDe = 'neue thematic';
    newTheme.nameEn = 'new thematic';
    newTheme.color = 'blue';

    this.backendService.createTheme(newTheme).subscribe( res => {
      newTheme.id = res;
      if (res > 0) {
        this.themes.push(newTheme);
      }

    });

  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }

  translateCatalog(object, preString: string): string {
    return translation(object, preString, this.translate);
  }

  setLanguage(language: string): void {
    this.store.dispatch(new SetFromUserAction(language));
    localStorage.setItem('language', language);
  }

}
