import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {User, UserHelper} from './store/user/user.model';
import {TranslateService} from '@ngx-translate/core';
import {LoadApp} from './store/app.actions';
import {selectUser} from './store/user/user.reducer';
import {SetFromUserAction} from './store/language/language.actions';
import {Observable} from 'rxjs';
import {AppState} from './store/app.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  public user$: Observable<User>;
  public user: User;
  public UserHelper = UserHelper;

  constructor(private store: Store<AppState>, public translate: TranslateService) {
    this.initLanguages();
  }

  ngOnInit() {
    this.store.dispatch(new LoadApp());

    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      this.user = user;
    });
  }

  initLanguages(): void {
    /* initializing the languages is required before using translate.set() in BackendService */

    this.translate.addLangs(['en', 'de', 'fr']);

    this.translate.setDefaultLang('de');
    //this.setLanguage('de');

    this.translate.setDefaultLang('fr');
    //this.setLanguage('fr');
  }

  setLanguage(language: string): void {
    this.store.dispatch(new SetFromUserAction(language));
  }
}
