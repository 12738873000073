import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreeStemType} from './tree-stem-type.model';
import {TreeStemTypeActions, TreeStemTypeActionType} from './tree-stem-type.action';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TreeStemType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TreeStemType> = createEntityAdapter<TreeStemType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeStemTypeActions
): State {
  switch (action.type) {
    case TreeStemTypeActionType.AddTreeStemType: {
      return adapter.addOne(action.payload.treeStemType, state);
    }

    case TreeStemTypeActionType.UpsertTreeStemType: {
      return adapter.upsertOne(action.payload.treeStemType, state);
    }

    case TreeStemTypeActionType.AddTreeStemTypes: {
      return adapter.addMany(action.payload.treeStemTypes, state);
    }

    case TreeStemTypeActionType.UpsertTreeStemTypes: {
      return adapter.upsertMany(action.payload.treeStemTypes, state);
    }

    case TreeStemTypeActionType.UpdateTreeStemType: {
      return adapter.updateOne(action.payload.treeStemType, state);
    }

    case TreeStemTypeActionType.UpdateTreeStemTypes: {
      return adapter.updateMany(action.payload.treeStemTypes, state);
    }

    case TreeStemTypeActionType.DeleteTreeStemType: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeStemTypeActionType.DeleteTreeStemTypes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeStemTypeActionType.LoadTreeStemTypes: {
      return adapter.addAll(action.payload.treeStemTypes, state);
    }

    case TreeStemTypeActionType.ClearTreeStemTypes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TreeStemType> => state.root.treeStemTypes;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
