import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Media } from './media.model';

export enum MediaActionTypes {
  LoadMedias = '[Media] Load Medias',
  AddMedia = '[Media] Add Media',
  UpsertMedia = '[Media] Upsert Media',
  AddMedias = '[Media] Add Medias',
  UpsertMedias = '[Media] Upsert Medias',
  UpdateMedia = '[Media] Update Media',
  UpdateMedias = '[Media] Update Medias',
  DeleteMedia = '[Media] Delete Media',
  DeleteMedias = '[Media] Delete Medias',
  ClearMedias = '[Media] Clear Medias'
}

export class LoadMedias implements Action {
  readonly type = MediaActionTypes.LoadMedias;

  constructor(public payload: { medias: Media[] }) {}
}

export class AddMedia implements Action {
  readonly type = MediaActionTypes.AddMedia;

  constructor(public payload: { media: Media }) {}
}

export class UpsertMedia implements Action {
  readonly type = MediaActionTypes.UpsertMedia;

  constructor(public payload: { media: Media }) {}
}

export class AddMedias implements Action {
  readonly type = MediaActionTypes.AddMedias;

  constructor(public payload: { medias: Media[] }) {}
}

export class UpsertMedias implements Action {
  readonly type = MediaActionTypes.UpsertMedias;

  constructor(public payload: { medias: Media[] }) {}
}

export class UpdateMedia implements Action {
  readonly type = MediaActionTypes.UpdateMedia;

  constructor(public payload: { media: Update<Media> }) {}
}

export class UpdateMedias implements Action {
  readonly type = MediaActionTypes.UpdateMedias;

  constructor(public payload: { medias: Update<Media>[] }) {}
}

export class DeleteMedia implements Action {
  readonly type = MediaActionTypes.DeleteMedia;

  constructor(public payload: { id: string }) {}
}

export class DeleteMedias implements Action {
  readonly type = MediaActionTypes.DeleteMedias;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearMedias implements Action {
  readonly type = MediaActionTypes.ClearMedias;
}

export type MediaActions =
 LoadMedias
 | AddMedia
 | UpsertMedia
 | AddMedias
 | UpsertMedias
 | UpdateMedia
 | UpdateMedias
 | DeleteMedia
 | DeleteMedias
 | ClearMedias;
