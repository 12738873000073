import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Question } from './question.model';

export enum QuestionActionTypes {
  LoadQuestions = '[Question] Load Questions',
  AddQuestion = '[Question] Add Question',
  UpsertQuestion = '[Question] Upsert Question',
  AddQuestions = '[Question] Add Questions',
  UpsertQuestions = '[Question] Upsert Questions',
  UpdateQuestion = '[Question] Update Question',
  UpdateQuestions = '[Question] Update Questions',
  DeleteQuestion = '[Question] Delete Question',
  DeleteQuestions = '[Question] Delete Questions',
  ClearQuestions = '[Question] Clear Questions'
}

export class LoadQuestions implements Action {
  readonly type = QuestionActionTypes.LoadQuestions;

  constructor(public payload: { questions: Question[] }) {}
}

export class AddQuestion implements Action {
  readonly type = QuestionActionTypes.AddQuestion;

  constructor(public payload: { question: Question }) {}
}

export class UpsertQuestion implements Action {
  readonly type = QuestionActionTypes.UpsertQuestion;

  constructor(public payload: { question: Question }) {}
}

export class AddQuestions implements Action {
  readonly type = QuestionActionTypes.AddQuestions;

  constructor(public payload: { questions: Question[] }) {}
}

export class UpsertQuestions implements Action {
  readonly type = QuestionActionTypes.UpsertQuestions;

  constructor(public payload: { questions: Question[] }) {}
}

export class UpdateQuestion implements Action {
  readonly type = QuestionActionTypes.UpdateQuestion;

  constructor(public payload: { question: Update<Question> }) {}
}

export class UpdateQuestions implements Action {
  readonly type = QuestionActionTypes.UpdateQuestions;

  constructor(public payload: { questions: Update<Question>[] }) {}
}

export class DeleteQuestion implements Action {
  readonly type = QuestionActionTypes.DeleteQuestion;

  constructor(public payload: { id: string }) {}
}

export class DeleteQuestions implements Action {
  readonly type = QuestionActionTypes.DeleteQuestions;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearQuestions implements Action {
  readonly type = QuestionActionTypes.ClearQuestions;
}

export type QuestionActions =
 LoadQuestions
 | AddQuestion
 | UpsertQuestion
 | AddQuestions
 | UpsertQuestions
 | UpdateQuestion
 | UpdateQuestions
 | DeleteQuestion
 | DeleteQuestions
 | ClearQuestions;
