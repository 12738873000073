import {Actions, Effect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../app.reducer';
import {LoadTour} from '../app.actions';
import * as fromRuntimeTour from '../runtime-tour/runtime-tour.reducer';
import {GlobalActionTypes, SetSelectedTour} from './global.action';

@Injectable()
export class GlobalEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {
  }

  @Effect({dispatch: false})
  loadTourWhenSelected = this.actions$.pipe(
    ofType(GlobalActionTypes.SetSelectedTour),
    map((action: SetSelectedTour) => {
      this.store.select(fromRuntimeTour.selectById(action.payload.tourId)).subscribe(runtimeTour => {
        if (runtimeTour && !runtimeTour.loading) {
          this.store.dispatch(new LoadTour({tourId: action.payload.tourId}));
        }
      });
    }),
  );
}
