import {createEntityAdapter, Dictionary, EntityAdapter, EntityState, Update} from '@ngrx/entity';
import {RuntimeTour} from './runtime-tour.model';
import {RuntimeTourActions, RuntimeTourActionTypes} from './runtime-tour.actions';
import {TourActions, TourActionTypes} from '../tour/tour.actions';
import {Tour} from '../tour/tour.model';
import {createSelector} from '@ngrx/store';
import * as fromGlobal from '../global/global.reducer';
import {AppActions, AppActionTypes} from '../app.actions';
import {Global, Side} from '../global/global.model';
import {AppState} from '../app.reducer';
import {Tree} from '../tree/tree.model';
import {Spot} from '../spot/spot.model';

export interface State extends EntityState<RuntimeTour> {
  // additional entities state properties
}

export const adapter: EntityAdapter<RuntimeTour> = createEntityAdapter<RuntimeTour>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

function generateOne(tour: Tour): RuntimeTour {
  return {
    id: tour.id,
    panoramaIds: [null, null],
    selectedTreeId: null,
    selectedSpotId: null,
    undoTree: null,
    loading: false,
  };
}

function generateMany(tours: Tour[]) {
  return tours.map(generateOne);
}

export function reducer(
  state = initialState,
  action: RuntimeTourActions | TourActions | AppActions,
  root: { root: AppState }
): State {
  switch (action.type) {
    case TourActionTypes.AddTour: {
      return adapter.addOne(generateOne(action.payload.tour), state);
    }

    case TourActionTypes.UpsertTour: {
      return adapter.upsertOne(generateOne(action.payload.tour), state);
    }

    case TourActionTypes.AddTours: {
      return adapter.addMany(generateMany(action.payload.tours), state);
    }

    case TourActionTypes.UpsertTours: {
      return adapter.upsertMany(generateMany(action.payload.tours), state);
    }

    case TourActionTypes.DeleteTour: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourActionTypes.DeleteTours: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourActionTypes.LoadTours: {
      return adapter.addAll(generateMany(action.payload.tours), state);
    }

    case TourActionTypes.ClearTours: {
      return adapter.removeAll(state);
    }

    case RuntimeTourActionTypes.SetSelectedPanorama: {
      const panoramaIds = state.entities[action.payload.tourId].panoramaIds;
      panoramaIds[action.payload.side] = action.payload.panoramaId;

      const update: Update<RuntimeTour> = {
        id: action.payload.tourId,
        changes: {
          panoramaIds: panoramaIds,
        }
      };

      return adapter.updateOne(update, state);
    }

    case RuntimeTourActionTypes.SetSelectedTree: {
      const tree = root.root.trees.entities[action.payload.treeId];
      const undo: Tree = {
        ...tree,
        reasonIds: [
          ...tree.reasonIds
        ]
      };

      const update: Update<RuntimeTour> = {
        id: action.payload.tourId,
        changes: {
          selectedTreeId: action.payload.treeId,
          undoTree: undo,
        }
      };

      return adapter.updateOne(update, state);
    }

    case RuntimeTourActionTypes.CancelTreeEdit:
    case RuntimeTourActionTypes.ClearSelectedTree: {
      const realtimeTour = selectSelectedRuntimeTour(Side.Left)(root);

      const update: Update<RuntimeTour> = {
        id: realtimeTour.id,
        changes: {
          selectedTreeId: null,
        }
      };

      return adapter.updateOne(update, state);
    }

    case RuntimeTourActionTypes.SetSelectedSpot: {
      const spot = root.root.spots.entities[action.payload.spotId];

      const update: Update<RuntimeTour> = {
        id: action.payload.tourId,
        changes: {
          selectedSpotId: action.payload.spotId,
        }
      };

      return adapter.updateOne(update, state);
    }

    case RuntimeTourActionTypes.CancelSpotEdit:
    case RuntimeTourActionTypes.ClearSelectedSpot: {
      const realtimeTour = selectSelectedRuntimeTour(Side.Left)(root);

      const update: Update<RuntimeTour> = {
        id: realtimeTour.id,
        changes: {
          selectedSpotId: null,
        }
      };

      return adapter.updateOne(update, state);
    }

    case AppActionTypes.LoadTour: {
      const update: Update<RuntimeTour> = {
        id: action.payload.tourId,
        changes: {
          loading: true,
        }
      };

      return adapter.updateOne(update, state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<RuntimeTour> => state.root.runtimeTours;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectSelectedRuntimeTour = (side: Side) => createSelector(
  selectEntities,
  fromGlobal.selectFeature,
  (runtimeTourEntites: Dictionary<RuntimeTour>, global: Global) => {
    const selectedTour = global.selectedTourIds[side];
    return selectedTour ? runtimeTourEntites[selectedTour] : null;
  }
);
