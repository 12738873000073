import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {PanoramaTree} from '../../store/panorama-tree/panorama-tree.model';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour} from '../../store/panorama-tree/panorama-tree.reducer';

@Component({
  selector: 'app-panorama-tree-panel',
  templateUrl: './panorama-tree-panel.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaTreePanelComponent implements OnInit {
  public selectedPanoramaTree$: Observable<PanoramaTree>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    this.selectedPanoramaTree$ = this.store.select(selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour);
  }
}
