import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TourDisabledModus } from './tour-disabled-modus.model';

export enum TourDisabledModusActionTypes {
  LoadTourDisabledModuss = '[TourDisabledModus] Load TourDisabledModuss',
  AddTourDisabledModus = '[TourDisabledModus] Add TourDisabledModus',
  UpsertTourDisabledModus = '[TourDisabledModus] Upsert TourDisabledModus',
  AddTourDisabledModuss = '[TourDisabledModus] Add TourDisabledModuss',
  UpsertTourDisabledModuss = '[TourDisabledModus] Upsert TourDisabledModuss',
  UpdateTourDisabledModus = '[TourDisabledModus] Update TourDisabledModus',
  UpdateTourDisabledModuss = '[TourDisabledModus] Update TourDisabledModuss',
  DeleteTourDisabledModus = '[TourDisabledModus] Delete TourDisabledModus',
  DeleteTourDisabledModuss = '[TourDisabledModus] Delete TourDisabledModuss',
  ClearTourDisabledModuss = '[TourDisabledModus] Clear TourDisabledModuss'
}

export class LoadTourDisabledModuss implements Action {
  readonly type = TourDisabledModusActionTypes.LoadTourDisabledModuss;

  constructor(public payload: { tourDisabledModuss: TourDisabledModus[] }) {}
}

export class AddTourDisabledModus implements Action {
  readonly type = TourDisabledModusActionTypes.AddTourDisabledModus;

  constructor(public payload: { tourDisabledModus: TourDisabledModus }) {}
}

export class UpsertTourDisabledModus implements Action {
  readonly type = TourDisabledModusActionTypes.UpsertTourDisabledModus;

  constructor(public payload: { tourDisabledModus: TourDisabledModus }) {}
}

export class AddTourDisabledModuss implements Action {
  readonly type = TourDisabledModusActionTypes.AddTourDisabledModuss;

  constructor(public payload: { tourDisabledModuss: TourDisabledModus[] }) {}
}

export class UpsertTourDisabledModuss implements Action {
  readonly type = TourDisabledModusActionTypes.UpsertTourDisabledModuss;

  constructor(public payload: { tourDisabledModuss: TourDisabledModus[] }) {}
}

export class UpdateTourDisabledModus implements Action {
  readonly type = TourDisabledModusActionTypes.UpdateTourDisabledModus;

  constructor(public payload: { tourDisabledModus: Update<TourDisabledModus> }) {}
}

export class UpdateTourDisabledModuss implements Action {
  readonly type = TourDisabledModusActionTypes.UpdateTourDisabledModuss;

  constructor(public payload: { tourDisabledModuss: Update<TourDisabledModus>[] }) {}
}

export class DeleteTourDisabledModus implements Action {
  readonly type = TourDisabledModusActionTypes.DeleteTourDisabledModus;

  constructor(public payload: { id: string }) {}
}

export class DeleteTourDisabledModuss implements Action {
  readonly type = TourDisabledModusActionTypes.DeleteTourDisabledModuss;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTourDisabledModuss implements Action {
  readonly type = TourDisabledModusActionTypes.ClearTourDisabledModuss;
}

export type TourDisabledModusActions =
 LoadTourDisabledModuss
 | AddTourDisabledModus
 | UpsertTourDisabledModus
 | AddTourDisabledModuss
 | UpsertTourDisabledModuss
 | UpdateTourDisabledModus
 | UpdateTourDisabledModuss
 | DeleteTourDisabledModus
 | DeleteTourDisabledModuss
 | ClearTourDisabledModuss;
