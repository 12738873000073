import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import {Panorama} from './panorama.model';
import {PanoramaActions, PanoramaActionTypes} from './panorama.actions';
import {createSelector} from '@ngrx/store';
import {Side} from '../global/global.model';
import * as fromRuntimeTour from '../runtime-tour/runtime-tour.reducer';
import * as fromTour from '../tour/tour.reducer';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';

export interface State extends EntityState<Panorama> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Panorama> = createEntityAdapter<Panorama>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: PanoramaActions
): State {
  switch (action.type) {
    case PanoramaActionTypes.AddPanorama: {
      return adapter.addOne(action.payload.panorama, state);
    }

    case PanoramaActionTypes.UpsertPanorama: {
      return adapter.upsertOne(action.payload.panorama, state);
    }

    case PanoramaActionTypes.AddPanoramas: {
      return adapter.addMany(action.payload.panoramas, state);
    }

    case PanoramaActionTypes.UpsertPanoramas: {
      return adapter.upsertMany(action.payload.panoramas, state);
    }

    case PanoramaActionTypes.UpdatePanorama: {
      return adapter.updateOne(action.payload.panorama, state);
    }

    case PanoramaActionTypes.UpdatePanoramas: {
      return adapter.updateMany(action.payload.panoramas, state);
    }

    case PanoramaActionTypes.DeletePanorama: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PanoramaActionTypes.DeletePanoramas: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PanoramaActionTypes.LoadPanoramas: {
      return adapter.addAll(action.payload.panoramas, state);
    }

    case PanoramaActionTypes.ClearPanoramas: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Panorama> => state.root.panoramas;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export function selectAllByTourId(tourId: string) {
  return createSelector(
    selectAll,
    (panoramas: Panorama[]) => {
      return panoramas.filter(panoarama => panoarama.tourId === tourId);
    });
}

export function selectPanoramasOfSelectedTour(side: Side) {
  return createSelector(
    selectAll,
    fromRuntimeTour.selectSelectedRuntimeTour(side),
    (panoramas: Panorama[], runtimeTour: RuntimeTour) => {
      if (runtimeTour) {
        const tourId = runtimeTour.id;
        return panoramas.filter(panorama => panorama.tourId === tourId);
      } else {
        return [];
      }
    });
}

export const selectSelectedPanoramaOfSelectedTour = (side: Side) => createSelector(
  selectEntities,
  fromRuntimeTour.selectSelectedRuntimeTour(side),
  (panoramaDictionary: Dictionary<Panorama>, runtimeTour: RuntimeTour) => {
    if (runtimeTour) {
      const panoramaId = runtimeTour.panoramaIds[side];
      return panoramaId ? panoramaDictionary[panoramaId] : null;
    } else {
      return null;
    }
  }
);
