export enum Side {
  Left = 0,
  Right = 1,
}

export namespace SideHelper {
  export function other(side: Side): Side {
    return side === Side.Left ? Side.Right : Side.Left;
  }
}

export interface Global {
  selectedTourIds: [string, string];
  size: number;
  treeMapMode: boolean;
}

