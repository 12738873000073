export class User {
  roleId = Role.NOTHING;
  id: number;
}

export namespace UserHelper {
  export function isLoggedIn(user: User) {
    return user.roleId !== Role.NOTHING;
  }

  export function isLoggedOut(user: User) {
    return !isLoggedIn(user);
  }

  export function isUser(user: User) {
    return user.roleId === Role.USER;
  }

  export function isEditor(user: User) {
    return user.roleId === Role.EDITOR;
  }

  export function isAdmin(user: User) {
    return user.roleId === Role.ADMIN;
  }

  export function isDeveloper(user: User) {
    return user.roleId === Role.DEVELEOPER;
  }

  export function isBackendUser(user: User) {
    return isEditor(user) || isAdmin(user) || isDeveloper(user);
  }


}

enum Role {
  NOTHING = 0,
  ADMIN = 1,
  EDITOR = 2,
  USER = 4,
  DEVELEOPER = 6,
}
