import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {CancelTreeEdit, ClearSelectedTree} from '../../store/runtime-tour/runtime-tour.actions';
import {Observable} from 'rxjs';
import {Tree} from '../../store/tree/tree.model';
import {selectSelectedTreeOfSelectedTour} from '../../store/tree/tree.reducer';
import {Side} from '../../store/global/global.model';
import {TranslateService} from '@ngx-translate/core';
import {TreePropertyHelper} from '../../store/tree-property/tree-property.model';
import {selectUser} from '../../store/user/user.reducer';
import {User, UserHelper} from '../../store/user/user.model';
import 'rxjs-compat/add/observable/of';
import {selectTreeMapMode} from '../../store/global/global.reducer';
import {TreeType} from '../../store/tree-type/tree-type.model';

@Component({
  selector: 'app-tree-panel',
  templateUrl: './tree-panel.component.html',
  styleUrls: ['./tree-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreePanelComponent implements OnInit {
  public TreePropertyHelper = TreePropertyHelper;
  public UserHelper = UserHelper;

  public selectedTree$: Observable<Tree>;
  public user$ = Observable.of(new User());
  public user: User;

  public selectedTree: Tree;
  public treeMapMode = false;

  public list = [];

  constructor(public store: Store<AppState>, public translate: TranslateService) {
  }

  ngOnInit() {
    this.store.select(selectTreeMapMode).subscribe((treeMapMode) => {
      this.treeMapMode = treeMapMode;
    });
    this.selectedTree$ = this.store.select(selectSelectedTreeOfSelectedTour(Side.Left));
    this.user$ = this.store.select(selectUser);
    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
    });

    this.selectedTree$.subscribe(this.updateTree.bind(this));
  }

  public updateTree(selectedTree: Tree): void {
    this.selectedTree = selectedTree;

    this.list = [];

    if (selectedTree.observation != null) {
      const e1 = [];
      e1.push('treeProperty.field.observation');
      e1.push(selectedTree.observation);
      this.list.push(e1);
    }

    if (selectedTree.ecology != null) {
      const e1 = [];
      e1.push('treeProperty.field.ecology');
      e1.push(selectedTree.ecology);
      this.list.push(e1);
    }

    if (selectedTree.volume != null) {
      const e1 = [];
      e1.push('treeProperty.field.volume');
      e1.push(selectedTree.volume);
      this.list.push(e1);
    }

    if (selectedTree.quality != null) {
      const e1 = [];
      e1.push('treeProperty.field.quality');
      e1.push(selectedTree.quality);
      this.list.push(e1);
    }

    if (selectedTree.ecologyNote != null) {
      const e1 = [];
      e1.push('treeProperty.field.ecologyNote');
      e1.push(selectedTree.ecologyNote);
      this.list.push(e1);
    }

  }

  getImage() {
    let tt: TreeType = null;
    this.TreePropertyHelper.getType(this.selectedTree, this.store).subscribe( type => {
      tt = type;
    });
    if (tt != null && tt.image != null && tt.image != '') {
      return 'http://martelage.sylvotheque.ch/assets/images/treetypes/' + tt.image;
    } else {
      return '';
    }
  }

  onCancel() {
    this.store.dispatch(new CancelTreeEdit());
  }

  onConfirm() {
    this.store.dispatch(new ClearSelectedTree());
  }
}
