import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-resizable-tree-map',
  templateUrl: './resizable-tree-map.component.html',
  styleUrls: ['./resizable-tree-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableTreeMapComponent implements OnInit {
  public size = 0.65;
  private maxSize = 1.6;
  private minSize = 0.65;

  constructor() {
  }

  ngOnInit() {
  }

  decreaseMapSize() {
    this.size = Math.max(this.size - 0.25, this.minSize);

  }

  increaseMapSize() {
    this.size = Math.min(this.size + 0.25, this.maxSize);

  }

  increaseMapSizeToMax() {
    this.size = this.maxSize;

  }

  decreaseMapSizeToMin() {
    this.size = this.minSize;

  }
}
