export class Theme {
  id: number;
  nameFr: string;
  nameDe: string;
  nameEn: string;
  descFr: string;
  descDe: string;
  descEn: string;
  url: string;
  color: string;
}
