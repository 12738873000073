import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TourInventoryType } from './tour-inventory-type.model';

export enum TourInventoryTypeActionTypes {
  LoadTourInventoryTypes = '[TourInventoryType] Load TourInventoryTypes',
  AddTourInventoryType = '[TourInventoryType] Add TourInventoryType',
  UpsertTourInventoryType = '[TourInventoryType] Upsert TourInventoryType',
  AddTourInventoryTypes = '[TourInventoryType] Add TourInventoryTypes',
  UpsertTourInventoryTypes = '[TourInventoryType] Upsert TourInventoryTypes',
  UpdateTourInventoryType = '[TourInventoryType] Update TourInventoryType',
  UpdateTourInventoryTypes = '[TourInventoryType] Update TourInventoryTypes',
  DeleteTourInventoryType = '[TourInventoryType] Delete TourInventoryType',
  DeleteTourInventoryTypes = '[TourInventoryType] Delete TourInventoryTypes',
  ClearTourInventoryTypes = '[TourInventoryType] Clear TourInventoryTypes'
}

export class LoadTourInventoryTypes implements Action {
  readonly type = TourInventoryTypeActionTypes.LoadTourInventoryTypes;

  constructor(public payload: { tourInventoryTypes: TourInventoryType[] }) {}
}

export class AddTourInventoryType implements Action {
  readonly type = TourInventoryTypeActionTypes.AddTourInventoryType;

  constructor(public payload: { tourInventoryType: TourInventoryType }) {}
}

export class UpsertTourInventoryType implements Action {
  readonly type = TourInventoryTypeActionTypes.UpsertTourInventoryType;

  constructor(public payload: { tourInventoryType: TourInventoryType }) {}
}

export class AddTourInventoryTypes implements Action {
  readonly type = TourInventoryTypeActionTypes.AddTourInventoryTypes;

  constructor(public payload: { tourInventoryTypes: TourInventoryType[] }) {}
}

export class UpsertTourInventoryTypes implements Action {
  readonly type = TourInventoryTypeActionTypes.UpsertTourInventoryTypes;

  constructor(public payload: { tourInventoryTypes: TourInventoryType[] }) {}
}

export class UpdateTourInventoryType implements Action {
  readonly type = TourInventoryTypeActionTypes.UpdateTourInventoryType;

  constructor(public payload: { tourInventoryType: Update<TourInventoryType> }) {}
}

export class UpdateTourInventoryTypes implements Action {
  readonly type = TourInventoryTypeActionTypes.UpdateTourInventoryTypes;

  constructor(public payload: { tourInventoryTypes: Update<TourInventoryType>[] }) {}
}

export class DeleteTourInventoryType implements Action {
  readonly type = TourInventoryTypeActionTypes.DeleteTourInventoryType;

  constructor(public payload: { id: string }) {}
}

export class DeleteTourInventoryTypes implements Action {
  readonly type = TourInventoryTypeActionTypes.DeleteTourInventoryTypes;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTourInventoryTypes implements Action {
  readonly type = TourInventoryTypeActionTypes.ClearTourInventoryTypes;
}

export type TourInventoryTypeActions =
 LoadTourInventoryTypes
 | AddTourInventoryType
 | UpsertTourInventoryType
 | AddTourInventoryTypes
 | UpsertTourInventoryTypes
 | UpdateTourInventoryType
 | UpdateTourInventoryTypes
 | DeleteTourInventoryType
 | DeleteTourInventoryTypes
 | ClearTourInventoryTypes;
