import {Actions, Effect, ofType} from '@ngrx/effects';
import {LanguageActions, LanguageActionTypes} from './language.actions';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BackendService} from '../../services/backend.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class LanguageEffects {
  constructor(private actions$: Actions, private backendService: BackendService, private translate: TranslateService) {
  }

  @Effect({dispatch: false})
  setFromBackend = this.actions$.pipe(
    ofType(LanguageActionTypes.SET_FROM_BACKEND),
    map((action: LanguageActions) => {
      this.translate.use(action.payload);
    }),
  );

  @Effect({dispatch: false})
  setFromUser = this.actions$.pipe(
    ofType(LanguageActionTypes.SET_FROM_USER),
    map((action: LanguageActions) => {
      if (action.payload != null) {
        this.translate.use(action.payload);
        this.backendService.setLanguage(action.payload);
      }
    }),
  );
}
