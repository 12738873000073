import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {MediaType} from './media-type.model';
import {MediaTypeActions, MediaTypeActionTypes} from './media-type.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<MediaType> {
  // additional entities state properties
}

export const adapter: EntityAdapter<MediaType> = createEntityAdapter<MediaType>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: MediaTypeActions
): State {
  switch (action.type) {
    case MediaTypeActionTypes.AddMediaType: {
      return adapter.addOne(action.payload.mediaType, state);
    }

    case MediaTypeActionTypes.UpsertMediaType: {
      return adapter.upsertOne(action.payload.mediaType, state);
    }

    case MediaTypeActionTypes.AddMediaTypes: {
      return adapter.addMany(action.payload.mediaTypes, state);
    }

    case MediaTypeActionTypes.UpsertMediaTypes: {
      return adapter.upsertMany(action.payload.mediaTypes, state);
    }

    case MediaTypeActionTypes.UpdateMediaType: {
      return adapter.updateOne(action.payload.mediaType, state);
    }

    case MediaTypeActionTypes.UpdateMediaTypes: {
      return adapter.updateMany(action.payload.mediaTypes, state);
    }

    case MediaTypeActionTypes.DeleteMediaType: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MediaTypeActionTypes.DeleteMediaTypes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MediaTypeActionTypes.LoadMediaTypes: {
      return adapter.addAll(action.payload.mediaTypes, state);
    }

    case MediaTypeActionTypes.ClearMediaTypes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<MediaType> => state.root.mediaTypes;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectMediaTypes = createSelector(selectAll, entities => entities);
