import {createSelector} from '@ngrx/store';
import {ContentCard, ContentType, LearningMode} from './content-card.model';
import {ContentCardActions, ContentCardActionTypes} from './content-card.actions';

export const initialState: ContentCard = {
  learningMode: LearningMode.Discovery,
  contentType: ContentType.ModeInfo
};

export function reducer(state = initialState, action: ContentCardActions): ContentCard {
  switch (action.type) {

    case ContentCardActionTypes.ModeInfo: {
      return {
        ...state,
        contentType: ContentType.ModeInfo
      };
    }

    case ContentCardActionTypes.Grid: {
      return {
        ...state,
        contentType: ContentType.Grid
      };
    }

    case ContentCardActionTypes.MultimediaContent: {
      return {
        ...state,
        contentType: ContentType.MultimediaContent
      };
    }

    case ContentCardActionTypes.InfoHabitat: {
      return {
        ...state,
        contentType: ContentType.HabitatInfo
      };
    }

    case ContentCardActionTypes.SwitchLearningMode: {
      return {
        ...state,
        // dont use the === comparator : just let == or it will not work
        contentType: (action.payload.learningMode == LearningMode.TreeMarking) || (action.payload.learningMode == LearningMode.Editor) ? ContentType.MultimediaContent : ContentType.Grid,
        learningMode: action.payload.learningMode
      };
    }

    default:
      return state;
  }
}

export const selectFeature = (state): ContentCard => state.root.contentCard;

export const selectLearningMode = createSelector(
    selectFeature,
    (state: ContentCard) => state.learningMode
);

export const selectContentType = createSelector(
    selectFeature,
    (state: ContentCard) => state.contentType
);

