import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import {Tour} from './tour.model';
import {TourActions, TourActionTypes} from './tour.actions';
import {createSelector} from '@ngrx/store';
import {Global, Side} from '../global/global.model';
import * as fromGlobal from '../global/global.reducer';

export interface State extends EntityState<Tour> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Tour> = createEntityAdapter<Tour>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TourActions
): State {
  switch (action.type) {
    case TourActionTypes.AddTour: {
      return adapter.addOne(action.payload.tour, state);
    }

    case TourActionTypes.UpsertTour: {
      return adapter.upsertOne(action.payload.tour, state);
    }

    case TourActionTypes.AddTours: {
      return adapter.addMany(action.payload.tours, state);
    }

    case TourActionTypes.UpsertTours: {
      return adapter.upsertMany(action.payload.tours, state);
    }

    case TourActionTypes.UpdateTour: {
      return adapter.updateOne(action.payload.tour, state);
    }

    case TourActionTypes.UpdateTours: {
      return adapter.updateMany(action.payload.tours, state);
    }

    case TourActionTypes.DeleteTour: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TourActionTypes.DeleteTours: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TourActionTypes.LoadTours: {
      return adapter.addAll(action.payload.tours, state);
    }

    case TourActionTypes.ClearTours: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Tour> => state.root.tours;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export function selectSelectedTour(side: Side) {
  return createSelector(
    selectEntities,
    fromGlobal.selectFeature,
    (tourDictionary: Dictionary<Tour>, global: Global) => {
      const tourId = global.selectedTourIds[side];
      return tourId ? tourDictionary[tourId] : null;
    }
  );
}
