import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {TreeStemType} from './tree-stem-type.model';

export enum TreeStemTypeActionType {
  LoadTreeStemTypes = '[TreeStemType] Load TreeStemTypes',
  AddTreeStemType = '[TreeStemType] Add TreeStemType',
  UpsertTreeStemType = '[TreeStemType] Upsert TreeStemType',
  AddTreeStemTypes = '[TreeStemType] Add TreeStemTypes',
  UpsertTreeStemTypes = '[TreeStemType] Upsert TreeStemTypes',
  UpdateTreeStemType = '[TreeStemType] Update TreeStemType',
  UpdateTreeStemTypes = '[TreeStemType] Update TreeStemTypes',
  DeleteTreeStemType = '[TreeStemType] Delete TreeStemType',
  DeleteTreeStemTypes = '[TreeStemType] Delete TreeStemTypes',
  ClearTreeStemTypes = '[TreeStemType] Clear TreeStemTypes'
}

export class LoadTreeStemTypes implements Action {
  readonly type = TreeStemTypeActionType.LoadTreeStemTypes;

  constructor(public payload: { treeStemTypes: TreeStemType[] }) {
  }
}

export class AddTreeStemType implements Action {
  readonly type = TreeStemTypeActionType.AddTreeStemType;

  constructor(public payload: { treeStemType: TreeStemType }) {
  }
}

export class UpsertTreeStemType implements Action {
  readonly type = TreeStemTypeActionType.UpsertTreeStemType;

  constructor(public payload: { treeStemType: TreeStemType }) {
  }
}

export class AddTreeStemTypes implements Action {
  readonly type = TreeStemTypeActionType.AddTreeStemTypes;

  constructor(public payload: { treeStemTypes: TreeStemType[] }) {
  }
}

export class UpsertTreeStemTypes implements Action {
  readonly type = TreeStemTypeActionType.UpsertTreeStemTypes;

  constructor(public payload: { treeStemTypes: TreeStemType[] }) {
  }
}

export class UpdateTreeStemType implements Action {
  readonly type = TreeStemTypeActionType.UpdateTreeStemType;

  constructor(public payload: { treeStemType: Update<TreeStemType> }) {
  }
}

export class UpdateTreeStemTypes implements Action {
  readonly type = TreeStemTypeActionType.UpdateTreeStemTypes;

  constructor(public payload: { treeStemTypes: Update<TreeStemType>[] }) {
  }
}

export class DeleteTreeStemType implements Action {
  readonly type = TreeStemTypeActionType.DeleteTreeStemType;

  constructor(public payload: { id: string }) {
  }
}

export class DeleteTreeStemTypes implements Action {
  readonly type = TreeStemTypeActionType.DeleteTreeStemTypes;

  constructor(public payload: { ids: string[] }) {
  }
}

export class ClearTreeStemTypes implements Action {
  readonly type = TreeStemTypeActionType.ClearTreeStemTypes;
}

export type TreeStemTypeActions =
  LoadTreeStemTypes
  | AddTreeStemType
  | UpsertTreeStemType
  | AddTreeStemTypes
  | UpsertTreeStemTypes
  | UpdateTreeStemType
  | UpdateTreeStemTypes
  | DeleteTreeStemType
  | DeleteTreeStemTypes
  | ClearTreeStemTypes;
