import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { SubTheme } from './sub-theme.model';

export enum SubThemeActionTypes {
  LoadSubThemes = '[SubTheme] Load SubThemes',
  AddSubTheme = '[SubTheme] Add SubTheme',
  UpsertSubTheme = '[SubTheme] Upsert SubTheme',
  AddSubThemes = '[SubTheme] Add SubThemes',
  UpsertSubThemes = '[SubTheme] Upsert SubThemes',
  UpdateSubTheme = '[SubTheme] Update SubTheme',
  UpdateSubThemes = '[SubTheme] Update SubThemes',
  DeleteSubTheme = '[SubTheme] Delete SubTheme',
  DeleteSubThemes = '[SubTheme] Delete SubThemes',
  ClearSubThemes = '[SubTheme] Clear SubThemes'
}

export class LoadSubThemes implements Action {
  readonly type = SubThemeActionTypes.LoadSubThemes;

  constructor(public payload: { subThemes: SubTheme[] }) {}
}

export class AddSubTheme implements Action {
  readonly type = SubThemeActionTypes.AddSubTheme;

  constructor(public payload: { subTheme: SubTheme }) {}
}

export class UpsertSubTheme implements Action {
  readonly type = SubThemeActionTypes.UpsertSubTheme;

  constructor(public payload: { subTheme: SubTheme }) {}
}

export class AddSubThemes implements Action {
  readonly type = SubThemeActionTypes.AddSubThemes;

  constructor(public payload: { subThemes: SubTheme[] }) {}
}

export class UpsertSubThemes implements Action {
  readonly type = SubThemeActionTypes.UpsertSubThemes;

  constructor(public payload: { subThemes: SubTheme[] }) {}
}

export class UpdateSubTheme implements Action {
  readonly type = SubThemeActionTypes.UpdateSubTheme;

  constructor(public payload: { subTheme: Update<SubTheme> }) {}
}

export class UpdateSubThemes implements Action {
  readonly type = SubThemeActionTypes.UpdateSubThemes;

  constructor(public payload: { subThemes: Update<SubTheme>[] }) {}
}

export class DeleteSubTheme implements Action {
  readonly type = SubThemeActionTypes.DeleteSubTheme;

  constructor(public payload: { id: string }) {}
}

export class DeleteSubThemes implements Action {
  readonly type = SubThemeActionTypes.DeleteSubThemes;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearSubThemes implements Action {
  readonly type = SubThemeActionTypes.ClearSubThemes;
}

export type SubThemeActions =
 LoadSubThemes
 | AddSubTheme
 | UpsertSubTheme
 | AddSubThemes
 | UpsertSubThemes
 | UpdateSubTheme
 | UpdateSubThemes
 | DeleteSubTheme
 | DeleteSubThemes
 | ClearSubThemes;
