import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-frequency-meter',
  templateUrl: './frequency-meter.component.html',
  styleUrls: ['./frequency-meter.component.css']
})
export class FrequencyMeterComponent implements OnInit {

  @Input() natural: number;
  @Input() operated: number;

  constructor() { }

  ngOnInit() {
  }

  getOperated() {
    let res = '';

    if (this.operated === 1) {
      res = 'veryRare';
    } else if (this.operated === 3) {
      res = 'rare';
    } else if (this.operated === 7) {
      res = 'frequent';
    } else if (this.operated === 9) {
      res = 'veryFrequent';
    } else {
      res = 'novalue';
    }

    return res;
  }

  getNatural() {
    let res = '';

    if (this.natural === 1) {
      res = 'veryRare';
    } else if (this.natural === 3) {
      res = 'rare';
    } else if (this.natural === 7) {
      res = 'frequent';
    } else if (this.natural === 9) {
      res = 'veryFrequent';
    } else {
      res = 'novalue';
    }

    return res;
  }

}
