import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Question} from './question.model';
import {QuestionActions, QuestionActionTypes} from './question.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<Question> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Question> = createEntityAdapter<Question>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: QuestionActions
): State {
  switch (action.type) {
    case QuestionActionTypes.AddQuestion: {
      return adapter.addOne(action.payload.question, state);
    }

    case QuestionActionTypes.UpsertQuestion: {
      return adapter.upsertOne(action.payload.question, state);
    }

    case QuestionActionTypes.AddQuestions: {
      return adapter.addMany(action.payload.questions, state);
    }

    case QuestionActionTypes.UpsertQuestions: {
      return adapter.upsertMany(action.payload.questions, state);
    }

    case QuestionActionTypes.UpdateQuestion: {
      return adapter.updateOne(action.payload.question, state);
    }

    case QuestionActionTypes.UpdateQuestions: {
      return adapter.updateMany(action.payload.questions, state);
    }

    case QuestionActionTypes.DeleteQuestion: {
      return adapter.removeOne(action.payload.id, state);
    }

    case QuestionActionTypes.DeleteQuestions: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case QuestionActionTypes.LoadQuestions: {
      return adapter.addAll(action.payload.questions, state);
    }

    case QuestionActionTypes.ClearQuestions: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Question> => state.root.questions;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectQuestions = createSelector(selectAll, entities => entities);
