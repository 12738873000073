export class Spot {
    id: string;
    panoramaId: string;
    contentId: number;
    treeNumber: number;
    altitude: number;
    azimuth: number;
    distance: number;
    subThemeId: number;
    altitudeTmp: number;
    azimuthTmp: number;
}
