import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TreeSocialPosition } from './tree-social-position.model';

export enum TreeSocialPositionActionTypes {
  LoadTreeSocialPositions = '[TreeSocialPosition] Load TreeSocialPositions',
  AddTreeSocialPosition = '[TreeSocialPosition] Add TreeSocialPosition',
  UpsertTreeSocialPosition = '[TreeSocialPosition] Upsert TreeSocialPosition',
  AddTreeSocialPositions = '[TreeSocialPosition] Add TreeSocialPositions',
  UpsertTreeSocialPositions = '[TreeSocialPosition] Upsert TreeSocialPositions',
  UpdateTreeSocialPosition = '[TreeSocialPosition] Update TreeSocialPosition',
  UpdateTreeSocialPositions = '[TreeSocialPosition] Update TreeSocialPositions',
  DeleteTreeSocialPosition = '[TreeSocialPosition] Delete TreeSocialPosition',
  DeleteTreeSocialPositions = '[TreeSocialPosition] Delete TreeSocialPositions',
  ClearTreeSocialPositions = '[TreeSocialPosition] Clear TreeSocialPositions'
}

export class LoadTreeSocialPositions implements Action {
  readonly type = TreeSocialPositionActionTypes.LoadTreeSocialPositions;

  constructor(public payload: { treeSocialPositions: TreeSocialPosition[] }) {}
}

export class AddTreeSocialPosition implements Action {
  readonly type = TreeSocialPositionActionTypes.AddTreeSocialPosition;

  constructor(public payload: { treeSocialPosition: TreeSocialPosition }) {}
}

export class UpsertTreeSocialPosition implements Action {
  readonly type = TreeSocialPositionActionTypes.UpsertTreeSocialPosition;

  constructor(public payload: { treeSocialPosition: TreeSocialPosition }) {}
}

export class AddTreeSocialPositions implements Action {
  readonly type = TreeSocialPositionActionTypes.AddTreeSocialPositions;

  constructor(public payload: { treeSocialPositions: TreeSocialPosition[] }) {}
}

export class UpsertTreeSocialPositions implements Action {
  readonly type = TreeSocialPositionActionTypes.UpsertTreeSocialPositions;

  constructor(public payload: { treeSocialPositions: TreeSocialPosition[] }) {}
}

export class UpdateTreeSocialPosition implements Action {
  readonly type = TreeSocialPositionActionTypes.UpdateTreeSocialPosition;

  constructor(public payload: { treeSocialPosition: Update<TreeSocialPosition> }) {}
}

export class UpdateTreeSocialPositions implements Action {
  readonly type = TreeSocialPositionActionTypes.UpdateTreeSocialPositions;

  constructor(public payload: { treeSocialPositions: Update<TreeSocialPosition>[] }) {}
}

export class DeleteTreeSocialPosition implements Action {
  readonly type = TreeSocialPositionActionTypes.DeleteTreeSocialPosition;

  constructor(public payload: { id: string }) {}
}

export class DeleteTreeSocialPositions implements Action {
  readonly type = TreeSocialPositionActionTypes.DeleteTreeSocialPositions;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTreeSocialPositions implements Action {
  readonly type = TreeSocialPositionActionTypes.ClearTreeSocialPositions;
}

export type TreeSocialPositionActions =
 LoadTreeSocialPositions
 | AddTreeSocialPosition
 | UpsertTreeSocialPosition
 | AddTreeSocialPositions
 | UpsertTreeSocialPositions
 | UpdateTreeSocialPosition
 | UpdateTreeSocialPositions
 | DeleteTreeSocialPosition
 | DeleteTreeSocialPositions
 | ClearTreeSocialPositions;
