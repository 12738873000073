import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TreeCorrection } from './tree-correction.model';

export enum TreeCorrectionActionTypes {
  LoadTreeCorrections = '[TreeCorrection] Load TreeCorrections',
  AddTreeCorrection = '[TreeCorrection] Add TreeCorrection',
  UpsertTreeCorrection = '[TreeCorrection] Upsert TreeCorrection',
  AddTreeCorrections = '[TreeCorrection] Add TreeCorrections',
  UpsertTreeCorrections = '[TreeCorrection] Upsert TreeCorrections',
  UpdateTreeCorrection = '[TreeCorrection] Update TreeCorrection',
  UpdateTreeCorrections = '[TreeCorrection] Update TreeCorrections',
  DeleteTreeCorrection = '[TreeCorrection] Delete TreeCorrection',
  DeleteTreeCorrections = '[TreeCorrection] Delete TreeCorrections',
  ClearTreeCorrections = '[TreeCorrection] Clear TreeCorrections'
}

export class LoadTreeCorrections implements Action {
  readonly type = TreeCorrectionActionTypes.LoadTreeCorrections;

  constructor(public payload: { treeCorrections: TreeCorrection[] }) {}
}

export class AddTreeCorrection implements Action {
  readonly type = TreeCorrectionActionTypes.AddTreeCorrection;

  constructor(public payload: { treeCorrection: TreeCorrection }) {}
}

export class UpsertTreeCorrection implements Action {
  readonly type = TreeCorrectionActionTypes.UpsertTreeCorrection;

  constructor(public payload: { treeCorrection: TreeCorrection }) {}
}

export class AddTreeCorrections implements Action {
  readonly type = TreeCorrectionActionTypes.AddTreeCorrections;

  constructor(public payload: { treeCorrections: TreeCorrection[] }) {}
}

export class UpsertTreeCorrections implements Action {
  readonly type = TreeCorrectionActionTypes.UpsertTreeCorrections;

  constructor(public payload: { treeCorrections: TreeCorrection[] }) {}
}

export class UpdateTreeCorrection implements Action {
  readonly type = TreeCorrectionActionTypes.UpdateTreeCorrection;

  constructor(public payload: { treeCorrection: Update<TreeCorrection> }) {}
}

export class UpdateTreeCorrections implements Action {
  readonly type = TreeCorrectionActionTypes.UpdateTreeCorrections;

  constructor(public payload: { treeCorrections: Update<TreeCorrection>[] }) {}
}

export class DeleteTreeCorrection implements Action {
  readonly type = TreeCorrectionActionTypes.DeleteTreeCorrection;

  constructor(public payload: { id: string }) {}
}

export class DeleteTreeCorrections implements Action {
  readonly type = TreeCorrectionActionTypes.DeleteTreeCorrections;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTreeCorrections implements Action {
  readonly type = TreeCorrectionActionTypes.ClearTreeCorrections;
}

export type TreeCorrectionActions =
 LoadTreeCorrections
 | AddTreeCorrection
 | UpsertTreeCorrection
 | AddTreeCorrections
 | UpsertTreeCorrections
 | UpdateTreeCorrection
 | UpdateTreeCorrections
 | DeleteTreeCorrection
 | DeleteTreeCorrections
 | ClearTreeCorrections;
