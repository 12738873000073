import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreeCorrection} from './tree-correction.model';
import {TreeCorrectionActions, TreeCorrectionActionTypes} from './tree-correction.actions';
import {Side} from '../global/global.model';
import {createSelector} from '@ngrx/store';
import {selectSelectedRuntimeTour} from '../runtime-tour/runtime-tour.reducer';
import {Spot} from '../spot/spot.model';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';

export interface State extends EntityState<TreeCorrection> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TreeCorrection> = createEntityAdapter<TreeCorrection>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeCorrectionActions
): State {
  switch (action.type) {
    case TreeCorrectionActionTypes.AddTreeCorrection: {
      return adapter.addOne(action.payload.treeCorrection, state);
    }

    case TreeCorrectionActionTypes.UpsertTreeCorrection: {
      return adapter.upsertOne(action.payload.treeCorrection, state);
    }

    case TreeCorrectionActionTypes.AddTreeCorrections: {
      return adapter.addMany(action.payload.treeCorrections, state);
    }

    case TreeCorrectionActionTypes.UpsertTreeCorrections: {
      return adapter.upsertMany(action.payload.treeCorrections, state);
    }

    case TreeCorrectionActionTypes.UpdateTreeCorrection: {
      return adapter.updateOne(action.payload.treeCorrection, state);
    }

    case TreeCorrectionActionTypes.UpdateTreeCorrections: {
      return adapter.updateMany(action.payload.treeCorrections, state);
    }

    case TreeCorrectionActionTypes.DeleteTreeCorrection: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeCorrectionActionTypes.DeleteTreeCorrections: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeCorrectionActionTypes.LoadTreeCorrections: {
      return adapter.addAll(action.payload.treeCorrections, state);
    }

    case TreeCorrectionActionTypes.ClearTreeCorrections: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TreeCorrection> => state.root.treeCorrections;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export function selectTreeCorrectionsFromPanorama(side: Side) {
  return createSelector(
      selectAll,
      selectSelectedRuntimeTour(side),
      (treeCorrections: TreeCorrection[], runtimeTour: RuntimeTour) => {
        if (runtimeTour) {
          return treeCorrections.filter(x => x.camera_id === runtimeTour.panoramaIds[0]);
        } else {
          return [];
        }
      });
}
