import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, switchMap, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BackendService} from '../../services/backend.service';
import {TreeActionTypes} from './tree.actions';
import {Side} from '../global/global.model';
import {RuntimeTourActionTypes} from '../runtime-tour/runtime-tour.actions';
import {selectSelectedTreeOfSelectedTour} from './tree.reducer';
import {AppState} from '../app.reducer';
import {Store} from '@ngrx/store';
import {Tree} from './tree.model';
import {selectSelectedRuntimeTour} from '../runtime-tour/runtime-tour.reducer';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';

@Injectable()
export class TreeEffects {
  constructor(private actions$: Actions, private backendService: BackendService, private store: Store<AppState>) {
  }

  @Effect({dispatch: false})
  setState = this.actions$.pipe(
    ofType(
      TreeActionTypes.SetTreeState,
      TreeActionTypes.ToggleTreeReason,
    ),
    switchMap(() =>
      this.store.select(selectSelectedTreeOfSelectedTour(Side.Left)).pipe(take(1))
    ),
    map((tree: Tree) => {
      this.backendService.setTreeState(tree);
    }),
  );

  @Effect({dispatch: false})
  cancelState = this.actions$.pipe(
    ofType(RuntimeTourActionTypes.CancelTreeEdit,),
    switchMap(() =>
      this.store.select(selectSelectedRuntimeTour(Side.Left)).pipe(take(1))
    ),
    map((runtimeTour: RuntimeTour) => {
      this.backendService.setTreeState(runtimeTour.undoTree);
    }),
  );

}
