import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PanoramaTree} from '../../store/panorama-tree/panorama-tree.model';
import {Tree} from '../../store/tree/tree.model';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {selectSelectedTreeOfSelectedTour} from '../../store/tree/tree.reducer';
import {Side} from '../../store/global/global.model';
import {selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour} from '../../store/panorama-tree/panorama-tree.reducer';

@Component({
  selector: 'app-tree-tab',
  templateUrl: './tree-tab.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeTabComponent implements OnInit {
  private selectedPanoramaTree$: Observable<PanoramaTree>;
  private selectedTree$: Observable<Tree>;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.selectedPanoramaTree$ = this.store.select(selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour);
    this.selectedTree$ = this.store.select(selectSelectedTreeOfSelectedTour(Side.Left));
  }
}
