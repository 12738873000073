import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {BackendService} from '../../../services/backend.service';
import {Content} from '../../../store/catalog/content/content.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {Media} from '../../../store/catalog/media/media.model';
import {Question} from '../../../store/catalog/question/question.model';
import {Response} from '../../../store/catalog/response/response.model';
import {selectMedias} from '../../../store/catalog/media/media.reducer';
import {selectQuestions} from '../../../store/catalog/question/question.reducer';
import {selectResponses} from '../../../store/catalog/response/response.reducer';
import {MatDialog} from '@angular/material';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';
import {translation} from '../../../app.translation';
import {TranslateService} from '@ngx-translate/core';


export interface TreeNode {
  name: string;
  children?: TreeNode[];
  subTheme: SubTheme;
}

@Component({
  selector: 'app-sub-theme',
  templateUrl: './sub-theme.component.html',
  styleUrls: ['./sub-theme.component.css']
})
export class SubThemeComponent implements OnInit {

  @Input() theme;
  @Input() subTheme;
  @Input() subThemes: SubTheme[] = [];
  @Input() selectedTheme: SubTheme;
  @Input() stepper;
  @Input() contents: Content[];
  @Input() tree: TreeNode[] = [];
  medias: Media[] = [];
  questions: Question[] = [];
  responses: Response[] = [];

  firstFormGroup: FormGroup;

  descriptionContent: Content = new Content();
  goodToKnowContent: Content = new Content();
  associatedSpeciesContent: Content = new Content();
  inventoryLevelContent: Content = new Content();
  otherInfoContent: Content = new Content();

  constructor(private store: Store<AppState>, private backendService: BackendService, public dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit() {
    this.store.select(selectMedias).subscribe(medias => {
      this.medias = medias;
    });
    this.store.select(selectQuestions).subscribe(questions => {
      this.questions = questions;
    });
    this.store.select(selectResponses).subscribe(responses => {
      this.responses = responses;
    });
    this.findContent();
  }

  getSubThemes() {
    return this.subThemes.filter(x => x.superThemeId === this.subTheme.id);
  }

  addSelectedSubTheme(subTheme: SubTheme) {
    this.selectedTheme = subTheme;
  }

  createSubTheme() {
    // @ts-ignore
    const subTheme: SubTheme = {};
    subTheme.nameEn = 'new';
    subTheme.nameDe = 'neu';
    subTheme.nameFr = 'nouveau';
    subTheme.themeId = this.theme.id;
    subTheme.level = +this.subTheme.level + 1;
    subTheme.superThemeId = this.subTheme.id;

    const newContent: Content = new Content();
    newContent.textFr = '';
    newContent.textDe = '';
    newContent.textEn = '';
    this.backendService.createContent(newContent).subscribe( ress => {
      if (ress > 0) {
        subTheme.descriptionContentId = ress;
        newContent.id = ress;
        this.contents.push(newContent);
        const newContentt: Content = new Content();
        newContentt.textFr = '';
        newContentt.textDe = '';
        newContentt.textEn = '';
        this.backendService.createContent(newContentt).subscribe( resss => {
          if (resss > 0) {

            subTheme.goodToKnowContentId = resss;
            newContentt.id = resss;
            this.contents.push(newContentt);
            const newContenttt: Content = new Content();
            newContenttt.textFr = '';
            newContenttt.textDe = '';
            newContenttt.textEn = '';
            this.backendService.createContent(newContenttt).subscribe( result => {
              if (result > 0) {

                subTheme.associatedSpeciesContentId = result;
                newContenttt.id = result;
                this.contents.push(newContenttt);
                const newContentttt: Content = new Content();
                newContentttt.textFr = '';
                newContentttt.textDe = '';
                newContentttt.textEn = '';
                this.backendService.createContent(newContentttt).subscribe( resultt => {
                  if (resultt > 0) {

                    subTheme.inventoryLevelContentId = resultt;
                    newContentttt.id = resultt;
                    this.contents.push(newContentttt);
                    const newContenttttt: Content = new Content();
                    newContenttttt.textFr = '';
                    newContenttttt.textDe = '';
                    newContenttttt.textEn = '';
                    this.backendService.createContent(newContenttttt).subscribe( resulttt => {
                      if (resulttt > 0) {

                        subTheme.otherInfoContentId = resulttt;
                        newContenttttt.id = resulttt;
                        this.contents.push(newContenttttt);
                        this.backendService.createSubTheme(subTheme).subscribe( res => {
                          subTheme.id = res;
                          if (res > 0) {
                            this.subThemes.push(subTheme);
                            this.getTreeData();
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  getTreeData() {
    this.tree = [];
    this.subThemes.filter(x => (x.themeId == this.theme.id && x.level == 0)).forEach( (subTheme) => {
      const list = [];
      this.subThemes.filter(x => x.level == 1 && x.superThemeId == subTheme.id).forEach((subsubTheme) => {
        const subList = [];
        this.subThemes.filter(x => x.level == 2 && x.superThemeId == subsubTheme.id).forEach((type) => {
          subList.push({name: type.nameEn, subTheme: type});
        });
        list.push({name: subsubTheme.nameEn, subTheme: subsubTheme, children: subList});
      });
      this.tree.push({name: subTheme.nameEn, subTheme: subTheme, children: list});
    });
    return this.tree;
  }

  findContent() {
    this.descriptionContent = this.contents.find(x => x.id === this.subTheme.descriptionContentId);
    this.goodToKnowContent = this.contents.find(x => x.id === this.subTheme.goodToKnowContentId);
    this.associatedSpeciesContent = this.contents.find(x => x.id === this.subTheme.associatedSpeciesContentId);
    this.inventoryLevelContent = this.contents.find(x => x.id === this.subTheme.inventoryLevelContentId);
    this.otherInfoContent = this.contents.find(x => x.id === this.subTheme.otherInfoContentId);
  }

  getQuestions() {
    return this.questions.filter(x => x.subThemeId === this.subTheme.id);
  }

  updateSubTheme() {
    this.backendService.updateSubTheme(this.subTheme).subscribe( res => {
      this.getTreeData();
    });
  }

  createQuestion() {
    const question: Question = new Question();
    question.subThemeId = this.subTheme.id;
    const newContent: Content = new Content();
    newContent.textFr = '';
    newContent.textDe = '';
    newContent.textEn = '';
    this.backendService.createContent(newContent).subscribe( res => {
      if (res > 0) {
        newContent.id = res;
        question.contentId = newContent.id;
        this.contents.push(newContent);
        this.backendService.createQuestion(question).subscribe( result => {
          if (result > 0) {
            question.id = result;
            this.questions.push(question);
          }
        });
      }
    });

  }

  deleteSubTheme() {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {ok: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.deleteSubTheme(this.subTheme).subscribe(res => {
          this.subThemes.splice(this.subThemes.indexOf(this.subTheme), 1);
          this.selectedTheme = null;
          this.subTheme = null;
          this.getTreeData();
        });
      }
    });

  }

  translateSubTheme(object, preString: string): string {
    return translation(object, preString, this.translate);
  }

}
