export enum LearningMode {
    Discovery,
    Determination,
    Research,
    TreeMarking,
    Editor
}

export enum ContentType {
    ModeInfo,
    HabitatInfo,
    Grid,
    MultimediaContent
}

export interface ContentCard {
    contentType: ContentType;
    learningMode: LearningMode;
}
