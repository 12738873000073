import {Action} from '@ngrx/store';

export enum LanguageActionTypes {
  SET_FROM_USER = '[Language] Set From UI',
  SET_FROM_BACKEND = '[Language] Set From Backend',
}

export class SetFromUserAction implements Action {
  readonly type = LanguageActionTypes.SET_FROM_USER;

  constructor(public payload: string) {
  }
}

export class SetFromBackendAction implements Action {
  readonly type = LanguageActionTypes.SET_FROM_BACKEND;

  constructor(public payload: string) {
  }
}

export type LanguageActions =
  | SetFromUserAction
  | SetFromBackendAction;
