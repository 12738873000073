import {Action} from '@ngrx/store';

export enum AppActionTypes {
  LoadTours = '[App] Load Common',
  LoadedTours = '[App] Loaded Common',
  LoadTour = '[App] Load Tour',
  LoadedTour = '[App] Loaded Tour',
}

export class LoadApp implements Action {
  readonly type = AppActionTypes.LoadTours;
}

export class LoadedApp implements Action {
  readonly type = AppActionTypes.LoadedTours;
}

export class LoadTour implements Action {
  readonly type = AppActionTypes.LoadTour;

  constructor(public payload: { tourId: string }) {
  }
}

export class LoadedTour implements Action {
  readonly type = AppActionTypes.LoadedTour;
}

export type AppActions =
  | LoadApp
  | LoadedApp
  | LoadTour
  | LoadedTour;
