import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  isDevMode,
  IterableChangeRecord,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Side} from '../../store/global/global.model';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Orientation, PanoramaTree} from '../../store/panorama-tree/panorama-tree.model';
import {
  selectPanoramaTreesOfSelectedPanoramaOfSelectedTour,
  selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour
} from '../../store/panorama-tree/panorama-tree.reducer';
import {Panorama} from '../../store/panorama/panorama.model';
import {
  selectPanoramasOfSelectedTour,
  selectSelectedPanoramaOfSelectedTour
} from '../../store/panorama/panorama.reducer';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {Angle, AngleHelper, Azimuth, Tour, TourPositionHelper} from '../../store/tour/tour.model';
import {
  ClearSelectedSpot,
  SetSelectedPanorama,
  SetSelectedSpot,
  SetSelectedTree
} from '../../store/runtime-tour/runtime-tour.actions';
import {RealtimePanorama, SideView, View, ViewHelper} from '../../store/realtime-panorama/realtime-panorama.model';
import {SetSyncOrigin, SetView} from '../../store/realtime-panorama/realtime-panorama.actions';
import {SetSelectedPanoramaTree} from '../../store/runtime-panorama/runtime-panorama.actions';
import {Tree} from '../../store/tree/tree.model';
import {
  selectSelectedTreeOfSelectedTour,
  selectTreeOfByTreeNumber,
  selectTreesOfSelectedTour
} from '../../store/tree/tree.reducer';
import {consistentUpdates, Update, updates} from '../../helper/ngrx';
import {
  selectSelectedRealtimePanoramaOfSelectedTour,
  selectSynchronization,
  Synchronization
} from '../../store/realtime-panorama/realtime-panorama.reducer';
import {CallbackService} from '../../services/callback.service';
import {Spot} from '../../store/spot/spot.model';
import {selectSelectedSpotOfSelectedTour, selectSpotsOfSelectedTour} from '../../store/spot/spot.reducer';
import {UpdateSpot} from '../../store/spot/spot.actions';
import {ModeInfo, MultimediaContent} from '../../store/habitat/content-card.actions';
import {selectTreeCorrectionsFromPanorama} from '../../store/tree-correction/tree-correction.reducer';
import {TreeCorrection} from '../../store/tree-correction/tree-correction.model';
import {BackendService} from '../../services/backend.service';
import {selectSubThemes} from '../../store/catalog/sub-theme/sub-theme.reducer';
import {SubTheme} from '../../store/catalog/sub-theme/sub-theme.model';
import {selectLearningMode} from '../../store/habitat/content-card.reducer';
import {LearningMode} from '../../store/habitat/content-card.model';
import {selectScores} from '../../store/score/score.reducer';
import {selectAnswers} from '../../store/answer/answer.reducer';
import {Score} from '../../store/score/score.model';
import {Answer} from '../../store/answer/answer.model';
import {User, UserHelper} from '../../store/user/user.model';
import {selectUser} from '../../store/user/user.reducer';
import {selectTreeMapMode} from '../../store/global/global.reducer';

declare let embedpano, removepano;

@Component({
  selector: 'app-panorama',
  templateUrl: './panorama.component.html',
  styleUrls: ['./panorama.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaComponent implements OnInit, OnDestroy {
  static MAX_DISTANCE = 12.62;

  private maxDistance = PanoramaComponent.MAX_DISTANCE;

  private panoramaElementRef$ = new BehaviorSubject<ElementRef>(null);

  @ViewChild('panorama', { read: false, static: false }) set setPanoramaElementRef(panoramaElementRef: ElementRef) {
    setTimeout(() => { this.panoramaElementRef$.next(panoramaElementRef); }, 10);
  }

  @Input() side: Side;
  @Input() viewDirection: View;

  private krpano$ = new BehaviorSubject<any>(null);

  private learningMode: LearningMode;
  public tour: Tour;
  public panorama: Panorama;
  private panoramaMap: Map<string, Panorama> = new Map();

  public subThemes: SubTheme[] = [];
  public spots: Spot[] = [];
  public selectedSpot: Spot;
  public scores: Score[]  = [];
  public answers: Answer[]  = [];
  public answers$: Observable<Answer[]>;
  public tour$: Observable<Tour>;

  public treeCorrections: TreeCorrection[] = [];

  private panoramaTreeDiffer: IterableDiffer<PanoramaTree>;
  private treeDiffer: IterableDiffer<Tree>;
  private spotDiffer: IterableDiffer<Spot>;

  private lastView: View;
  private synchronized: boolean;

  public user: User;

  krpano: any = null;

  constructor(private store: Store<AppState>, private cd: ChangeDetectorRef, private iterable: IterableDiffers, private callbackService: CallbackService, private backendService: BackendService) {
    this.panoramaTreeDiffer = this.iterable.find([]).create();
    this.treeDiffer = this.iterable.find([]).create();
    this.spotDiffer = this.iterable.find([]).create();
  }

  ngOnInit() {

    setTimeout( () => {
      this.store.select(selectUser).subscribe((user) => {
        this.user = user;
      });

      this.store.select(selectSubThemes).subscribe((subThemes: SubTheme[]) => {
        this.subThemes = subThemes;
      });

      this.store.select(selectLearningMode).subscribe((learningMode) => {
        this.learningMode = learningMode;
        if (this.spots.length > 0) {
          this.spots.forEach((x) => {
            this.store.dispatch(new UpdateSpot({spot: {id: x.id, changes: x}}));
          });
        }
      });

      this.store.select(selectTreeCorrectionsFromPanorama(Side.Left)).subscribe((treeCorrections) => {
        this.treeCorrections = treeCorrections;
      });

      this.store.select(selectScores).subscribe((scores) => {
        this.scores = scores;
      });

      this.answers$ = this.store.select(selectAnswers);

      this.store.select(selectAnswers).subscribe((answers) => {
        this.answers = answers;
      });

      this.store.select(selectTreeMapMode);

      combineLatest(
        this.panoramaElementRef$,
        this.krpano$,
        this.store.select(selectSelectedTour(this.side)),
        this.store.select(selectPanoramasOfSelectedTour(this.side)),
        this.store.select(selectSelectedPanoramaOfSelectedTour(this.side)),
        this.store.select(selectPanoramaTreesOfSelectedPanoramaOfSelectedTour(this.side)),
        this.store.select(selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour),
        this.store.select(selectTreesOfSelectedTour(this.side)),
        this.store.select(selectSelectedTreeOfSelectedTour(this.side)),
        this.store.select(selectSubThemes),
        this.store.select(selectSpotsOfSelectedTour(this.side)),
        this.store.select(selectSelectedSpotOfSelectedTour(this.side)),
        this.store.select(selectTreeCorrectionsFromPanorama(this.side)),
        this.store.select(selectSynchronization),
      ).pipe(consistentUpdates).subscribe(combination => this.update.apply(this, combination));

      combineLatest(
        this.store.select(selectSelectedRealtimePanoramaOfSelectedTour(this.side)),
      ).pipe(updates).subscribe(combination => this.updateRealtimePanorama.apply(this, combination));
    }, 0);


  }

  ngOnDestroy() {
    this.remove();
  }

  private update(
    panoramaElementRef: Update<ElementRef>,
    krpano: Update<any>,
    tour: Update<Tour>,
    panoramas: Update<Panorama[]>,
    selectedPanorama: Update<Panorama>,
    panoramaTrees: Update<PanoramaTree[]>,
    selectedPanoramaTree: Update<PanoramaTree>,
    trees: Update<Tree[]>,
    selectedTree: Update<Tree>,
    subThemes: Update<SubTheme[]>,
    spots: Update<Spot[]>,
    selectedSpot: Update<Spot>,
    treeCorrections: Update<TreeCorrection[]>,
    synchronization: Update<Synchronization> ) {

    if ((tour.updated || panoramaElementRef.updated) && tour.value && panoramaElementRef.value) {
      this.updateTour(panoramaElementRef.value, tour.value);
    }

    if (panoramas.updated) {
      this.updatePanoramas(panoramas.value);
    }

    this.krpano = krpano.value;

    if (!(this.krpano && selectedPanorama.value)) {
      return;
    }

    if (selectedPanorama.updated) {
      this.updateSelectedPanorama(selectedPanorama.value);
    }




    if (selectedPanoramaTree.updated) {
      this.updateSelectedPanoramaTree(selectedPanoramaTree.value, selectedPanoramaTree.last);
    }

    this.treeCorrections = treeCorrections.value;
    if (treeCorrections.value) {
      this.tour = tour.value;
      this.forceUpdateTrees(trees.value, selectedTree.value, selectedPanorama.value);
    }

    let moveToTree = false;
    if (selectedSpot.value != null && selectedSpot.last != null) {
      moveToTree = selectedSpot.value.id != selectedSpot.last.id;
    }

    if (selectedSpot.updated) {
      this.updateSelectedSpot(selectedSpot.value, selectedSpot.last);
    }

    if (selectedTree.updated) {
      this.updateSelectedTree(selectedTree.value, selectedTree.last, selectedPanorama.value, moveToTree);
    }

    this.updatePanoramaTrees(panoramaTrees.value, selectedPanoramaTree.value);

    if (selectedPanorama.updated || trees.updated) {
      this.updateTreesOrPanorama(trees.value, selectedTree.value, selectedPanorama.value, selectedPanorama.updated);
    }

    this.updateSpots(spots.value, selectedSpot.value);

    this.updateSubThemes(subThemes.value);

    this.synchronized = synchronization.value.enabled;

  }

  private updateRealtimePanorama(realtimePanorama: Update<RealtimePanorama>) {
    if (!this.krpano) {
      return;
    }

    if (realtimePanorama.updated && realtimePanorama.value && realtimePanorama.value.view && realtimePanorama.value.view.side !== this.side) {
      this.setView(realtimePanorama.value.view);
    }
  }

  private updateTour(panoramaElementRef: ElementRef, tour: Tour): void {

    if (tour) {
      this.tour = tour;
    }
    if (this.krpano) {
      // this.remove();
    }

    if (tour.displayDistance != null && tour.displayDistance !== 0) {
      this.maxDistance = tour.displayDistance;
    } else {
      this.maxDistance = PanoramaComponent.MAX_DISTANCE;
    }

    if (panoramaElementRef && tour && tour.hasPanoramas) {
      this.tour = tour;
      this.embed(panoramaElementRef);
    }
  }

  private updatePanoramas(panoramas: Panorama[]): void {
    this.panoramaMap.clear();

    panoramas.forEach(panorama => {
      this.panoramaMap.set(panorama.scene, panorama);
    });

    this.setCurrentPanorama();
  }

  private updateSelectedPanorama(panorama: Panorama): void {
    if (panorama !== this.panorama) {
      this.panorama = panorama;
      this.lastView = null;
      this.loadScene(panorama.scene);
    }

    this.lastView = null;
    this.emitView();
  }

  getName() {
    return 'pano_' + (this.side === Side.Right ? 'right' : 'left');
  }

  getId() {
    return this.getName() + '_id';
  }

  /*
   * panorama trees
   */

  private updatePanoramaTrees(panoramaTrees: PanoramaTree[], selectedPanoramaTree: PanoramaTree): void {
    const panoramaTreeChanges = this.panoramaTreeDiffer.diff(panoramaTrees);
    if (panoramaTreeChanges) {
      /* remove before adding because if the same id is used for a new hotspot it is first removed and added with the new properties */
      panoramaTreeChanges.forEachRemovedItem((record: IterableChangeRecord<any>) => {
        const panoramaTree = record.item;
        this.removeHotspot(panoramaTree.id);
      });

      panoramaTreeChanges.forEachAddedItem((record: IterableChangeRecord<any>) => {
        const panoramaTree = record.item;
        this.addPanoramaTree(panoramaTree, selectedPanoramaTree);
      });
    }
  }

  private updateSelectedPanoramaTree(selectedPanoramaTree: PanoramaTree, lastSelectedPanoramaTree: PanoramaTree) {
    if (lastSelectedPanoramaTree) {
      this.updatePanoramaTree(lastSelectedPanoramaTree, selectedPanoramaTree);
    }

    if (selectedPanoramaTree) {
      this.updatePanoramaTree(selectedPanoramaTree, selectedPanoramaTree);
      this.moveToOrientation(({
        altitude: {radians: -selectedPanoramaTree.orientation.altitude.radians},
        azimuth: {radians: selectedPanoramaTree.orientation.azimuth.radians},
      }));
    }
  }

  private updatePanoramaTree(panoramaTree: PanoramaTree, selectedPanoramaTree: PanoramaTree) {
    this.removePanoramaTree(panoramaTree);
    this.addPanoramaTree(panoramaTree, selectedPanoramaTree);
  }

  private addPanoramaTree(panoramaTree: PanoramaTree, selectedPanoramaTree: PanoramaTree) {
    this.addHotspot(
      this.getPanoramaTreeId(panoramaTree),
      this.getPanoramaTreeIcon(panoramaTree, selectedPanoramaTree),
      20,
      20,
      10,
      true,
      panoramaTree.orientation,
      this.selectPanoramaTree.bind(this),
      panoramaTree,
    );
  }

  private removePanoramaTree(panoramaTree: PanoramaTree) {
    this.removeHotspot(this.getPanoramaTreeId(panoramaTree));
  }

  private selectPanoramaTree(panoramaTree: PanoramaTree): void {
    if (this.side === Side.Left) {
      this.store.dispatch(new SetSelectedPanoramaTree({panoramaId: panoramaTree.panoramaId, panoramaTreeId: panoramaTree.id}));
    }
  }

  private getPanoramaTreeId(panoramaTree: PanoramaTree): string {
    return 'panorama_tree_' + panoramaTree.id;
  }

  private getPanoramaTreeIcon(panoramaTree: PanoramaTree, selectedPanoramaTree: PanoramaTree) {
    if (panoramaTree === selectedPanoramaTree) {
      return '/assets/images/markers/marker-icon-green.png';
    } else {
      return '/assets/images/markers/marker-icon-blue.png';
    }
  }

  /* spots */

  public updateSpots(spots: Spot[], selectedSpot: Spot): void {
    this.spots = spots;
    this.spotDiffer.diff(spots);

    spots.forEach((spot) => {
      this.removeSpot(spot);
    });

    spots.forEach((spot) => {
      this.addSpot(spot, selectedSpot);
    });
  }

  public updateSubThemes(subThemes: SubTheme[]): void {
    this.subThemes = subThemes;
  }

  private updateSelectedSpot(selectedSpot: Spot, lastSelectedSpot: Spot) {
    if (lastSelectedSpot) {
      this.updateSpot(lastSelectedSpot, selectedSpot);
    }

    if (selectedSpot) {
      this.updateSpot(selectedSpot, selectedSpot);
      if (selectedSpot.altitude !== undefined) {
        this.moveToOrientation({
          altitude: {radians: -(+selectedSpot.altitude)},
          azimuth: {radians: +selectedSpot.azimuth},
        });
      }
    }
  }

  private updateSpot(spot: Spot, selectedSpot: Spot) {
    this.selectedSpot = selectedSpot;
    this.removeSpot(spot);
    this.addSpot(spot, selectedSpot);
  }


  private addSpot(spot: Spot, selectedSpot: Spot) {

    if (spot.subThemeId != null || this.learningMode === LearningMode.Editor) {
      const width = selectedSpot != null && spot.id === selectedSpot.id && spot.id != '0' ? 50 : this.learningMode === LearningMode.Research && (this.isSpotResponded(spot) == null ) ? 140 : 35;
      this.addHotspot(
        this.getSpotId(spot),
        this.getSpotIcon(spot, selectedSpot),
        width,
        width,
        10,
        true,
        {
          altitude: {radians: +spot.altitude},
          azimuth: {radians: +spot.azimuth},
        },
        this.selectSpot.bind(this),
        spot,
      );
    }
  }

  private removeSpot(spot: Spot) {
    this.removeHotspot(this.getSpotId(spot));
    this.removeHotspot(this.getSpotId(spot));
  }


  private selectSpot(spot: Spot): void {
    if(spot != null) {
      if (this.side === Side.Left) {
        this.store.select(selectTreeOfByTreeNumber(spot.treeNumber)).subscribe((tree) => {
          this.store.dispatch(new SetSelectedTree({tourId: tree.tourId, treeId: tree.id}));

          this.store.dispatch(new MultimediaContent());
          this.store.dispatch(new SetSelectedSpot({tourId: this.tour.id, spotId: spot.id}));

        });
      }
    }

  }

  private getSpotId(spot: Spot): string {
    return 'spot_' + spot.id;
  }

  private getSpotIcon(spot: Spot, selectedSpot: Spot): string {

    if (spot.id == '0' || spot.subThemeId == null) {
      return '/assets/images/spots/empty.png';
    } else {
      let subTheme = this.subThemes.find(x => x.id == spot.subThemeId);
      if ( subTheme.level == 2 ) {
        subTheme = this.subThemes.find(x => x.id == subTheme.superThemeId);
      }

      if (subTheme.level == 1) {
        subTheme = this.subThemes.find(x => x.id == subTheme.superThemeId);
      }

      if ( (this.getSpotScore(spot) && this.isSpotResponded(spot)) || (this.learningMode !== LearningMode.Determination && this.learningMode != LearningMode.Research)) {
        if (subTheme != null && subTheme.iconUrl != null && subTheme.iconUrl !== '') {
          return '/assets/images/spots/' + subTheme.iconUrl + '.png';
        } else {
          return '/assets/images/spots/empty.png';
        }
      } else {

        if (this.learningMode == LearningMode.Determination) {

          if (this.getSpotScore(spot) && this.isSpotResponded(spot) && UserHelper.isLoggedIn(this.user)) {
            return '/assets/images/spots/' + subTheme.iconUrl + '.png';
          } else {
            return '/assets/images/spots/empty.png';
          }
        } else if (this.learningMode === LearningMode.Research) {
          if (this.getSpotScore(spot) && this.isSpotResponded(spot) && UserHelper.isLoggedIn(this.user)) {
            return '/assets/images/spots/' + subTheme.iconUrl + '.png';
          } else {
            if ( (!this.isSpotResponded(spot) && this.isSpotResponded(spot) != null) || (selectedSpot != null && spot.id == selectedSpot.id)) {
              return '/assets/images/spots/empty.png';
            } else {
              return '/assets/images/spots/transparent.png';
            }
          }
        }
      }
    }


  }

  getSpotScore(spot: Spot) {
    const score = this.scores.find(x => x.spotId == +spot.id && x.modeId == this.learningMode && x.userId == this.user.id);
    if (score == null) {
      return false;
    } else {
      return this.answers.filter(x => x.scoreId == score.id).length == 3 || (this.answers.filter(x => x.scoreId == score.id).length != this.answers.filter(x => x.scoreId == score.id && x.correct).length );
    }
  }

  isSpotResponded(spot: Spot): boolean {
    const score = this.scores.find(x => x.spotId == +spot.id && x.modeId == this.learningMode && x.userId == this.user.id);
    if (score != null) {
      return this.answers.filter(x => x.scoreId == score.id && x.correct).length == this.answers.filter(x => x.scoreId == score.id).length;
    } else {
      return null;
    }
  }



  /*
   * trees
   */

  private updateTreesOrPanorama(trees: Tree[], selectedTree: Tree, panorama: Panorama, panoramaUpdated: boolean): void {
    if (panoramaUpdated) {
      this.updateTrees([], selectedTree, panorama);
      this.updateTrees(trees, selectedTree, panorama);
    } else {
      this.updateTrees(trees, selectedTree, panorama);
    }
  }

  private updateTrees(trees: Tree[], selectedTree: Tree, panorama: Panorama): void {
    const treeChanges = this.treeDiffer.diff(trees);

    if (treeChanges) {
      /* remove before adding because if the same id is used for a new hotspot it is first removed and added with the new properties */
      treeChanges.forEachRemovedItem((record: IterableChangeRecord<any>) => {
        const tree = record.item;
        this.removeHotspot(tree.id);
      });

      treeChanges.forEachAddedItem((record: IterableChangeRecord<any>) => {
        const tree = record.item;
        this.addTreeIfInRange(tree, selectedTree, panorama);
      });
    }
  }

  private forceUpdateTrees(trees: Tree[], selectedTree: Tree, panorama: Panorama): void {

    trees.forEach((tree) => {
      this.removeHotspot(tree.id);
    });

    trees.forEach((tree) => {
      this.addTreeIfInRange(tree, selectedTree, panorama);
    });
  }

  private updateSelectedTree(selectedTree: Tree, lastSelectedTree: Tree, panorama: Panorama, move: boolean) {
    if (lastSelectedTree) {
      this.updateTree(lastSelectedTree, selectedTree, panorama);
    }

    if (selectedTree) {
      this.updateTree(selectedTree, selectedTree, panorama);

      const orientation = this.calculateTreeOrientation(selectedTree, panorama);

      // ignore selected tree in synchronized habitat in the right panorama to not get out of sync
      if (orientation && !(this.synchronized && this.side == Side.Right)) {
        //this.moveToOrientation(orientation);
        this.moveToOrientation(({
          altitude: {radians: -orientation.altitude.radians},
          azimuth: {radians: orientation.azimuth.radians},
        }));
      }
    }
  }

  private updateTree(tree: Tree, selectedTree: Tree, panorama: Panorama) {
    this.removeTree(tree);
    this.addTreeIfInRange(tree, selectedTree, panorama);
  }

  private addTreeIfInRange(tree: Tree, selectedTree: Tree, panorama: Panorama) {
    const orientation = this.calculateTreeOrientation(tree, panorama);

    if (orientation) {
      this.addTree(tree, selectedTree, orientation);
    }
  }

  private addTree(tree: Tree, selectedTree: Tree, orientation: Orientation) {
    this.addHotspot(
    //this.addTreeHotspot(
      this.getTreeId(tree),
      this.getTreeIcon(tree, selectedTree),
      tree.width,
      tree.width,
      10,
      true,
      orientation,
      this.selectTree.bind(this),
      tree,
    );
  }

  private removeTree(tree: Tree) {
    this.removeHotspot(this.getTreeId(tree));
  }

  private selectTree(tree: Tree): void {
    if (this.side == Side.Left) {
      this.store.dispatch(new SetSelectedTree({tourId: tree.tourId, treeId: tree.id}));
      if (this.selectedSpot != undefined && tree.treenumber != this.selectedSpot.treeNumber) {
        this.store.dispatch(new ClearSelectedSpot());
        this.store.dispatch(new ModeInfo());
      }
    }
  }

  private getTreeId(tree: Tree): string {
    return 'panorama_tree_' + tree.id;
  }

  private getTreeIcon(tree: Tree, selectedTree: Tree) {
    let selected: string;
    if (selectedTree != null) {
      selected = tree.treenumber == selectedTree.treenumber ? '_selected' : '';
    } else {
      selected = tree == selectedTree ? '_selected' : '';
    }

    return '/assets/images/icons/tree_' + tree.stateId + selected + '.png';
  }

  private calculateTreeOrientation(tree: Tree, panorama: Panorama): Orientation {

    const distance = TourPositionHelper.distance(panorama.position, tree.position);

    if (distance >= this.maxDistance) {
      return null;
    }

    tree.width = 25 + (25 * ((this.maxDistance - distance) / this.maxDistance));

    let azimuth = AngleHelper.fromPositions(panorama.position, tree.position);
    azimuth = AngleHelper.add(azimuth, this.tour.rotate);
    azimuth =  AngleHelper.subtract(azimuth, AngleHelper.fromDegrees(panorama.athCorrection));
    const treeCorrection = this.treeCorrections.find(x => x.treenumber == tree.treenumber);

    let correct_atv = 0;
    let correct_ath = azimuth;
    if (treeCorrection != null) {
      correct_atv = +treeCorrection.atv_correction;
      correct_ath = {radians: -AngleHelper.subtract(AngleHelper.fromDegrees(treeCorrection.ath_correction), azimuth).radians};
    }

    return {
      azimuth: correct_ath,
      altitude: AngleHelper.fromDegrees(0 + correct_atv),
    };
  }

  /*
   * synchronization
   * see https://krpano.com/examples/108b9/examples/javascript-interface/js-sync/splitscreen.html
   */

  emitView(): void {
    const view: SideView = {
      azimuth: this.azimuthFromPanorama(this.get('view.hlookat')),
      altitude: this.altitudeFromPanorama(this.get('view.vlookat')),
      zoom: AngleHelper.fromDegrees(this.get('view.fov')),
      side: this.side
    };

    // this may be false when a krpano is removed
    const hasValues = view.azimuth.radians !== undefined && view.altitude !== undefined && view.zoom !== undefined;

    if (hasValues && !(this.lastView && ViewHelper.equals(view, this.lastView))) {
      this.lastView = view;
      this.store.dispatch(new SetView({side: this.side, view: view}));
    }
  }

  emitOrigin(): void {
    this.store.dispatch(new SetSyncOrigin({side: this.side}));
  }

  /*
   * krpano callbacks
   */

  onReady(krpano) {
    this.krpano = krpano;
    this.krpano$.next(this.krpano);

    if (isDevMode()) {
      // make pano easily accessible from console
      document[this.getName()] = this.krpano;
    }

    this.registerEvents();
  }

  registerEvents() {
    this.registerEvent('onloadcomplete', this.onLoadCompleted.bind(this));
    this.registerEvent('onviewchange', this.onViewChanged.bind(this));
    this.registerEvent('onclick', this.onClick.bind(this));
    this.registerEvent('onmousedown', this.onMouseDown.bind(this));
    this.registerEvent('onmousewheel', this.onMouseWheel.bind(this));
  }

  onLoadCompleted() {
    this.setCurrentPanorama();
  }

  setCurrentPanorama() {
    const scene = this.get('xml.scene');
    const panorama = this.panoramaMap.get(scene);

    if (panorama !== this.panorama && panorama !== undefined) {
      this.panorama = panorama;
      this.lastView = null;
      this.store.dispatch(new SetSelectedPanorama({tourId: this.tour.id, side: this.side, panoramaId: this.panorama ? this.panorama.id : null}));
    }
  }

  onViewChanged() {
    if (this.panorama) {
      this.emitView();
    }
  }

  onClick() {
    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe((spot) => {
      this.selectedSpot = spot;
    });

    if (this.selectedSpot != null && this.learningMode == LearningMode.Editor) {
      const position = this.krpano.screentosphere(this.get('mouse.x'), this.get('mouse.y'));

      const ath = this.azimuthPanoToMartelage(position.x);
      const atv = position.y;




      if (this.selectedSpot.azimuthTmp == null && this.selectedSpot.altitudeTmp == null) {
        this.selectedSpot.azimuthTmp = this.selectedSpot.azimuth;
        this.selectedSpot.altitudeTmp = this.selectedSpot.altitude;
      }

      this.selectedSpot.azimuth = +AngleHelper.fromGeodesyDegrees(ath).radians.toFixed(5);
      this.selectedSpot.altitude = +AngleHelper.fromDegrees(atv).radians.toFixed(5);
      this.store.dispatch(new UpdateSpot({spot: {id: this.selectedSpot.id, changes: this.selectedSpot}}));

    } else {
      if (this.side == Side.Left) {
        if (this.panorama) {
          //this.store.dispatch(new ClearSelectedPanoramaTree({panoramaId: this.panorama.id}));
        }

        //this.store.dispatch(new ClearSelectedTree());
      }
    }

  }

  azimuthPanoToMartelage(azimuth) {
    const current_scene = this.krpano.get('xml.scene');
    const heading = parseFloat(this.krpano.get('scene[' + current_scene + '].heading'));
    return this.positiveModulo(azimuth + heading, 360);
  }

  positiveModulo(dividend, divisor) {
    return ((dividend % divisor) + divisor) % divisor;
  }

  onMouseDown() {
    this.emitOrigin();
  }

  onMouseWheel() {
    this.emitOrigin();
  }

  /*
   * commands
   */

  private loadScene(scene: string) {
    this.call('loadscene', [scene]);
  }

  private moveToOrientation(orientation: Orientation) {
    this.call('moveto', [
      this.azimuthToPanorama(orientation.azimuth),
      this.altitudeToPanorama(orientation.altitude),
      'smooth(100,100,200)'
    ]);
  }

  private setView(view: View) {
    if (view !== this.lastView) {
      this.set('view.hlookat', this.azimuthToPanorama(view.azimuth));
      this.set('view.vlookat', this.altitudeToPanorama(view.altitude));
      this.set('view.fov', AngleHelper.getDegrees(view.zoom));

    }
  }

  /*
   * hotspots
   */

  hotspotSet(id, key, value) {
    this.set(this.getHotspotId(id) + '.' + key, value);
  }

  addHotspot(id: string, icon: string, width: number, height: number, zoom: number, enabled: boolean, orientation: Orientation, onclick: any, payload: any): void {
    this.call('addhotspot', [id]);
    this.hotspotSet(id, 'url', icon);
    this.hotspotSet(id, 'width', width);
    this.hotspotSet(id, 'height', height);
    this.hotspotSet(id, 'zoom', zoom);
    this.hotspotSet(id, 'enabled', true);
    this.hotspotSet(id, 'ath', this.azimuthToPanorama(orientation.azimuth));
    this.hotspotSet(id, 'atv', -this.altitudeToPanorama(orientation.altitude));

    const callbackName = this.createCallback(id + '_onclick', onclick, [payload]);
    this.hotspotSet(id, 'onclick', callbackName);
  }

  addTreeHotspot(id: string, icon: string, width: number, height: number, zoom: number, enabled: boolean, orientation: Orientation, onclick: any, payload: any): void {

    this.call('addhotspot', [id]);
    this.hotspotSet(id, 'url', icon);
    this.hotspotSet(id, 'width', width);
    this.hotspotSet(id, 'height', height);
    this.hotspotSet(id, 'zoom', zoom);
    this.hotspotSet(id, 'enabled', true);
    this.hotspotSet(id, 'ath', orientation.azimuth.radians);
    this.hotspotSet(id, 'atv', orientation.altitude.radians);

    const callbackName = this.createCallback(id + '_onclick', onclick, [payload]);
    this.hotspotSet(id, 'onclick', callbackName);
  }

  removeHotspot(id: string) {
    this.call('removehotspot', [id]);
  }

  private getHotspotId(id) {
    return 'hotspot[' + id + ']';
  }

  /*
   * azimuth correction
   */
  private azimuthFromPanorama(azimuth: number): Azimuth {
    return AngleHelper.subtract(AngleHelper.fromGeodesyDegrees(azimuth), this.getHeading());
  }

  private azimuthToPanorama(azimuth: Azimuth): number {
    return AngleHelper.getGeodesyDegrees(AngleHelper.add(azimuth, this.getHeading()));
  }

  private getHeading(): Angle {
    const current_scene = this.get('xml.scene');
    const headingDegrees = parseFloat(this.get('scene[' + current_scene + '].heading'));
    return AngleHelper.fromDegrees(headingDegrees);
  }

  private altitudeFromPanorama(altitude: number): Angle {
    return AngleHelper.flipSign(AngleHelper.fromDegrees(altitude));
  }

  private altitudeToPanorama(altitude: Angle): number {
    return AngleHelper.getDegrees(AngleHelper.flipSign(altitude));
  }

  /*
   * krpano entry points
   */

  set(key: string, value: any): void {
    if (!this.krpano) {
      return;
    }

    this.krpano.set(key, value);
  }

  get(key: string): any {
    if (!this.krpano) {
      return undefined;
    }

    return this.krpano.get(key);
  }

  call(fctName: string, args: any[]): any {
    if (!this.krpano) {
      return undefined;
    }

    const invocationString = fctName + '(' + args.join(',') + ')';

    return this.krpano.call(invocationString);
  }

  embed(panoramaElementRef: ElementRef) {
    // here we were looking to change the id of the pano div
    // but the angular take too much time to load that and the pano were not loading correctly with this method
    // however this was use to precise div for left pano and right pano
    // since we are not using more than one pano
    // we can staticly put the id as the left pano
    // (functionnality for 2 pano are not remove cause they will be used in a futur project and so probably need to rework this in the futur)
    // have fun :)
    // -> //panoramaElementRef.nativeElement.setAttribute('id', this.getName());

    embedpano({
      swf: 'krpano.swf',
      xml: this.tour ? this.tour.path : null,
      target: this.getName(),
      id: this.getId(),
      onready: this.onReady.bind(this)
    });
  }

  remove() {
    removepano(this.getName());
    this.krpano$.next(null);
  }

  /*
   * krpano callbacks helpers
   */

  registerEvent(eventName: string, fct) {
    const callbackName = this.createCallback(eventName, fct);
    this.set('events.' + eventName, callbackName);
  }

  createCallback(eventName: string, fct, args: any[] = []) {
    const eventNameWithSide = this.getName() + '_' + eventName;
    const callbackName = this.callbackService.create(eventNameWithSide, fct, args);
    return 'js(' + callbackName + ')';
  }
}
