export class SubTheme {
  id: number;
  nameFr: string;
  nameDe: string;
  nameEn: string;
  themeId: number;
  superThemeId: number;
  level: number;
  descriptionContentId: number;
  naturalFrequency: number;
  operatedFrequency: number;
  replacementSpeed: number;
  goodToKnowContentId: number;
  associatedSpeciesContentId: number;
  inventoryLevelContentId: number;
  otherInfoContentId: number;
  iconUrl: string;
}
