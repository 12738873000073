import {Actions, Effect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BackendService} from '../services/backend.service';
import {UserSetAction, UserState} from './user/user.reducer';
import {AppActionTypes, LoadedApp, LoadedTour, LoadTour} from './app.actions';
import {LanguageEffects} from './language/language.effects';
import {RuntimeTourEffects} from './runtime-tour/runtime-tour.effects';
import {GlobalEffects} from './global/global.effects';
import {TreeEffects} from './tree/tree.effects';
import {SetFromBackendAction} from './language/language.actions';
import {LoadReasons} from './reason/reason.actions';
import {LoadTourCanopyCoverDegrees} from './tour-canopy-cover-degree/tour-canopy-cover-degree.actions';
import {LoadTourCategories} from './tour-category/tour-category.actions';
import {LoadTourDevelopmentalStages} from './tour-developmental-stage/tour-developmental-stage.actions';
import {LoadTourInventoryTypes} from './tour-inventory-type/tour-inventory-type.actions';
import {LoadTreeSocialPositions} from './tree-social-position/tree-social-position.actions';
import {LoadTreeStates} from './tree-state/tree-state.actions';
import {LoadTreeStemTypes} from './tree-stem-type/tree-stem-type.action';
import {LoadTreeTypes} from './tree-type/tree-type.actions';
import {LoadTreeVerticalStructures} from './tree-vertical-structure/tree-vertical-structure.actions';
import {LoadTours} from './tour/tour.actions';
import { AddPanoramas, DeletePanoramas, ClearPanoramas } from './panorama/panorama.actions';
import * as FromAllPanorama from './all-panorama/all-panorama.actions';
import {AddPanoramaTrees, ClearPanoramaTrees} from './panorama-tree/panorama-tree.actions';
import {AddTreeCorrections, LoadTreeCorrections, ClearTreeCorrections} from './tree-correction/tree-correction.actions';
import {AddTrees, ClearTrees} from './tree/tree.actions';
import {User} from './user/user.model';
import {Reason} from './reason/reason.model';
import {Panorama} from './panorama/panorama.model';
import {PanoramaTree} from './panorama-tree/panorama-tree.model';
import {AngleHelper, positionFromString, Tour} from './tour/tour.model';
import {TourCanopyCoverDegree} from './tour-canopy-cover-degree/tour-canopy-cover-degree.model';
import {TourCategory} from './tour-category/tour-category.model';
import {TourDevelopmentalStage} from './tour-developmental-stage/tour-developmental-stage.model';
import {TourInventoryType} from './tour-inventory-type/tour-inventory-type.model';
import {Tree} from './tree/tree.model';
import {TreeCorrection} from './tree-correction/tree-correction.model';
import {TreeSocialPosition} from './tree-social-position/tree-social-position.model';
import {TreeState} from './tree-state/tree-state.model';
import {TreeStemType} from './tree-stem-type/tree-stem-type.model';
import {TreeType} from './tree-type/tree-type.model';
import {TreeVerticalStructure} from './tree-vertical-structure/tree-vertical-structure.model';
import {LoadThemes} from './catalog/theme/theme.actions';
import {LoadSubThemes} from './catalog/sub-theme/sub-theme.actions';
import {LoadContents} from './catalog/content/content.actions';
import {LoadMedias} from './catalog/media/media.actions';
import {LoadMediaTypes} from './catalog/media-type/media-type.actions';
import {LoadQuestions} from './catalog/question/question.actions';
import {LoadResponses} from './catalog/response/response.actions';
import {Theme} from './catalog/theme/theme.model';
import {SubTheme} from './catalog/sub-theme/sub-theme.model';
import {Content} from './catalog/content/content.model';
import {Media} from './catalog/media/media.model';
import {MediaType} from './catalog/media-type/media-type.model';
import {Question} from './catalog/question/question.model';
import {Response} from './catalog/response/response.model';
import {Spot} from './spot/spot.model';
import {LoadSpots} from './spot/spot.actions';
import {LoadTourThematics} from './tour-thematic/tour-thematic.actions';
import {LoadTourDisabledModuss} from './tour-disabled-modus/tour-disabled-modus.actions';
import {TourDisabledModus} from './tour-disabled-modus/tour-disabled-modus.model';
import {TourThematic} from './tour-thematic/tour-thematic.model';
import {Score} from './score/score.model';
import {Answer} from './answer/answer.model';
import {LoadScores} from './score/score.actions';
import {LoadAnswers} from './answer/answer.actions';

@Injectable()
export class AppEffects {

    constructor(private actions$: Actions, private store: Store<UserState>, public backendService: BackendService) {}

    @Effect()
    getTours = this.actions$.pipe(
        ofType(AppActionTypes.LoadTours),
        map((arg) => {
        this.backendService.getCommon().subscribe( res => {
            this.processCommon(res);
        });
        return new LoadedApp();
        }),
    );

    @Effect()
    getTour = this.actions$.pipe(
        ofType(AppActionTypes.LoadTour),
        map((action: LoadTour) => {
          this.backendService.getTour(action.payload.tourId);
          return new LoadedTour();
        }),
    );



    processCommon(jsonCommon: any): void {
        try {
            const language = this.parseLanguages(jsonCommon.language);
            const reasons = this.parseReasons(jsonCommon.reasons);
            const tours = this.parseTours(jsonCommon.tours);
            const panoramas = this.parsePanorama(jsonCommon.panoramas);
            const tourCanopyCoverDegrees = this.parseTourCanopyCoverDegrees(jsonCommon.tour_canopy_cover_degrees);
            const tourCategories = this.parseTourCategories(jsonCommon.tour_categories);
            const tourDevelopmentalStages = this.parseTourDevelopmentalStages(jsonCommon.tour_developmental_stages);
            const tourInventoryTypes = this.parseTourInventoryTypes(jsonCommon.tour_inventory_types);
            const tourDisabledModus = this.parseTourDisabledModus(jsonCommon.tour_disabled_modus);
            const tourThematics = this.parseTourThematics(jsonCommon.tour_thematics);
            const treeSocialPositions = this.parseTreeSocialPositions(jsonCommon.tree_social_positions);
            const treeStates = this.parseTreeStates(jsonCommon.states);
            const treeStemTypes = this.parseTreeStemTypes(jsonCommon.tree_stem_types);
            const treeTypes = this.parseTreeTypes(jsonCommon.tree_types);
            const treeCorrections = this.parseTreeCorrection(jsonCommon.tree_corrections);
            const treeVerticalStructure = this.parseTreeVerticalStructures(jsonCommon.tree_vertical_structures);
            const user = this.parseUser(jsonCommon.user);

            const scores = this.parseScores(jsonCommon.scores);
            const answers = this.parseAnswers(jsonCommon.answers);

            const themes = this.parseThemes(jsonCommon.themes);
            const subThemes = this.parseSubThemes(jsonCommon.sub_themes);
            const spots = this.parseSpots(jsonCommon.spots);

            const contents = this.parseContents(jsonCommon.contents);
            const medias = this.parseMedias(jsonCommon.medias);
            const mediaTypes = this.parseMediaTypes(jsonCommon.media_types);

            const questions = this.parseQuestions(jsonCommon.questions);
            const responses = this.parseResponses(jsonCommon.responses);

            this.store.dispatch(new SetFromBackendAction(language));
            this.store.dispatch(new LoadReasons({reasons: reasons}));
            this.store.dispatch(new LoadTourCanopyCoverDegrees({tourCanopyCoverDegrees: tourCanopyCoverDegrees}));
            this.store.dispatch(new LoadTourCategories({tourCategories: tourCategories}));
            this.store.dispatch(new LoadTourDevelopmentalStages({tourDevelopmentalStages: tourDevelopmentalStages}));
            this.store.dispatch(new LoadTourInventoryTypes({tourInventoryTypes: tourInventoryTypes}));
            this.store.dispatch(new LoadTourDisabledModuss({tourDisabledModuss: tourDisabledModus}));
            this.store.dispatch(new LoadTourThematics({tourThematics: tourThematics}));
            this.store.dispatch(new LoadTreeSocialPositions({treeSocialPositions: treeSocialPositions}));
            this.store.dispatch(new LoadTreeStates({treeStates: treeStates}));
            this.store.dispatch(new LoadTreeStemTypes({treeStemTypes: treeStemTypes}));
            this.store.dispatch(new LoadTreeTypes({treeTypes: treeTypes}));
            this.store.dispatch(new LoadTreeCorrections({treeCorrections: treeCorrections}));
            this.store.dispatch(new LoadTreeVerticalStructures({treeVerticalStructures: treeVerticalStructure}));
            this.store.dispatch(new FromAllPanorama.AddAllPanoramas({panoramas: panoramas}));
            this.store.dispatch(new LoadTours({tours: tours}));
            this.store.dispatch(new UserSetAction(user));

            this.store.dispatch(new LoadScores({scores: scores}));
            this.store.dispatch(new LoadAnswers({answers: answers}));

            this.store.dispatch(new LoadThemes({themes: themes}));
            this.store.dispatch(new LoadSubThemes({subThemes: subThemes}));
            this.store.dispatch(new LoadSpots({spots: spots}));

            this.store.dispatch(new LoadContents({contents: contents}));
            this.store.dispatch(new LoadMedias({medias: medias}));
            this.store.dispatch(new LoadMediaTypes({mediaTypes: mediaTypes}));

            this.store.dispatch(new LoadQuestions({questions: questions}));
            this.store.dispatch(new LoadResponses({responses: responses}));

        } catch (error) {
            console.error('unable to parse common', error);
        }
    }

    private processTour(jsonCommon: any): void {
        try {
            const panoramas = this.parsePanorama(jsonCommon.panoramas);
            const panoramaTrees = this.parsePanoramaTree(jsonCommon.panorama_trees);
            const treeCorrections = this.parseTreeCorrection(jsonCommon.tree_corrections);
            const trees = this.parseTree(jsonCommon.trees);

            this.store.dispatch(new AddPanoramas({panoramas: panoramas}));
            this.store.dispatch(new AddPanoramaTrees({panoramaTrees: panoramaTrees}));
            this.store.dispatch(new AddTreeCorrections({treeCorrections: treeCorrections}));
            this.store.dispatch(new AddTrees({trees: trees}));
        } catch (error) {
            console.error('unable to parse common', error);
        }
    }

    private parseLanguages(jsonLanguage: any): string {
        return jsonLanguage;
    }

    private parseUser(jsonUser: any): User {
        return {
            id: +jsonUser.id,
            roleId: +jsonUser.role_id,
        };
    }

    private parseReasons(jsonReasons: any): Reason[] {
        return jsonReasons.map((jsonReason): Reason => ({
            id: jsonReason.id,
            stateId: jsonReason.status,
            title: this.processTranslations(jsonReason, 'reason', 'title')
        }));
    }

    private parsePanorama(jsonPanoramas: any): Panorama[] {
        return jsonPanoramas.map((jsonPanorama): Panorama => ({
            id: jsonPanorama.id,
            tourId: jsonPanorama.tour_id,
            scene: jsonPanorama.scene,
            position: {
                x: +jsonPanorama.camera_x,
                y: -jsonPanorama.camera_y,
            },
            athCorrection: +jsonPanorama.ath_correction,
            cameraDistance: +jsonPanorama.camera_distance,
            k: +jsonPanorama.k,
            label: jsonPanorama.label,
        }));
    }

    private parsePanoramaTree(jsonPanoramaTrees: any): PanoramaTree[] {
        return jsonPanoramaTrees.map((jsonPanoramaTree): PanoramaTree => ({
            id: jsonPanoramaTree.id,
            panoramaId: '' + jsonPanoramaTree.panorama_id,
            socialPositionId: jsonPanoramaTree.tree_social_position_id,
            stemTypeId: jsonPanoramaTree.tree_stem_type_id,
            typeId: jsonPanoramaTree.treetype_id,
            verticalStructureId: jsonPanoramaTree.tree_vertical_structure_id,
            treenumber: +jsonPanoramaTree.treenumber,
            diameter: +jsonPanoramaTree.diameter,
            quality: jsonPanoramaTree.quality,
            ecology: jsonPanoramaTree.ecology,
            ecologyNote: +jsonPanoramaTree.ecology_note,
            volume: +jsonPanoramaTree.volume,
            comment: jsonPanoramaTree.comment,
            orientation: {
                azimuth: AngleHelper.fromGeodesyDegrees(+jsonPanoramaTree.azimuth),
                altitude: AngleHelper.fromDegrees(+jsonPanoramaTree.altitude),
            },
            observation: jsonPanoramaTree.observation,
            distance: jsonPanoramaTree.distance,
        }));
    }

    private parseTours(jsonTours: any): Tour[] {
        return jsonTours.map((jsonTour): Tour => ({
            id: jsonTour.id,
            name: jsonTour.name,
            title: this.processTranslations(jsonTour, 'tour', 'title'),
            titleFr: jsonTour.title_fr,
            titleDe: jsonTour.title_de,
            titleEn: jsonTour.title_en,
            canopyCoverDegreeId: jsonTour.tour_canopy_cover_degree_id,
            categoryId: jsonTour.tour_category_id,
            developmentalStageId: jsonTour.tour_developmental_stage_id,
            inventoryTypeId: jsonTour.tour_inventory_type_id,
            treeTypeId: jsonTour.treetype_id,
            description: this.processTranslations(jsonTour, 'tour', 'description'),
            descriptionFr: jsonTour.description_fr,
            descriptionDe: jsonTour.description_de,
            descriptionEn: jsonTour.description_en,
            path: jsonTour.path + '/' + jsonTour.name + '.xml',
            imagePath: jsonTour.path + '/thumbnail.jpg',
            date: jsonTour.date,
            location: jsonTour.gps_origin,
            position: positionFromString(jsonTour.gps_origin),
            surface: +jsonTour.surface,
            shape: +jsonTour.shape,
            rotate: AngleHelper.fromDegrees(+jsonTour.rotate),
            order: +jsonTour.order,
            published: +jsonTour.published,
            statistic: +jsonTour.statistic,
            coniferousProportion: +jsonTour.coniferous_proportion,
            mapSymboleScaleOffset: jsonTour.map_symbole_scale_offset,
            mapSymboleScaleFactor: jsonTour.map_symbole_scale_factor,
            fieldVd: jsonTour.field_vd,
            fieldEk: jsonTour.field_ek,
            displayDistance: jsonTour.display_distance,
            treeCount: +jsonTour.tree_count,
            hasPanoramas: jsonTour.has_panoramas,
            statusCount: jsonTour.hasOwnProperty('status_count') ? [jsonTour.status_count[0], jsonTour.status_count[1], jsonTour.status_count[2]] : null,
        }));
    }

    private parseTourCanopyCoverDegrees(jsonTourCanopyCoverDegree: any): TourCanopyCoverDegree[] {
        return jsonTourCanopyCoverDegree.map((jsonCanopyCoverDegree): TourCanopyCoverDegree => ({
            id: jsonCanopyCoverDegree.id,
            title: this.processTranslations(jsonCanopyCoverDegree, 'canopycoverdegree', 'title'),
        }));
    }

    private parseTourCategories(jsonTourCategories: any): TourCategory[] {
        return jsonTourCategories.map((jsonTourCategory): TourCategory => ({
            id: jsonTourCategory.id,
            title: this.processTranslations(jsonTourCategory, 'tourCategory', 'title'),
        }));
    }

    private parseTourDevelopmentalStages(jsonTourDevelopmentalStages: any): TourDevelopmentalStage[] {
        return jsonTourDevelopmentalStages.map((jsonTourDevelopmentalStage): TourDevelopmentalStage => ({
            id: jsonTourDevelopmentalStage.id,
            title: this.processTranslations(jsonTourDevelopmentalStage, 'tourdevelopmentalstage', 'title'),
        }));
    }

    private parseTourInventoryTypes(jsonTourInventoryTypes: any): TourDevelopmentalStage[] {
        return jsonTourInventoryTypes.map((jsonTourInventoryType): TourInventoryType => ({
            id: jsonTourInventoryType.id,
            title: this.processTranslations(jsonTourInventoryType, 'tourinventorytype', 'title'),
        }));
    }

    private parseTourDisabledModus(jsonTourDisabledModus: any): TourDisabledModus[] {
        return jsonTourDisabledModus.map((jsonTourDisabledModu): TourDisabledModus => ({
            id: jsonTourDisabledModu.id,
            tourId: jsonTourDisabledModu.tour_id,
            modusId: jsonTourDisabledModu.modus_id,
        }));
    }

    private parseTourThematics(jsonTourThematics: any): TourThematic[] {
        return jsonTourThematics.map((jsonTourThematic): TourThematic => ({
            id: jsonTourThematic.id,
            tourId: jsonTourThematic.tour_id,
            themeId: jsonTourThematic.theme_id,
        }));
    }

    private parseTree(jsonTrees: any): Tree[] {
        return jsonTrees.map((jsonTree): Tree => ({
            id: jsonTree.tree_id,
            tourId: jsonTree.tour_id,
            socialPositionId: jsonTree.tree_social_position_id,
            stemTypeId: jsonTree.tree_stem_type_id,
            typeId: jsonTree.treetype_id,
            verticalStructureId: jsonTree.vertical_structure_id,
            treenumber: +jsonTree.treenumber,
            diameter: +jsonTree.diameter,
            quality: jsonTree.quality,
            ecology: jsonTree.ecology,
            ecologyNote: +jsonTree.ecology_note,
            volume: +jsonTree.volume,
            comment: jsonTree.comment,
            position: {
                x: +jsonTree.coordinate_x,
                y: -jsonTree.coordinate_y,
            },
            width: 40,
            observation:  jsonTree.observation,
            stateId: jsonTree.state ? jsonTree.state : '0',
            reasonIds: jsonTree.reason ? jsonTree.reason.split('|') : [],
        }));
    }

    private parseTreeCorrection(jsonTreeCorrections: any): TreeCorrection[] {
        return jsonTreeCorrections.map((jsonTreeCorrection): TreeCorrection => ({
            id: jsonTreeCorrection.id,
            camera_id: jsonTreeCorrection.camera_id,
            treenumber: jsonTreeCorrection.treenumber,
            ath_correction: jsonTreeCorrection.ath_correction,
            atv_correction: jsonTreeCorrection.atv_correction,
        }));
    }

    private parseTreeSocialPositions(jsonTreeSocialPositions: any): TreeSocialPosition[] {
        return jsonTreeSocialPositions.map((jsonTreeSocialPosition): TreeSocialPosition => ({
            id: jsonTreeSocialPosition.id,
            title: this.processTranslations(jsonTreeSocialPosition, 'treesocialposition', 'title'),
        }));
    }

    private parseTreeStates(jsonTreeStates: any): TreeState[] {
        return jsonTreeStates.map((jsonTreeState): TreeState => ({
            id: jsonTreeState.id,
            title: this.processTranslations(jsonTreeState, 'status', 'title'),
            svg: jsonTreeState.svg,
        }));
    }

    private parseTreeStemTypes(jsonTreeStemTypes: any): TreeStemType[] {
        return jsonTreeStemTypes.map((jsonTreeStemType): TreeStemType => ({
            id: jsonTreeStemType.id,
            title: this.processTranslations(jsonTreeStemType, 'treestemtype', 'title'),
        }));
    }

    private parseTreeTypes(jsonTreeTypes: any): TreeType[] {
        return jsonTreeTypes.map((jsonTreeType): TreeType => ({
            id: jsonTreeType.id,
            type: this.processTranslations(jsonTreeType, 'treetype', 'type'),
            name: this.processTranslations(jsonTreeType, 'treetype', 'name'),
            url: this.processTranslations(jsonTreeType, 'treetype', 'url'),
            latinName: jsonTreeType.name_latin,
            image: jsonTreeType.image,
            shape: +jsonTreeType.shape,
            color: jsonTreeType.color,
        }));
    }

    private parseTreeVerticalStructures(jsonTreeVerticalStructures: any): TreeVerticalStructure[] {
        return jsonTreeVerticalStructures.map((jsonTreeVerticalStructure): TreeVerticalStructure => ({
            id: jsonTreeVerticalStructure.id,
            title: this.processTranslations(jsonTreeVerticalStructure, 'treeverticalstructure', 'title'),
        }));
    }

    private processTranslations(jsonEntity: any, type: string, prefix: string): string {
        const tag = type + '_' + jsonEntity.id + '_' + prefix;
        const translations = new Map([['de', jsonEntity[prefix + '_de']], ['fr', jsonEntity[prefix + '_fr']]]);

        translations.forEach((translation, language) => {
            if (this.backendService.translate !== undefined) {
                this.backendService.translate.set(tag, translation, language);
            }
        });

        return tag;
    }

    private parseThemes(jsonThemes: any): Theme[] {
        return jsonThemes.map((jsonTheme): Theme => ({
            id: jsonTheme.id,
            nameFr: jsonTheme.name_fr,
            nameDe: jsonTheme.name_de,
            nameEn: jsonTheme.name_en,
            descFr: jsonTheme.desc_fr,
            descDe: jsonTheme.desc_de,
            descEn: jsonTheme.desc_en,
            url: jsonTheme.url,
            color: jsonTheme.color,
        }));
    }

    private parseSubThemes(jsonSubThemes: any): SubTheme[] {
        return jsonSubThemes.map((jsonSubTheme): SubTheme => ({
            id: jsonSubTheme.id,
            nameFr: jsonSubTheme.name_fr,
            nameDe: jsonSubTheme.name_de,
            nameEn: jsonSubTheme.name_en,
            themeId: jsonSubTheme.theme_id,
            superThemeId: jsonSubTheme.super_theme_id,
            level: jsonSubTheme.level,
            descriptionContentId: jsonSubTheme.description_content_id,
            naturalFrequency: jsonSubTheme.natural_frequency,
            operatedFrequency: jsonSubTheme.operated_frequency,
            replacementSpeed: jsonSubTheme.replacement_speed,
            goodToKnowContentId: jsonSubTheme.good_to_know_content_id,
            associatedSpeciesContentId: jsonSubTheme.associated_species_content_id,
            inventoryLevelContentId: jsonSubTheme.inventory_level_content_id,
            otherInfoContentId: jsonSubTheme.other_info_content_id,
            iconUrl: jsonSubTheme.icon_url,
        }));
    }

    private parseSpots(jsonSpots: any): Spot[] {
        return jsonSpots.map((jsonSpot): Spot => ({
            id: jsonSpot.id,
            panoramaId: jsonSpot.panorama_id,
            contentId: jsonSpot.content_id,
            treeNumber: jsonSpot.tree_number,
            altitude: jsonSpot.altitude,
            altitudeTmp: null,
            azimuth: jsonSpot.azimuth,
            azimuthTmp: null,
            distance: jsonSpot.distance,
            subThemeId: jsonSpot.sub_theme_id,
        }));
    }

    private parseContents(jsonContents: any): Content[] {
        return jsonContents.map((jsonContent): Content => ({
            id: jsonContent.id,
            textFr: jsonContent.text_fr,
            textDe: jsonContent.text_de,
            textEn: jsonContent.text_en,
        }));
    }

    private parseMedias(jsonMedias: any): Media[] {
        return jsonMedias.map((jsonMedia): Media => ({
            id: jsonMedia.id,
            contentId: jsonMedia.content_id,
            mediaType: jsonMedia.media_type,
            mediaUrl: jsonMedia.media_url,
            hyperlink: jsonMedia.hyperlink,
            textFr: jsonMedia.text_fr,
            textDe: jsonMedia.text_de,
            textEn: jsonMedia.text_en,
        }));
    }

    private parseMediaTypes(jsonMediaTypes: any): MediaType[] {
        return jsonMediaTypes.map((jsonMediaType): MediaType => ({
            id: jsonMediaType.id,
            name: jsonMediaType.name
        }));
    }

    private parseQuestions(jsonQuestions: any): Question[] {
        return jsonQuestions.map((jsonQuestion): Question => ({
            id: jsonQuestion.id,
            subThemeId: jsonQuestion.sub_theme_id,
            contentId: jsonQuestion.content_id,
            multipleChoice: jsonQuestion.multiple_choice == 1,
            determination: jsonQuestion.determination == 1,
            explanationFr: jsonQuestion.explanation_fr,
            explanationDe: jsonQuestion.explanation_de,
            explanationEn: jsonQuestion.explanation_en,
        }));
    }

    private parseResponses(jsonResponses: any): Response[] {
        return jsonResponses.map((jsonResponse): Response => ({
            id: jsonResponse.id,
            questionId: jsonResponse.question_id,
            contentId: jsonResponse.content_id,
            correct: jsonResponse.correct == 1
        }));
    }

    private parseScores(jsonScores: any): Score[] {
        return jsonScores.map((jsonScore): Score => ({
            id: jsonScore.id,
            userId: jsonScore.user_id,
            tourId: jsonScore.tour_id,
            modeId: jsonScore.mode_id,
            spotId: jsonScore.spot_id,
        }));
    }

    private parseAnswers(jsonAnswers: any): Answer[] {
        return jsonAnswers.map((jsonAnswer): Answer => ({
            id: jsonAnswer.id,
            scoreId: jsonAnswer.score_id,
            questionId: jsonAnswer.question_id,
            correct: jsonAnswer.correct == 1
        }));
    }


}

export const effects = [
  AppEffects,
  GlobalEffects,
  LanguageEffects,
  RuntimeTourEffects,
  TreeEffects
];
