import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Tree, TreeStateId} from '../../store/tree/tree.model';
import {Panorama} from '../../store/panorama/panorama.model';
import {AngleHelper, Azimuth, Tour, TourPosition, TourShape} from '../../store/tour/tour.model';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {Side} from '../../store/global/global.model';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {
  CancelSpotEdit,
  ClearSelectedTree,
  SetSelectedPanorama,
  SetSelectedTree
} from '../../store/runtime-tour/runtime-tour.actions';
import {RealtimePanorama, View} from '../../store/realtime-panorama/realtime-panorama.model';
import {DrawService} from '../../services/draw.service';
import {PanoramaComponent} from '../panorama/panorama.component';
import {consistentUpdates, Update, updates} from '../../helper/ngrx';
import {
  selectPanoramasOfSelectedTour,
  selectSelectedPanoramaOfSelectedTour
} from '../../store/panorama/panorama.reducer';
import {selectSelectedRealtimePanoramaOfSelectedTour} from '../../store/realtime-panorama/realtime-panorama.reducer';
import {selectSelectedTreeOfSelectedTour, selectTreesOfSelectedTour} from '../../store/tree/tree.reducer';
import {selectSelectedTour} from '../../store/tour/tour.reducer';
import {TreePropertyHelper} from '../../store/tree-property/tree-property.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tree-map',
  templateUrl: './tree-map.component.html',
  styleUrls: ['./tree-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeMapComponent implements OnInit {
  @ViewChild('view', { read: false, static: false }) set view(elementRef: ElementRef) {
    this.elementRef$.next(elementRef);
  }

  tourShape = TourShape;
  treeStateId = TreeStateId;

  public TreePropertyHelper = TreePropertyHelper;
  public AzimuthHelper = AngleHelper;

  public panoramas: Panorama[] = [];
  public tour: Tour;
  public trees: Tree[] = [];

  public elementRef$ = new BehaviorSubject(null);
  public ready$ = new BehaviorSubject(false); // notifies realtime part about update of consistent part
  public selectedPanorama$: Observable<Panorama>;
  public selectedTree$: Observable<Tree>;

  scaleWidth = 20;
  scaleStep = 0;
  zoom = 1;

  xOffset: number;
  yOffset: number;
  scaleFactor: number;

  constructor(private router: Router, private store: Store<AppState>, private ref: ChangeDetectorRef, private draw: DrawService) {
  }

  ngOnInit() {
    this.selectedPanorama$ = this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left));
    this.selectedTree$ = this.store.select(selectSelectedTreeOfSelectedTour(Side.Left));

    combineLatest(
      this.store.select(selectSelectedTour(Side.Left)),
      this.store.select(selectPanoramasOfSelectedTour(Side.Left)),
      this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left)),
      this.store.select(selectTreesOfSelectedTour(Side.Left)),
    ).pipe(consistentUpdates).subscribe(combination => this.update.apply(this, combination));

    combineLatest(
      this.ready$,
      this.elementRef$,
      this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left)),
      this.store.select(selectSelectedRealtimePanoramaOfSelectedTour(Side.Left)),
    ).pipe(updates).subscribe(combination => this.updateRealtime.apply(this, combination));
  }

  update(
    tour: Update<Tour>,
    panoramas: Update<Panorama[]>,
    selectedPanorama: Update<Panorama>,
    trees: Update<Tree[]>
  ) {
    if (tour.updated && tour.value) {
      this.updateTour(tour.value);
    }

    if (!tour.value) {
      return;
    }

    if ((panoramas.updated || trees.updated) && (panoramas.value.length || trees.value.length)) {
      this.updatePanoramasAndTrees(panoramas.value, trees.value);
      this.ready$.next(true);
    }
  }

  updateRealtime(ready: Update<boolean>, viewElementRef: Update<ElementRef>, panorama: Update<Panorama>, realtimePanorama: Update<RealtimePanorama>) {
    if (!((viewElementRef.updated || panorama.updated || realtimePanorama.updated) && viewElementRef.value && panorama.value && realtimePanorama.value)) {
      return;
    }

    const view = viewElementRef.value.nativeElement;

    if (ready.value && realtimePanorama && realtimePanorama.value.view && this.scaleFactor) {
      view.setAttribute('d', this.draw.sectorPath(
        this.transformX(panorama.value.position),
        this.transformY(panorama.value.position),
        this.transformDistance(this.tour.displayDistance != null ? this.tour.displayDistance : PanoramaComponent.MAX_DISTANCE),
        {
          ...realtimePanorama.value.view,
          azimuth: this.transformAzimuth(realtimePanorama.value.view.azimuth)
        },
        0, 0, 100, 100
      ));
      view.setAttribute('hidden', false);
    } else {
      view.setAttribute('hidden', true);
    }
  }

  updateTour(tour: Tour) {
    this.tour = tour;
    this.ref.markForCheck();
  }

  updatePanoramasAndTrees(panoramas: Panorama[], trees: Tree[]) {
    this.panoramas = panoramas;
    // Sort trees by diameter to have the small ones on top of the bigger ones
    this.trees = trees;
    this.trees.sort((t1, t2) => t2.diameter - t1.diameter);

    this.updateScale(panoramas, trees);
    this.updateScaleWidth();

    this.ref.markForCheck();
  }

  updateScale(panoramas: Panorama[], trees: Tree[]) {
    const xs = trees.map(tree => tree.position.x).concat(panoramas.map(panorama => panorama.position.x));
    const ys = trees.map(tree => tree.position.y).concat(panoramas.map(panorama => panorama.position.y));

    const xMin = Math.min.apply(null, xs);
    const xMax = Math.max.apply(null, xs);
    const yMin = Math.min.apply(null, ys);
    const yMax = Math.max.apply(null, ys);

    const xDiff = xMax - xMin;
    const yDiff = yMax - yMin;

    if (xDiff > yDiff) {
      this.xOffset = -xMin;
      this.yOffset = -yMin + (xDiff - yDiff) / 2;
      this.scaleFactor = xDiff;
    } else {
      this.xOffset = -xMin + (yDiff - xDiff) / 2;
      this.yOffset = -yMin;
      this.scaleFactor = yDiff;
    }
  }

  updateScaleWidth() {
    if (this.scaleFactor >= 50) {
      this.scaleStep = Math.floor(this.scaleFactor / 50) * 5;
    } else {
      this.scaleStep = 5;
    }

    this.scaleWidth = Math.round(this.scaleStep * 100 / this.scaleFactor) * 3;
  }

  onSelectTree(tree: Tree): void {
    this.store.dispatch(new SetSelectedTree({tourId: tree.tourId, treeId: tree.id}));
    this.store.dispatch(new CancelSpotEdit());
    // this.insertParam('treeId', tree.treenumber);
    //event.preventDefault();
  }

  onSelectPanorama(panorama: Panorama): void {
    this.store.dispatch(new SetSelectedPanorama({tourId: panorama.tourId, side: Side.Left, panoramaId: panorama.id}));
    event.preventDefault();
  }

  onSelectBackground(tour: Tour): void {
    this.store.dispatch(new ClearSelectedTree());
    event.preventDefault();
  }

  transformDistance(distance: number): number {
    return distance / this.scaleFactor;
  }

  transformX(position: TourPosition): number {
    return this.transformDistance(position.x + this.xOffset);
  }

  transformY(position: TourPosition): number {
    return this.transformDistance(position.y + this.yOffset);
  }

  transformXToSvg(position: TourPosition): number {
    return this.draw.transformX(this.transformX(position), 0, 100);
  }

  transformYToSvg(position: TourPosition): number {
    return this.draw.transformY(this.transformY(position), 0, 100);
  }

  transformAzimuth(azimuth: Azimuth): Azimuth {
    return AngleHelper.subtract(azimuth, this.tour.rotate);
  }

  scale(value: number) {
    return value * this.zoom;
  }
}
