import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.reducer';
import {selectSubThemes} from '../../../store/catalog/sub-theme/sub-theme.reducer';
import {Theme} from '../../../store/catalog/theme/theme.model';
import {SubTheme} from '../../../store/catalog/sub-theme/sub-theme.model';
import {BackendService} from '../../../services/backend.service';
import {Content} from '../../../store/catalog/content/content.model';
import {selectContents} from '../../../store/catalog/content/content.reducer';
import {MatDialog} from '@angular/material';
import {TreeNode} from '../sub-theme/sub-theme.component';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {translation} from '../../../app.translation';


@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements OnInit {

  @Input() theme;
  @Input() themes: Theme[];
  subThemes: SubTheme[] = [];
  contents: Content[] = [];
  selectedTheme: SubTheme;
  selectedIndex = 0;

  modified = false;

  tree: TreeNode[] = [];

  constructor(private store: Store<AppState>, private backendService: BackendService, public dialog: MatDialog, private translate: TranslateService) {

  }

  ngOnInit() {

    this.store.select(selectContents).subscribe(contents => {
      this.contents = contents;
      this.store.select(selectSubThemes).subscribe(subThemes => {
        this.subThemes = subThemes;
      });
    });
  }

  createSubTheme() {
    // @ts-ignore
    const subTheme: SubTheme = {};
    subTheme.nameEn = 'new';
    subTheme.nameDe = 'neu';
    subTheme.nameFr = 'nouveau';
    subTheme.level = 0;
    subTheme.themeId = this.theme.id;
    subTheme.superThemeId = null;
    const newContent: Content = new Content();
    newContent.textFr = '';
    newContent.textDe = '';
    newContent.textEn = '';
    this.backendService
      .createContent(newContent)
      .subscribe( ress => {
      if (ress > 0) {
        subTheme.descriptionContentId = ress;
        newContent.id = ress;
        this.contents.push(newContent);
        const newContentt: Content = new Content();
        newContentt.textFr = '';
        newContentt.textDe = '';
        newContentt.textEn = '';
        this.backendService
          .createContent(newContentt)
          .subscribe( resss => {
          if (resss > 0) {

            subTheme.goodToKnowContentId = resss;
            newContentt.id = resss;
            this.contents.push(newContentt);
            const newContenttt: Content = new Content();
            newContenttt.textFr = '';
            newContenttt.textDe = '';
            newContenttt.textEn = '';
            this.backendService
            .createContent(newContenttt)
            .subscribe( result => {
              if (result > 0) {

                subTheme.associatedSpeciesContentId = result;
                newContenttt.id = result;
                this.contents.push(newContenttt);
                const newContentttt: Content = new Content();
                newContentttt.textFr = '';
                newContentttt.textDe = '';
                newContentttt.textEn = '';
                this.backendService
                .createContent(newContentttt)
                .subscribe( resultt => {
                  if (resultt > 0) {

                    subTheme.inventoryLevelContentId = resultt;
                    newContentttt.id = resultt;
                    this.contents.push(newContentttt);
                    const newContenttttt: Content = new Content();
                    newContenttttt.textFr = '';
                    newContenttttt.textDe = '';
                    newContenttttt.textEn = '';
                    this.backendService
                    .createContent(newContenttttt)
                    .subscribe( resulttt => {
                      if (resulttt > 0) {

                        subTheme.otherInfoContentId = resulttt;
                        newContenttttt.id = resulttt;
                        this.contents.push(newContenttttt);
                        this.backendService
                        .createSubTheme(subTheme)
                        .subscribe( res => {
                          subTheme.id = res;
                          if (res > 0) {
                            this.subThemes.push(subTheme);

                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  filterSubThemes(level) {
    return this.subThemes.filter(x => x.themeId == this.theme.id && x.level == level);
  }

  filterSubSubThemes(level, superTheme) {
    return this.subThemes.filter(x => x.themeId == this.theme.id && x.level == level && x.superThemeId == superTheme);
  }

  filterTypesThemes(level, superTheme) {
    return this.subThemes.filter(x => x.themeId == this.theme.id && x.level == level && x.superThemeId == superTheme);
  }

  async addSelectedSubTheme(treeNode, subTh) {
    this.selectedTheme = null;
    await this.delay(300);
    this.selectedTheme = treeNode;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  updateTheme() {
    this.backendService.updateTheme(this.theme).subscribe( res => {

    });
  }

  deleteTheme() {

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      panelClass: 'media-dialog-container',
      data: {ok: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.backendService.deleteTheme(this.theme).subscribe(res => {

          this.themes.splice(this.themes.indexOf(this.theme), 1);
        });
      }
    });
  }

  translateTheme(object, preString: string): string {
    return translation(object, preString, this.translate);
  }

}
