import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CallbackService {
  constructor() {
  }

  public create(callbackName: string, fct, args: any[] = []) {
    const _this = this;

    document[callbackName] = function () {
      setTimeout(function () {
        fct.apply(_this, args);
      }, 0);
    };

    return 'document.' + callbackName + '()';
  }
}
