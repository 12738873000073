import { Action } from '@ngrx/store';
import {Side} from '../global/global.model';
import {LearningMode} from './content-card.model';

export enum ContentCardActionTypes {
  MultimediaContent = '[ContentCard] Load Multimedia content',
  InfoHabitat = '[ContentCard] Load info content about habitat',
  ModeInfo = '[ContentCard] Load info content about mode',
  Grid = '[ContentCard] Load grid',
  SwitchLearningMode = '[ContentCard] Switch between different learning mode',
}

export class MultimediaContent implements Action {
  readonly type = ContentCardActionTypes.MultimediaContent;

  constructor() {
  }
}

export class ModeInfo implements Action {
  readonly type = ContentCardActionTypes.ModeInfo;

  constructor() {
  }
}

export class Grid implements Action {
  readonly type = ContentCardActionTypes.Grid;

  constructor() {
  }
}

export class InfoHabitat implements Action {
  readonly type = ContentCardActionTypes.InfoHabitat;

  constructor() {
  }
}

export class SwitchLearningMode implements Action {
  readonly type = ContentCardActionTypes.SwitchLearningMode;

  constructor(public payload: { learningMode: LearningMode}) {
  }
}

export type ContentCardActions =
    MultimediaContent |
    InfoHabitat |
    ModeInfo |
    Grid |
    SwitchLearningMode;
