import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TreeState} from './tree-state.model';
import {TreeStateActions, TreeStateActionTypes} from './tree-state.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<TreeState> {
  // additional entities state properties
}

export const adapter: EntityAdapter<TreeState> = createEntityAdapter<TreeState>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: TreeStateActions
): State {
  switch (action.type) {
    case TreeStateActionTypes.AddTreeState: {
      return adapter.addOne(action.payload.treeState, state);
    }

    case TreeStateActionTypes.UpsertTreeState: {
      return adapter.upsertOne(action.payload.treeState, state);
    }

    case TreeStateActionTypes.AddTreeStates: {
      return adapter.addMany(action.payload.treeStates, state);
    }

    case TreeStateActionTypes.UpsertTreeStates: {
      return adapter.upsertMany(action.payload.treeStates, state);
    }

    case TreeStateActionTypes.UpdateTreeState: {
      return adapter.updateOne(action.payload.treeState, state);
    }

    case TreeStateActionTypes.UpdateTreeStates: {
      return adapter.updateMany(action.payload.treeStates, state);
    }

    case TreeStateActionTypes.DeleteTreeState: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TreeStateActionTypes.DeleteTreeStates: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TreeStateActionTypes.LoadTreeStates: {
      return adapter.addAll(action.payload.treeStates, state);
    }

    case TreeStateActionTypes.ClearTreeStates: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<TreeState> => state.root.treeStates;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);
