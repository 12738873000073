import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {SubTheme} from './sub-theme.model';
import {SubThemeActions, SubThemeActionTypes} from './sub-theme.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<SubTheme> {
  // additional entities state properties
}

export const adapter: EntityAdapter<SubTheme> = createEntityAdapter<SubTheme>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: SubThemeActions
): State {
  switch (action.type) {
    case SubThemeActionTypes.AddSubTheme: {
      return adapter.addOne(action.payload.subTheme, state);
    }

    case SubThemeActionTypes.UpsertSubTheme: {
      return adapter.upsertOne(action.payload.subTheme, state);
    }

    case SubThemeActionTypes.AddSubThemes: {
      return adapter.addMany(action.payload.subThemes, state);
    }

    case SubThemeActionTypes.UpsertSubThemes: {
      return adapter.upsertMany(action.payload.subThemes, state);
    }

    case SubThemeActionTypes.UpdateSubTheme: {
      return adapter.updateOne(action.payload.subTheme, state);
    }

    case SubThemeActionTypes.UpdateSubThemes: {
      return adapter.updateMany(action.payload.subThemes, state);
    }

    case SubThemeActionTypes.DeleteSubTheme: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SubThemeActionTypes.DeleteSubThemes: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SubThemeActionTypes.LoadSubThemes: {
      return adapter.addAll(action.payload.subThemes, state);
    }

    case SubThemeActionTypes.ClearSubThemes: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<SubTheme> => state.root.subThemes;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectSubThemes = createSelector(selectAll, entities => entities);
