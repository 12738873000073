import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Tour} from '../../store/tour/tour.model';
import {Side} from '../../store/global/global.model';
import {SetFromUserAction} from '../../store/language/language.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.reducer';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {User, UserHelper} from '../../store/user/user.model';
import {selectUser} from '../../store/user/user.reducer';
import {BackendService} from '../../services/backend.service';
import {UnsetSelectedTour} from '../../store/global/global.action';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToursComponent implements OnInit{
  public Side = Side;
  public user$: Observable<User>;
  public user: User;
  public UserHelper = UserHelper;

  constructor(private _location: Location, private store: Store<AppState>, private router: Router, public translate: TranslateService, private backendService: BackendService) {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      this.user = user;
    });

    this.store.dispatch(new UnsetSelectedTour());

  }

  ngOnInit(): void {
  }

  onSelectTour(tour: Tour) {
    this._location.go('/tours/' + tour.id);
    location.reload();
  }

  setLanguage(language: string): void {
    this.store.dispatch(new SetFromUserAction(language));
  }

  logout() {
    this.backendService.logout().subscribe( res => {
      setTimeout(() => { window.location.reload(); }, 1000);
    });

  }

}
