import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Orientation, PanoramaTree} from '../../store/panorama-tree/panorama-tree.model';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {selectPanoramaTreesOfSelectedPanoramaOfSelectedTour} from '../../store/panorama-tree/panorama-tree.reducer';
import {ClearSelectedPanoramaTree, SetSelectedPanoramaTree} from '../../store/runtime-panorama/runtime-panorama.actions';
import {Side} from '../../store/global/global.model';
import {RealtimePanorama} from '../../store/realtime-panorama/realtime-panorama.model';
import {selectSelectedRealtimePanoramaOfSelectedTour} from '../../store/realtime-panorama/realtime-panorama.reducer';
import {DrawService} from '../../services/draw.service';
import {consistentUpdates, Update, updates} from '../../helper/ngrx';
import {Panorama} from '../../store/panorama/panorama.model';
import {selectSelectedPanoramaOfSelectedTour} from '../../store/panorama/panorama.reducer';
import {AngleHelper} from '../../store/tour/tour.model';

@Component({
  selector: 'app-panorama-tree-map',
  templateUrl: './panorama-tree-map.component.html',
  styleUrls: ['./panorama-tree-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanoramaTreeMapComponent implements OnInit {
  @ViewChild('view', { read: false, static: false }) viewElementRef: ElementRef;

  panorama: Panorama;
  panoramaTrees: PanoramaTree[];
  selectedPanoramaTree$: Observable<PanoramaTree>;

  maxDistance: number;

  constructor(private store: Store<AppState>, private ref: ChangeDetectorRef, private draw: DrawService) {
  }

  ngOnInit() {
    combineLatest(
      this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left)),
      this.store.select(selectPanoramaTreesOfSelectedPanoramaOfSelectedTour(Side.Left)),
    ).pipe(consistentUpdates).subscribe(combination => this.update.apply(this, combination));

    combineLatest(
      this.store.select(selectSelectedRealtimePanoramaOfSelectedTour(Side.Left)),
    ).pipe(updates).subscribe(combination => this.updateRealtimePanorama.apply(this, combination));
  }

  private update(panorama: Update<Panorama>, panoramaTrees: Update<PanoramaTree[]>) {
    this.panorama = panorama.value;

    if (panoramaTrees.updated) {
      this.updatePanoramaTrees(panoramaTrees.value);
    }
  }

  private updateRealtimePanorama(realtimePanorama: Update<RealtimePanorama>): void {
    if (!(realtimePanorama.updated && realtimePanorama.value)) {
      return;
    }

    const line = this.viewElementRef.nativeElement;
    if (realtimePanorama && realtimePanorama.value.view) {
      line.setAttribute('hidden', false);
      line.setAttribute('d', this.draw.sectorPath(0, 0, 0.5, realtimePanorama.value.view, -100, -100, 100, 100));
    } else {
      line.setAttribute('hidden', true);
    }

    this.ref.markForCheck();
  }

  private updatePanoramaTrees(panoramaTrees): void {
    this.panoramaTrees = panoramaTrees;
    this.calculateMaxDistance(panoramaTrees);

    this.ref.markForCheck();
  }

  private calculateMaxDistance(panoramaTrees): void {
    const distances = panoramaTrees.map(panoramaTree => panoramaTree.distance).filter(distance => distance);

    if (distances.length) {
      this.maxDistance = Math.max.apply(null, distances);
    } else {
      this.maxDistance = 1;
    }
  }

  getLineX(orientation: Orientation): number {
    return this.draw.transformX(AngleHelper.getX(orientation.azimuth), -100, 100) / 2;
  }

  getLineY(orientation: Orientation): number {
    return this.draw.transformY(AngleHelper.getY(orientation.azimuth), -100, 100) / 2;
  }

  getDistance(panoramaTree: PanoramaTree): number {
    return panoramaTree.distance ? panoramaTree.distance / this.maxDistance : 1;
  }

  // noinspection JSMethodCanBeStatic
  onSelectTree(panoramaTree: PanoramaTree): void {
    this.store.dispatch(new SetSelectedPanoramaTree({panoramaId: panoramaTree.panoramaId, panoramaTreeId: panoramaTree.id}));
  }

  onSelectBackground(): void {
    this.store.dispatch(new ClearSelectedPanoramaTree({panoramaId: this.panorama.id}));
  }
}
