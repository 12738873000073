import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Content } from './content.model';

export enum ContentActionTypes {
  LoadContents = '[Content] Load Contents',
  AddContent = '[Content] Add Content',
  UpsertContent = '[Content] Upsert Content',
  AddContents = '[Content] Add Contents',
  UpsertContents = '[Content] Upsert Contents',
  UpdateContent = '[Content] Update Content',
  UpdateContents = '[Content] Update Contents',
  DeleteContent = '[Content] Delete Content',
  DeleteContents = '[Content] Delete Contents',
  ClearContents = '[Content] Clear Contents'
}

export class LoadContents implements Action {
  readonly type = ContentActionTypes.LoadContents;

  constructor(public payload: { contents: Content[] }) {}
}

export class AddContent implements Action {
  readonly type = ContentActionTypes.AddContent;

  constructor(public payload: { content: Content }) {}
}

export class UpsertContent implements Action {
  readonly type = ContentActionTypes.UpsertContent;

  constructor(public payload: { content: Content }) {}
}

export class AddContents implements Action {
  readonly type = ContentActionTypes.AddContents;

  constructor(public payload: { contents: Content[] }) {}
}

export class UpsertContents implements Action {
  readonly type = ContentActionTypes.UpsertContents;

  constructor(public payload: { contents: Content[] }) {}
}

export class UpdateContent implements Action {
  readonly type = ContentActionTypes.UpdateContent;

  constructor(public payload: { content: Update<Content> }) {}
}

export class UpdateContents implements Action {
  readonly type = ContentActionTypes.UpdateContents;

  constructor(public payload: { contents: Update<Content>[] }) {}
}

export class DeleteContent implements Action {
  readonly type = ContentActionTypes.DeleteContent;

  constructor(public payload: { id: string }) {}
}

export class DeleteContents implements Action {
  readonly type = ContentActionTypes.DeleteContents;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearContents implements Action {
  readonly type = ContentActionTypes.ClearContents;
}

export type ContentActions =
 LoadContents
 | AddContent
 | UpsertContent
 | AddContents
 | UpsertContents
 | UpdateContent
 | UpdateContents
 | DeleteContent
 | DeleteContents
 | ClearContents;
