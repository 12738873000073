import {Action} from '@ngrx/store';

export enum RuntimePanoramaActionTypes {
  SetSelectedPanoramaTree = '[RuntimePanorama] SetSelectedPanoramaTree',
  ClearSelectedPanoramaTree = '[RuntimePanorama] ClearSelectedPanoramaTree',
}

export class SetSelectedPanoramaTree implements Action {
  readonly type = RuntimePanoramaActionTypes.SetSelectedPanoramaTree;

  constructor(public payload: { panoramaId: string; panoramaTreeId: string }) {
  }
}

export class ClearSelectedPanoramaTree implements Action {
  readonly type = RuntimePanoramaActionTypes.ClearSelectedPanoramaTree;

  constructor(public payload: { panoramaId: string }) {
  }
}

export type RuntimePanoramaActions =
  SetSelectedPanoramaTree |
  ClearSelectedPanoramaTree;
