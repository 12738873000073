import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Response} from './response.model';
import {ResponseActions, ResponseActionTypes} from './response.actions';
import {createSelector} from '@ngrx/store';

export interface State extends EntityState<Response> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Response> = createEntityAdapter<Response>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: ResponseActions
): State {
  switch (action.type) {
    case ResponseActionTypes.AddResponse: {
      return adapter.addOne(action.payload.response, state);
    }

    case ResponseActionTypes.UpsertResponse: {
      return adapter.upsertOne(action.payload.response, state);
    }

    case ResponseActionTypes.AddResponses: {
      return adapter.addMany(action.payload.responses, state);
    }

    case ResponseActionTypes.UpsertResponses: {
      return adapter.upsertMany(action.payload.responses, state);
    }

    case ResponseActionTypes.UpdateResponse: {
      return adapter.updateOne(action.payload.response, state);
    }

    case ResponseActionTypes.UpdateResponses: {
      return adapter.updateMany(action.payload.responses, state);
    }

    case ResponseActionTypes.DeleteResponse: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ResponseActionTypes.DeleteResponses: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ResponseActionTypes.LoadResponses: {
      return adapter.addAll(action.payload.responses, state);
    }

    case ResponseActionTypes.ClearResponses: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<Response> => state.root.responses;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities) => entities[id]
);

export const selectResponses = createSelector(selectAll, entities => entities);
