import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {TreeState} from '../../store/tree-state/tree-state.model';
import * as fromTreeStates from '../../store/tree-state/tree-state.reducer';
import {Reason} from '../../store/reason/reason.model';
import {selectSelectedTreeOfSelectedTour} from '../../store/tree/tree.reducer';
import {Side} from '../../store/global/global.model';
import {Tree} from '../../store/tree/tree.model';
import {SetTreeState, ToggleTreeReason, SetTreeComment} from '../../store/tree/tree.actions';
import {selectReasonsByTreeState, selectSelectedReasonsByTreeState} from '../../store/reason/reason.reducer';
import { selectSelectedTour } from 'src/app/store/tour/tour.reducer';
import { selectTourDisabledModus } from 'src/app/store/tour-disabled-modus/tour-disabled-modus.reducer';
import { TourDisabledModus } from 'src/app/store/tour-disabled-modus/tour-disabled-modus.model';
import { Tour } from 'src/app/store/tour/tour.model';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-status-panel',
  templateUrl: './status-panel.component.html',
  styleUrls: ['./status-panel.component.css'],
//  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPanelComponent implements OnInit {
  public treeStates$: Observable<TreeState[]>;
  public allReasons$: Observable<Reason[]>;
  public selectedReasons$: Observable<Reason[]>;
  public selectedTree: Tree = null;

  private tourDisabledModus: TourDisabledModus[];
  private selectedTour: Tour;

  public copyState: string;
  public copyReasonIds: string[] = [];
  public copyComment: string;

  constructor(private store: Store<AppState>, public translate: TranslateService, private ref: ChangeDetectorRef, private backendService: BackendService) {
  }

  ngOnInit() {
    this.treeStates$ = this.store.select(fromTreeStates.selectAll);
    this.allReasons$ = this.store.select(selectReasonsByTreeState);
    this.selectedReasons$ = this.store.select(selectSelectedReasonsByTreeState);

    this.store.select(selectSelectedTreeOfSelectedTour(Side.Left)).subscribe((selectedTree) => {
      this.selectedTree = selectedTree;
      if (this.copyState == null) {
        this.copyState = selectedTree.stateId;
        selectedTree.reasonIds.forEach(x => {
          this.copyReasonIds.push(x);
        });
        this.copyComment = selectedTree.comment;
      }
    });

    this.store.select(selectSelectedTour(Side.Left)).subscribe((tour) => {
      this.selectedTour = tour;
    });

    this.store.select(selectTourDisabledModus).subscribe((tourDisabledModus) => {
      this.tourDisabledModus = tourDisabledModus.filter(x => x.tourId === +this.selectedTour.id);
    });
  }

  isMode4Available() {
    return this.tourDisabledModus.find(x => x.modusId === 4) == null;
  }

  onSelectState(event: Event, state: TreeState) {
    this.store.dispatch(new SetTreeState({treeId: this.selectedTree.id, stateId: state.id}));

    this.ref.markForCheck();
  }

  isStateChecked(state: TreeState): boolean {
    return this.selectedTree.stateId === state.id;
  }

  onToggleReason(event: Event, reason: Reason) {
    this.store.dispatch(new ToggleTreeReason({treeId: this.selectedTree.id, reasonId: reason.id}));

    this.ref.markForCheck();
  }

  isReasonChecked(reason: Reason): boolean {
    return this.selectedTree.reasonIds.includes(reason.id);
  }

  onCommentChange(text: string) {
    this.store.dispatch(new SetTreeComment({treeId: this.selectedTree.id, comment: text}));
  }

  save() {
    this.backendService.setTreeState(this.selectedTree).subscribe((res) => {

      if (this.copyState != null && this.copyState !== '') {
        this.selectedTour.statusCount[+this.copyState] -= 1;
      }

      this.selectedTour.statusCount[+this.selectedTree.stateId] += 1;

      this.copyState = this.selectedTree.stateId;
      this.selectedTree.reasonIds.forEach(x => {
        this.copyReasonIds.push(x);
      });
      this.copyComment = this.selectedTree.comment;
    });
  }

  cancel() {
    this.store.dispatch(new SetTreeState({treeId: this.selectedTree.id, stateId: this.copyState}));
    this.ref.markForCheck();

    this.selectedTree.reasonIds.forEach( x => {
      this.store.dispatch(new ToggleTreeReason({treeId: this.selectedTree.id, reasonId: x}));
      this.ref.markForCheck();
    });
    this.copyReasonIds.forEach( x => {
      this.store.dispatch(new ToggleTreeReason({treeId: this.selectedTree.id, reasonId: x}));
      this.ref.markForCheck();
    });

    this.store.dispatch(new SetTreeComment({treeId: this.selectedTree.id, comment: this.copyComment}));
  }

}
