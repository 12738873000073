import * as fromUser from './user/user.reducer';
import {UserState} from './user/user.reducer';
import * as fromGlobal from '../store/global/global.reducer';
import * as fromContentCard from '../store/habitat/content-card.reducer';
import * as fromAllPanorama from '../store/all-panorama/all-panorama.reducer';
import * as fromPanorama from '../store/panorama/panorama.reducer';
import * as fromPanoramaTree from '../store/panorama-tree/panorama-tree.reducer';
import * as fromReason from '../store/reason/reason.reducer';
import * as fromSpot from '../store/spot/spot.reducer';
import * as fromTheme from '../store/catalog/theme/theme.reducer';
import * as fromScore from '../store/score/score.reducer';
import * as fromAnswer from '../store/answer/answer.reducer';
import * as fromSubTheme from '../store/catalog/sub-theme/sub-theme.reducer';
import * as fromContent from '../store/catalog/content/content.reducer';
import * as fromMedia from '../store/catalog/media/media.reducer';
import * as fromMediaType from '../store/catalog/media-type/media-type.reducer';
import * as fromQuestion from '../store/catalog/question/question.reducer';
import * as fromResponse from '../store/catalog/response/response.reducer';
import * as fromRealtimePanorama from '../store/realtime-panorama/realtime-panorama.reducer';
import * as fromRuntimePanorama from '../store/runtime-panorama/runtime-panorama.reducer';
import * as fromRuntimeTour from '../store/runtime-tour/runtime-tour.reducer';
import * as fromTour from '../store/tour/tour.reducer';
import * as fromTourCanopyCoverDegree from '../store/tour-canopy-cover-degree/tour-canopy-cover-degree.reducer';
import * as fromTourCategory from '../store/tour-category/tour-category.reducer';
import * as fromTourDevelopmentalStage from '../store/tour-developmental-stage/tour-developmental-stage.reducer';
import * as fromTourInventoryType from '../store/tour-inventory-type/tour-inventory-type.reducer';
import * as fromTourDisabledModus from '../store/tour-disabled-modus/tour-disabled-modus.reducer';
import * as fromTourThematic from '../store/tour-thematic/tour-thematic.reducer';
import * as fromTree from '../store/tree/tree.reducer';
import * as fromTreeCorrection from '../store/tree-correction/tree-correction.reducer';
import * as fromTreeSocialPosition from '../store/tree-social-position/tree-social-position.reducer';
import * as fromTreeState from '../store/tree-state/tree-state.reducer';
import * as fromTreeStemType from './tree-stem-type/tree-stem-type.reducer';
import * as fromTreeType from './tree-type/tree-type.reducer';
import * as fromTreeVerticalStructure from '../store/tree-vertical-structure/tree-vertical-structure.reducer';
import {Global} from './global/global.model';
import {ContentCard} from './habitat/content-card.model';

export interface AppState {
  global: Global;
  contentCard: ContentCard;
  allPanoramas: fromAllPanorama.State;
  panoramas: fromPanorama.State;
  panoramaTrees: fromPanoramaTree.State;
  reasons: fromReason.State;
  realtimePanoramas: fromRealtimePanorama.State;
  runtimePanoramas: fromRuntimePanorama.State;
  runtimeTours: fromRuntimeTour.State;
  tours: fromTour.State;
  tourCanopyCoverDegrees: fromTourCanopyCoverDegree.State;
  tourCategories: fromTourCategory.State;
  tourDevelopmentalStages: fromTourDevelopmentalStage.State;
  tourInventoryTypes: fromTourInventoryType.State;
  tourDisabledModus: fromTourDisabledModus.State;
  tourThematics: fromTourThematic.State;
  trees: fromTree.State;
  treeCorrections: fromTreeCorrection.State;
  treeSocialPositions: fromTreeSocialPosition.State;
  treeStates: fromTreeState.State;
  treeStemTypes: fromTreeStemType.State;
  treeTypes: fromTreeType.State;
  treeVerticalStructures: fromTreeVerticalStructure.State;
  user: UserState;
  spots: fromSpot.State;
  themes: fromTheme.State;
  scores: fromScore.State;
  answers: fromAnswer.State;
  subThemes: fromSubTheme.State;
  contents: fromContent.State;
  medias: fromMedia.State;
  mediaTypes: fromMediaType.State;
  questions: fromQuestion.State;
  responses: fromResponse.State;
}

export const appInitialState: AppState = {
  global: fromGlobal.initialState,
  contentCard: fromContentCard.initialState,
  allPanoramas: fromAllPanorama.initialState,
  panoramas: fromPanorama.initialState,
  panoramaTrees: fromPanoramaTree.initialState,
  reasons: fromReason.initialState,
  realtimePanoramas: fromRealtimePanorama.initialState,
  runtimePanoramas: fromRuntimePanorama.initialState,
  runtimeTours: fromRuntimeTour.initialState,
  tours: fromTour.initialState,
  tourCanopyCoverDegrees: fromTourCanopyCoverDegree.initialState,
  tourCategories: fromTourCategory.initialState,
  tourDevelopmentalStages: fromTourDevelopmentalStage.initialState,
  tourInventoryTypes: fromTourInventoryType.initialState,
  tourDisabledModus: fromTourDisabledModus.initialState,
  tourThematics: fromTourThematic.initialState,
  trees: fromTree.initialState,
  treeCorrections: fromTreeCorrection.initialState,
  treeSocialPositions: fromTreeSocialPosition.initialState,
  treeStates: fromTreeState.initialState,
  treeStemTypes: fromTreeStemType.initialState,
  treeTypes: fromTreeType.initialState,
  treeVerticalStructures: fromTreeVerticalStructure.initialState,
  user: fromUser.initialState,
  spots: fromSpot.initialState,
  themes: fromTheme.initialState,
  scores: fromScore.initialState,
  answers: fromAnswer.initialState,
  subThemes: fromSubTheme.initialState,
  contents: fromContent.initialState,
  medias: fromMedia.initialState,
  mediaTypes: fromMediaType.initialState,
  questions: fromQuestion.initialState,
  responses: fromResponse.initialState,
};

export function appReducer(state: AppState = appInitialState, action: any): AppState {

  const root = {root: state};

  return {
    global: fromGlobal.reducer(state.global, action),
    contentCard: fromContentCard.reducer(state.contentCard, action),
    allPanoramas: fromAllPanorama.reducer(state.allPanoramas, action),
    panoramas: fromPanorama.reducer(state.panoramas, action),
    panoramaTrees: fromPanoramaTree.reducer(state.panoramaTrees, action),
    reasons: fromReason.reducer(state.reasons, action),
    realtimePanoramas: fromRealtimePanorama.reducer(state.realtimePanoramas, action, root),
    runtimePanoramas: fromRuntimePanorama.reducer(state.runtimePanoramas, action),
    runtimeTours: fromRuntimeTour.reducer(state.runtimeTours, action, root),
    tours: fromTour.reducer(state.tours, action),
    tourCanopyCoverDegrees: fromTourCanopyCoverDegree.reducer(state.tourCanopyCoverDegrees, action),
    tourCategories: fromTourCategory.reducer(state.tourCategories, action),
    tourDevelopmentalStages: fromTourDevelopmentalStage.reducer(state.tourDevelopmentalStages, action),
    tourInventoryTypes: fromTourInventoryType.reducer(state.tourInventoryTypes, action),
    tourDisabledModus: fromTourDisabledModus.reducer(state.tourDisabledModus, action),
    tourThematics: fromTourThematic.reducer(state.tourThematics, action),
    trees: fromTree.reducer(state.trees, action, root),
    treeCorrections: fromTreeCorrection.reducer(state.treeCorrections, action),
    treeSocialPositions: fromTreeSocialPosition.reducer(state.treeSocialPositions, action),
    treeStates: fromTreeState.reducer(state.treeStates, action),
    treeStemTypes: fromTreeStemType.reducer(state.treeStemTypes, action),
    treeTypes: fromTreeType.reducer(state.treeTypes, action),
    treeVerticalStructures: fromTreeVerticalStructure.reducer(state.treeVerticalStructures, action),
    user: fromUser.userReducer(state.user, action),
    spots: fromSpot.reducer(state.spots, action),
    themes: fromTheme.reducer(state.themes, action),
    scores: fromScore.reducer(state.scores, action),
    answers: fromAnswer.reducer(state.answers, action),
    subThemes: fromSubTheme.reducer(state.subThemes, action),
    contents: fromContent.reducer(state.contents, action),
    medias: fromMedia.reducer(state.medias, action),
    mediaTypes: fromMediaType.reducer(state.mediaTypes, action),
    questions: fromQuestion.reducer(state.questions, action),
    responses: fromResponse.reducer(state.responses, action),
  };
}
