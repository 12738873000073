import {Injectable} from '@angular/core';
import {View} from '../store/realtime-panorama/realtime-panorama.model';
import {AngleHelper} from '../store/tour/tour.model';

@Injectable({
  providedIn: 'root'
})
export class DrawService {

  constructor() {
  }

  public sectorPath(x: number, y: number, radius: number, view: View, xStart: number, yStart: number, xEnd: number, yEnd: number) {
    const halfZoom = AngleHelper.divide(view.zoom, 2);
    const x1 = this.transformX(x + AngleHelper.getX(AngleHelper.add(view.azimuth, halfZoom)) * radius, xStart, xEnd);
    const y1 = this.transformY(y + AngleHelper.getY(AngleHelper.add(view.azimuth, halfZoom)) * radius, yStart, yEnd);
    const x2 = this.transformX(x + AngleHelper.getX(AngleHelper.subtract(view.azimuth, halfZoom)) * radius, xStart, xEnd);
    const y2 = this.transformY(y + AngleHelper.getY(AngleHelper.subtract(view.azimuth, halfZoom)) * radius, yStart, yEnd);
    const rx = this.scaleX(radius, xStart, xEnd);
    const ry = this.scaleY(radius, yStart, yEnd);
    const cx = this.transformX(x, xStart, xEnd);
    const cy = this.transformY(y, yStart, yEnd);

    return 'M' + cx + ',' + cy + ',' + x1 + ',' + y1 + 'A' + rx + ',' + ry + ',0,0,1,' + x2 + ',' + y2 + 'Z';
  }

  public scaleX(x: number, xStart: number, xEnd: number): number {
    return x * this.getWidth(xStart, xEnd);
  }

  public scaleY(y: number, yStart: number, yEnd: number): number {
    return y * this.getHeight(yStart, yEnd);
  }

  public transformX(x: number, xStart: number, xEnd: number): number {
    return this.scaleX(x, xStart, xEnd);
  }

  public transformY(y: number, yStart: number, yEnd: number): number {
    return yStart + yEnd - this.scaleY(y, yStart, yEnd);
  }

  public getWidth(xStart: number, xEnd: number): number {
    return xEnd - xStart;
  }

  public getHeight(yStart: number, yEnd: number): number {
    return yEnd - yStart;
  }
}
