import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import {AllPanorama} from './all-panorama.model';
import {AllPanoramaActions, AllPanoramaActionTypes} from './all-panorama.actions';
import {createSelector} from '@ngrx/store';
import {Side} from '../global/global.model';
import * as fromRuntimeTour from '../runtime-tour/runtime-tour.reducer';
import * as fromTour from '../tour/tour.reducer';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';

export interface State extends EntityState<AllPanorama> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AllPanorama> = createEntityAdapter<AllPanorama>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: AllPanoramaActions
): State {
  switch (action.type) {
    case AllPanoramaActionTypes.AddAllPanorama: {
      return adapter.addOne(action.payload.panorama, state);
    }

    case AllPanoramaActionTypes.UpsertAllPanorama: {
      return adapter.upsertOne(action.payload.panorama, state);
    }

    case AllPanoramaActionTypes.AddAllPanoramas: {
      return adapter.addMany(action.payload.panoramas, state);
    }

    case AllPanoramaActionTypes.UpsertAllPanoramas: {
      return adapter.upsertMany(action.payload.panoramas, state);
    }

    case AllPanoramaActionTypes.UpdateAllPanorama: {
      return adapter.updateOne(action.payload.panorama, state);
    }

    case AllPanoramaActionTypes.UpdateAllPanoramas: {
      return adapter.updateMany(action.payload.panoramas, state);
    }

    case AllPanoramaActionTypes.DeleteAllPanorama: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AllPanoramaActionTypes.DeleteAllPanoramas: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case AllPanoramaActionTypes.LoadAllPanoramas: {
      return adapter.addAll(action.payload.panoramas, state);
    }

    case AllPanoramaActionTypes.ClearAllPanoramas: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<AllPanorama> => state.root.allPanoramas;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);
