import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TreeVerticalStructure } from './tree-vertical-structure.model';

export enum TreeVerticalStructureActionTypes {
  LoadTreeVerticalStructures = '[TreeVerticalStructure] Load TreeVerticalStructures',
  AddTreeVerticalStructure = '[TreeVerticalStructure] Add TreeVerticalStructure',
  UpsertTreeVerticalStructure = '[TreeVerticalStructure] Upsert TreeVerticalStructure',
  AddTreeVerticalStructures = '[TreeVerticalStructure] Add TreeVerticalStructures',
  UpsertTreeVerticalStructures = '[TreeVerticalStructure] Upsert TreeVerticalStructures',
  UpdateTreeVerticalStructure = '[TreeVerticalStructure] Update TreeVerticalStructure',
  UpdateTreeVerticalStructures = '[TreeVerticalStructure] Update TreeVerticalStructures',
  DeleteTreeVerticalStructure = '[TreeVerticalStructure] Delete TreeVerticalStructure',
  DeleteTreeVerticalStructures = '[TreeVerticalStructure] Delete TreeVerticalStructures',
  ClearTreeVerticalStructures = '[TreeVerticalStructure] Clear TreeVerticalStructures'
}

export class LoadTreeVerticalStructures implements Action {
  readonly type = TreeVerticalStructureActionTypes.LoadTreeVerticalStructures;

  constructor(public payload: { treeVerticalStructures: TreeVerticalStructure[] }) {}
}

export class AddTreeVerticalStructure implements Action {
  readonly type = TreeVerticalStructureActionTypes.AddTreeVerticalStructure;

  constructor(public payload: { treeVerticalStructure: TreeVerticalStructure }) {}
}

export class UpsertTreeVerticalStructure implements Action {
  readonly type = TreeVerticalStructureActionTypes.UpsertTreeVerticalStructure;

  constructor(public payload: { treeVerticalStructure: TreeVerticalStructure }) {}
}

export class AddTreeVerticalStructures implements Action {
  readonly type = TreeVerticalStructureActionTypes.AddTreeVerticalStructures;

  constructor(public payload: { treeVerticalStructures: TreeVerticalStructure[] }) {}
}

export class UpsertTreeVerticalStructures implements Action {
  readonly type = TreeVerticalStructureActionTypes.UpsertTreeVerticalStructures;

  constructor(public payload: { treeVerticalStructures: TreeVerticalStructure[] }) {}
}

export class UpdateTreeVerticalStructure implements Action {
  readonly type = TreeVerticalStructureActionTypes.UpdateTreeVerticalStructure;

  constructor(public payload: { treeVerticalStructure: Update<TreeVerticalStructure> }) {}
}

export class UpdateTreeVerticalStructures implements Action {
  readonly type = TreeVerticalStructureActionTypes.UpdateTreeVerticalStructures;

  constructor(public payload: { treeVerticalStructures: Update<TreeVerticalStructure>[] }) {}
}

export class DeleteTreeVerticalStructure implements Action {
  readonly type = TreeVerticalStructureActionTypes.DeleteTreeVerticalStructure;

  constructor(public payload: { id: string }) {}
}

export class DeleteTreeVerticalStructures implements Action {
  readonly type = TreeVerticalStructureActionTypes.DeleteTreeVerticalStructures;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearTreeVerticalStructures implements Action {
  readonly type = TreeVerticalStructureActionTypes.ClearTreeVerticalStructures;
}

export type TreeVerticalStructureActions =
 LoadTreeVerticalStructures
 | AddTreeVerticalStructure
 | UpsertTreeVerticalStructure
 | AddTreeVerticalStructures
 | UpsertTreeVerticalStructures
 | UpdateTreeVerticalStructure
 | UpdateTreeVerticalStructures
 | DeleteTreeVerticalStructure
 | DeleteTreeVerticalStructures
 | ClearTreeVerticalStructures;
