import {TreeProperty} from '../tree-property/tree-property.model';
import {Angle, AngleHelper, Azimuth} from '../tour/tour.model';

export interface Orientation {
  azimuth: Azimuth;
  altitude: Angle;
}

export interface OrientationOffset {
  azimuth: Angle;
  altitude: Angle;
}

export namespace OrientationHelper {
  export function equals(orientation1: Orientation, orientation2: Orientation) {
    return orientation1.azimuth === orientation2.azimuth && orientation1.altitude === orientation2.altitude;
  }

  export function difference(orientation1: Orientation, orientation2: Orientation): OrientationOffset {
    return {
      azimuth: AngleHelper.difference(orientation1.azimuth, orientation2.azimuth),
      altitude: AngleHelper.difference(orientation1.altitude, orientation2.altitude),
    };
  }

  export function add(orientation: Orientation, offset: OrientationOffset): Orientation {
    return {
      azimuth: AngleHelper.add(orientation.azimuth, offset.azimuth),
      altitude: AngleHelper.add(orientation.altitude, offset.altitude),
    };
  }

  export function subtract(orientation: Orientation, offset: OrientationOffset): OrientationOffset {
    return {
      azimuth: AngleHelper.subtract(orientation.azimuth, offset.azimuth),
      altitude: AngleHelper.subtract(orientation.altitude, offset.altitude),
    };
  }
}

export interface PanoramaTree extends TreeProperty {
  panoramaId: string;
  orientation: Orientation;
  distance: number;
}
