import {Injectable} from '@angular/core';
import {XHRService} from './xhr.service';
import {AngleHelper, positionFromString, Tour, TourPosition} from '../store/tour/tour.model';
import {AppState} from '../store/app.reducer';
import {Store} from '@ngrx/store';
import {AddPanoramaTrees, ClearPanoramaTrees} from '../store/panorama-tree/panorama-tree.actions';
import {AddTreeCorrections, ClearTreeCorrections} from '../store/tree-correction/tree-correction.actions';
import {AddTrees, ClearTrees} from '../store/tree/tree.actions';
import {TreeCorrection} from '../store/tree-correction/tree-correction.model';
import {Tree} from '../store/tree/tree.model';
import {PanoramaTree} from '../store/panorama-tree/panorama-tree.model';
import {Panorama} from '../store/panorama/panorama.model';
import {AddPanoramas} from '../store/panorama/panorama.actions';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {Theme} from '../store/catalog/theme/theme.model';
import {SubTheme} from '../store/catalog/sub-theme/sub-theme.model';
import {Content} from '../store/catalog/content/content.model';
import {Media} from '../store/catalog/media/media.model';
import {Question} from '../store/catalog/question/question.model';
import {Response} from '../store/catalog/response/response.model';
import {Spot} from '../store/spot/spot.model';
import {TourDisabledModus} from '../store/tour-disabled-modus/tour-disabled-modus.model';
import {TourThematic} from '../store/tour-thematic/tour-thematic.model';
import {Score} from '../store/score/score.model';
import {Answer} from '../store/answer/answer.model';

@Injectable()
export class BackendService {
  // public static readonly API_URL = 'http://localhost:4200';
  //public static readonly API_URL = 'http://test.sylvotheque.ch';
  public static readonly API_URL = 'http://demo.habitat.sylvotheque.ch';
  public static readonly BASE_URL = BackendService.API_URL + '/api';
  public static readonly THEMATIC = 12;
  public static readonly COMMON = 'common';
  public static readonly CATALOG = 'catalog';
  public static readonly TOUR = 'tour';

  constructor(public xhrService: XHRService, public store: Store<AppState>, public translate: TranslateService) {
  }

  getApiUrl(): string {
    return BackendService.API_URL;
  }

  getUrl(path: string): string {
    return BackendService.BASE_URL + '/' + path;
  }

  setLanguage(language: string): Observable<string> {
    // language can be set by a dummy call to /api/test
    const url = this.getUrl('test?lang=' + language);
    this.xhrService.get(url).subscribe( res => {
      return res;
    });

    return null;
  }

  logout(): Observable<string> {
    // language can be set by a dummy call to /api/test
    const url = this.getUrl('logout');
    return this.xhrService.get(url);
  }

  setTreeState(tree: Tree): any {
    const data = {
      tour_id: tree.tourId,
      treenumber: tree.treenumber,
      state: tree.stateId,
      reason: tree.reasonIds,
      comment: tree.comment ? tree.comment : ''
    };

    return this.xhrService.post(this.getUrl('state/'), data);
  }

  createTreeCorrection(treeCorrection: TreeCorrection): any {
    const data = {
      camera_id: treeCorrection.camera_id,
      treenumber: treeCorrection.treenumber,
      ath_correction: treeCorrection.ath_correction,
      atv_correction: treeCorrection.atv_correction,
    };
    return this.xhrService.post(this.getUrl('createTreeCorrection/'), data);
  }

  updateTreeCorrection(treeCorrection: TreeCorrection): any {
    const data = {
      id: treeCorrection.id,
      camera_id: treeCorrection.camera_id,
      treenumber: treeCorrection.treenumber,
      ath_correction: treeCorrection.ath_correction,
      atv_correction: treeCorrection.atv_correction,
    };
    return this.xhrService.post(this.getUrl('updateTreeCorrection/'), data);
  }

  updatePanorama(panorama: Panorama): any {
    const data = {
      id: panorama.id,
      ath_correction: panorama.athCorrection,
    };
    return this.xhrService.post(this.getUrl('updatePanorama/'), data);
  }

  createScore(score: Score): any {
    const data = {
      user_id: score.userId,
      tour_id: score.tourId,
      mode_id: score.modeId,
      spot_id: score.spotId,
    };
    return this.xhrService.post(this.getUrl('createScore/'), data);
  }

  deleteScore(score: Score): any {
    const data = {
      id: score.id,
    };
    return this.xhrService.post(this.getUrl('deleteScore/'), data);
  }

  createAnswer(answer: Answer): any {
    const data = {
      score_id: answer.scoreId,
      question_id: answer.questionId,
      correct: answer.correct ? 1 : 0,
    };
    return this.xhrService.post(this.getUrl('createAnswer/'), data);
  }

  getCommon(): Observable<any> {
    const url = this.getUrl(BackendService.COMMON);
    const res = this.xhrService.get(url);
    return res;
  }

  createTourDisabledModus(tourDisabledModus: TourDisabledModus): any {
    const data = {
      tour_id: tourDisabledModus.tourId,
      modus_id: tourDisabledModus.modusId,
    };
    return this.xhrService.post(this.getUrl('createTourDisabledModus/'), data);
  }

  deleteTourDisabledModus(tourDisabledModus: TourDisabledModus): any {
    const data = {
      id: tourDisabledModus.id,
    };
    return this.xhrService.post(this.getUrl('deleteTourDisabledModus/'), data);
  }

  createTourThematic(tourThematic: TourThematic): any {
    const data = {
      tour_id: tourThematic.tourId,
      theme_id: tourThematic.themeId,
    };
    return this.xhrService.post(this.getUrl('createTourThematic/'), data);
  }

  updateTourThematic(tourThematic: TourThematic): any {
    const data = {
      id: tourThematic.id,
      tour_id: tourThematic.tourId,
      theme_id: tourThematic.themeId,
    };
    return this.xhrService.post(this.getUrl('updateTourThematic/'), data);
  }

  createTheme(theme: Theme): any {
    const data = {
      name_fr: theme.nameFr,
      name_de: theme.nameDe,
      name_en: theme.nameEn,
      desc_fr: theme.descFr,
      desc_de: theme.descDe,
      desc_en: theme.descEn,
      url: theme.url,
      color: theme.color
    };
    return this.xhrService.post(this.getUrl('createTheme/'), data);
  }

  updateTheme(theme: Theme): any {
    const data = {
      id: theme.id,
      name_fr: theme.nameFr,
      name_de: theme.nameDe,
      name_en: theme.nameEn,
      desc_fr: theme.descFr,
      desc_de: theme.descDe,
      desc_en: theme.descEn,
      url: theme.url,
      color: theme.color
    };
    return this.xhrService.post(this.getUrl('updateTheme/'), data);
  }

  deleteTheme(theme: Theme): any {
    const data = {
      id: theme.id,
    };
    return this.xhrService.post(this.getUrl('deleteTheme/'), data);
  }

  createSpot(spot: Spot): any {
    const data = {
      id: +spot.id,
      panorama_id: +spot.panoramaId,
      tree_number: spot.treeNumber,
      altitude: spot.altitude,
      azimuth: spot.azimuth,
      distance: spot.distance,
      sub_theme_id: spot.subThemeId
    };
    return this.xhrService.post(this.getUrl('createSpot/'), data);
  }

  updateSpot(spot: Spot): any {
    const data = {
      id: +spot.id,
      panorama_id: +spot.panoramaId,
      tree_number: spot.treeNumber,
      altitude: spot.altitude,
      azimuth: spot.azimuth,
      distance: spot.distance,
      sub_theme_id: spot.subThemeId,
      content_id: spot.contentId
    };
    return this.xhrService.post(this.getUrl('updateSpot/'), data);
  }

  deleteSpot(spot: Spot): any {
    const data = {
      id: spot.id,
    };
    return this.xhrService.post(this.getUrl('deleteSpot/'), data);
  }

  createSubTheme(subTheme: SubTheme): any {
    const data = {
      name_fr: subTheme.nameFr,
      name_de: subTheme.nameDe,
      name_en: subTheme.nameEn,
      theme_id: subTheme.themeId,
      super_theme_id: subTheme.superThemeId,
      level: subTheme.level,
      description_content_id: subTheme.descriptionContentId,
      natural_frequency: subTheme.naturalFrequency,
      operated_frequency: subTheme.operatedFrequency,
      replacement_speed: subTheme.replacementSpeed,
      good_to_know_content_id: subTheme.goodToKnowContentId,
      associated_species_content_id: subTheme.associatedSpeciesContentId,
      inventory_level_content_id: subTheme.inventoryLevelContentId,
      other_info_content_id: subTheme.otherInfoContentId,
      icon_url: subTheme.iconUrl,
    };
    return this.xhrService.post(this.getUrl('createSubTheme/'), data);
  }

  updateSubTheme(subTheme: SubTheme): any {
    const data = {
      id: subTheme.id,
      name_fr: subTheme.nameFr,
      name_de: subTheme.nameDe,
      name_en: subTheme.nameEn,
      theme_id: subTheme.themeId,
      super_theme_id: subTheme.superThemeId,
      level: subTheme.level,
      description_content_id: subTheme.descriptionContentId,
      natural_frequency: subTheme.naturalFrequency,
      operated_frequency: subTheme.operatedFrequency,
      replacement_speed: subTheme.replacementSpeed,
      good_to_know_content_id: subTheme.goodToKnowContentId,
      associated_species_content_id: subTheme.associatedSpeciesContentId,
      inventory_level_content_id: subTheme.inventoryLevelContentId,
      other_info_content_id: subTheme.otherInfoContentId,
      icon_url: subTheme.iconUrl,
    };
    return this.xhrService.post(this.getUrl('updateSubTheme/'), data);
  }

  deleteSubTheme(subTheme: SubTheme): any {
    const data = {
      id: subTheme.id,
    };
    return this.xhrService.post(this.getUrl('deleteSubTheme/'), data);
  }

  createContent(content: Content): any {
    const data = {
      text_fr: content.textFr,
      text_de: content.textDe,
      text_en: content.textEn,
    };
    return this.xhrService.post(this.getUrl('createContent/'), data);
  }

  updateContent(content: Content): any {
    const data = {
      id: content.id,
      text_fr: content.textFr,
      text_de: content.textDe,
      text_en: content.textEn,
    };
    return this.xhrService.post(this.getUrl('updateContent/'), data);
  }

  deleteContent(content: Content): any {
    const data = {
      id: content.id,
    };
    return this.xhrService.post(this.getUrl('deleteContent/'), data);
  }

  createMedia(media: Media): any {
    const data = {
      content_id: media.contentId,
      media_type: media.mediaType,
      media_url: media.mediaUrl,
      hyperlink: media.hyperlink,
      text_fr: media.textFr,
      text_de: media.textDe,
      text_en: media.textEn,
    };
    return this.xhrService.post(this.getUrl('createMedia/'), data);
  }

  updateMedia(media: Media): any {
    const data = {
      id: media.id,
      content_id: media.contentId,
      media_type: media.mediaType,
      media_url: media.mediaUrl,
      hyperlink: media.hyperlink,
      text_fr: media.textFr,
      text_de: media.textDe,
      text_en: media.textEn,
    };
    return this.xhrService.post(this.getUrl('updateMedia/'), data);
  }

  deleteMedia(media: Media): any {
    const data = {
      id: media.id
    };
    return this.xhrService.post(this.getUrl('deleteMedia/'), data);
  }

  createQuestion(question: Question): any {
    const data = {
      sub_theme_id: question.subThemeId,
      content_id: question.contentId,
      multiple_choice: question.multipleChoice,
      determination: question.determination,
      explanation_fr: question.explanationFr,
      explanation_de: question.explanationDe,
      explanation_en: question.explanationEn,
    };
    return this.xhrService.post(this.getUrl('createQuestion/'), data);
  }

  updateQuestion(question: Question): any {
    const data = {
      id: question.id,
      sub_theme_id: question.subThemeId,
      content_id: question.contentId,
      multiple_choice: question.multipleChoice ? 1 : 0,
      determination: question.determination ? 1 : 0,
      explanation_fr: question.explanationFr,
      explanation_de: question.explanationDe,
      explanation_en: question.explanationEn,
    };
    return this.xhrService.post(this.getUrl('updateQuestion/'), data);
  }

  deleteQuestion(question: Question): any {
    const data = {
      id: question.id,
    };
    return this.xhrService.post(this.getUrl('deleteQuestion/'), data);
  }

  createResponse(response: Response): any {
    const data = {
      question_id: response.questionId,
      content_id: response.contentId,
      correct: response.correct ? 1 : 0,
    };
    return this.xhrService.post(this.getUrl('createResponse/'), data);
  }

  updateResponse(response: Response): any {
    const data = {
      id: response.id,
      question_id: response.questionId,
      content_id: response.contentId,
      correct: response.correct ? 1 : 0,
    };
    return this.xhrService.post(this.getUrl('updateResponse/'), data);
  }

  deleteResponse(response: Response): any {
    const data = {
      id: response.id,
    };
    return this.xhrService.post(this.getUrl('deleteResponse/'), data);
  }

  getTour(tourId): void {
    const url = this.getUrl(BackendService.TOUR) + '/' + tourId;
    this.xhrService.get(url).subscribe(jsonTourData => this.processTour(jsonTourData));
  }

  private processTour(jsonCommon: any): void {
    try {
      const panoramas = this.parsePanorama(jsonCommon.panoramas);
      const panoramaTrees = this.parsePanoramaTree(jsonCommon.panorama_trees);
      const treeCorrections = this.parseTreeCorrection(jsonCommon.tree_corrections);
      const trees = this.parseTree(jsonCommon.trees);

      this.store.dispatch(new AddPanoramas({panoramas: panoramas}));
      this.store.dispatch(new AddPanoramaTrees({panoramaTrees: panoramaTrees}));
      this.store.dispatch(new AddTreeCorrections({treeCorrections: treeCorrections}));
      this.store.dispatch(new AddTrees({trees: trees}));
    } catch (error) {
      console.error('unable to parse common', error);
    }
  }

  private parsePanorama(jsonPanoramas: any): Panorama[] {
    return jsonPanoramas.map((jsonPanorama): Panorama => ({
      id: jsonPanorama.id,
      tourId: jsonPanorama.tour_id,
      scene: jsonPanorama.scene,
      position: {
        x: +jsonPanorama.camera_x,
        y: -jsonPanorama.camera_y,
      },
      athCorrection: +jsonPanorama.ath_correction,
      cameraDistance: +jsonPanorama.camera_distance,
      k: +jsonPanorama.k,
      label: jsonPanorama.label,
    }));
  }

  private parsePanoramaTree(jsonPanoramaTrees: any): PanoramaTree[] {
    return jsonPanoramaTrees.map((jsonPanoramaTree): PanoramaTree => ({
      id: jsonPanoramaTree.id,
      panoramaId: '' + jsonPanoramaTree.panorama_id,
      socialPositionId: jsonPanoramaTree.tree_social_position_id,
      stemTypeId: jsonPanoramaTree.tree_stem_type_id,
      typeId: jsonPanoramaTree.treetype_id,
      verticalStructureId: jsonPanoramaTree.tree_vertical_structure_id,
      treenumber: +jsonPanoramaTree.treenumber,
      diameter: +jsonPanoramaTree.diameter,
      quality: jsonPanoramaTree.quality,
      ecology: jsonPanoramaTree.ecology,
      ecologyNote: +jsonPanoramaTree.ecology_note,
      volume: +jsonPanoramaTree.volume,
      comment: jsonPanoramaTree.comment,
      orientation: {
        azimuth: AngleHelper.fromGeodesyDegrees(+jsonPanoramaTree.azimuth),
        altitude: AngleHelper.fromDegrees(+jsonPanoramaTree.altitude),
      },
      observation: jsonPanoramaTree.observation,
      distance: jsonPanoramaTree.distance,
    }));
  }

  private parseTree(jsonTrees: any): Tree[] {
    return jsonTrees.map((jsonTree): Tree => ({
      id: jsonTree.tree_id,
      tourId: jsonTree.tour_id,
      socialPositionId: jsonTree.tree_social_position_id,
      stemTypeId: jsonTree.tree_stem_type_id,
      typeId: jsonTree.treetype_id,
      verticalStructureId: jsonTree.vertical_structure_id,
      treenumber: +jsonTree.treenumber,
      diameter: +jsonTree.diameter,
      quality: jsonTree.quality,
      ecology: jsonTree.ecology,
      ecologyNote: +jsonTree.ecology_note,
      volume: +jsonTree.volume,
      comment: jsonTree.comment,
      width: 40,
      position: {
        x: +jsonTree.coordinate_x,
        y: -jsonTree.coordinate_y,
      },
      observation: jsonTree.observation,
      stateId: jsonTree.state ? jsonTree.state : '0',
      reasonIds: jsonTree.reason ? jsonTree.reason.split('|') : [],
    }));
  }

  private parseTreeCorrection(jsonTreeCorrections: any): TreeCorrection[] {
    return jsonTreeCorrections.map((jsonTreeCorrection): TreeCorrection => ({
      id: jsonTreeCorrection.id,
      camera_id: jsonTreeCorrection.camera_id,
      treenumber: jsonTreeCorrection.treenumber,
      ath_correction: jsonTreeCorrection.ath_correction,
      atv_correction: jsonTreeCorrection.atv_correction,
    }));
  }
}
