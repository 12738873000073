import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Side} from '../../store/global/global.model';

@Component({
  selector: 'app-tours-tab',
  templateUrl: './tours-tab.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToursTabComponent {
  public Side = Side;

  constructor() {
  }
}
