import {Orientation, OrientationHelper, OrientationOffset} from '../panorama-tree/panorama-tree.model';
import {Side} from '../global/global.model';
import {Angle, AngleHelper} from '../tour/tour.model';

export interface View extends Orientation {
  zoom: Angle;
}

export interface ViewOffset extends OrientationOffset {
  zoom: Angle;
}

export interface SideView extends View {
  side: Side | null;
}

export namespace ViewHelper {
  export function equals(view1: View, view2: View) {
    return OrientationHelper.equals(view1, view2) && view1.zoom === view2.zoom;
  }

  export function difference(view1: View, view2: View): ViewOffset {
    return {
      ...OrientationHelper.difference(view1, view2),
      zoom: AngleHelper.difference(view1.zoom, view2.zoom)
    };
  }

  export function add(view1: View, offset: ViewOffset): View {
    return {
      ...OrientationHelper.add(view1, offset),
      zoom: AngleHelper.add(view1.zoom, offset.zoom)
    };
  }

  export function subtract(view1: View, offset: ViewOffset): ViewOffset {
    return {
      ...OrientationHelper.subtract(view1, offset),
      zoom: AngleHelper.subtract(view1.zoom, offset.zoom)
    };
  }
}

export interface RealtimePanorama {
  view: SideView;
  id: string;
}
