import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import {PanoramaTree} from './panorama-tree.model';
import {PanoramaTreeActions, PanoramaTreeActionTypes} from './panorama-tree.actions';
import {createSelector} from '@ngrx/store';
import {Side} from '../global/global.model';
import {RuntimePanorama} from '../runtime-panorama/runtime-panorama.model';
import {selectSelectedRuntimePanoramaOfSelectedTour} from '../runtime-panorama/runtime-panorama.reducer';
import {RuntimeTour} from '../runtime-tour/runtime-tour.model';
import {selectSelectedRuntimeTour} from '../runtime-tour/runtime-tour.reducer';

export interface State extends EntityState<PanoramaTree> {
  // additional entities state properties
}

export const adapter: EntityAdapter<PanoramaTree> = createEntityAdapter<PanoramaTree>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: PanoramaTreeActions
): State {
  switch (action.type) {
    case PanoramaTreeActionTypes.AddPanoramaTree: {
      return adapter.addOne(action.payload.panoramaTree, state);
    }

    case PanoramaTreeActionTypes.UpsertPanoramaTree: {
      return adapter.upsertOne(action.payload.panoramaTree, state);
    }

    case PanoramaTreeActionTypes.AddPanoramaTrees: {
      return adapter.addMany(action.payload.panoramaTrees, state);
    }

    case PanoramaTreeActionTypes.UpsertPanoramaTrees: {
      return adapter.upsertMany(action.payload.panoramaTrees, state);
    }

    case PanoramaTreeActionTypes.UpdatePanoramaTree: {
      return adapter.updateOne(action.payload.panoramaTree, state);
    }

    case PanoramaTreeActionTypes.UpdatePanoramaTrees: {
      return adapter.updateMany(action.payload.panoramaTrees, state);
    }

    case PanoramaTreeActionTypes.DeletePanoramaTree: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PanoramaTreeActionTypes.DeletePanoramaTrees: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PanoramaTreeActionTypes.LoadPanoramaTrees: {
      return adapter.addAll(action.payload.panoramaTrees, state);
    }

    case PanoramaTreeActionTypes.ClearPanoramaTrees: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const selectFeature = (state): EntityState<PanoramaTree> => state.root.panoramaTrees;

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectFeature);

export function selectByPanoramaId(panoramaId: string) {
  return createSelector(
    selectAll,
    (panoramaTrees: PanoramaTree[]) => {
      return panoramaTrees.filter(panoramaTree => panoramaTree.panoramaId === panoramaId);
    });
}

export const selectSelectedPanoramaTreeOfSelectedPanoramaOfSelectedTour = createSelector(
  selectEntities,
  selectSelectedRuntimePanoramaOfSelectedTour(Side.Left),
  (panoramaDictionary: Dictionary<PanoramaTree>, runtimePanorama: RuntimePanorama) => {
    if (runtimePanorama) {
      const panoramaTreeId = runtimePanorama.selectedPanoramaTreeId;
      return panoramaTreeId ? panoramaDictionary[panoramaTreeId] : null;
    } else {
      return null;
    }
  });

export function selectPanoramaTreesOfSelectedPanoramaOfSelectedTour(side: Side) {
  return createSelector(
    selectAll,
    selectSelectedRuntimeTour(side),
    (panoramaTrees: PanoramaTree[], runtimeTour: RuntimeTour) => {
      if (runtimeTour) {
        const panoramaId = runtimeTour.panoramaIds[side];
        return panoramaId ? panoramaTrees.filter(panoramaTree => panoramaTree.panoramaId === panoramaId) : [];
      } else {
        return [];
      }
    });
}
