import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {positionToArray, Tour} from '../../store/tour/tour.model';
import {TranslateService} from '@ngx-translate/core';
import {Side} from '../../store/global/global.model';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {AppState} from '../../store/app.reducer';
import {Store} from '@ngrx/store';
import {selectAll, selectSelectedTour} from '../../store/tour/tour.reducer';
import {CallbackService} from '../../services/callback.service';
import {consistentUpdates, Update} from '../../helper/ngrx';
import {User, UserHelper} from '../../store/user/user.model';
import {BackendService} from '../../services/backend.service';
import {selectUser} from '../../store/user/user.reducer';
import {selectTourThematics} from 'src/app/store/tour-thematic/tour-thematic.reducer';
import {TourThematic} from 'src/app/store/tour-thematic/tour-thematic.model';
import {Theme} from 'src/app/store/catalog/theme/theme.model';

declare let L;

@Component({
  selector: 'app-tour-map',
  templateUrl: './tour-map.component.html',
  styleUrls: ['./tour-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourMapComponent implements OnInit {
  @Input() side: Side;
  @Input() selector: boolean;

  @Output() selectTour = new EventEmitter<Tour>();

  @ViewChild('map', { read: false, static: false }) set setMapElementRef(mapElementRef: ElementRef) {
    this.mapElementRef$.next(mapElementRef);
  }

  public mapElementRef$ = new BehaviorSubject(null);

  private map: any = null;
  private viewInitialized = false;
  private tourLayer: any = null;
  private greenIcon: any;
  private redIcon: any;
  private blueIcon: any;
  private activeIcon: any;

  public user$: Observable<User>;
  public user: User;
  public UserHelper = UserHelper;

  public tourThematics: TourThematic[] = [];
  public themes: Theme[];
  public selectedTour: Tour;
  public tours: Tour[];

  public requirePanorama = false;

  constructor(private store: Store<AppState>, private translate: TranslateService, private callback: CallbackService, private backendService: BackendService) {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.loadMarkerIcons();

    this.store.select(selectTourThematics).subscribe(tourThematics => {
      this.tourThematics = tourThematics;
    });

    combineLatest(
      this.mapElementRef$,
      this.store.select(selectAll),
      this.store.select(selectSelectedTour(this.side)),
    ).pipe(consistentUpdates).subscribe(combination => this.update.apply(this, combination));
  }

  private initMap(mapElementRef: ElementRef): boolean {
    if (mapElementRef && !this.map) {
      this.map = L.map(mapElementRef.nativeElement);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

    }

    return this.map != null;
  }

  private loadMarkerIcons(): void {
    this.redIcon = this.loadMarkerIcon('/assets/images/markers/marker-icon-red.png');
    this.greenIcon = this.loadMarkerIcon('/assets/images/markers/marker-icon-green.png');
    this.blueIcon = this.loadMarkerIcon('/assets/images/markers/marker-icon-blue.png');
    this.activeIcon = this.loadMarkerIcon('/assets/leaflet/images/marker-icon-active.png');
  }

  private loadMarkerIcon(path: string): any {
    return L.icon({
      iconUrl: path,
      shadowUrl: '/assets/leaflet/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      popupAnchor: [0, -41],
    });
  }

  private update(mapElementRef: Update<ElementRef>, tours: Update<Tour[]>, selectedTour: Update<Tour>) {
    if (!this.initMap(mapElementRef.value)) {
      return;
    }

    this.map.scrollWheelZoom.disable(); // disable at the beginning because to avoid zooming instead of scrolling into the page

    this.map.on('focus', () => { this.map.scrollWheelZoom.enable(); }); // we need the focus to zoon on the map
    this.map.on('blur', () => { this.map.scrollWheelZoom.disable(); }); // when the map is not focus, zoon is disabled

    if (tours.updated) {
      this.updateTours(tours.value, selectedTour.value);
    }

    if (selectedTour.updated) {
      this.updateSelectedTour(selectedTour.value);
    }
  }

  private updateTours(tours: Tour[], selectedTour: Tour): void {

    this.selectedTour = selectedTour;

    tours.sort((t1, t2) => t2.order - t1.order);
    tours.reverse();
    const tmp = tours;

    tours = tmp.filter(x => {
      if (BackendService.THEMATIC == null) {
        return true;
      } else {
        return this.tourThematics.find(y => +y.tourId === +x.id) != null &&
        this.tourThematics.find(y => +y.tourId == +x.id).themeId == BackendService.THEMATIC;
        }
    });

    this.tours = tours;
    console.log(this.tours);

    if (this.tourLayer) {
      this.map.removeLayer(this.tourLayer);
    }

    const tourMarkers = tours.filter(tour => tour.position && (!this.requirePanorama || tour.hasPanoramas)).map(tour => {
      const marker = L.marker(positionToArray(tour.position))
        .setIcon(this.getMarker(tour, selectedTour));

      this.setMarkerPopup(tour, marker);

      return marker;
    });

    this.tourLayer = L.layerGroup(tourMarkers).addTo(this.map);

    if (!(selectedTour && selectedTour.position) && tourMarkers.length) {
      this.centerTours(tourMarkers);
    }
  }

  private updateSelectedTour(selectedTour: Tour) {
    if (selectedTour && selectedTour.position) {
      this.centerTour(selectedTour);
    }
  }

  private centerTour(selectedTour): void {
    if (!this.viewInitialized) {
      this.map.setZoom(10);
    }

    this.map.setView(positionToArray(selectedTour.position));
    this.viewInitialized = true;
  }

  private centerTours(tourMarkers): void {
    if (!this.viewInitialized) {
      const featureGroup = new L.featureGroup(tourMarkers);
      this.map.fitBounds(featureGroup.getBounds());

      this.viewInitialized = true;
    }
  }

  private getMarker(tour: Tour, selectedTour: Tour): any {
    if (tour === selectedTour) {
      return this.activeIcon;
    } else if (tour.hasPanoramas && tour.treeCount > 0) {
      return this.redIcon;
    } else if (tour.treeCount > 0) {
      return this.blueIcon;
    } else {
      return this.greenIcon;
    }
  }

  private setMarkerPopup(tour: Tour, marker: any) {
    // this is hack for language change detection
    this.translate.stream('dummy').subscribe(() => {
      const callbackInvocation = this.callback.create('map_tour_' + tour.id, () => {
        this.selectTour.emit(tour);
        //this.store.dispatch(new SetSelectedTour({side: this.side, tourId: tour.id}));
      });

      this.renderPopupText(marker, tour, callbackInvocation);
    });
  }

  private renderPopupText(marker: any, tour: Tour, callbackInvocation: string): void {
    combineLatest(
      this.translate.get(tour.title),
      this.translate.get(tour.description),
    ).subscribe(([title, description]) => {
      const text = `
        <strong>${title}</strong>
        <p>${description}</p>
        <p>
          <a onclick="${callbackInvocation}">
            ${title}
          </a>
        </p>
      `;
      marker.bindPopup(text);
    });
  }

  switch(checked: boolean) {

    this.requirePanorama = !checked;

    if (this.tourLayer) {
      this.map.removeLayer(this.tourLayer);
    }

    const tourMarkers = this.tours.filter(tour => tour.position && (!this.requirePanorama || tour.hasPanoramas)).map(tour => {
      const marker = L.marker(positionToArray(tour.position))
        .setIcon(this.getMarker(tour, this.selectedTour));

      this.setMarkerPopup(tour, marker);

      return marker;
    });

    this.tourLayer = L.layerGroup(tourMarkers).addTo(this.map);

    if (!(this.selectedTour && this.selectedTour.position) && tourMarkers.length) {
      this.centerTours(tourMarkers);
    }
  }
}
