import {Action} from '@ngrx/store';
import {Side} from '../global/global.model';

export enum RuntimeTourActionTypes {
  SetSelectedPanorama = '[RuntimeTour] Set Selected Panorama',
  SetSelectedTree = '[RuntimeTour] Set Selected Tree',
  CancelTreeEdit = '[RuntimeTour] Cancel Tree Edit',
  ClearSelectedTree = '[RuntimeTour] Clear Selected Tree',
  SetSelectedSpot = '[RuntimeTour] Set Selected Spot',
  CancelSpotEdit = '[RuntimeTour] Cancel Spot Edit',
  ClearSelectedSpot = '[RuntimeTour] Clear Selected Spot',
}

export class SetSelectedPanorama implements Action {
  readonly type = RuntimeTourActionTypes.SetSelectedPanorama;

  constructor(public payload: { tourId: string, side: Side, panoramaId: string }) {
  }
}

export class SetSelectedTree implements Action {
  readonly type = RuntimeTourActionTypes.SetSelectedTree;

  constructor(public payload: { tourId: string, treeId: string }) {
  }
}

export class CancelTreeEdit implements Action {
  readonly type = RuntimeTourActionTypes.CancelTreeEdit;
}

export class ClearSelectedTree implements Action {
  readonly type = RuntimeTourActionTypes.ClearSelectedTree;
}

export class SetSelectedSpot implements Action {
  readonly type = RuntimeTourActionTypes.SetSelectedSpot;

  constructor(public payload: { tourId: string, spotId: string }) {
  }
}

export class CancelSpotEdit implements Action {
  readonly type = RuntimeTourActionTypes.CancelSpotEdit;
}

export class ClearSelectedSpot implements Action {
  readonly type = RuntimeTourActionTypes.ClearSelectedSpot;
}

export type RuntimeTourActions =
  SetSelectedPanorama |
  SetSelectedTree |
  CancelTreeEdit |
  ClearSelectedTree |
    SetSelectedSpot |
    CancelSpotEdit |
    ClearSelectedSpot;
