import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Reason } from './reason.model';

export enum ReasonActionTypes {
  LoadReasons = '[Reason] Load Reasons',
  AddReason = '[Reason] Add Reason',
  UpsertReason = '[Reason] Upsert Reason',
  AddReasons = '[Reason] Add Reasons',
  UpsertReasons = '[Reason] Upsert Reasons',
  UpdateReason = '[Reason] Update Reason',
  UpdateReasons = '[Reason] Update Reasons',
  DeleteReason = '[Reason] Delete Reason',
  DeleteReasons = '[Reason] Delete Reasons',
  ClearReasons = '[Reason] Clear Reasons'
}

export class LoadReasons implements Action {
  readonly type = ReasonActionTypes.LoadReasons;

  constructor(public payload: { reasons: Reason[] }) {}
}

export class AddReason implements Action {
  readonly type = ReasonActionTypes.AddReason;

  constructor(public payload: { reason: Reason }) {}
}

export class UpsertReason implements Action {
  readonly type = ReasonActionTypes.UpsertReason;

  constructor(public payload: { reason: Reason }) {}
}

export class AddReasons implements Action {
  readonly type = ReasonActionTypes.AddReasons;

  constructor(public payload: { reasons: Reason[] }) {}
}

export class UpsertReasons implements Action {
  readonly type = ReasonActionTypes.UpsertReasons;

  constructor(public payload: { reasons: Reason[] }) {}
}

export class UpdateReason implements Action {
  readonly type = ReasonActionTypes.UpdateReason;

  constructor(public payload: { reason: Update<Reason> }) {}
}

export class UpdateReasons implements Action {
  readonly type = ReasonActionTypes.UpdateReasons;

  constructor(public payload: { reasons: Update<Reason>[] }) {}
}

export class DeleteReason implements Action {
  readonly type = ReasonActionTypes.DeleteReason;

  constructor(public payload: { id: string }) {}
}

export class DeleteReasons implements Action {
  readonly type = ReasonActionTypes.DeleteReasons;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearReasons implements Action {
  readonly type = ReasonActionTypes.ClearReasons;
}

export type ReasonActions =
 LoadReasons
 | AddReason
 | UpsertReason
 | AddReasons
 | UpsertReasons
 | UpdateReason
 | UpdateReasons
 | DeleteReason
 | DeleteReasons
 | ClearReasons;
