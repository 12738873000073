import {Action, createSelector} from '@ngrx/store';
import {User} from './user.model';

export const SET = '[User] SET';

export class UserSetAction implements Action {
  readonly type = SET;

  constructor(public payload: User) {
  }
}

export interface UserState {
  user: User;
}

export const initialState: UserState = {
  user: null,
};

export function userReducer(state: UserState = initialState, action: any): UserState {
  switch (action.type) {
    case SET:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
}

export const selectFeature = (state): UserState => state.root.user;
export const selectUser = createSelector(selectFeature, userState => userState.user);
