import {Component, OnInit} from '@angular/core';
import {Grid, MultimediaContent} from '../../../store/habitat/content-card.actions';
import {Store} from '@ngrx/store';
import {Score} from '../../../store/score/score.model';
import {selectScores} from '../../../store/score/score.reducer';
import {selectUser} from '../../../store/user/user.reducer';
import {User, UserHelper} from '../../../store/user/user.model';
import {selectPanoramasOfSelectedTour, selectSelectedPanoramaOfSelectedTour} from '../../../store/panorama/panorama.reducer';
import {selectSelectedSpotOfSelectedTour, selectSpots} from '../../../store/spot/spot.reducer';
import {Side} from '../../../store/global/global.model';
import {Panorama} from '../../../store/panorama/panorama.model';
import {Spot} from '../../../store/spot/spot.model';
import {Tour} from '../../../store/tour/tour.model';
import {selectSelectedTour} from '../../../store/tour/tour.reducer';
import {selectLearningMode} from '../../../store/habitat/content-card.reducer';
import {LearningMode} from '../../../store/habitat/content-card.model';
import {selectAnswers} from '../../../store/answer/answer.reducer';
import {Answer} from '../../../store/answer/answer.model';
import {selectTreesOfSelectedTour} from '../../../store/tree/tree.reducer';
import {Tree} from '../../../store/tree/tree.model';
import {SetSelectedPanorama, SetSelectedSpot, SetSelectedTree, ClearSelectedSpot} from '../../../store/runtime-tour/runtime-tour.actions';

@Component({
  selector: 'app-score-grid',
  templateUrl: './score-grid.component.html',
  styleUrls: ['./score-grid.component.css']
})
export class ScoreGridComponent implements OnInit {

  public selectedTour: Tour;
  public selectedPanorama: Panorama;
  public selectedSpot: Spot;
  public learningMode: LearningMode;
  public scores: Score[]  = [];
  public answers: Answer[]  = [];
  public panoramas: Panorama[]  = [];
  public spots: Spot[] = [];
  public trees: Tree[] = [];
  public user: User;

  constructor(private store: Store<any>) { }

  ngOnInit() {

    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
    });

    this.store.select(selectSelectedPanoramaOfSelectedTour(Side.Left)).subscribe((selectedPanorama) => {
      this.selectedPanorama = selectedPanorama;
    });

    this.store.select(selectSelectedSpotOfSelectedTour(Side.Left)).subscribe((selectedSpot) => {
      this.selectedSpot = selectedSpot;
    });

    this.store.select(selectTreesOfSelectedTour(Side.Left)).subscribe((trees) => {
      this.trees = trees;
    });

    this.store.select(selectSelectedTour(Side.Left)).subscribe((selectedTour) => {
      this.selectedTour = selectedTour;
    });

    this.store.select(selectLearningMode).subscribe((learningMode) => {
      this.learningMode = learningMode;
    });

    this.store.select(selectPanoramasOfSelectedTour(Side.Left)).subscribe((panoramas) => {
      this.panoramas = panoramas;
    });

    this.store.select(selectSpots).subscribe((spots) => {
      this.spots = spots;
    });

    if (UserHelper.isLoggedIn(this.user)) {

      this.store.select(selectScores).subscribe((scores) => {
        this.scores = scores;
      });

      this.store.select(selectAnswers).subscribe((answers) => {
        this.answers = answers;
      });

    }
  }

  onHabitatClick(spot: Spot) {
    const targetedPanorama = this.panoramas.find(x => x.id == spot.panoramaId)

    // change Panorama
    this.store.dispatch(new SetSelectedPanorama({tourId: this.selectedTour.id, side: Side.Left, panoramaId: targetedPanorama.id}));

    const targetedTree = this.trees.find(x => x.treenumber == spot.treeNumber && x.tourId == this.selectedTour.id);


    const targetedSpot = this.spots.find(x => x.id == spot.id);

    if (this.learningMode === LearningMode.Research) {
      if (this.isSpotResponded(spot) != null) {
        // change Tree
        this.store.dispatch(new SetSelectedTree({tourId: this.selectedTour.id, treeId: targetedTree.id}));
        // change Spot
        console.log('0');
        this.store.dispatch(new SetSelectedSpot({tourId: this.selectedTour.id, spotId: targetedSpot.id}));
        this.store.dispatch(new MultimediaContent());
      } else {
        console.log('1');
        this.store.dispatch(new Grid());
        //this.store.dispatch(new ClearSelectedSpot());
      }
    } else {
      // change Tree
      this.store.dispatch(new SetSelectedTree({tourId: this.selectedTour.id, treeId: targetedTree.id}));
      // change Spot
      this.store.dispatch(new SetSelectedSpot({tourId: this.selectedTour.id, spotId: targetedSpot.id}));
      this.store.dispatch(new MultimediaContent());
    }


  }

  isCurrentSpot(spot: Spot) {
    return this.selectedSpot != null && this.selectedSpot.id == spot.id;
  }

  isCurrentPanorama(panorama: Panorama) {
    return this.selectedPanorama.id == panorama.id;
  }

  getPanoramas() {

    return this.panoramas;
  }

  getSceneNumber(scene: string) {
    return scene.match(/\d/g).toString().replace(',', '').toString();
  }

  getSpots(panorama: Panorama) {

    return this.spots.filter(x => x.panoramaId == panorama.id);
  }

  isSpotResponded(spot: Spot): boolean {
    const score = this.scores.find(x => x.tourId == +this.selectedTour.id && x.spotId == +spot.id && x.userId == this.user.id && x.modeId == this.learningMode);
    if (score != null) {
      if (this.learningMode == LearningMode.Discovery) {
        return this.answers.filter(x => x.scoreId == score.id && x.correct).length > 0;
      } else {
        return this.answers.filter(x => x.scoreId == score.id && x.correct).length == this.answers.filter(x => x.scoreId == score.id).length;
      }
    } else {
      return null;
    }
  }

  getPoints(): number {
    let res = 0;
    this.getPanoramas().forEach( (p) => {
      this.getSpots(p).forEach( (s) => {
        if (this.isSpotResponded(s)) {
          res += 1;
        }
      });
    });
    return res;
  }

  getMaxPoints(): number {
    let res = 0;
    this.getPanoramas().forEach( (p) => {
      this.getSpots(p).forEach( (s) => {
        res += 1;
      });
    });
    return res;
  }

}
